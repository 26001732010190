import { SystemStyleObject } from '@styled-system/css';
import { SxStyleProp } from 'theme-ui';
import { gsap } from 'gsap';
import { getNumber } from '@modules';
import glassesBase64 from '../../../images/icons/jsx/eyeglasses.svg';

const staticLogoPositions = [
  { x: '-17vw', y: '-10vh' },
  { x: '-10vw', y: '-20vh' },
  { x: '3vw', y: '-12vh' },
  { x: '11vw', y: '-16vh' },
  { x: '-5vw', y: '10vh' },
  { x: '11vw', y: '13vh' },
  { x: '20vw', y: '6vh' },
  { x: '-6vw', y: '-20vh' },
  { x: '18vw', y: '-6vh' },
  { x: '-14vw', y: '10vh' },
  { x: '-16vw', y: '0vh' },
  { x: '0vw', y: '5vh' },
];
export const styles = {
  container: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    '&>*': {
      gridArea: '1/1',
    },
  } as SxStyleProp,
  glasses: {
    backgroundImage: `url(${glassesBase64})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'center',
    width: 'min-content',
    margin: 'auto',
    px: '30px',
    columnGap: '30px',
    zIndex: 2,
  },
  view: {
    overflow: 'hidden',
    transform: 'translateZ(0)', // needed for safari to apply overflow
    width: '190px',
    height: '190px',
    border: 'solid 19px',
    borderColor: 'text',
    borderRadius: 'fullSize',
    bg: 'primary',
    display: 'grid',
    alignContent: 'flex-end', // vertical-align
  },

  logo: (index: number) => {
    const { x, y: yVh } = staticLogoPositions[index];

    const randomSize = gsap.utils.random(35, 82, 5);
    const yNumber = getNumber(yVh) * 1.3 - 8;

    const zIndexThresHold = (size: number, threshold= 60) => (size < threshold ? 1 : 2 + Math.ceil((size - threshold) / 10));
    return ({
      transform: [
        `translate(${getNumber(x)*1.2}vh, ${yVh})`,
        `translate(${getNumber(x)*1.2}vh, ${yVh})`,
        `translate(${x}, ${gsap.utils.random(yNumber*0.8, yNumber*1.2)}vh)`,
      ],
      width: `${randomSize}px`,
      height: `${randomSize}px`,
      zIndex: zIndexThresHold(randomSize),

      maxWidth: 'unset',
      objectFit: 'contain',
      borderRadius: 3,
      bg: 'background',
      p: 2,
    // opacity: 0,
    }) as SxStyleProp & {height: SystemStyleObject};
  },

  dimensionList: {
    width: '200%',
    justifyContent: 'center',
    ml: '-50%',
    flexWrap: 'wrap',
  } as SxStyleProp,
  dimension: {
    variant: 'buttons.ctaInvert',
    bg: 'background',
    color: 'text',
    fontWeight: 'normal',
    fontSize: '10px',
    py: 3,
    whiteSpace: 'nowrap',
    flex: '0 0 max-content',
    minHeight: 'unset',
    minWidth: 'unset',
  },
};
