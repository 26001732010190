import { finnoscoreDataStyles } from '../finnoscore-data/index.styles';

export const styles = {
  ...finnoscoreDataStyles,
  content: {
    ...finnoscoreDataStyles.content,
    mt: [0, 3, 0, 0],
    gridTemplateRows: [
      'repeat(4, auto) 80px auto auto !important',
      'repeat(5, auto) !important',
      finnoscoreDataStyles.content.gridTemplateRows[2],
      '80px repeat(4, auto) !important',
    ],
    gridTemplateAreas: [
      '"chartType" "control" "content" "contact" "report" "press"',
      ...finnoscoreDataStyles.content.gridTemplateAreas.slice(1),
    ],
  },

  chart: {
    width: '100%',
    height: 'auto !important',
    justifyContent: 'flex-start',
    '&>*': {
      width: '100%',
    },
  },
};
