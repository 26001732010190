import { SxStyleProp } from 'theme-ui';

export const styles = {
  banner: {
    bg: 'background',
    boxShadow: 'rgba(0, 0, 0, 0.20) 0px -1px 6px',
    p: 3,
    mt: 3,
    borderBottom: 'solid 8px',
    borderBottomColor: 'primary',
    justifyContent: 'space-between',
    position: 'fixed',
    width: 'fullSize',
    left: 0,
    zIndex: 'popup',
  } as SxStyleProp,
  buttons: {
    variant: 'flex.center',
    flex: '0 0 auto',
  },
  button: {
    minWidth: 'unset',
    fontSize: ['xxxs', 'xxxs', 'xxs'],
    mb: 0,
    mr: [2, 3],
    p: [2, 3],
    px: [4, 4],
    minHeight: ['unset', 'auto'],
    flex: '0 0 auto',
  },
  cta: {
  },
  info: {
    border: ['thin', 'none'],
    borderColor: 'primary',
    borderRadius: 4,
    px: 0,
    fontSize: ['0', 'xxxs', 'xxs'],
    width: ['icon', 'auto'],
    height: ['icon', 'auto'],
    small: {
      variant: 'flex.center',
      width: 'fullSize',
      fontSize: ['xxxs', '0'],
    },
  },

  text: {
    fontSize: ['xxxs', 'xxxs', 'xxs'],
    py: 3,
  },
};
