import React from 'react';
import { ChildrenProps } from '@types';

interface Text {
  key: string;
  value: string;
}

export interface Action {
  type: string
}

export enum ActionTypes {
  TEXT_EDITED = 'TEXT_EDITED',
  TEXT_PERSIST = 'TEXT_PERSIST',
}

interface TextConfig {
  [key: string]: string;
}

export interface UpdateTextAction extends Action {
  payload: Text;
}

export interface CancelTextAction extends Action {
  payload: string;
}

export interface PersistTextsAction extends Action {
}

export type AllActions = UpdateTextAction | PersistTextsAction | CancelTextAction; // TODO: extend | with other actions

// The Type Guard Functions
// function isUpdateTextAction(action: Action): action is UpdateTextAction {
//   return action.type === 'TEXT_EDITED'
// }

const reducer = (state: StateType, action: AllActions) => {
  switch (action.type) {
    case 'TEXT_EDITED':
    // eslint-disable-next-line no-case-declarations
      const text = (action as UpdateTextAction).payload;
      return {
        ...state,
        texts: { ...state.texts, [text.key]: text.value },
      };
    case 'TEXT_CANCEL':
    /* eslint-disable no-case-declarations */
      const key = (action as CancelTextAction).payload;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: remove, ...texts } = state.texts;
      /* eslint-enable no-case-declarations */
      return {
        ...state,
        texts,
      };
    case 'ALL_CANCEL':
      return {
        ...state,
        texts: {},
      };
    case 'TEXT_PERSIST':
      return {
        ...state,
        texts: {},
      };
    default:
      return state;
  }
};

interface StateType {
  texts: TextConfig;
  updateText?: (m: Text) => void;
  cancelText?: (key: string) => void;
  revertAll?: () => void;
  persistAll?: () => void;
}
const initialState: StateType = {
  texts: {
  },
};

export const EditableContext = React.createContext(initialState);

export const EditableProvider = ({ children }: ChildrenProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  function updateText(text: Text) {
    dispatch({
      type: 'TEXT_EDITED',
      payload: text,
    });
  }

  function cancelText(key: string) {
    dispatch({
      type: 'TEXT_CANCEL',
      payload: key,
    });
  }

  function revertAll() {
    dispatch({
      type: 'ALL_CANCEL',
    });
  }

  function persistAll() {
    console.log('TODO: POST REQUEST! ', state.texts);
    // dispatch({
    //   type: 'ALL_CANCEL',
    // });
  }

  return (
    <EditableContext.Provider
      value={{
        texts: state.texts,
        updateText,
        cancelText,
        revertAll,
        persistAll,
      }}
    >
      {children}
    </EditableContext.Provider>
  );
};
