import { DefaultChartDataBase } from '@types';

export const DefaultChartData:DefaultChartDataBase = {
  sample: {
    id: -2,
    name: 'Sample Bank',
    rank: -2,
    total: 0.0,
    logo: {
      url: 'https://freeiconshop.com/wp-content/uploads/edd/bank-flat.png',
    },
    fsType: 'bank',
    country: {
      countryCode: 'PL',
      flag: {
        url: 'https://media.finnoconsult.at/finno-cms-staging/PL_775adcc96b.jpg',
      },
    },
    dimensions: [
      {
        key: 'Webseite',
        value: '5',
      },
      {
        key: 'Onlinemarketing',
        value: '5',
      },
      {
        key: 'Attraktivität für potenzielle Kunden',
        value: '5',
      },
      {
        key: 'Online-Verkauf',
        value: '5',
      },
      {
        key: 'Online-Onboarding',
        value: '5',
      },
      {
        key: 'Preis-Transparenz',
        value: '5',
      },
      {
        key: 'Omnichannel-Kommunikation',
        value: '5',
      },
      {
        key: 'Social Media & Community',
        value: '5',
      },
      {
        key: 'Loyalty & Ökosystem',
        value: '5',
      },
      {
        key: 'Mobile Apps',
        value: '5',
      },
      {
        key: 'Online Banking',
        value: '5',
      },
      {
        key: 'Innovation und Nachhaltigkeitsagenda',
        value: '5',
      },
    ],
  },
  sampleRelative: {
    id: -1,
    name: 'Sample Bank',
    rank: -1,
    total: 7.39,
    logo: {
      url: 'https://freeiconshop.com/wp-content/uploads/edd/bank-flat.png',
    },
    // image: {
    //   url: 'https://freeiconshop.com/wp-content/uploads/edd/bank-flat.png',
    // },
    // text: 'PKO Bank Polski',
    fsType: 'bank',
    country: {
      countryCode: 'PL',
      flag: {
        url: 'https://media.finnoconsult.at/finno-cms-staging/PL_775adcc96b.jpg',
      },
    },
    dimensions: [
      {
        key: 'Webseite',
        value: '6.65',
      },
      {
        key: 'Onlinemarketing',
        value: '4.20',
      },
      {
        key: 'Attraktivität für potenzielle Kunden',
        value: '5.80',
      },
      {
        key: 'Online-Verkauf',
        value: '5.05',
      },
      {
        key: 'Online-Onboarding',
        value: '9.60',
      },
      {
        key: 'Preis-Transparenz',
        value: '3.70',
      },
      {
        key: 'Omnichannel-Kommunikation',
        value: '8.40',
      },
      {
        key: 'Social Media & Community',
        value: '9.20',
      },
      {
        key: 'Loyalty & Ökosystem',
        value: '5.40',
      },
      {
        key: 'Mobile Apps',
        value: '9.00',
      },
      {
        key: 'Online Banking',
        value: '9.20',
      },
      {
        key: 'Innovation und Nachhaltigkeitsagenda',
        value: '9.00',
      },
    ],
  },
  international: {
    banks: [
      {
        id: 1,
        name: 'PKO Bank Polski',
        rank: 1,
        total: 7.39,
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/PL_PKO_BP_af7b8e8808.png',
          alternativeText: 'PL-PKO-BP',
        },
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/PL_PKO_BP_af7b8e8808.png',
        //   alternativeText: 'PL-PKO-BP',
        // },
        country: {
          countryCode: 'PL',
        },
        // text: 'PKO Bank Polski',
        dimensions: [
          {
            key: 'Webseite',
            value: '6.65',
          },
          {
            key: 'Onlinemarketing',
            value: '4.20',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '5.80',
          },
          {
            key: 'Online-Verkauf',
            value: '5.05',
          },
          {
            key: 'Online-Onboarding',
            value: '9.60',
          },
          {
            key: 'Preis-Transparenz',
            value: '3.70',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '8.40',
          },
          {
            key: 'Social Media & Community',
            value: '9.20',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '5.40',
          },
          {
            key: 'Mobile Apps',
            value: '9.00',
          },
          {
            key: 'Online Banking',
            value: '9.20',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '9.00',
          },
        ],
      },
      {
        id: 2,
        name: 'Slovenská sporiteľňa',
        rank: 2,
        total: 7.12,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/AT_Erste_Bank_05b72f12c8.png',
        //   alternativeText: 'AT-Erste-Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/AT_Erste_Bank_05b72f12c8.png',
          alternativeText: 'AT-Erste-Bank',
        },
        country: {
          countryCode: 'SK',
        },
        // text: 'Slovenská sporiteľňa',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.67',
          },
          {
            key: 'Onlinemarketing',
            value: '6.75',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '7.20',
          },
          {
            key: 'Online-Verkauf',
            value: '5.70',
          },
          {
            key: 'Online-Onboarding',
            value: '9.10',
          },
          {
            key: 'Preis-Transparenz',
            value: '4.75',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.70',
          },
          {
            key: 'Social Media & Community',
            value: '7.60',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '5.70',
          },
          {
            key: 'Mobile Apps',
            value: '8.75',
          },
          {
            key: 'Online Banking',
            value: '6.40',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '8.00',
          },
        ],
      },
      {
        id: 3,
        name: 'Starling Bank',
        rank: 3,
        total: 7.08,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/UK_Starling_2ce0bf598d.png',
        //   alternativeText: 'UK-Starling',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/UK_Starling_2ce0bf598d.png',
          alternativeText: 'UK-Starling',
        },
        country: {
          countryCode: 'UK',
        },
        // text: 'Starling Bank',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.95',
          },
          {
            key: 'Onlinemarketing',
            value: '4.10',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '8.40',
          },
          {
            key: 'Online-Verkauf',
            value: '5.80',
          },
          {
            key: 'Online-Onboarding',
            value: '9.60',
          },
          {
            key: 'Preis-Transparenz',
            value: '5.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.75',
          },
          {
            key: 'Social Media & Community',
            value: '8.20',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '0.00',
          },
          {
            key: 'Mobile Apps',
            value: '9.20',
          },
          {
            key: 'Online Banking',
            value: '7.40',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '9.00',
          },
        ],
      },
      {
        id: 4,
        name: 'mBank',
        rank: 4,
        total: 7.04,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/PL_m_Bank_7a60d7e8eb.png',
        //   alternativeText: 'PL-mBank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/PL_m_Bank_7a60d7e8eb.png',
          alternativeText: 'PL-mBank',
        },
        country: {
          countryCode: 'PL',
        },
        // text: 'mBank',
        dimensions: [
          {
            key: 'Webseite',
            value: '6.42',
          },
          {
            key: 'Onlinemarketing',
            value: '4.45',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '8.30',
          },
          {
            key: 'Online-Verkauf',
            value: '6.25',
          },
          {
            key: 'Online-Onboarding',
            value: '9.05',
          },
          {
            key: 'Preis-Transparenz',
            value: '5.05',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '5.03',
          },
          {
            key: 'Social Media & Community',
            value: '7.80',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '5.90',
          },
          {
            key: 'Mobile Apps',
            value: '8.70',
          },
          {
            key: 'Online Banking',
            value: '6.40',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '9.00',
          },
        ],
      },
      {
        id: 5,
        name: 'US Bank',
        rank: 5,
        total: 6.99,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/US_US_Bancorp_d15c661e9a.png',
        //   alternativeText: 'US Bancorp',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/US_US_Bancorp_d15c661e9a.png',
          alternativeText: 'US Bancorp',
        },
        country: {
          countryCode: 'US',
        },
        // text: 'US Bank',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.75',
          },
          {
            key: 'Onlinemarketing',
            value: '6.90',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '6.80',
          },
          {
            key: 'Online-Verkauf',
            value: '7.00',
          },
          {
            key: 'Online-Onboarding',
            value: '8.40',
          },
          {
            key: 'Preis-Transparenz',
            value: '5.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '5.20',
          },
          {
            key: 'Social Media & Community',
            value: '7.20',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '7.40',
          },
          {
            key: 'Mobile Apps',
            value: '8.30',
          },
          {
            key: 'Online Banking',
            value: '5.60',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '6.50',
          },
        ],
      },
      {
        id: 6,
        name: 'Banca Transilvania',
        rank: 6,
        total: 6.98,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/RO_Banca_Transilvania_b881a5f24e.png',
        //   alternativeText: 'RO Banca Transilvania',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/RO_Banca_Transilvania_b881a5f24e.png',
          alternativeText: 'RO Banca Transilvania',
        },
        country: {
          countryCode: 'RO',
        },
        // text: 'Banca Transilvania',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.95',
          },
          {
            key: 'Onlinemarketing',
            value: '4.05',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '8.20',
          },
          {
            key: 'Online-Verkauf',
            value: '4.05',
          },
          {
            key: 'Online-Onboarding',
            value: '10.00',
          },
          {
            key: 'Preis-Transparenz',
            value: '4.50',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.00',
          },
          {
            key: 'Social Media & Community',
            value: '7.20',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '9.40',
          },
          {
            key: 'Mobile Apps',
            value: '7.20',
          },
          {
            key: 'Online Banking',
            value: '7.00',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '8.50',
          },
        ],
      },
      {
        id: 7,
        name: 'N26',
        rank: 7,
        total: 6.88,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/DE_N26_a0378bff6e.png',
        //   alternativeText: 'DE-N26 Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/DE_N26_a0378bff6e.png',
          alternativeText: 'DE-N26 Bank',
        },
        country: {
          countryCode: 'DE',
        },
        // text: 'N26',
        dimensions: [
          {
            key: 'Webseite',
            value: '8.40',
          },
          {
            key: 'Onlinemarketing',
            value: '4.95',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '8.20',
          },
          {
            key: 'Online-Verkauf',
            value: '4.35',
          },
          {
            key: 'Online-Onboarding',
            value: '9.60',
          },
          {
            key: 'Preis-Transparenz',
            value: '5.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '2.05',
          },
          {
            key: 'Social Media & Community',
            value: '7.60',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '4.60',
          },
          {
            key: 'Mobile Apps',
            value: '8.10',
          },
          {
            key: 'Online Banking',
            value: '5.80',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '10.00',
          },
        ],
      },
      {
        id: 8,
        name: 'Capital One',
        rank: 8,
        total: 6.83,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/US_Capital_One_ded6552a80.png',
        //   alternativeText: 'US Capital One',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/US_Capital_One_ded6552a80.png',
          alternativeText: 'US Capital One',
        },
        country: {
          countryCode: 'US',
        },
        // text: 'Capital One',
        dimensions: [
          {
            key: 'Webseite',
            value: '9.20',
          },
          {
            key: 'Onlinemarketing',
            value: '5.60',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '9.00',
          },
          {
            key: 'Online-Verkauf',
            value: '6.95',
          },
          {
            key: 'Online-Onboarding',
            value: '8.10',
          },
          {
            key: 'Preis-Transparenz',
            value: '3.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '2.20',
          },
          {
            key: 'Social Media & Community',
            value: '6.60',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '5.60',
          },
          {
            key: 'Mobile Apps',
            value: '8.30',
          },
          {
            key: 'Online Banking',
            value: '5.00',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '8.50',
          },
        ],
      },
      {
        id: 9,
        name: 'ING',
        rank: 9,
        total: 6.78,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
        //   alternativeText: 'NL-ING-Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
          alternativeText: 'NL-ING-Bank',
        },
        country: {
          countryCode: 'NL',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.85',
          },
          {
            key: 'Onlinemarketing',
            value: '7.30',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '3.40',
          },
          {
            key: 'Online-Verkauf',
            value: '5.70',
          },
          {
            key: 'Online-Onboarding',
            value: '9.20',
          },
          {
            key: 'Preis-Transparenz',
            value: '4.30',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.45',
          },
          {
            key: 'Social Media & Community',
            value: '5.40',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '7.60',
          },
          {
            key: 'Mobile Apps',
            value: '8.70',
          },
          {
            key: 'Online Banking',
            value: '7.20',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '6.00',
          },
        ],
      },
      {
        id: 10,
        name: 'N26',
        rank: 10,
        total: 6.75,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/DE_N26_a0378bff6e.png',
        //   alternativeText: 'DE-N26 Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/DE_N26_a0378bff6e.png',
          alternativeText: 'DE-N26 Bank',
        },
        country: {
          countryCode: 'FR',
        },
        // text: 'N26',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.97',
          },
          {
            key: 'Onlinemarketing',
            value: '6.70',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '7.80',
          },
          {
            key: 'Online-Verkauf',
            value: '4.27',
          },
          {
            key: 'Online-Onboarding',
            value: '9.60',
          },
          {
            key: 'Preis-Transparenz',
            value: '3.50',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '2.78',
          },
          {
            key: 'Social Media & Community',
            value: '7.33',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '4.80',
          },
          {
            key: 'Mobile Apps',
            value: '7.50',
          },
          {
            key: 'Online Banking',
            value: '5.20',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '10.00',
          },
        ],
      },
    ],
    similars: [
      {
        id: -1,
        name: 'ING',
        rank: 9,
        total: 6.78,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
        //   alternativeText: 'NL-ING-Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
          alternativeText: 'NL-ING-Bank',
        },
        country: {
          countryCode: 'NL',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.85',
          },
          {
            key: 'Onlinemarketing',
            value: '7.30',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '3.40',
          },
          {
            key: 'Online-Verkauf',
            value: '5.70',
          },
          {
            key: 'Online-Onboarding',
            value: '9.20',
          },
          {
            key: 'Preis-Transparenz',
            value: '4.30',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.45',
          },
          {
            key: 'Social Media & Community',
            value: '5.40',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '7.60',
          },
          {
            key: 'Mobile Apps',
            value: '8.70',
          },
          {
            key: 'Online Banking',
            value: '7.20',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '6.00',
          },
        ],
      },
      {
        id: -1,
        name: 'ING',
        rank: 14,
        total: 6.59,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
        //   alternativeText: 'NL-ING-Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
          alternativeText: 'NL-ING-Bank',
        },
        country: {
          countryCode: 'PL',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.40',
          },
          {
            key: 'Onlinemarketing',
            value: '7.45',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '7.80',
          },
          {
            key: 'Online-Verkauf',
            value: '5.40',
          },
          {
            key: 'Online-Onboarding',
            value: '9.40',
          },
          {
            key: 'Preis-Transparenz',
            value: '5.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '6.20',
          },
          {
            key: 'Social Media & Community',
            value: '5.40',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '4.00',
          },
          {
            key: 'Mobile Apps',
            value: '8.10',
          },
          {
            key: 'Online Banking',
            value: '3.60',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '6.50',
          },
        ],
      },
      {
        id: -1,
        name: 'ING',
        rank: 18,
        total: 6.5,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
        //   alternativeText: 'NL-ING-Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
          alternativeText: 'NL-ING-Bank',
        },
        country: {
          countryCode: 'RO',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '8.25',
          },
          {
            key: 'Onlinemarketing',
            value: '5.10',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '9.60',
          },
          {
            key: 'Online-Verkauf',
            value: '4.90',
          },
          {
            key: 'Online-Onboarding',
            value: '10.00',
          },
          {
            key: 'Preis-Transparenz',
            value: '3.60',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.85',
          },
          {
            key: 'Social Media & Community',
            value: '7.40',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '4.80',
          },
          {
            key: 'Mobile Apps',
            value: '7.10',
          },
          {
            key: 'Online Banking',
            value: '3.60',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '7.50',
          },
        ],
      },
      {
        id: -1,
        name: 'ING',
        rank: 71,
        total: 5.99,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/AT_ING_Di_Ba_95bbd42cb3.png',
        //   alternativeText: 'AT-ING-DiBa',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/AT_ING_Di_Ba_95bbd42cb3.png',
          alternativeText: 'AT-ING-DiBa',
        },
        country: {
          countryCode: 'AT',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '8.03',
          },
          {
            key: 'Onlinemarketing',
            value: '3.25',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '8.10',
          },
          {
            key: 'Online-Verkauf',
            value: '5.85',
          },
          {
            key: 'Online-Onboarding',
            value: '8.00',
          },
          {
            key: 'Preis-Transparenz',
            value: '3.50',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '2.67',
          },
          {
            key: 'Social Media & Community',
            value: '6.50',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '0.00',
          },
          {
            key: 'Mobile Apps',
            value: '7.30',
          },
          {
            key: 'Online Banking',
            value: '5.90',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '7.00',
          },
        ],
      },
      {
        id: -1,
        name: 'ING',
        rank: 74,
        total: 5.96,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/DE_ING_Di_Ba_6551a411d8.png',
        //   alternativeText: 'DE-ING-DiBa',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/DE_ING_Di_Ba_6551a411d8.png',
          alternativeText: 'DE-ING-DiBa',
        },
        country: {
          countryCode: 'DE',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '7.93',
          },
          {
            key: 'Onlinemarketing',
            value: '5.90',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '7.90',
          },
          {
            key: 'Online-Verkauf',
            value: '4.63',
          },
          {
            key: 'Online-Onboarding',
            value: '9.10',
          },
          {
            key: 'Preis-Transparenz',
            value: '3.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '3.00',
          },
          {
            key: 'Social Media & Community',
            value: '5.00',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '0.00',
          },
          {
            key: 'Mobile Apps',
            value: '8.10',
          },
          {
            key: 'Online Banking',
            value: '3.80',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '6.25',
          },
        ],
      },
      {
        id: -1,
        name: 'ING',
        rank: 119,
        total: 5.44,
        fsType: 'bank',
        // image: {
        //   url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
        //   alternativeText: 'NL-ING-Bank',
        // },
        logo: {
          url: 'https://media.finnoconsult.at/finno-cms-staging/NL_ING_Bank_f9d5bbb13b.png',
          alternativeText: 'NL-ING-Bank',
        },
        country: {
          countryCode: 'BE',
        },
        // text: 'ING',
        dimensions: [
          {
            key: 'Webseite',
            value: '8.30',
          },
          {
            key: 'Onlinemarketing',
            value: '5.00',
          },
          {
            key: 'Attraktivität für potenzielle Kunden',
            value: '6.40',
          },
          {
            key: 'Online-Verkauf',
            value: '6.30',
          },
          {
            key: 'Online-Onboarding',
            value: '7.80',
          },
          {
            key: 'Preis-Transparenz',
            value: '5.00',
          },
          {
            key: 'Omnichannel-Kommunikation',
            value: '4.67',
          },
          {
            key: 'Social Media & Community',
            value: '5.60',
          },
          {
            key: 'Loyalty & Ökosystem',
            value: '0.00',
          },
          {
            key: 'Mobile Apps',
            value: '4.90',
          },
          {
            key: 'Online Banking',
            value: '4.60',
          },
          {
            key: 'Innovation und Nachhaltigkeitsagenda',
            value: '3.75',
          },
        ],
      },
    ],
  },
  multi: {
    worldwide:
    {
      id: -1,
      name: 'PKO Bank Polski',
      rank: 1,
      total: 7.4,
      fsType: 'bank',
      country: {
        countryCode: 'EU',
      },
      // image: {
      //   url: 'https://media.finnoconsult.at/finno-cms-staging/PL_PKO_BP_af7b8e8808.png',
      //   alternativeText: '',
      // },
      logo: {
        url: 'https://media.finnoconsult.at/finno-cms-staging/PL_PKO_BP_af7b8e8808.png',
        alternativeText: '',
      },
      // text: 'PKO Bank Polski',
      dimensions: [
        {
          key: 'Webseite',
          value: '6.50',
        },
        {
          key: 'Onlinemarketing',
          value: '7.02',
        },
        {
          key: 'Attraktivität für potenzielle Kunden',
          value: '7.00',
        },
        {
          key: 'Online-Verkauf',
          value: '5.95',
        },
        {
          key: 'Online-Onboarding',
          value: '7.80',
        },
        {
          key: 'Preis-Transparenz',
          value: '5.30',
        },
        {
          key: 'Omnichannel-Kommunikation',
          value: '7.02',
        },
        {
          key: 'Social Media & Community',
          value: '6.00',
        },
        {
          key: 'Loyalty & Ökosystem',
          value: '2.70',
        },
        {
          key: 'Mobile Apps',
          value: '10.00',
        },
        {
          key: 'Online Banking',
          value: '9.20',
        },
        {
          key: 'Innovation und Nachhaltigkeitsagenda',
          value: '6.75',
        },
      ],
    },
    current: {
      id: -1,
      name: 'Bank Cler',
      rank: 33,
      total: 6.6,
      fsType: 'bank',
      country: {
        countryCode: 'EU',
      },

      // image: {
      //   url: 'https://media.finnoconsult.at/finno-cms-staging/CH_Cler_b8183ea8f1.png',
      //   alternativeText: '',
      // },
      logo: {
        url: 'https://media.finnoconsult.at/finno-cms-staging/CH_Cler_b8183ea8f1.png',
        alternativeText: '',
      },
      // text: 'Bank Cler',
      dimensions: [
        {
          key: 'Webseite',
          value: '7.47',
        },
        {
          key: 'Onlinemarketing',
          value: '2.45',
        },
        {
          key: 'Attraktivität für potenzielle Kunden',
          value: '8.80',
        },
        {
          key: 'Online-Verkauf',
          value: '6.20',
        },
        {
          key: 'Online-Onboarding',
          value: '8.77',
        },
        {
          key: 'Preis-Transparenz',
          value: '4.70',
        },
        {
          key: 'Omnichannel-Kommunikation',
          value: '5.82',
        },
        {
          key: 'Social Media & Community',
          value: '6.06',
        },
        {
          key: 'Loyalty & Ökosystem',
          value: '5.57',
        },
        {
          key: 'Mobile Apps',
          value: '8.40',
        },
        {
          key: 'Online Banking',
          value: '5.20',
        },
        {
          key: 'Innovation und Nachhaltigkeitsagenda',
          value: '7.92',
        },
      ],
    },
    relative: {
      id: -1,
      name: 'Bank Cler',
      rank: 61,
      total: 6.13,
      fsType: 'bank',
      country: {
        countryCode: 'EU',
      },

      // image: {
      //   url: 'https://media.finnoconsult.at/finno-cms-staging/CH_Cler_b8183ea8f1.png',
      //   alternativeText: '',
      // },
      logo: {
        url: 'https://media.finnoconsult.at/finno-cms-staging/CH_Cler_b8183ea8f1.png',
        alternativeText: '',
      },
      // text: 'Bank Cler',
      dimensions: [
        {
          key: 'Webseite',
          value: '7.83',
        },
        {
          key: 'Onlinemarketing',
          value: '4.45',
        },
        {
          key: 'Attraktivität für potenzielle Kunden',
          value: '6.20',
        },
        {
          key: 'Online-Verkauf',
          value: '4.52',
        },
        {
          key: 'Online-Onboarding',
          value: '8.65',
        },
        {
          key: 'Preis-Transparenz',
          value: '4.25',
        },
        {
          key: 'Omnichannel-Kommunikation',
          value: '5.97',
        },
        {
          key: 'Social Media & Community',
          value: '6.10',
        },
        {
          key: 'Loyalty & Ökosystem',
          value: '0.40',
        },
        {
          key: 'Mobile Apps',
          value: '7.35',
        },
        {
          key: 'Online Banking',
          value: '5.60',
        },
        {
          key: 'Innovation und Nachhaltigkeitsagenda',
          value: '8.25',
        },
      ],
    },
    national: {
      id: 9049,
      name: 'Credit Suisse',
      rank: 21,
      total: 6.77,
      fsType: 'bank',
      country: {
        countryCode: 'EU',
      },

      // image: {
      //   url: 'https://media.finnoconsult.at/finno-cms-staging/CH_Credit_Suisse_d444175d43.png',
      //   alternativeText: '',
      // },
      logo: {
        url: 'https://media.finnoconsult.at/finno-cms-staging/CH_Credit_Suisse_d444175d43.png',
        alternativeText: '',
      },
      // text: 'Credit Suisse',
      dimensions: [
        {
          key: 'Webseite',
          value: '6.35',
        },
        {
          key: 'Onlinemarketing',
          value: '3.65',
        },
        {
          key: 'Attraktivität für potenzielle Kunden',
          value: '9.60',
        },
        {
          key: 'Online-Verkauf',
          value: '6.40',
        },
        {
          key: 'Online-Onboarding',
          value: '7.00',
        },
        {
          key: 'Preis-Transparenz',
          value: '4.00',
        },
        {
          key: 'Omnichannel-Kommunikation',
          value: '2.47',
        },
        {
          key: 'Social Media & Community',
          value: '5.65',
        },
        {
          key: 'Loyalty & Ökosystem',
          value: '5.00',
        },
        {
          key: 'Mobile Apps',
          value: '8.93',
        },
        {
          key: 'Online Banking',
          value: '8.73',
        },
        {
          key: 'Innovation und Nachhaltigkeitsagenda',
          value: '5.58',
        },
      ],
    },
  },
};
