import React from 'react';
import { useLocation } from '@reach/router';
import { SectionWithContent } from '@core';
import { Box, CTAButton, Grid } from '@themed';
import { useUpdatedGlobalDimensionsValue } from 'src/store/DimensionContext';
import { GlobalUpdatedInstitutionStackType, useGlobalUpdatedInstitutionStack } from 'src/store/InstitutionState';
import { getStrapiRoutes, useLocale } from '@modules';
import { UpdatedInstitution } from '@types';
import { BubbleInstitutionComparisonChart, ChartItem, ExportButton } from '../components';
import { ComparisonHeader } from '../../finnoscore-comparison/ComparisonSelector';
import { finnoChartStyles as gridStyles } from '../components/index.styles';
import { DefaultChartData } from '../helpers';
import { ClosableColorPicker, useColorAtIndex } from '../../colorpicker';
import { useTypeDropDownControl } from '../components/control/useTypeDropDownControl';
import { usePeriodDropDownControl } from '../components/control/usePeriodDropDownControl';
import { useInstitutionDropDownControl } from '../components/control/useInstitutionDropDownControl';
import { ChartDropDownHeader } from '../ChartDropDownControl';
import { ChartDropDownControlComponent } from '../components/control/ChartDropDownControl';
import { InstitutionDropDownWithState } from '../../finnoscore-comparison/InstitutionGroupedDropDown';
import { Monochrome } from '../components/BasicComponents/Monochrome';
import { useOptionalCountryOrInstitutionDropdown } from '../hooks/CountryOrInstitutionDropdown';
import { fillColorStyles } from '../components/BasicComponents';
import { ChartWizardStep } from './ChartWizardStep';
import { styles } from './HistoryChart.styles';
import { UpdateStackPreviewer } from './UpdateStackPreviewer';
import { UpdateStackResetter } from './UpdateStackResetter';

export const ComparisonChart = () => {
  const locale = useLocale();
  const location = useLocation();
  const [colorB, setColorB] = useColorAtIndex(1);
  const exportChartRef = React.useRef(null);
  const globalDimensions = useUpdatedGlobalDimensionsValue();
  const [updateStack, setUpdateStack] = useGlobalUpdatedInstitutionStack();
  const [isUpdatePreview, setIsUpdatePreview] = React.useState(false);
  const [UpdateStackResetComponentState, setUpdateStackResetComponentState] = React.useState<JSX.Element | null>(null);
  const [currentlyEditedInstitution, setCurrentlyEditedInstitution] = React.useState<UpdatedInstitution | null>(null);
  const { types, setTypeId, typeId, type: fsType, isLoading: isTypeLoading } = useTypeDropDownControl();
  const { periods, period, periodId, setPeriodId, isLoading: isPeriodLoading } = usePeriodDropDownControl(fsType);

  const { institutions: currentInstitutions,
    institution: currentInstitution,
    institutionSetHandler: currentInstitutionSetHandler,
    isLoading: isInstitutionLoading, getInstitutions } = useInstitutionDropDownControl(fsType, period);

  const { getCountryOrInstitution, relativeInstitution, CountryOrInstitutionDropDown } = useOptionalCountryOrInstitutionDropdown({ fsType, period, currentInstitution });

  const handleUpdateComplete = () => getInstitutions();

  React.useEffect(() => {
    setCurrentlyEditedInstitution(null);
  }, [currentInstitution]);

  const currentEditedOrSelectedInstitution = currentlyEditedInstitution || currentInstitution;

  const saveHandler = async () => {
    if (period && currentInstitution && globalDimensions) {
      const existingIndex = updateStack.map(gui => gui.name).indexOf(updateStack.find(i => i.name===currentInstitution.name)?.name || '-1');
      const newValue = { ...currentInstitution, dimensions: globalDimensions, fs_period: periodId };

      setUpdateStack(existingIndex !== -1
        ? [...updateStack.slice(0, existingIndex), newValue, ...updateStack.slice(existingIndex+1)]
        : [...updateStack, newValue]);
    }
  };

  const updateStackResetHandler = (value: string, setter: (props: any) => void, hasChangedCallback: (updateStack: GlobalUpdatedInstitutionStackType) => boolean) => {
    if (updateStack.length > 0 && hasChangedCallback(updateStack)) {
      setUpdateStackResetComponentState(
        <UpdateStackResetter
          value={value}
          setter={setter}
          onCancel={() => setUpdateStackResetComponentState(null)}
        />,
      );
    } else {
      setter(value);
    }
  };

  const updateStackResetPeriodHandler = (value: string, setter: (props: any) => void) => {
    updateStackResetHandler(value, setter, (stack) => stack[0].fs_period !== value);
  };

  const updateStackResetTypeHandler = (value: string, setter: (props: any) => void) => {
    updateStackResetHandler(value, setter, (stack) => stack[0]?.fsType !== types.find(type => type.id === value)?.text);
  };

  // eslint-disable-next-line camelcase
  const generatePreviewLink = () => getStrapiRoutes(location.origin).previewLink(locale, updateStack?.[0]?.fsType, periods.find(p => p.id === updateStack?.[0]?.fs_period)?.date);

  return (
    <SectionWithContent>
      {UpdateStackResetComponentState}

      <UpdateStackPreviewer
        display={isUpdatePreview && updateStack.length > 0}
        setIsUpdatePreview={setIsUpdatePreview}
        setCurrentlyEditedInstitution={setCurrentlyEditedInstitution}
        previewUrl={generatePreviewLink()}
        onUpdateComplete={handleUpdateComplete}
      />

      <Grid sx={gridStyles.content}>

        <ChartWizardStep id="type" label="Type" isLoading={isTypeLoading}>
          <ChartDropDownControlComponent
            zIndex={20}
            list={types}
            selectedId={typeId}
            setter={(id:string) => updateStackResetTypeHandler(id, setTypeId)}
          >
            <ChartDropDownHeader title={fsType?.text || 'Choose a type'} />
          </ChartDropDownControlComponent>
        </ChartWizardStep>

        <ChartWizardStep
          id="pastPeriod"
          label="Period"
          isLoading={!fsType || isPeriodLoading}
          loading={fsType ? undefined : null}
        >
          <ChartDropDownControlComponent
            zIndex={15}
            list={periods}
            selectedId={periodId}
            setter={(id:string) => updateStackResetPeriodHandler(id, setPeriodId)}
          >
            <ChartDropDownHeader title={period?.text || 'Choose a period'} />
          </ChartDropDownControlComponent>
        </ChartWizardStep>

        <ChartWizardStep
          id="currentPeriod"
          label="Other Institution"
          isLoading={(!fsType && !period) || isInstitutionLoading}
          loading={(fsType && period) ? undefined : null}
        >
          <CountryOrInstitutionDropDown />
        </ChartWizardStep>

        <ChartWizardStep
          id="bankSelector"
          label="Current Institution"
          isLoading={(!fsType && !period) || isInstitutionLoading}
          loading={null}
        >
          <InstitutionDropDownWithState
            institutions={currentInstitutions}
            onSelect={currentInstitutionSetHandler}
            selectedInstitution={currentEditedOrSelectedInstitution}
            sx={{ zIndex: 10 }}
            header={<ComparisonHeader
              subject={currentEditedOrSelectedInstitution}
              chartComponent={<ChartItem
                percentage={(currentEditedOrSelectedInstitution?.total || 0) * 10}
                sx={styles.headerChart}
                fillComponent={<Monochrome sx={fillColorStyles(colorB)} />}
              />}
              colorPickerComponent={
                <Box onClick={e => e.stopPropagation()}>
                  <ClosableColorPicker defaultColor={colorB} setColorHandler={setColorB} />
                </Box>
              }
            />}
          />
        </ChartWizardStep>

        <ChartWizardStep
          id="downloadButton"
          label=""
          isLoading={!(currentInstitution && relativeInstitution)}
          loading={null}
        >
          <ExportButton element={exportChartRef.current} type="chart" name={`compare_${relativeInstitution?.name}.${currentInstitution?.name}`} />
        </ChartWizardStep>

        <ChartWizardStep
          id="updateStack"
          label="Update Viewer"
          isLoading={!(fsType && period && updateStack && updateStack?.length > 0 && currentInstitution && relativeInstitution)}
          loading={null}
        >
          <CTAButton onClick={() => setIsUpdatePreview(true)}>{`Preview (${updateStack.length})`}</CTAButton>
        </ChartWizardStep>

        <ChartWizardStep
          id="saveButton"
          label=""
          isLoading={!(globalDimensions && currentInstitution && (globalDimensions !== currentInstitution.dimensions))}
          loading={null}
        >
          <CTAButton onClick={saveHandler}>Save Changes</CTAButton>
        </ChartWizardStep>

        <Box
          id="chart"
          variant={currentInstitution && relativeInstitution ? undefined : 'styles.sampleChart'}
        >
          <BubbleInstitutionComparisonChart
            ref={exportChartRef}
            currentInstitution={currentEditedOrSelectedInstitution || DefaultChartData.sampleRelative}
            relativeInstitution={getCountryOrInstitution()}
          />
        </Box>

      </Grid>

    </SectionWithContent>
  );
};
