/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import GatsbyBackgroundImage from 'gatsby-background-image';
import { useBackgroundImage } from './useBackgroundImage';
import { BackgroundImageComponentProps } from './BackgroundImage';

const styles = {
  container: {
    // variant: 'styles.maxWidth',
    width: 'fullSize',
  },
};

// NOTE: this component cannot be used by ParallaxBackgroundImage and with animateParallaxBackgroundImage as it uses some other technique to display bg images
export const BackgroundImageLoader = React.forwardRef<HTMLDivElement, BackgroundImageComponentProps>(({
  src, as, children, id, sx, className,
}, ref) => {
  const fluidSource = useBackgroundImage(src);

  if (!fluidSource) {
    console.error('background image not found!', src);
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <GatsbyBackgroundImage
      // TODO: better solution to match React.ElementType<any>; to. Eslint disable until solution with the below one.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Tag={as as any || 'div'}
      fluid={fluidSource}
      id={id}
      className={className}
      sx={{ ...styles.container, ...sx }}
      // TODO: here we got ref?.current?.selfRef which should have been mapped to ref?.current... but unable to conclude how, eslint disable until solution
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
    >
      {children}
    </GatsbyBackgroundImage>
  );
});
