import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { __tr, __selectAll, __el } from '@modules';
import { getBreakpoint, themeConfig } from '@themed';
import { scrub, withAnimation } from '@core';

interface AnimateDimensionsCircleProps {
  triggerRef: () => React.RefObject<HTMLDivElement>;
  circleRef: () => React.RefObject<HTMLDivElement>;
}

const getCounterProps = (sourceElement: Element, id: 'best' | 'average') => ({
  innerText: gsap.getProperty(sourceElement, `data-finnoscore-${id}`),
  duration: 0.9,
  ease: 'linear',
  modifiers: {
    innerText: (value: string) => Number(value).toFixed(2).replace(/\./, ','),
  },
});

export const normalizeIndex = (index: number, length: number) => index % length;

// Create an array, starting with biggest index, then proceed from 1 -> length-1,
// example: length = 5 ==> [6,1,2,3,4,5];
export const generateIndexes = (length: number, originalActiveIndex = 0) => {
  const activeIndex = normalizeIndex(originalActiveIndex, length);
  return Array(length)
    .fill(length)
    .map((value, index) => {
      if (activeIndex === index) return value;
      if (index > activeIndex) return index - activeIndex;
      return length + index - activeIndex;
    });
};

export const getZIndex = (originalIndex: number, progress: number, length: number) => {
  const index = normalizeIndex(originalIndex, length);
  const activeIndex = normalizeIndex(Math.round(progress * length), length);
  const indexes = generateIndexes(length, activeIndex);
  return indexes[index];
};

const activeStyle = {
  backgroundColor: themeConfig.colors.primary,
  color: themeConfig.colors.background,
  fontWeight: 'bold',
};

const getOneSliceDegree = (length: number) => (360/(length));

const animateRevealCircle = (dimensions: Element[], texts: Element[], props?: gsap.TimelineVars) => {
  const oneSliceDegree = getOneSliceDegree(dimensions.length);
  return gsap.timeline(props)
    .set(dimensions, {
      zIndex: (index) => getZIndex(index, 0, dimensions.length),
    })
    .set(texts, {
      rotation: (index) => 180 + (index * oneSliceDegree),
    })
    .from(dimensions, {
      opacity: 0,
      width: '0',
      x: '-18vh', // just to align the svaling to the center of the wheel
      ease: 'back',
      // NOTE: CustomEase is a paid plugin, we need gsap package downloaded from the private soure for that
      // ease: CustomEase.create("custom", "M0,0,C0,0,0.05,0.228,0.09,0.373,0.12,0.484,0.322,1.315,0.578,1.316,0.804,1.316,0.816,1.138,0.87,1.056,0.888,1.027,1,1,1,1");,
      stagger: { amount: 0.6, from: 'end', ease: 'linear' },
    })
    .to(dimensions, {
      transformOrigin: 'left center',
      rotation: (index) => 180 - (index * oneSliceDegree),
      stagger: { amount: 0.6, from: 'end', ease: 'linear' },
    }, '<')
    .to(texts[0], {
      ...activeStyle,
    })
    .from('[aria-label=best-container]', {
      opacity: 0,
      scale: 0,
      ease: 'back',
    }, '-=0.5')
    .from('[aria-label=average-container]', {
      opacity: 0,
      scale: 0,
      ease: 'back',
    }, '<');
};

const animateCollapseCircle = (circle: Element | null, dimensions: Element[], props?: gsap.TimelineVars) => gsap.timeline(props)
  .to([
    '[aria-label=best-container] span',
    '[aria-label=average-container] span',
  ], {
    scale: 0,
    stagger: { amount: 1, ease: 'linear' },
  }, '>')
  .to(dimensions, {
    scale: 0,
    stagger: { amount: 3, ease: 'linear' },
  }, '>')
  .to(dimensions, { opacity: 0, duration: 0.5 }, '-=0.5')
  .to(circle, { duration: 4 }, '>')
  .to(circle, { y: '100vh', duration: 10, ease: 'linear' }, 0)
  .set(circle, { opacity: 0 });

const animateSpinCircle = (dimensions: Element[], texts: Element[]) => {
  const duration = 1.3;
  const delay = 0;
  const oneSliceDegree = getOneSliceDegree(dimensions.length);

  // TODO: refactor to func or hook
  const inactiveStyleGetter = gsap.getProperty(texts[texts.length-1]);
  const inactiveStyle = {
    color: inactiveStyleGetter?.('color'),
    backgroundColor: inactiveStyleGetter?.('backgroundColor'),
    fontWeight: inactiveStyleGetter?.('fontWeight'),
  };

  const master = gsap.timeline({ repeat: -1, paused: false });

  const createSlice = (nextIndex: number) => {
    const revealCurrent = gsap.timeline()
      .to(texts[nextIndex], {
        // y: '1vw',
        // scaleX: 0.6,
        // scaleY: -0.6,
        duration: 0.2,
      }, '<')
      .set(dimensions, {
        zIndex: (index) => getZIndex(index, master.progress(), texts.length),
      })
      .set(texts[nextIndex], {
        ...activeStyle,
      })
      .to(texts[nextIndex], {
        duration: 0.2,
        scale: 1.1,
        // y: '0',
      })
      .to(texts[nextIndex], {
        duration: 0.2,
        scale: 1,
        // y: '0',
      })
      .to('[aria-label=best]', {
        ...getCounterProps(texts[nextIndex], 'best'),
      }, '0.5')
      .to('[aria-label=average]', {
        ...getCounterProps(texts[nextIndex], 'average'),
      }, '0.5');

    return gsap.timeline()
      .to(dimensions, {
        rotation: `+=${oneSliceDegree}`,
        ease: 'linear',
        duration,
      })
      .to(texts, {
        rotation: `-=${oneSliceDegree}`,
        ease: 'linear',
        duration,
      }, '<')
      .to(texts, {
        ...inactiveStyle,
        duration: 1,
      }, '<')
      .add(revealCurrent, '-=0.2')
      .to(texts[0], { duration: delay }, '>');
  };

  texts.forEach((_, index) => master.add(createSlice(index < texts.length - 1 ? index + 1 : 0)));
  return master;
};

const createCollapseScroller = (triggerRef: () => React.RefObject<HTMLDivElement>, circleRef: () => React.RefObject<HTMLDivElement>, dimensions: Element[]) => {
  // const scrollers = [] as ScrollerArray;
  const initScrollers = () => {
    const trigger = __tr(triggerRef);
    if (trigger) {
      ScrollTrigger.create({
        refreshPriority: 2, // between Contact and Rankings
        trigger,
        // markers: process.env.NODE_ENV === 'development',
        ...scrub(),
        start: () => 'bottom bottom',
        end: () => 'bottom top',
        animation: animateCollapseCircle(__el(circleRef), dimensions),
        invalidateOnRefresh: true,
      });
    }
  };

  ScrollTrigger.matchMedia({
    // NOTE: disable for mobile:
    [`(min-width: ${getBreakpoint(1)})`]: initScrollers,
  });
  // return scrollers;
};

export const animateDimensionsCircle = ({
  triggerRef, circleRef,
}: AnimateDimensionsCircleProps) => {
  gsap.registerPlugin(ScrollTrigger);

  const dimensions = __selectAll('[aria-label$=Dimension]', circleRef);
  const texts = __selectAll('[aria-label$=Dimension] [aria-label$=Title]', circleRef);

  animateRevealCircle(dimensions, texts, {
    onComplete: () => {
      animateSpinCircle(dimensions, texts);
    },
  });

  createCollapseScroller(triggerRef, circleRef, dimensions);
};

export const useAnimationDimensionsCircle = (props: AnimateDimensionsCircleProps) => withAnimation<AnimateDimensionsCircleProps>(animateDimensionsCircle, props);
