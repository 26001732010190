import { SxStyleProp } from 'theme-ui';
import { containerStyles } from '../BubbleComparison/BubbleInstitutionComparisonChart.styles';

export const historyContianerStyles = {
  ...containerStyles,
  maxWidth: '47rem',
  gridTemplateRows: 'minmax(70px, max-content) max-content 1fr 1fr',
};

export const logoStyles={
  box: {
    width: '80%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    variant: 'styles.h4',
    mb: 3,
    ml: 3,
  },

  logo: {
    minHeight: '70px',
    minWidth: '70px',
    width: '100%',
    flex: '0 0 11%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'transparent',
    transition: 'all 0.3s ease',
    position: 'relative',

    input: {
      display: 'none',
    },

    label: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    img: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      objectFit: 'contain',
      boxShadow: '0 0 10px 0 rgba(255, 255, 255, 0.35)',
      // borderRadius: 'fullSize',
      zIndex: 0,
    },

    '&:hover': {
      '#logo_label': {
        minHeight: '70px',
        minWidth: '70px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 10000,
        color: 'rgb(250, 250, 250)',
        transition: 'background-color 0.2s ease-in-out',
        // borderRadius: 'fullSize',
        marginBottom: 0,
      },
    },
    span: {
      display: 'inline-flex',
      padding: '0.2em',
      fontSize: '12px',
      textAlign: 'center',
    },
  }as SxStyleProp,
};

export const gridStyles = {
  grid: {
    width: '75%',
    gridTemplateColumns: '0.5fr 0.5fr 1fr 2fr',
    gridTemplateRows: '1fr',
    placeItems: 'center self-start',
    columnGap: 0,
    gridTemplateAreas:
              '"chart year bubble subtitle"',
  },
  bubble: {
    gridArea: 'bubble',
    width: ['4rem', '6.5rem'],
    height: ['4rem', '6.5rem'],
  },
  margins: {
    ml: [8, 9],
    mb: [0, 3, 5],
    mt: [0, 3],
  },
};
