/** @jsx jsx */
import React from 'react';
import { jsx } from '@themed';
import { ChildrenProps } from '@types';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const ShowBackButtonContext = React.createContext({
  show: false,
  toggleShow: (_newVal: boolean) => {},
  title: null as string | null,
  setTitle: (_newVal: string | null) => {},
});
/* eslint-enable @typescript-eslint/no-unused-vars */

const ShowBackButtonProviderType = ShowBackButtonContext.Provider;

export const ShowBackButtonProvider = ({ children }: ChildrenProps) => {
  const [show, setShow] = React.useState(false);
  const [title, setTitle] = React.useState<string | null>(null);

  function toggleShow(newVal: boolean) {
    setShow(newVal || !show);
  }

  return (
    <ShowBackButtonProviderType value={{
      show, toggleShow, title, setTitle,
    }}
    >{children}</ShowBackButtonProviderType>
  );
};

export const useShowBackButtonOnMount = (backTitle?: string) => {
  const { toggleShow, setTitle } = React.useContext(ShowBackButtonContext);

  React.useEffect(() => {
    toggleShow(true);
    setTitle(backTitle || null);
  }, [backTitle]);

  return () => toggleShow(false);
};

interface SideBarProps {
  title?: string;
}

export const ShowBackButtonComponent = ({ title }: SideBarProps) => {
  useShowBackButtonOnMount(title);

  return null;
};
