import icoJsx360 from '../../../images/icons/jsx/360';
import icoJsxArrow from '../../../images/icons/jsx/Arrow';
import icoJsxBarchartTrend from '../../../images/icons/jsx/BarchartTrend';
import icoJsxBulbRecycle from '../../../images/icons/jsx/BulbRecycle';
import icoJsxChartTrendUp from '../../../images/icons/jsx/ChartTrendUp';
import icoJsxCler from '../../../images/icons/jsx/Cler';
import icoJsxClose from '../../../images/icons/jsx/Close';
import icoJsxEye from '../../../images/icons/jsx/Eye';
import icoJsxFinnoscore from '../../../images/icons/jsx/Finnoscore';
import icoJsxGlobeAndPlay from '../../../images/icons/jsx/GlobeAndPlay';
import icoJsxHandshake from '../../../images/icons/jsx/Handshake';
import icoJsxMail from '../../../images/icons/jsx/Mail';
import icoJsxMap from '../../../images/icons/jsx/Map';
import icoJsxN26 from '../../../images/icons/jsx/N26';
import icoJsxNn from '../../../images/icons/jsx/Nn';
import icoJsxPlannedRoute from '../../../images/icons/jsx/PlannedRoute';
import icoJsxBulb1 from '../../../images/icons/jsx/Bulb1';
import icoJsxBulb2 from '../../../images/icons/jsx/Bulb2';
import icoJsxBulb3 from '../../../images/icons/jsx/Bulb3';
import icoJsxBulb4 from '../../../images/icons/jsx/Bulb4';
import icoJsxBulb5 from '../../../images/icons/jsx/Bulb5';
import icoJsxBulb6 from '../../../images/icons/jsx/Bulb6';
import icoJsxSantaHat from '../../../images/icons/jsx/SantaHat';
import icoJsxPlay from '../../../images/icons/jsx/Play';
import icoJsxPlus from '../../../images/icons/jsx/Plus';
import icoJsxBank from '../../../images/icons/jsx/Bank';
import icoJsxInsurance from '../../../images/icons/jsx/Insurance';
import icoJsxTieEN from '../../../images/icons/jsx/TieEN';
import icoJsxTieDE from '../../../images/icons/jsx/TieDE';
import icoJsxTieFR from '../../../images/icons/jsx/TieFR';
import icoJsxPodium from '../../../images/icons/jsx/Podium';
import icoJsxQuestionResponse from '../../../images/icons/jsx/QuestionResponse';
import icoJsxRocket from '../../../images/icons/jsx/Rocket';
import icoJsxTailedArrow from '../../../images/icons/jsx/TailedArrow';
import icoLogoFinnoscoreLogo from '../../../images/icons/logo/FinnoscoreLogo';
import icoLogoFinnoscoreLogoInverted from '../../../images/icons/logo/FinnoscoreLogoInverted';
import icoLogoLogo from '../../../images/icons/logo/Logo';
import icoLogoLogoInverted from '../../../images/icons/logo/LogoInverted';

export const iconsGenerated = {
  ico: {
    jsx: {
      icoJsx360,
      icoJsxArrow,
      icoJsxBarchartTrend,
      icoJsxBulbRecycle,
      icoJsxChartTrendUp,
      icoJsxCler,
      icoJsxClose,
      icoJsxEye,
      icoJsxFinnoscore,
      icoJsxGlobeAndPlay,
      icoJsxHandshake,
      icoJsxMail,
      icoJsxMap,
      icoJsxN26,
      icoJsxNn,
      icoJsxPlannedRoute,
      icoJsxBulb1,
      icoJsxBulb2,
      icoJsxBulb3,
      icoJsxBulb4,
      icoJsxBulb5,
      icoJsxBulb6,
      icoJsxSantaHat,
      icoJsxPlay,
      icoJsxPlus,
      icoJsxPodium,
      icoJsxQuestionResponse,
      icoJsxRocket,
      icoJsxTailedArrow,
      icoJsxBank,
      icoJsxInsurance,
      icoJsxTieDE,
      icoJsxTieEN,
      icoJsxTieFR,
    },
    logo: {
      icoLogoFinnoscoreLogo,
      icoLogoFinnoscoreLogoInverted,
      icoLogoLogo,
      icoLogoLogoInverted,
    },
  },
};
