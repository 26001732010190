import axios, { AxiosError } from 'axios';

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface RequestHeader {
  [key: string]: string;
}

interface CreateCustomErrorFunc<ErrorType> {
  (error: ErrorType): Error;
}

interface RequestOptions<Body, ErrorType> {
  method?: RequestMethod;
  headers?: RequestHeader;
  body?: Body,
  timeout?: number;
  createCustomError?: CreateCustomErrorFunc<ErrorType>;
}

function defaultCreateCustomError(error: AxiosError) {
  let customError: Error;

  if (error.response) {
    // Server responded, but not with 2xx
    customError = new Error(`Server responded with error: ${JSON.stringify(error)}`);
  } else if (error.request) {
    // Server did not respond
    customError = new Error('The server is not available.');
  } else {
    // Another error happened while setting up the request
    customError = new Error('A client error happened.');
  }

  return customError;
}

interface AuthenticationResponse {
  jwt: string;
}

export async function authenticate(body: { [key:string]: string }, url: string, createCustomError: CreateCustomErrorFunc<AxiosError> = defaultCreateCustomError) {
  try {
    const { data } = await axios.post<AuthenticationResponse>(url, body);
    axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;
  } catch (error) {
    throw createCustomError(error);
  }
}

export async function request<Resource = unknown, Body = void>(url: string, options: RequestOptions<Body, AxiosError>) {
  const {
    method = 'GET',
    headers,
    body,
    timeout = 0,
    createCustomError = defaultCreateCustomError,
  } = options;

  try {
    const response = await axios.request({
      url,
      method,
      headers,
      data: body,
      timeout,
    });

    return response.data as Resource;
  } catch (error) {
    throw createCustomError(error);
  }
}
