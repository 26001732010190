import { CaseStudy, CaseStudyFromApi } from '@types';
import { formatCaseStudy } from '../../formatting/case-studies/formatCaseStudies';

import { getQuery } from './previewCaseStudyQueries';
import { graphqlRequest } from '../graphqlRequest';
import { languages } from '../../i18n';

interface CaseStudyData {
  casestudies: CaseStudyFromApi[];
}
interface MappedCaseStudyResult {
  de: CaseStudy;
  en?: CaseStudy;
  fr?: CaseStudy;
}

const formatCaseStudyResult = (caseStudy: CaseStudyData): MappedCaseStudyResult => {
  const caseStudyFromApi: CaseStudyFromApi[] | undefined | null = caseStudy && caseStudy.casestudies;

  if (!caseStudyFromApi || caseStudyFromApi.length === 0) throw new Error('No case study found');

  const germanCaseStudyFromApi = caseStudyFromApi[0];

  const germanCaseStudy = formatCaseStudy(germanCaseStudyFromApi, 'de', undefined);

  const result: MappedCaseStudyResult = {
    de: { ...germanCaseStudy },
  };

  languages.forEach(lang => {
    const caseStudyFromApiForLang = germanCaseStudyFromApi[lang];

    if (caseStudyFromApiForLang) {
      const caseStudyForLang = formatCaseStudy(caseStudyFromApiForLang, lang, [germanCaseStudyFromApi]);
      result[lang] = { ...caseStudyForLang };
    }
  });

  return result;
};

export const fetchCaseStudyWithSlug = async (locale: string, slug: string) => {
  const query = getQuery(locale, slug);
  if (!query) throw new Error(`No query found for locale ${locale}`);
  const response = await graphqlRequest<CaseStudyData>(query);
  return formatCaseStudyResult(response);
};
