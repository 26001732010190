/* eslint-disable no-nested-ternary */
import { useLocation } from '@reach/router';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link, SvgIcon } from '@core';
import { SmallText, FlexProps, Flex, useIsMobile } from '@themed';
import { linkProps } from '@config';
import { camelize } from '../string';
import { useTranslate } from '../i18n';

const styles = {
  logo: {
    svg: {
      maxWidth: ['33vw', 'auto'],
    },
  },
  owner: (logoType?: LogoTypes) => ({
    fontSize: ['0.875rem', 'xxs'],
    fontWeight: 'bold',
    borderRadius: 6,
    display: 'flex',
    pl: [3, 4],
    ml: 2,
    py: [0, '10px'],
    pr: 5,
    pt: [1, 3],
    mb: [3, 0],
    bg: ['none', 'grey.4'],
    color: logoType ==='inverted' ? 'background' : ['#BEC5D2', 'text'],
  }),
};

type LogoTypes = 'inverted';

const useLogoLinks = (logoType?: LogoTypes) => {
  const intl = useIntl();
  const location = useLocation();
  const isMobile = useIsMobile();

  // TODO: use nav.ts - isFinnoscoreToolPath && isFinnoChartsPath
  const isFinnoScore = !!location.pathname.match('^(/(en|de|fr))?/(banks|banks-middleeast|compare|insurances|privatebanks)');
  const isFinnoCharts = !!location.pathname.match('/(charts)');

  const defaultLogoName = isFinnoScore ? 'finnoscoreLogo' : 'logo';

  return [
    camelize(`${(isMobile && isFinnoScore ? 'finnoscoreLogo' : defaultLogoName)}${logoType==='inverted' ? '-inverted' : ''}`),
    isFinnoScore ? `/${intl.locale}/finnoscore` : `/${intl.locale}/`,
    isFinnoScore ? <SmallText sx={styles.owner(logoType)}>by Finnoconsult</SmallText> : isFinnoCharts ? <SmallText sx={styles.owner(logoType)}>chart-generator</SmallText> : null,
    isFinnoScore ? `/${intl.locale}/` : isFinnoCharts ? `/${intl.locale}/charts` : null,
  ] as const;
};

interface LogoProps extends FlexProps{
  onClick?: () => void
}

export const useLogoWithLink = (logoType?: LogoTypes) => {
  const [logo, logoLink, logo2, logo2Link] = useLogoLinks(logoType);
  const __ = useTranslate();

  return ({ onClick, ...props }: LogoProps) => (
    <Flex {...props}>
      <Link {...linkProps} aria-label="Finnoconsult" to={logoLink} onClick={() => { if (onClick) onClick(); }}>
        <SvgIcon data-cy="finnoconsult-logo-link" name={logo} alt={__('navbar.logoAlt')} sx={styles.logo} type="ico.logo" />
      </Link>
      {logo2Link && (
        <Link {...linkProps} aria-label="Finnoconsult" to={logo2Link}>
          {logo2}
        </Link>
      )}
    </Flex>
  );
};
