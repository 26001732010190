import { compactSectionContentPadding } from '@themed';

export const styles = {
  pt: 'headerHeight',
  testimonials: {
    section: {
      bg: 'muted',
    },
    content: {
      zIndex: 112,
      px: compactSectionContentPadding,
      width: '100%',
      pb: 5,
    },
  },
};
