interface Point {
  x: number;
  y: number;
}
function distanceSq(p1: Point, p2: Point) {
  return ((p1.x - p2.x) ** 2) + ((p1.y - p2.y) ** 2);
}

export function maxScale(x: number, y: number, width: number, height: number) {
  const maxWidth = document?.body.scrollWidth;
  const maxHeight = window?.innerHeight;
  const radiusStart = Math.max((width / 2), (height / 2));
  const point = { x: x + (width / 2), y: y + (height / 2) };
  const da = distanceSq(point, { x: 0, y: 0 });
  const db = distanceSq(point, { x: maxWidth, y: 0 });
  const dc = distanceSq(point, { x: maxWidth, y: maxHeight });
  const dd = distanceSq(point, { x: 0, y: maxHeight });
  return Math.sqrt(Math.max(da, db, dc, dd)) / radiusStart;
}

export function translateWithSize(end: number, size: number, max: number) {
  return (((max-size)/max) * end);
}
