import React from 'react';
import { Grid } from 'theme-ui';
import { useColors } from '@composite';
import { getBoundedNumericValue, getNumericValue, updateAtIndex } from '@modules';
import { DoubleChartItem } from './DoubleChartItem';
import { PositionBar } from './PositionBar';
import useChartDragValue from './useChartDragValue';
import { DoubleChartComponentProps } from './ComparisonChartWithLabel';
import { useEditableDimensionState } from './useEditableDimensionState';
import { Monochrome } from './Monochrome';
import { doubleChartStyles } from './DoubleChartContainer.styles';
import { fillColorStyles } from './ChartItem.styles';
import { ChartInput } from './ChartValueInput';

const CharValues = ({ dimensionValue, relativeDimensionValue, dimensionKey, globalDimensions, setGlobalDimensions }: DoubleChartComponentProps) => {
  const [[colorA, colorB]] = useColors();
  const [shouldSetGlobalValue, setShouldSetGlobalValue] = React.useState(false);

  const [monochromeValue, setMonochromeValue] = useEditableDimensionState(dimensionValue);
  const [percentageBarValue, setPercentageBarValue] = useEditableDimensionState(relativeDimensionValue);

  const setGlobalDimensionToMonochromeValue = (value?:string) => {
    const dimension = globalDimensions?.find(d => d.key === dimensionKey);
    if (!globalDimensions || !dimension) return;

    const newDimension = {
      ...dimension,
      value: value || `${getBoundedNumericValue(monochromeValue)}`,
    };

    if (globalDimensions && setGlobalDimensions) {
      const index = globalDimensions.indexOf(dimension);
      setGlobalDimensions(updateAtIndex(globalDimensions, newDimension, index));
    }
  };

  const handleGlobalDimensionValueUpdate = () => {
    setGlobalDimensionToMonochromeValue();
    setShouldSetGlobalValue(false);
  };

  React.useEffect(() => {
    if (shouldSetGlobalValue) {
      handleGlobalDimensionValueUpdate();
    }
    return () => {
      if (shouldSetGlobalValue) {
        handleGlobalDimensionValueUpdate();
      }
    };
  }, [shouldSetGlobalValue]);

  const container = React.useRef<HTMLDivElement>(null);
  const item = React.useRef<HTMLDivElement>(null);
  const item2 = React.useRef<HTMLDivElement>(null);

  useChartDragValue({ boundRef: container, itemRef: item, setHandler: setMonochromeValue, monochromeValue, setShouldSetGlobalValue });
  useChartDragValue({ boundRef: container, itemRef: item2, setHandler: setPercentageBarValue, percentageBarValue });

  return (
    <Grid sx={doubleChartStyles.chartBox}>
      <ChartInput
        color={colorA}
        itemRef={item2}
        value={percentageBarValue}
        setter={setPercentageBarValue}
        containerRef={container}
        globalDimensionSetter={setGlobalDimensionToMonochromeValue}
      />
      <ChartInput
        color={colorB}
        itemRef={item}
        value={monochromeValue}
        setter={setMonochromeValue}
        containerRef={container}
        globalDimensionSetter={setGlobalDimensionToMonochromeValue}
      />

      <DoubleChartItem
        ref={container}
        fillComponents={{
          a: <Monochrome ref={item} draggable sx={fillColorStyles(colorB)} />,
          b: <PositionBar ref={item2} draggable sx={fillColorStyles(colorA)} />,
        }}
        percentages={{
          a: getNumericValue(monochromeValue) * 10,
          b: getNumericValue(percentageBarValue),
        }}
      />
    </Grid>
  );
};
export const CharValueWithMonochromeAndPercentage = React.memo(CharValues);
