import { finnoscoreDataStyles } from '../../finnoscore-data/index.styles';

export const styles = {
  control: {
    ...finnoscoreDataStyles.control,
    rowGap: '12px',
    display: 'grid !important',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    columnGap: '18px',
  },
  headerChart: {
    height: '10px',
    width: 6,
    mt: 2,
  },
  rankText: {
    fontSize: '12px',
    ml: 3,
  },
};
