import { themeConfig } from '@themed';

export const trendStyles = {
  box: {
    width: '20px',
    height: '20px',
    bg: 'muted',
    flexGrow: 0,
    mr: 4,
  },
  icon: {
    width: '14px',
    color: themeConfig.colors.primary,
  },
  decliningIcon: {
    color: '#bd0000',
  },
  stagnantIcon: {
    color: themeConfig.colors.mutedUi,
  },
};
