export const titleStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};
export const formStyles = {
  variant: 'flex.col',
  aligntItems: 'center',
  width: '28rem',
  minHeight: 'calc(100vh - 80px - 36px - 1px) !important',
  gap: 5,
  gridTemplateRows: '0.2fr 1fr 0.2fr',
  placeItems: 'start center',
  form: {
    '&>*': {
      my: 4,
      fontSize: ['xxs'],
      display: 'flex',
      flexDirection: 'column',
    },
    py: 7,
    px: 6,
    width: '100%',
    borderTop: '2px solid strapiBlue',
    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px;',
    '& > button[type="submit"]': {
      flexDirection: 'row',
      justifyContent: 'center',
      variant: 'buttons.pure',
      textAlign: 'center',
      width: '100%',
      color: 'background',
      bg: 'strapiBlue',
      borderRadius: 0,
      textTransform: 'uppercase',
      py: 3,
      mt: 2,
    },
    '#pacman-loader-image': {
      margin: 'auto',
    },
  },
};
