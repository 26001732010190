import React from 'react';
import { Grid } from 'theme-ui';
import { Institution } from '@types';
import ChartLabel from '../BasicComponents/ChartLabel';
import { InternationalChartItem } from '../BasicComponents/InternationalChartItem';
import { styles } from './InstitutionChartRow.styles';

interface InstitutionChartRowProps{
  institutions?: Institution[];
  currentInstitution: Institution;
  valueChangeHandler:(newValue: number | number[], index:number) => void;
  shouldRenderCountryCode?:boolean;
}

export const InstitutionChartRows = ({ institutions, currentInstitution, valueChangeHandler, shouldRenderCountryCode = false }:InstitutionChartRowProps) => (
  <Grid sx={styles}>
    {institutions?.map((institution, i) => (
      <Grid key={institution.id} sx={styles.row}>
        { !shouldRenderCountryCode
          ?<ChartLabel>{institution.name}</ChartLabel>
          :<ChartLabel>{institution.name} {institution.country.countryCode}</ChartLabel>}

        <InternationalChartItem
          institution={institution}
          current={institution.id === currentInstitution.id}
          index={i}
          valueChangeHandler={valueChangeHandler}
        />
      </Grid>
    ))}
  </Grid>
);
