import React from 'react';
import { ChildrenProps } from '@types';

type OpenType = 'a' | 'b' | undefined;

export interface CompareDropDownProps {
  open: OpenType;
  setOpen: (id: OpenType) => void;
}

export const ComparisonDropDownContext = React.createContext<CompareDropDownProps>({} as CompareDropDownProps);

interface ComparisonDropDownContextProviderProps extends ChildrenProps, Partial<CompareDropDownProps> {
}

export const ComparisonDropDownContextProvider = ({ children, open, setOpen }: ComparisonDropDownContextProviderProps) => {
  const [openState, setOpenState] = React.useState<OpenType>(undefined);

  const setExclusiveOpen = (id: OpenType) => {
    // closing back on received the same id as current open
    setOpenState(id === openState ? undefined : id);
  };

  const value = {
    open: open !== undefined ? open : openState,
    setOpen: setOpen !== undefined ? setOpen : setExclusiveOpen,
  };

  return <ComparisonDropDownContext.Provider value={value}>{children}</ComparisonDropDownContext.Provider>;
};

export const useComparisonDropDown = () => {
  const { open, setOpen } = React.useContext(ComparisonDropDownContext);

  return { open, setOpen };
};
