import { useNestedNavItemsProps, useTranslate } from '@modules';
import { linkProps } from '@config';
import { Button, SxStyleProp } from '@themed';
import { NestedNavItems, NestedNavItemsHeader } from './NestedNavItems';
import { NavItemProps, useNavMatching, useNavItemText } from './NavItem';

const buttonStyles ={
  all: 'unset',
  variant: 'links.nav',
  '&:hover': {
    boxShadow: 'none',
    transform: 'scale(1) !important',
  },
} as SxStyleProp;

export const NestedNavParent = ({
  id, match, sx, children, items,
}: NavItemProps) => {
  const text = useNavItemText(id);
  const isMatching = useNavMatching(match);
  const {
    isOpen, parentProps, childrenGroupProps, childrenProps,
  } = useNestedNavItemsProps(items);
  const __ = useTranslate();

  return (
    <Button
      {...linkProps}
      {...parentProps}
      className={isMatching ? 'active' : ''}
      sx={{
        ...parentProps.sx,
        ...sx,
        ...buttonStyles,
      }}
    >
      {text}

      <NestedNavItems items={items} isOpen={isOpen} childrenGroupProps={childrenGroupProps} childrenProps={childrenProps}>
        <NestedNavItemsHeader isOpen={isOpen}>{__(`navbar.${id}`)}</NestedNavItemsHeader>
      </NestedNavItems>

      {children}
    </Button>
  );
};
