import React from 'react';
import { Flex, SxProps } from 'theme-ui';
import { DropDownArrow, DropDownComponent } from '@core';
import { SubTitle } from '@themed';
import { Children, ChildrenProps } from '@types';
import { ucfirst } from '@modules';
import { styles } from './components/ChartDropDownControl.styles';

interface ChartDropDownHeaderProps extends ChildrenProps {
  title:string,
}

export const ChartDropDownHeader = ({ title, children }: ChartDropDownHeaderProps) => (
  <Flex sx={styles.header.box}>
    <Flex sx={styles.header.left}>
      {children}
    </Flex>

    <Flex sx={styles.header.titleArea}>
      <SubTitle sx={styles.header.title}>{ucfirst(title)}</SubTitle>
      <DropDownArrow />
    </Flex>
  </Flex>
);

interface ChartDropDownControlProps extends ChildrenProps, SxProps{
  header: Children;
  closeOnContentClick?: boolean,
}

export const ChartDropDownControl = ({
  sx, ...props
}: ChartDropDownControlProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <DropDownComponent
      {...props}
      sx={{ ...styles.dropDown, ...sx }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
