import { SxStyleProp } from 'theme-ui';

export const tabSelectorItemStyles = (selected: boolean) => ({
  variant: 'buttons.pure',
  '&>*': {
    py: theme => `${theme.sizes[3]} !important`,
    px: theme => `${theme.sizes[5]} !important`,
    margin: 0,
    fontWeight: 'bold !important',
    color: theme => `${selected ? theme.colors.background : theme.colors.primary} !important`,
    backgroundColor: selected ? 'primary' : 'transparent',
    borderRadius: '100px',
  },
} as SxStyleProp);

export const tabSelectorStyles = {
  box: {
    borderRadius: '100px',
    backgroundColor: 'muted',
  },
};
