import React from 'react';
import { useLocale, translateInstitutionListDimensionsForLocale } from '@modules';
import { FsTypeBase, InstitutionWithImage } from '@types';
import { getInstitutionsForPeriodByType } from '../../networking';

export const useInstitutionDropDownControl = (type?: FsTypeBase<string>, period?: FsTypeBase<string>) => {
  const locale = useLocale();
  const [institutions, setInstitutions] = React.useState<InstitutionWithImage[]>([]);
  const [institution, setInstitution] = React.useState<InstitutionWithImage | undefined>(undefined);

  const getInstitutions = React.useCallback(async () => {
    setInstitutions([]);
    setInstitution(undefined);
    if (type && period) {
      const res = await getInstitutionsForPeriodByType(type.text, period?.id);
      if (res.list) {
        const translatedInstitutions = translateInstitutionListDimensionsForLocale(res.list, res.translations, locale);
        setInstitutions(translatedInstitutions);
        setInstitution(translatedInstitutions[0]);
      }
    }
  }, [type, period]);

  React.useEffect(() => {
    getInstitutions();
  }, [type, period]);

  const setHandler = (e:string) => {
    // matching of institution is done differently in FS tool: CC.NAME
    setInstitution(institutions.find((i) => `${i.country.countryCode}.${i.name}` === e));
  };

  return ({
    institutions,
    institution,
    setInstitution,
    institutionSetHandler: setHandler,
    isLoading: institutions.length===0,
    getInstitutions,
  });
};
