import React, { useState } from 'react';
import { RGBColor } from 'react-color';
import { useLocation } from '@reach/router';
import { Flex } from '@themed';
import { ItemsBySection, RequiredDropDownContextProviderProps, SectionedSearchList } from '@core';
import { useInstitutionsByCountries, useTranslate } from '@modules';
import { Children, ComparisonSubject, Institution, InstitutionWithImage } from '@types';

import { isFinnoscoreBankMiddleEastPath } from '@config';
import { ChartItem } from '../chart-generator';
import { InstitutionListItem } from '../finnoscore-data/List';
import { ComparisonDropDownWithContext } from './ComparisonSelector';
import { ResponsiveTextShortener } from './ResponsiveTextShortener';
import { styles } from './ComparisonDropDown.styles';

interface CommonInstitutionDropDownProps {
  selectedInstitution?: InstitutionWithImage | ComparisonSubject;
  onSelect: (value: string) => void;
  fillComponent?: Children;
  header?: Children;
}
interface InstitutionGroupedDropDownProps extends RequiredDropDownContextProviderProps, CommonInstitutionDropDownProps{
  institutionsGrouped?: ItemsBySection[];
  institutionColor?: RGBColor,
}

const subjectScoreColorStyles = (color?: RGBColor) => ({
  '#institutionScore': {
    color: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : undefined,
  },
});

export const InstitutionGroupedDropDown = ({
  institutionsGrouped, isOpen, setIsOpen, onSelect, selectedInstitution, fillComponent, institutionColor, ...props
}: InstitutionGroupedDropDownProps) => {
  const __ = useTranslate();
  const { pathname } = useLocation();

  const translatedComparisonTitle = isFinnoscoreBankMiddleEastPath(pathname)
    ? __('finnoscore.comparison.middle-east')
    : __('finnoscore.comparison.worldwide');

  return (
    <ComparisonDropDownWithContext
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      subject={selectedInstitution}
      sx={subjectScoreColorStyles(institutionColor)}
      chartComponent={<Flex variant="flex.rowCenter">
        <ChartItem
          percentage={(selectedInstitution?.total || 0) * 10}
          sx={styles.headerChart(institutionColor)}
          defaultColor={institutionColor}
          fillComponent={fillComponent}
        />
        <ResponsiveTextShortener
          sx={styles.rankText}
          short={`${__('finnoscore.comparison.rank-mobile')}${selectedInstitution?.rank} ${translatedComparisonTitle}`}
          maxHeight={30}
        >
          {`${__('finnoscore.comparison.rank')}${selectedInstitution?.rank} ${translatedComparisonTitle}`}
        </ResponsiveTextShortener>
      </Flex>}
    >
      {institutionsGrouped && <SectionedSearchList
        listItemsBySection={institutionsGrouped}
        onSelect={onSelect}
        selected={`${selectedInstitution?.country?.countryCode}.${selectedInstitution?.name}`} // TODO: handle better, to make SearchList string | number?
        ListItemComponent={InstitutionListItem}
      />}
    </ComparisonDropDownWithContext>
  );
};

interface InstitutionDropDownWithStateProps extends CommonInstitutionDropDownProps{
  institutions: Institution[];
}
export const InstitutionDropDownWithState = ({ institutions, ...props }: InstitutionDropDownWithStateProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const institutionsGrouped = useInstitutionsByCountries(institutions);
  return (
    <InstitutionGroupedDropDown
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      institutionsGrouped={institutionsGrouped}
    />
  );
};
