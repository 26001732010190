/** @jsx jsx */
import { jsx, Flex, Box, SubTitle, Text, FlexProps } from '@themed';
import { ImageLoader, Link } from '@core';
import { useTranslate } from '@modules';
import { styles } from './Profile.styles';

export default (props: FlexProps) => {
  const __ = useTranslate();

  return (
    <Flex variant="flex.rowCenter" {...props} sx={{ ...styles.profileBox, ...props.sx }}>
      <ImageLoader src={__('finnoscore.init.profileImage.url')} alt={__('finnoscore.init.profileImage.alternativeText')} sx={{ ...styles.profile, ...styles.leftProfile }} />
      <Box>
        <SubTitle sx={styles.question} as="h3">{__('finnoscore.init.contactTitle')}</SubTitle>
        <Link sx={{ variant: 'links.thin' }} to={__(__('pages.contact.person.linkedin'))}>
          {__('finnoscore.init.contactText')}
        </Link>
        <Text variant="fsText">{__('finnoscore.init.contactPerson')}</Text>
      </Box>
      <ImageLoader src={__('finnoscore.init.profileImage.url')} alt={__('finnoscore.init.profileImage.alternativeText')} sx={{ ...styles.profile, ...styles.rightProfile }} />
    </Flex>
  );
};
