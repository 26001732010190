/** @jsx jsx */
import React from 'react';
import { Flex, Grid, jsx } from 'theme-ui';
import { Title, Paragraph, CTAButton } from '@themed';
import { useTranslate } from '@modules';
import { styles } from './NotSupportedPopup.styles';

export const NotSupportedPopup = () => {
  const [clicked, setClicked] = React.useState(false);
  const __ = useTranslate();

  return clicked ? null: (
    <Flex sx={styles.container}>
      <Grid sx={styles.popup}>
        <Title>{__('common.notsupported.title')}</Title>
        <Paragraph>{__('common.notsupported.paragraph')}</Paragraph>
        <CTAButton onClick={() => setClicked(true)} sx={styles.button}>{__('common.notsupported.button')}</CTAButton>
      </Grid>
    </Flex>
  );
};
