import { SxStyleProp } from 'theme-ui';
import { Colors } from '../../../types';

export function reColorSvg(color?: Colors, container = 'svg'): SxStyleProp {
  return ({
    [`${container} g > polygon, ${container} g > ellipse, ${container} g > path, ${container} g > circle, ${container} g > use, ${container} > polygon, ${container} > ellipse, ${container} > path, ${container} > circle, ${container} > use`]: {
      fill: color,
    },
    /* NEEDED because of SVGO prefixing the ids */
    [`${container} [id$="norecolor"] polygon, ${container} [id$="norecolor"] ellipse, ${container} [id$="norecolor"] path, ${container} [id$="norecolor"] circle, ${container} [id$="norecolor"] use, ${container} #norecolor polygon, ${container} #norecolor ellipse, ${container} #norecolor path, ${container} #norecolor circle, ${container} #norecolor use`]: {
      fill: 'inherit',
    },
    /* NEEDED because of SVGO prefixing the ids */
    [`${container} [id$="recolorwhite"], ${container} [id$="recolorwhite"] polygon, ${container} [id$="recolorwhite"] ellipse, ${container} [id$="recolorwhite"] path, ${container} [id$="recolorwhite"] circle, ${container} [id$="recolorwhite"] use, ${container} #recolorwhite, ${container} #recolorwhite polygon, ${container} #recolorwhite ellipse, ${container} #recolorwhite path, ${container} #recolorwhite circle, ${container} #recolorwhite use`]: {
      stroke: '#ffffff !important',
      fill: '#ffffff !important',
    },
  });
}
