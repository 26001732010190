import { SxStyleProp } from 'theme-ui';

export const styles = {
  label: {
    justifyContent: 'end',
    alignItems: 'center',
    mr: [2, 3],
    height: 5,
  },
  labelText: {
    fontSize: ['10px', '14px', '14px'],
  } as SxStyleProp,
};
