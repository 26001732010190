import React from 'react';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { getNumericValue } from '@modules';

interface useChartDragValueProps{
  boundRef: React.RefObject<HTMLDivElement>
  itemRef: React.RefObject<HTMLDivElement>
  monochromeValue?: string;
  percentageBarValue?: string;
  setHandler?: (value: string) => void;
  setShouldSetGlobalValue?: (b:boolean)=>void;
}
const useChartDragValue = ({
  boundRef, itemRef, setHandler, monochromeValue, percentageBarValue, setShouldSetGlobalValue,
}:useChartDragValueProps) => {
  React.useLayoutEffect(() => {
    const containerWidth = boundRef.current?.offsetWidth;
    if (containerWidth !== undefined && itemRef.current) {
      if (percentageBarValue) {
        const percentage = getNumericValue(percentageBarValue) * 10;
        if (percentage === 0) gsap.set(itemRef.current, { transform: 'translateX(15px) tranlateY(-50%)' });
        else gsap.set(itemRef.current, { transform: `translateX(${containerWidth * (percentage / 100)}px) translateY(-50%)` });
      }
      if (monochromeValue) {
        const percentage = getNumericValue(monochromeValue) * 10;
        if (percentage === 0) gsap.set(itemRef.current, { transform: 'translateX(15px)' });
        else gsap.set(itemRef.current, { transform: `translateX(${containerWidth * (percentage / 100)}px)` });
      }
    }
  }, [percentageBarValue, monochromeValue, itemRef.current]);

  React.useEffect(() => {
    // NOTE: https://greensock.com/forums/topic/22657-gatsby-build-errors-when-using-gsapregisterplugin/
    gsap.registerPlugin(Draggable);

    if (itemRef.current) {
      Draggable.create(itemRef.current, {
        bounds: boundRef.current,
        lockAxis: true,
        type: 'x',

        onDrag({ x }) {
          const min = boundRef.current?.offsetLeft;
          const width = boundRef.current?.offsetWidth;

          if (min!==undefined && width!==undefined && setHandler) {
            const percentage = ((x-min) / width) * 10;
            const maxPercentage = 10;
            const minPercentage = 0;

            if (percentage > maxPercentage) setHandler(`${maxPercentage}`);
            else if (percentage < minPercentage) setHandler(`${minPercentage}`);
            else setHandler(`${percentage}`);
          }
        },
        onDragEnd() {
          if (setShouldSetGlobalValue) setShouldSetGlobalValue(true);
        },
      });
    }
  }, [boundRef, itemRef, itemRef.current]);
};

export default useChartDragValue;
