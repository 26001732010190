/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';
import React from 'react';

import { Link as BrowserLink } from '@reach/router';
import { ChildrenProps } from '@types';
import { useTranslate } from '@modules';
import { linkProps, NavigationItem } from '@config';
import { useIsMobile } from '@themed';
import { Link } from './Link';

export interface NavItemProps extends NavigationItem, SxProps, ChildrenProps {}

export const useNavItemText = (id?: string) => {
  const __ = useTranslate();
  const isMobile = useIsMobile();
  const mobileText = __(`navbar.${id}-mobile`, true);
  const defaultText = __(`navbar.${id}`, true);
  return (isMobile && mobileText) ? mobileText : defaultText;
};

export const useNavMatching = (match?: RegExp) => {
  const [matching, setMatching] = React.useState(false);

  React.useEffect(() => {
    if (match && window) setMatching(match.test(window?.location?.href));
  }, [match]);

  return matching;
};

const linkStyles = {
  variant: 'links.nav',
  display: 'flex',
};

export const SimpleNavItem = ({
  id,
  link,
  partiallyActive,
  match,
  withClientRoute,
  children,
  ...props
}: NavItemProps) => {
  const text = useNavItemText(id);
  const isMatching = useNavMatching(match);
  const LinkComponent = withClientRoute ? BrowserLink : Link;

  return (
    <LinkComponent
      {...linkProps}
      to={link}
      partiallyActive={!!partiallyActive}
      activeClassName="active"
      className={isMatching ? 'active' : ''}
      sx={linkStyles}
      {...props}
    >
      {text}
      {children}
    </LinkComponent>
  );
};
