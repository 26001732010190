import React from 'react';

import { ContactSection, FinnoscoreBanner } from '@composite';
import { CompactSection, PureSection, SectionWithContent, SvgIcon, ThemedIcon } from '@core';
import { Flex } from '@themed';
import { StyledTestimonals } from '../Testimonals';
import { FinniRevealCaseTeasers } from '../casestudies';
import { FinnoscoreWorkshop } from './FinnoscoreWorkshop';
import { testimonialStyles } from './Testimonials.styles';
import { styles } from './index.styles';

export const WorkshopPage = () => {
  const testimonialRef = React.useRef(null);
  const servicesRef = React.useRef(null);
  const finnoWorkshopRef = React.useRef(null);

  return (
    <React.Fragment>
      <PureSection sx={styles}>
        <FinnoscoreBanner />
        <CompactSection titleAs="h1" id="workshops.init">
          <FinniRevealCaseTeasers triggerRef={() => finnoWorkshopRef} />
        </CompactSection>
      </PureSection>

      <SectionWithContent id="workshops-workshop-types" sx={{ zIndex: 111, position: 'relative', bg: 'primary' }} ref={finnoWorkshopRef}>
        <FinnoscoreWorkshop triggerRef={() => finnoWorkshopRef} />
      </SectionWithContent>

      <SectionWithContent id="workshops-testimonials" ref={testimonialRef} variant="styles.layoutMaxWidth" sx={styles.testimonials.section}>
        <Flex sx={styles.testimonials.content} variant="flex.colCenter">
          <StyledTestimonals styles={testimonialStyles} />
        </Flex>
      </SectionWithContent>

      <ContactSection id="finnoscore.contact" triggerRef={() => servicesRef}>
        <ThemedIcon sx={{ variant: 'images.pureCircleIcon' }}><SvgIcon name="rocket" /></ThemedIcon>
      </ContactSection>
    </React.Fragment>
  );
};
