import { SxStyleProp } from 'theme-ui';

export const stackRow = {
  margin: '5px 0px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  logo: {
    height: '30px',
    width: '30px',
    borderRadius: 999,
  },
  text: {
    fontSize: '12px',
    marginLeft: '5px',
  },
};
export const stackContainer = (display = false) => ({
  display: display ? 'inline-block' : 'none',
  position: 'fixed',
  zIndex: 9999,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0,0,0,0.6)',
}) as SxStyleProp;

export const stackModal = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '10px',
  backgroundColor: '#fefefe',
  margin: '15% auto',
  padding: '30px',
  width: 'max-content',
  maxWidth: '500px',
  height: 'max-content',
  minHeight: '200px',
  maxHeight: '500px',
} as SxStyleProp;

export const stackModalTitleRow = {
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
};
export const stackModalUpdatedInstitutions = {
  overflowY: 'auto',
  overflowX: 'hidden',
} as SxStyleProp;

export const stackModalButtons = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
} as SxStyleProp;
