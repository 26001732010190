import { useEffect, useState } from 'react';
import { useSelectedCountry } from '@modules';
import { Institution } from '@types';

interface Props {
  institution?: Institution | null;
  institutionList?: Institution[];
  maxLength?: number;
}
function useCountryInstitutionSelection({
  institution, institutionList, maxLength = 10,
}:Props) {
  const [limitedList, setLimitedList] = useState<Institution[]>([]);
  const selectedCountry = useSelectedCountry();
  const [countryCode, limit] = selectedCountry.countryCode.split('_') || [];

  useEffect(() => {
    const institutionsByCountry = institutionList?.filter(institute => institute.country?.countryCode===countryCode);
    const limited = (countryCode === 'ALLE' ? institutionList : institutionsByCountry)?.slice(0, +limit);
    if (limited) setLimitedList(limited);
  }, [selectedCountry, institutionList]);

  const [firstWord, secondWord] = institution?.name.split(' ') || [];

  let similars = firstWord ? institutionList?.filter(ins => ins.name.includes(firstWord)) : [];

  // Need this because of "Bank Austria"...
  if (similars && similars?.length > maxLength) {
    similars = institution ? similars?.filter(ins => ins.name.includes(`${firstWord} ${secondWord}`)) : [];
  }

  return { limitedList, similars: similars?.slice(0, maxLength) };
}

export default useCountryInstitutionSelection;
