/** @jsx jsx */
import { Box, Flex, jsx, SxProps } from 'theme-ui';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as CarouselDepenedency } from 'react-responsive-carousel';
import { Props as CarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel';
import { ReactChild } from 'react';
import { Children, Direction } from '@types';
import { SvgIcon } from '../core/ui';
import { arrowStyles, styles } from './Carousel.styles';

interface ArrowProps {
  clickHandler: () => void;
  direction: Direction;
}

const Arrow = ({ clickHandler, direction }: ArrowProps) => <Flex
  variant="flex.colCenter"
  onClick={clickHandler}
  sx={arrowStyles.box(direction)}
  className="carousel-arrow"
>
  <SvgIcon sx={arrowStyles.icon(direction)} name="arrow" />
</Flex>;

interface Props extends SxProps, Partial<CarouselProps> {
  children: ReactChild[];
  arrow?:boolean,
  autoPlay?:boolean,
}

export const CarouselBox = ({ children }: { children: Children}) => <Box sx={styles.box}>{children}</Box>;

export const Carousel = ({
  children, arrow = true, autoPlay = false, ...props
}: Props) => <CarouselBox>
  <CarouselDepenedency
    autoPlay={autoPlay}
    swipeable
    swipeScrollTolerance={0}
    emulateTouch
    infiniteLoop
    renderArrowNext={clickHandler => arrow && <Arrow direction="right" clickHandler={clickHandler} />}
    renderArrowPrev={clickHandler => arrow && <Arrow direction="left" clickHandler={clickHandler} />}
    showStatus={false}
    showIndicators={false}
    showThumbs={false}
    {...props}
  >
    {children}
  </CarouselDepenedency>
</CarouselBox>;
