export const styles = {
  box: {
    variant: 'images.fullWidth',
    flex: '0 0 auto',
    overflow: 'hidden',
    transformStyle: 'preserve-3d',
  },
  imagePlace: {
    width: '100%',
    height: '100%',
  },
  image: {
    variant: 'images.parallax',
  },
};
