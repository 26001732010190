import { Box, BoxProps } from 'theme-ui';
import React from 'react';
import { ChildrenProps } from '@types';
import { barStyles } from './Bar.styles';

interface BarProps extends ChildrenProps, BoxProps { }

export const Bar = React.forwardRef<HTMLDivElement, BarProps>(({ children, ...props }, ref) => <Box ref={ref} {...props} sx={{ ...barStyles, ...props.sx }} className={props.className}>
  {children}
</Box>);
