import * as React from "react";

const SvgTailedArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={12}
    viewBox="0 0 15 12"
    {...props}
  >
    <path
      d="M14.5 6L10.089.5 8.558 1.722l2.648 3.301L.5 5.022v1.956l10.706.001-2.648 3.299 1.531 1.222z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgTailedArrow;
