import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const imageStyles = {
  multipleImages: (imageLength: number) => {
    const normalWideWidth = `${92/imageLength}vh`;
    const extraWideWidth = '80%';

    const normalHeight = '50vh';
    const extraHeight = '40vh';

    return {
      width: [normalWideWidth, normalWideWidth, normalWideWidth, normalWideWidth, normalWideWidth, extraWideWidth],
      height: [normalHeight, normalHeight, normalHeight, normalHeight, normalHeight, extraHeight],
      [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
        marginLeft: `${4 * imageLength}%`,
        transform: 'scale(0.85)',
      },
    };
  },
  shadow: {
    width: '100%',
    height: 'auto',
  },
  imageContainer: {
    gridArea: 'images',
    transformOrigin: 'center bottom',
    position: 'relative',
  } as SxStyleProp,
  shadowContainer: {
    width: '80%',
    height: 'auto',
    position: 'absolute',
    bottom: '-20%',
    left: '10%',
    opacity: 0.5,
  } as SxStyleProp,
};
