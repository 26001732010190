import React from 'react';
import { useLocale, translateInstitutionDimensionForLocale } from '@modules';
import { FsTypeBase, Institution } from '@types';
import { getHistoryNationalWordwideByInstitution } from '../../networking';

function useMultiChartInstitutions(type?: string, pastPeriod?: FsTypeBase<string>, currentPeriod?: FsTypeBase<string>, institutionName?: string, countryCode?: string) {
  const locale = useLocale();
  const [national, setNational] = React.useState<Institution | null>(null);
  const [current, setCurrent] = React.useState<Institution | null>(null);
  const [relative, setRelative] = React.useState<Institution | null>(null);
  const [worldwide, setWorldwide] = React.useState<Institution | null>(null);

  React.useEffect(() => {
    async function fetchData() {
      setRelative(null);
      setCurrent(null);
      setWorldwide(null);
      setNational(null);
      if (type && institutionName && countryCode && pastPeriod && currentPeriod) {
        const data = await getHistoryNationalWordwideByInstitution(type, pastPeriod, currentPeriod, institutionName, countryCode);
        if (data && data.current && data.relative) {
          setRelative(translateInstitutionDimensionForLocale(data.current, data.tranlations, locale));
          setCurrent(translateInstitutionDimensionForLocale(data.relative, data.tranlations, locale));
          setNational(translateInstitutionDimensionForLocale(data.national, data.tranlations, locale));
          setWorldwide(translateInstitutionDimensionForLocale(data.worldwide, data.tranlations, locale));
        }
      }
    }
    fetchData();
  }, [institutionName, countryCode, pastPeriod, currentPeriod]);

  return {
    current,
    relative,
    national,
    worldwide,
    isLoading: (!current || !relative || !national || !worldwide),
  };
}

export default useMultiChartInstitutions;
