import { Slugs } from '@core';
import { Author, AuthorFromApi } from './Author';
import { Locale } from './Locale';
import { Tag, TagFromApi } from './Tag';
import { Video } from './Video';
import { PageMeta } from './Seo';

export interface ImageFormat {
  url: string;
  width: number;
  height: number;
  hash: string;
  mime: string;
  ext: string;
}

export type AvailableFormat = 'large' | 'medium' | 'thumbnail';
export interface ImageFormats {
  // see finno-cms/config/plugin.js for exact dimension definition
  medium?: ImageFormat; // used by SEO and on lists (ie blog or case study)
  large?: ImageFormat; // used by media content, covers, with full image details
  thumbnail?: ImageFormat; // used by thumbnails, icons, cannot be overridden, default strapi config is THUMBNAIL_RESIZE_OPTIONS = 245x156
}
export interface Image {
  url: string;
  alternativeText?: string;
  formats?: ImageFormats;
}

export interface QuoteBase {
  name: string;
  jobTitle: string;
  text: string;
}

export interface QuoteFromApi extends QuoteBase {
  profile: Image;
}

export interface Quote extends QuoteBase {
  profile: Image;
}

export interface TabbedImageFromApi {
  title: string;
  image: Image;
  selected?: boolean;
}

export interface TabbedImage {
  title: string;
  image: Image;
  selected?: boolean;
}

interface VideoFromApi {
  headline?: string;
  video?: {
    url: string;
  }
  youtubeVideo?: string;
}

interface ButtonFromApi {
  link: string;
  title: string;
}

export interface ContentFromApi {
  images?: Image[];
  image?: Image;
  text?: string;
  quote?: QuoteFromApi;
  tabbedImages?: TabbedImageFromApi[];
  video?: VideoFromApi;
  button?: ButtonFromApi
}
export interface ContentWithText {
  text?: string;
}
export interface ContentWithImageList {
  images?: Image[];
}
export interface ContentWithImage {
  image?: Image;
}
export interface ContentWithQuote {
  quote?: Quote;
}
export interface ContentWithTabbedImageList {
  tabbedImages?: TabbedImage[];
}
export interface ContentWithVideo {
  video?: Video;
}

export interface ContentWithButton {
  button?: ButtonFromApi;
}

export type Content = ContentWithImageList | ContentWithText | ContentWithImage | ContentWithQuote | ContentWithTabbedImageList | ContentWithVideo | ContentWithButton;

export function hasContentText(part: Content): part is Required<ContentWithText> {
  return !!((part as ContentWithText).text);
}
export function hasContentImageList(part: Content): part is Required<ContentWithImageList> {
  const partWithImageList = (part as ContentWithImageList);
  return !!(partWithImageList.images && partWithImageList.images?.length > 0);
}
export function hasContentImage(part: Content): part is Required<ContentWithImage> {
  return !!((part as ContentWithImage)?.image?.url);
}
export function hasContentQuote(part: Content): part is Required<ContentWithQuote> {
  return !!((part as ContentWithQuote).quote?.text || (part as ContentWithQuote).quote?.name);
}
export function hasContentTabbedImageList(part: Content): part is Required<ContentWithTabbedImageList> {
  const partWithTabbedImageList = (part as ContentWithTabbedImageList);
  return !!(partWithTabbedImageList.tabbedImages && partWithTabbedImageList?.tabbedImages?.length > 0);
}
export function hasContentVideo(part: Content): part is Required<ContentWithVideo> {
  return !!((part as ContentWithVideo).video?.url || (part as ContentWithVideo).video?.youtubeUrl);
}

export function hasContentButton(part: Content): part is Required<ContentWithButton> {
  return !!((part as ContentWithButton).button || (part as ContentWithButton).button?.title);
}

export const getVideoContent = (content: PageMeta | undefined) => {
  const videoContent = content?.content?.find((section): section is ContentWithVideo => hasContentVideo(section) && (!!section.video?.url || !!section.video?.youtubeUrl));
  return videoContent?.video;
};

export const getButtonContent = (content: PageMeta | undefined) => {
  const buttonContent = content?.content?.find((section) : section is ContentWithButton => hasContentButton(section) && (!!section.button?.link || !!section.button?.title));
  return buttonContent?.button;
};

export const findFirstContentWithText = (contents?: Content[]) => contents?.find(section => hasContentText(section)) as Required<ContentWithText> | null;
export const findFirstContentWithButton = (contents?: Content[]) => contents?.find(section => hasContentButton(section)) as Required<ContentWithButton> | null;
export const findFirstContentWithImage = (contents?: Content[]) => contents?.find(section => hasContentImage(section)) as Required<ContentWithImage> | null;
export const findFirstContentWithImageList = (contents?: Content[]) => contents?.find(section => hasContentImageList(section)) as Required<ContentWithImageList> | null;
export const findFirstContentWithTabbedImageList = (contents?: Content[]) => contents?.find(section => hasContentTabbedImageList(section)) as Required<ContentWithTabbedImageList> | null;

export interface ArticleContentBase {
  // NOTE: `slug` still might be empty on draft articles in database, but those entries shall not be anyway queried by blog-preiew, as it would always give empty result, so assuming slug is always there is FINE!
  slug: string;
  // NOTE: following fields are set to mandatory in Strapi for PUBLISHED articles, however for Draft articles (shown by blow-preview) we might have it undefined
  title?: string;
  subtitle?: string;
  published?: boolean;
  publishDate?: Date;
  publishedAt?: Date;
  modified?: Date;
}

export interface ArticleFromApiImages {
  thumbnails?: Image[];
  covers?: Image[];
}

export interface ArticleContentFromApi extends ArticleFromApiImages, ArticleContentBase {
  // TODO: shall we make mandatory these in Strapi model?
  author?: AuthorFromApi;
  tags?: TagFromApi[];
  content?: ContentFromApi[];
}

export interface ThumbnailProps {
  thumbnails?: Image[];
}

export interface ArticleImages extends ThumbnailProps {
  covers?: Image[];
}
export interface ArticleContent extends ArticleImages, ArticleContentBase {
  author?: Author;
  tags?: Tag[];
  content?: Content[];
}

export interface ArticleFromApi extends ArticleContentFromApi {
  'en'?: ArticleFromApi;
  'fr'?: ArticleFromApi;
  'de'?: ArticleFromApi;
}

export interface Article extends ArticleContent {
  language: Locale;
  slug: string;
  slugs: Slugs;
}

export interface ArticleNode {
  node: ArticleFromApi[];
}
