type WhereCondition = string;

interface WhereGenerator {
  [key: string]: (slug: string) => string;
}

const articleSelectors = [{
  to: 'en',
  from: 'english_article',
}, {
  to: 'fr',
  from: 'french_article',
}];

const query = (where: WhereCondition) => `{
  articles(${where}publicationState: PREVIEW) {
    published
    slug
    subtitle
    title
    publishDate: publish_date
    author {
      id
      name
      text
      textEn
      textFr
      title
      titleEn
      titleFr
      description
      descriptionEn
      descriptionFr
      slug
      slugEn
      slugFr
      avatar {
        url
        alternativeText
        formats
      }
    }
    tags {
      slug
      slugEn
      slugFr
      tag
      tagEn
      tagFr
      description
      descriptionEn
      descriptionFr
      articles {
        title
        subtitle
        thumbnails: thumbnail {
          url
          alternativeText
          formats
        }
        slug
        publishDate: publish_date
        ${articleSelectors.map(selector => `${selector.to}: ${selector.from} {
          title
          subtitle
          slug
          publishDate: publish_date
        }`)}
      }
    }
    thumbnails: thumbnail {
      url
      alternativeText
      formats
    }
    covers: cover {
      url
      alternativeText
      formats
    }
    content {
      __typename
      ... on ComponentArticleSingleImage {
        image {
          url
          alternativeText
          formats
        }
      }
      ... on ComponentArticleText {
        text
      }
      ... on ComponentArticleMultipleImageGallery {
        images {
          url
          alternativeText
          formats
        }
      }
    }
    ${articleSelectors.map(selector => `${selector.to}: ${selector.from}{
      id
      title
      publishDate: publish_date
      de: german_article {
        publishDate: publish_date
      }
      subtitle
      slug
      content {
        __typename
        ... on ComponentArticleSingleImage {
          image {
            url
            alternativeText
           formats
          }
        }
        ... on ComponentArticleText {
          text
        }
        ... on ComponentArticleMultipleImageGallery {
          images {
            url
            alternativeText
            formats
          }
        }
      }
    }`)}
  }
}`;

const where: WhereGenerator = {
  de: (slug: string) => (`where: { slug: "${slug}" }, `),
  en: (slug: string) => (`where: { english_article: { slug: "${slug}" } }, `),
  fr: (slug: string) => (`where: { french_article: { slug: "${slug}" } }, `),
};

export const getQuery = (locale: string, slug: string) => {
  const localeQuery = (where[locale] && query(where[locale](slug))) ||'';
  return localeQuery;
};
