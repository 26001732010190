import { Institution } from '@types';

interface Props {
  institutionList: Institution[]
  currentInstitutionName: string | undefined
  relativeInstitutionName: string | undefined
}
function useComparisonInstitutionSelection({
  currentInstitutionName, institutionList, relativeInstitutionName,
}:Props) {
  if (institutionList && currentInstitutionName && relativeInstitutionName) {
    const relativeInstitution = institutionList.filter(institution => institution.name === relativeInstitutionName);
    const currentInstitution = institutionList.filter(institution => institution.name === currentInstitutionName);
    return { currentInstitution: currentInstitution?.[0], relativeInstitution: relativeInstitution?.[0] };
  }
  return { currentInstitution: undefined, relativeInstitution: undefined };
}

export default useComparisonInstitutionSelection;
