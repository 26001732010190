import React from 'react';
import { ListItem } from '@core';
import { Children, ComparisonSubject, ComparisonSubjectType } from '@types';
import { CountryCalculationDropDown } from './CountryCalculationDropDown';

interface CountryCalculationDropDownWithStateProps {
  setSelected: (value: string) => void;
  countries: ListItem[];
  selectedCalculation: ComparisonSubject;
  header?: Children;
}

function ComparisonDropDownWithState({ countries, selectedCalculation, setSelected, ...props }:CountryCalculationDropDownWithStateProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <CountryCalculationDropDown
      {...props}
      countries={countries}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSelect={value => {
        setIsOpen(false);
        setSelected(`${ComparisonSubjectType.CountryCalculation}.${value}`);
      }}
      selectedCalculation={selectedCalculation}
    />
  );
}

export default ComparisonDropDownWithState;
