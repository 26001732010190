export const convertDotsToCommas = (n: number | string) => {
  const str = n.toString();
  return str.replace('.', ',');
};

const roundScore = (strValue?: string | number) => Math.round(+`${strValue}` * 100) / 100;

export const getScore = (str?: string | number, fallback= 'n/a') => (!Number.isNaN(+`${str}`) ? roundScore(str).toFixed(2) : fallback);

export const getNumericValue = (str: string | number | undefined, fallback= 0) => (Number(str) || fallback);

export const getBoundedNumericValue = (input: string | number | undefined, { min, max }={ min: 0, max: 10 }) => {
  const value = getNumericValue(input);
  if (value < min) return min;
  if (value > max) return max;
  return value;
};
