/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React from 'react';
import { sectionStyles } from 'src/components/core/layout/Section.styles';
import { ImageLoader, CompactSectionContent, CompactSectionContentStyles, Flex, ParallaxBackgroundImage } from '@core';
import { selectResponsiveProp } from '@themed';
import { ChildrenProps } from '@types';
import { useTranslate } from '@modules';
import { styles } from './Howtodoit.styles';

export const HowtodoitBackground = (props: ChildrenProps) => {
  const images = ['zak-blue-mobile.jpg', 'zak-blue-mobile.jpg', 'zak-blue.jpg'];
  const __ = useTranslate();

  // prepare for desktop by default
  const [src, setSrc] = React.useState<string>('zak-blue-mobile.jpg');

  const setSrcFromProp = () => {
    if (selectResponsiveProp(images) !== src) {
      setSrc(selectResponsiveProp(images));
    }
  };

  React.useEffect(() => {
    setSrcFromProp();
    // TODO: we can set again the corresponding image for the actual resolution, however it destroys the parallax animation for the new resolution (but restoring the size the original will work)
    // NOTE: I believe this is still way better to show an image with correct resolution and aligment, without parallax, so keep for for a later FIX.
    window.addEventListener('resize', setSrcFromProp);
    return () => window.removeEventListener('resize', setSrcFromProp);
  }, []);

  if (!src) return <React.Fragment>props.children</React.Fragment>;

  return <ParallaxBackgroundImage src={src} alt={__('pages.landing.howtodoit.parallaxBgAlt')} {...props} sx={styles.background} />;
};

export const Howtodoit = () => (
  <Box sx={{ ...sectionStyles.sectionContent, ...styles.sectionContentBox }}>
    <CompactSectionContentStyles sx={styles.sectionContent}>
      <CompactSectionContent id="landing.howtodoit" buttonVariant="ctaInvert">
        <Flex sx={styles.iconList} data-cy="howtodoit-list">
          <ImageLoader src="consantinus-award.png" alt="Consantinus award" sx={styles.icon} />
          <ImageLoader src="zak-handelsblatt-award.png" alt="Zak Handelsblatt award" sx={styles.iconShrink} />
          <ImageLoader src="judged-awards-icon.png" alt="Judged awards" sx={styles.icon} />
          {/* <ImageLoader src="teo-capital-award.jpg" sx={styles.iconShrink} /> */}
        </Flex>
      </CompactSectionContent>
    </CompactSectionContentStyles>
  </Box>
);
