import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    visibility: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 'browserHeight',
    py: 4,
    px: 4,
    bg: 'primary',
    overflow: 'auto',
    zIndex: 'mobileNav',
  } as SxStyleProp,
  header: {
    justifyContent: 'space-between',
    marginBottom: 7,
  },
  logo: {
    position: 'relative',
    zIndex: 'logo',
  },
  navigationButton: {
    display: 'inline-block',
    borderRadius: '50%',
    width: 6,
    height: 6,
    padding: 2,
    cursor: 'pointer',
  },
};

export const navigationButtonClose = {
  ...styles.navigationButton,
  bg: 'background',
};
