/** @jsx jsx */
import { Link } from '@core';
import { getComparisonQueryParams, useComparisonLink } from '@modules';
import { jsx, Flex, Text, Image } from '@themed';
import { ChildrenProps, Institution } from '@types';
import { TrendComponent, ScoreComponent } from './Trend';
import { itemStyles, useInstitutionItemLayoutStyles } from './InstitutionItem.styles';

interface InstitutionProps {
  institution: Institution;
}

interface LinkComponentProps extends InstitutionProps, ChildrenProps {
}
const LinkComponent = ({ institution, children }: LinkComponentProps) => {
  const [a, b] = getComparisonQueryParams(institution);
  const relativeComparison = (institution.fsType === 'privatebank' || institution.fsType === 'bankMiddleEast') ? 'fs_country_calculations.ALLE.average' : a;
  const comparisonLink = useComparisonLink(relativeComparison, b, institution.fsType);
  const layoutStyles = useInstitutionItemLayoutStyles();
  return <Link to={comparisonLink} sx={layoutStyles.link}>{children}</Link>;
};

export interface InstitutionItemProps extends InstitutionProps{
  index: number;
}
export const InstitutionItem = ({ institution, index }: InstitutionItemProps) => {
  const layoutStyles = useInstitutionItemLayoutStyles();

  return (
    <Flex data-cy="finnoscore-institution-item" sx={layoutStyles.row}>
      <LinkComponent institution={institution}>
        {institution?.logo ? <Image src={institution.logo?.url} alt={institution.logo?.alternativeText} sx={itemStyles.logo} /> : <Text sx={itemStyles.logo}>&nbsp;</Text>}
        <Text sx={itemStyles.rank}>{index+1}</Text>
        <Text data-cy="finnoscore-institution-name" sx={itemStyles.name}>{institution.name}</Text>
        <Text sx={itemStyles.country}>{institution.country.name || institution.country.countryCode}</Text>
        <TrendComponent sx={itemStyles.trend} trend={institution.trend} />
        <ScoreComponent sx={itemStyles.score} score={institution.total} />
      </LinkComponent>
    </Flex>
  );
};
