/** @jsx jsx */
import { Box, Flex, jsx, SxProps, Text } from 'theme-ui';
import { Quote as QuoteType } from '@types';
import { Paragraph, UserAvatar } from '@core';
import { styles } from '../Quote.styles';

interface Props extends SxProps {
  quote: QuoteType;
}

export const Quote = ({ quote, ...props }: Props) => (
  <Box {...props} sx={{ ...styles.box, ...props.sx }}>
    <Box sx={styles.background} />
    <Flex>
      <Flex variant="flex.rowCenter">
        <UserAvatar sx={styles.profile} src={quote.profile.url} alt={quote.profile.alternativeText} />
        <Flex sx={styles.quoteTexts}>
          <Text sx={styles.name}>{quote.name}</Text>
          <Text sx={styles.jobTitle}>{quote.jobTitle}</Text>
        </Flex>
      </Flex>
    </Flex>
    <Paragraph>{quote.text}</Paragraph>
  </Box>
);
