/** @jsx jsx */
import { getScore, useTranslate } from '@modules';
import { Box, Grid, jsx, SxStyleProp, Text } from '@themed';
import { Comparison, Dimensions } from '@types';
import { DoubleChartItem } from '../chart-generator/components/BasicComponents/DoubleChartItem';
import { headerStyles, rowStyles, styles } from './Charts.styles';

interface HeaderProps {
  sx?: SxStyleProp;
}
export const Header = ({ sx, ...props }: HeaderProps) => {
  const __ = useTranslate();
  const dimensionsText = __('common.dimensions');
  const finnoscoreText = __('finnoscore.list.finnoscore');

  return (
    <Grid sx={{ ...headerStyles.box, ...sx }} {...props}>
      <Text sx={headerStyles.text}>{dimensionsText}</Text>
      <Text sx={headerStyles.text}>{finnoscoreText}</Text>
    </Grid>
  );
};

interface RowData {
  dimension: keyof Dimensions;
  scoreA: string;
  scoreB: string;
}

const Row = ({ dimension, scoreA, scoreB }: RowData) => {
  const getPercentageFromString = (str: string) => (!Number.isNaN(+str) ? +str * 10 : 0);
  const percentageA = getPercentageFromString(scoreA);
  const percentageB = getPercentageFromString(scoreB);
  return (
    <Grid data-testid="finnoscoreRow" sx={rowStyles.row}>
      <Text sx={rowStyles.dimensionName}>{dimension}</Text>
      <Text sx={{ ...rowStyles.score, ...rowStyles.scoreA }}>{scoreA}</Text>
      <Text sx={{ ...rowStyles.score, ...rowStyles.scoreB }}>{scoreB}</Text>
      <DoubleChartItem
        sx={rowStyles.chartItem}
        percentages={{
          a: percentageB,
          b: percentageA,
        }}
      />
    </Grid>
  );
};

interface Props {
  comparison: Comparison;
}

export default ({ comparison }: Props) => {
  const getDimensions = () => {
    const aDimensions = comparison.a.dimensions;
    const bDimensions = comparison.b.dimensions;

    return aDimensions.map((_, index) => ({
      name: bDimensions[index]?.key || 'n/a',
      scoreA: getScore(aDimensions[index].value),
      scoreB: getScore(bDimensions[index]?.value || 'n/a'),
    }));
  };

  return <Box>
    <Header />
    <Box sx={styles.dataBox}>
      {getDimensions().map(dimension => <Row key={dimension.name} dimension={dimension.name as keyof Dimensions} scoreA={dimension.scoreA} scoreB={dimension.scoreB} />)}
    </Box>
  </Box>;
};
