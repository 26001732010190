import * as React from "react";

const SvgPodium = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={120}
    height={120}
    viewBox="0 0 120 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="podium_svg__podium"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <circle id="podium_svg__Oval-Copy" fill="#FFF" cx={60} cy={60} r={60} />
      <path id="podium_svg__Rectangle" fill="#000" d="M23 61h24v20H23z" />
      <path id="podium_svg__Rectangle-Copy" fill="#000" d="M73 64h24v17H73z" />
      <path
        id="podium_svg__Rectangle-Copy-2"
        fill="#00BD59"
        d="M48 50h24v31H48z"
      />
      <g id="podium_svg__Group" transform="translate(54 54)">
        <circle id="podium_svg__Oval" fill="#FFF" cx={6} cy={7} r={6} />
        <text
          id="podium_svg__1"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={9}
          fontWeight="bold"
          letterSpacing={0.044}
          fill="#000"
        >
          <tspan x={3.41} y={10}>
            {"1"}
          </tspan>
        </text>
      </g>
      <g id="podium_svg__Group-Copy" transform="translate(79 66)">
        <circle id="podium_svg__Oval" fill="#FFF" cx={6} cy={7} r={6} />
        <text
          id="podium_svg__3"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={9}
          fontWeight="bold"
          letterSpacing={0.044}
          fill="#000"
        >
          <tspan x={3.41} y={10}>
            {"3"}
          </tspan>
        </text>
      </g>
      <g id="podium_svg__Group-Copy-2" transform="translate(29 64)">
        <circle id="podium_svg__Oval" fill="#FFF" cx={6} cy={7} r={6} />
        <text
          id="podium_svg__2"
          fontFamily="OpenSans-Bold, Open Sans"
          fontSize={9}
          fontWeight="bold"
          letterSpacing={0.044}
          fill="#000"
        >
          <tspan x={3.41} y={10}>
            {"2"}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default SvgPodium;
