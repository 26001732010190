import * as React from "react";

const SvgRocket = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={62}
    height={63}
    viewBox="0 0 62 63"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="rocket_svg__Symbols"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="rocket_svg__Abschluss/finnoscore" transform="translate(-27 -31)">
        <g id="rocket_svg__rocket" transform="translate(27 31)">
          <path
            d="M16.407 47.02c2.292 3.366 2.678 7.105.691 9.09-2.87 2.867-6.254 3.151-9 2.797-2.746-.353-6.162 4.2-7.06 3.303-.513-.512-3.475-7.774 3.715-13.653C2.236 46.997.721 48.51.15 47.94c-.49-.49 1.17-3.451 3.243-5.522 1.986-1.985 5.729-1.599 9.099.69l-.457.456c-2.554 2.552-3.074 6.169-2.667 6.576.408.408 4.029-.112 6.583-2.664l.456-.456z"
            id="rocket_svg__Path1"
            fill="#00BD59"
          />
          <path
            d="M54.14 2.196c2.5-.366 4.711-.815 5.227-.305.513.507.05 2.692-.316 5.128-.455 3.033-1.38 6.81-2.796 10.699-2.047 5.627-5.113 11.51-9.315 15.662-2.285 2.258-4.892 4.378-7.632 6.25h0l.209 2.364a9.784 9.784 0 01-2.699 7.635h0l-1.856 1.84-3.84-7.184c-2.857 1.303-5.659 2.245-8.203 2.686h0l-9.21-9.103c.454-2.537 1.423-5.332 2.762-8.18h0l-7.387-3.346 1.574-2.136a10.082 10.082 0 017.952-2.86h0l2.615.227c1.87-2.655 3.978-5.18 6.221-7.398 4.205-4.156 10.16-7.188 15.857-9.213 3.94-1.4 7.764-2.316 10.836-2.766z"
            id="rocket_svg__Path2"
            stroke="#000"
            strokeWidth={3.75}
            fill="#000"
          />
          <path
            d="M45.694 14.674c-2.344-2.35-6.127-2.368-8.449-.04-2.322 2.329-2.305 6.12.04 8.47 2.343 2.35 6.126 2.368 8.448.04 2.322-2.329 2.305-6.12-.04-8.47z"
            id="rocket_svg__Path3"
            fill="#FFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgRocket;
