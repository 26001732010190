/** @jsx jsx */
import React, { forwardRef, useRef } from 'react';
import { jsx } from 'theme-ui';
import { Box, CompactSection, CTAWithOptionalLink } from '@core';
import { useLocale, useSelectedFsType, useTranslate } from '@modules';
import CompareThroughGlasses from './CompareThroughGlasses';
import { useAnimationEyeglasses } from './animateCompareEyeglasses';
import { styles } from './Compare.styles';

export const Compare = forwardRef<HTMLDivElement, unknown>(() => {
  const triggerRef = useRef(null);
  const boxRef = useRef(null);
  const __ = useTranslate();
  const lang = useLocale();
  const [type] = useSelectedFsType();
  const showMECTAButton = lang === 'en';

  useAnimationEyeglasses({
    triggerRef: () => triggerRef,
    boxRef: () => boxRef,
  });

  return (
    <CompactSection
      sx={styles.section(showMECTAButton)}
      ref={triggerRef}
      id={`finnoscore.compare.${type}`}
      contentBottom={(
        <React.Fragment>
          <CTAWithOptionalLink to={__(`pages.finnoscore.compare.${type}.button2Link`)}>
            {__(`pages.finnoscore.compare.${type}.button2`)}
          </CTAWithOptionalLink>
          <CTAWithOptionalLink to={__(`pages.finnoscore.compare.${type}.button3Link`)}>
            {__(`pages.finnoscore.compare.${type}.button3`)}
          </CTAWithOptionalLink>

          {showMECTAButton && (
            <CTAWithOptionalLink to={__(`pages.finnoscore.compare.${type}.button4Link`)} buttonVariant="info">
              {__(`pages.finnoscore.compare.${type}.button4`)}
            </CTAWithOptionalLink>
          )}

        </React.Fragment>
      )}
    >
      <Box sx={styles.box} ref={boxRef}>
        <CompareThroughGlasses />
      </Box>
    </CompactSection>
  );
});
