import React from 'react';
import { Title, SubTitle } from '@themed';
import { ArticleContent } from '@types';
import { ImageLoader } from '@core';
import { PostContentContainer } from './PostContentContainer';
import { PostAuthor } from './PostAuthor';
import { styles } from './PostHeader.styles';

export interface PostHeaderProps extends Omit<ArticleContent, 'slug'> {
}

export const PostHeader = ({
  title,
  publishDate,
  subtitle,
  covers,
  author,
}: PostHeaderProps) => (
  <React.Fragment>
    <PostContentContainer>
      <Title as="h1" sx={styles.title}>
        {title}
      </Title>
    </PostContentContainer>
    {
      subtitle && (
        <PostContentContainer>
          <SubTitle sx={styles.subtitle}>
            {subtitle}
          </SubTitle>
        </PostContentContainer>
      )
    }
    {
      author && (
        <PostAuthor
          {...author}
          link={author.slug}
          date={publishDate}
        />
      )
    }
    {
      covers && (
        <PostContentContainer sx={styles.image}>
          {covers.map(cover => <ImageLoader key={cover.url} src={cover.url} alt={cover.alternativeText} />)}
        </PostContentContainer>
      )
    }
  </React.Fragment>
);
