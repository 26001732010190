import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    variant: 'flex.center',
    alignItems: 'flex-start',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 'popup',
    backgroundColor: 'black50Percent',
    backdropFilter: 'blur(3px)',
  } as SxStyleProp,
  content: {
    position: 'relative',
    variant: 'flex.center',
    width: 'fullSize',
    height: 'auto',
    overflow: 'visible',
    mx: [6, 7, '15vw'],
    p: 0,
    mb: 0,
    mt: '15vh',
    section: {
      py: [0, 0, 4],
      px: [0, 0, 4, 6],
      pb: 0,
      my: 0,
      '&, & > *': {
        minHeight: 'min-content',
        maxHeight: 'min-content',
        height: 'auto',
      },
    },
  } as SxStyleProp,
  closeButton: {
    position: 'absolute',
    top: -7,
    right: 5,
    color: '#fff',
    width: '19px',
    height: '19px',
    cursor: 'pointer',
    // TODO: internal icon instead of this
    background: 'url(https://assets.calendly.com/assets/external/close-icon.svg) no-repeat',
    backgroundSize: 'contain',
  } as SxStyleProp,
};
