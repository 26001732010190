import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';

export const internationalSliderStyle = (color?: RGBColor, defaultThemeColor = 'primary') => ({
  display: 'flex',
  alignItems: 'center',
  height: '16px',
  position: 'relative',
  '.MuiSlider-root': {
    position: 'relative',
    height: '100%',
    color: '#00bd59',
    '&:hover *': {
      visibility: 'visible',
    },
  },
  '.MuiSlider-thumb': {
    visibility: 'hidden',
    height: 20,
    width: 20,
    backgroundColor: 'background',
    border: `2px solid ${color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : defaultThemeColor}`,
    marginTop: '-3px',
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  '.MuiSlider-valueLabel': {
    right: '100%',
    top: 0,
    color: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : defaultThemeColor,
    '& > .PrivateValueLabel-circle-4': {
      all: 'unset',
      color: 'white',
    },
  },
  '.MuiSlider-track': {
    height: '16px',
    borderRadius: '999px',
    backgroundColor: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : defaultThemeColor,
  },

  '.MuiSlider-rail': {
    backgroundColor: 'transparent',
    height: '16px',
    borderRadius: '999px',
  },
  '.MuiSlider-label': {
    position: 'absolute',
    left: 0,
    top: 0,
    color: 'white',
  },
}) as SxStyleProp;

export const labelStyle: SxStyleProp = {
  height: '16px',
  border: 'none',
  position: 'absolute',
  fontSize: '90%',
  top: '-27px',
  left: 4,
  '&:focus': {
    outline: 'none',
  },
  zIndex: 1,
};
