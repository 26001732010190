/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { CompactSectionContent, Flex, useIsDesktop, ParallaxImage, SectionWithContent, SvgIcon } from '@core';
import { useAllIntlTree, useTranslate } from '@modules';
import { useAnimationJumpingServiceIcons } from './animateJumpingServiceIcons';
import { listStyles, styles } from './Services.styles';

export const Services = React.forwardRef<HTMLDivElement, unknown>((_props, ref) => {
  const messages = useAllIntlTree();
  const isDesktop = useIsDesktop();
  const __ = useTranslate();

  if (Object.keys(messages.pages.finnoscore.services).length !== listStyles.imageN.length) throw new Error('The amount of images and services must be the same');

  useAnimationJumpingServiceIcons({
    sectionRef: () => ref as React.MutableRefObject<HTMLDivElement>,
  });

  return <SectionWithContent data-cy="finnoscore-services" sx={styles.section} ref={ref}>
    <Flex sx={styles.box}>
      {Object.entries(messages.pages.finnoscore.services).map(([key, service], index) => (
        <Flex key={`${key}`} sx={styles.outerBox}>
          <ParallaxImage
            src={__(`pages.finnoscore.services.${key}.imageParallaxAside.url`)}
            alt={__(`pages.finnoscore.services.${key}.imageParallaxAside.alternativeText`)}
            sx={{ ...styles.image, ...listStyles.imageN[index] }}
          />

          <Flex variant="flex.colLeft" sx={styles.innerBox}>
            <SvgIcon
              sx={{
                ...styles.icon,
                ...listStyles.iconN[index],
                opacity: () => (index !== 0 && isDesktop ? 0 : 1),
              }}
              name={service.icon.url}
              alt={service.icon.alternativeText}
              className="serviceIcon"
            />

            <CompactSectionContent
              key={key}
              id={`finnoscore.services.${key}`}
              sx={styles.content}
              icon={service.icon}
              iconSx={styles.icon}
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  </SectionWithContent>;
});
