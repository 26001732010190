/** @jsx jsx */
import { jsx, Box, Flex, Input } from 'theme-ui';
import React from 'react';
import { ImageLoader, SvgIcon, useIsDesktop } from '@core';
import { ChildrenProps, TriggerElementProps } from '@types';
import { isChristmasTime, useLocale, useTranslate } from '@modules';
import { finniStyles } from '@config';
import { useAnimationFinniPacMan } from './animateFinniPacMan';
import { useAnimationFinniRevealCards } from './animateFinniRevealCards';

interface FinniRevealCardsProps extends ChildrenProps, TriggerElementProps {
  image: string;
}

const FoodContainer = ({
  isChristmas,
}: {isChristmas: boolean}) => {
  const isDesktop = useIsDesktop();
  const foodBase = isChristmas
    ? ['bulb-1', 'bulb-2', 'bulb-3', 'bulb-4', 'bulb-5', 'bulb-6']
    : ['bank', 'insurance', 'bank', 'insurance', 'bank', 'insurance'];
  const food = [...foodBase, ...foodBase, ...(isDesktop ? foodBase : [])];
  return (
    <React.Fragment>
      {food.map((name, index) => (
        <SvgIcon key={index} as="figure" name={name} sx={finniStyles.food(isChristmas)} />
      ))}
    </React.Fragment>
  );
};

export const FinniRevealCards = ({ image, triggerRef }: FinniRevealCardsProps) => {
  const __ = useTranslate();
  const locale = useLocale();
  const pacmanRef = React.useRef(null);
  const imageRef = React.useRef(null);

  const pacmanForeheadRef = React.useRef(null);
  const pacmanChinRef = React.useRef(null);
  const pacmanHatRef = React.useRef(null);
  const pacmanTieRef = React.useRef(null);
  const foodContainerRef = React.useRef(null);
  const containerRef = React.useRef(null);

  useAnimationFinniPacMan({
    pacmanForehead: () => pacmanForeheadRef,
    pacmanChin: () => pacmanChinRef,
    pacmanHat: () => pacmanHatRef,
    pacmanTie: () => pacmanTieRef,
    foodContainer: () => foodContainerRef,
    triggerRef,
  });

  useAnimationFinniRevealCards({
    triggerRef,
    container: () => containerRef,
    pacman: () => pacmanRef,
    image: () => imageRef,
  });

  const [date, setDate]= React.useState(new Date());
  const isChristmas = isChristmasTime(date);
  const isDev = false && process.env.NODE_ENV === 'development';

  const tie = isChristmas ? '' : `tie${locale.toUpperCase()}`;

  return (
    <Flex sx={finniStyles.container} ref={containerRef} data-print="no">
      <Flex sx={finniStyles.foodContainer} ref={foodContainerRef}>
        <FoodContainer isChristmas={isChristmas} />
      </Flex>

      <Box data-cy="finni-animation" ref={pacmanRef} sx={finniStyles.pacman}>
        <Box ref={pacmanForeheadRef} sx={finniStyles.pacmanForehead}>
          ForeHead
          {isChristmas && <SvgIcon ref={pacmanHatRef} as="figure" name="santa-hat" sx={finniStyles.hat} />}
        </Box>
        <Box ref={pacmanChinRef} sx={finniStyles.pacmanChin}>Chin</Box>
        <SvgIcon ref={pacmanTieRef} as="figure" name={tie} sx={finniStyles.tie} />

        <Box ref={imageRef} sx={finniStyles.image}>
          <ImageLoader alt={__('pages.landing.neobanks.parallaxBgAlt')} src={image} />
        </Box>
        {isDev && <Input type="text" value={date.toISOString().substring(0, 10)} onChange={(e) => setDate(new Date(e.target.value))} sx={{ color: 'black' }} />}
      </Box>

    </Flex>
  );
};
