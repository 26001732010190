import { headerStyles } from '../../finnoscore-comparison/ComparisonSelector.styles';

export const styles = {
  dropDown: {
    boxShadow: ['none', 'none', '0 0 6px 0 rgba(0, 0, 0, 0.1)'],
  },
  header: {
    ...headerStyles,
    box: {
      ...headerStyles.box,
      variant: 'flex.rowLeft',
    },
    left: {
      variant: 'flex.colCenter',
      mr: 3,
    },
    title: {
      ...headerStyles.title(),
    },
  },
};
