import React from 'react';
import { css, get } from '@styled-system/css';
import themeConfig from '../../../gatsby-plugin-theme-ui';

// helper functions
export function rawThemeProp(key: string | string[]) {
  return get(themeConfig, key);
}
// helper functions
export function themedCSSProps(key: string | string[]) {
  return css(get(themeConfig, key))(themeConfig);
}
export function getBreakpoints(): string[] {
  const bps = get(themeConfig, 'breakpoints');
  return bps;
}
export function getBreakpoint(index: number): string | undefined {
  const bps = getBreakpoints();
  return bps[index];
}

export function getCurrentBreakpointIndex(): number {
  const bps = getBreakpoints();
  const foundIndex = bps.findIndex(bp => window?.matchMedia(`(max-width: ${bp})`).matches);
  return foundIndex>=0 ? foundIndex : bps.length;
}

// type PropsType = CSSInterpolation | string | number | (number | string)[] ;
export function selectResponsiveProp<T>(props: T[]): T {
  const index = getCurrentBreakpointIndex();
  return props[index] || props[props.length-1];
}
export function themedProp(key: string | string[]): string {
  // enforce object to array (if that is not already array), assuming simple prop was received
  const props = Object.values(themedCSSProps(key));

  return `${selectResponsiveProp(props)}`;
}

export function useCurrentBreakpointIndex(): number {
  const [foundIndex, setFoundIndex] = React.useState(-1); // by default we should not have mobile.
  const setIndexFromMediaSize = () => {
    setFoundIndex(getCurrentBreakpointIndex());
  };
  React.useEffect(() => {
    setIndexFromMediaSize();
    window.addEventListener('resize', () => setIndexFromMediaSize());
  }, []);
  return foundIndex;
}
export function useIsPageLoaded() {
  const pb = useCurrentBreakpointIndex();
  return pb >= 0;
}
export function useIsPhone() {
  const pb = useCurrentBreakpointIndex();
  return pb === 0;
}
export function useIsTablet() {
  const pb = useCurrentBreakpointIndex();
  return pb === 1;
}
export function useIsMobile() {
  const pb = useCurrentBreakpointIndex();
  return pb <=1;
}

export function useIsDesktop() {
  const pb = useCurrentBreakpointIndex();
  return pb >= 2;
}
export function useIsWidescreen() {
  const pb = useCurrentBreakpointIndex();
  return pb >= 3;
}
export function useIsExtraWidescreen() {
  const pb = useCurrentBreakpointIndex();
  return pb >= 4;
}
// NIU:
// export function useIsSmallscreen() {
//   const pb = useCurrentBreakpointIndex();
//   return pb <=2;
// }
