import React from 'react';
import { ParallaxImage, CompactSectionContentStyles, CompactSectionContent, SectionWithContent } from '@core';
import { useTranslate } from '@modules';
import { useAnimationList } from './animateList';
import { styles } from './Steps.styles';

const contentBlocks = [
  {
    id: 'rapid.growth', image: 'desktop-people-working.jpg', style: { ...styles.contentImage, ...styles.contentFirstImage },
  },
  {
    id: 'rapid.design', image: 'post-it.jpg', style: styles.contentImage,
  },
  {
    id: 'rapid.develop', image: 'phone-black-circle.jpg', style: styles.contentImage,
  },
  {
    id: 'rapid.scaling', image: 'mobile-browsing.jpg', style: { ...styles.contentImage, ...styles.contentLastImage },
  },
];

export const Steps = () => {
  const ref = React.useRef(null);
  useAnimationList({ triggerRef: () => ref });
  const __ = useTranslate();

  return (
    <SectionWithContent data-cy="rapid-steps" ref={ref} sx={styles.section}>
      {contentBlocks.map(({
        id, image, style,
      }, index) => (
        <CompactSectionContentStyles key={id} sx={{ ...styles.content, ...(index % 2 ? { } : { variant: 'grids.CompactSectionContentRTL' }) }}>
          <CompactSectionContent id={id}>
            <ParallaxImage src={image} sx={style} alt={__(`${id}.parallaxImgAlt`)} />
          </CompactSectionContent>
        </CompactSectionContentStyles>
      ))}
    </SectionWithContent>
  );
};
