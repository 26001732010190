/** @jsx jsx */
import React, { forwardRef } from 'react';
import {
  Box, BoxProps, Flex, Input, jsx,
} from '@themed';
import { SectionWithContent, SvgIcon, Title, Paragraph } from '@core';
import { useTranslate } from '@modules';
import { ChildrenProps } from '@types';
import { styles } from './NewsLetterSignup.styles';

export const DefaultNewsLetterIcon = () => (
  <SvgIcon sx={styles.icon} name="mail" data-cy="contact-svg" />
);
interface NewsLetterSignupProps extends ChildrenProps, BoxProps {
  id?: string;
  onSubmit?: (e: React.FormEvent<any>) => void;
  Icon?: (() => React.FunctionComponent) | JSX.Element;
}

export const NewsLetterSignup = forwardRef<HTMLDivElement, NewsLetterSignupProps>(({
  id, children, onSubmit, Icon, ...props
}, ref) => {
  const __ = useTranslate();

  const url = process.env.GATSBY_MAILCHIMP_URL;
  if (!url) throw new Error('No Mailchimp url found in environment');

  const paragraph = __(`${id}.paragraph`);

  return (
    <SectionWithContent {...props} id="contact" ref={ref} sx={{ ...styles.section, ...props.sx }}>
      <Flex sx={styles.contentBox}>
        <Box className="newsletter-content">

          {Icon || DefaultNewsLetterIcon}
          {id && (
            <React.Fragment>
              <Title as="h2" sx={styles.title} data-cy="contact-title">{__(`${id}.title`)}</Title>
              <Paragraph as="span" className="subTitle" sx={styles.paragraph} data-cy="contact-paragraph">
                {paragraph}
              </Paragraph>
            </React.Fragment>
          )}

          {onSubmit
            ? <form
              sx={styles.form}
              className="validate"
              onSubmit={(e) => onSubmit(e)}
            >
              {children}
            </form>
            : (
              <form
                sx={styles.form}
                action={onSubmit ? '' : url}
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                onSubmit={(() => null)}
              >
                {children}
              </form>
            )}

        </Box>
      </Flex>
    </SectionWithContent>
  );
});

export const BasicProtectionAgainsBots = () => {
  const botInputName = process.env.GATSBY_MAILCHIMP_BOT_INPUT_NAME;
  if (!botInputName) throw new Error('No Mailchimp bot input found in environment');

  return (
    <Box
      style={{
        position: 'absolute',
        left: '-5000px',
      }}
      aria-hidden="true"
    >
      <Input type="text" name={botInputName} tabIndex={-1} />
    </Box>
  );
};
