// import get from 'lodash.get';
import { useIntl } from 'gatsby-plugin-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import { Locale } from '@types';
// gatsby build can't find @core
import { useSiteMeta } from '../components/core/layout/useSiteMeta';
import translation from '../config/intl/en.json';
import translationDe from '../config/intl/de.json';
import translationFr from '../config/intl/fr.json';
import { getDefaultLanguageId, getCanonicalUrl } from './i18n-lang';

export { changeLocale, useIntl } from 'gatsby-plugin-intl';

// NOTE: __() is a hook, but used inline, which is not optimal. Thanks to Gatsby SSG we don't run into issue.
// However we shall use it more wisely in the future with useTranslate. ie:
// ```
// const __ = useTranslate();
// ...
// render (
//   ...
//   <html title={__('translations.any.title.you.want')}>
//   ...
// )
// ```
export const useTranslate = () => {
  const intl = useIntl();
  return (id?: string, emptyNotFound=false) => {
    // NOTE: onError seemed not working, buggy from react-intl, so had to apply a hack with defaultMessage, when we use emptyNotFound
    const directLookup: string | undefined = (!!id && (typeof intl.messages[id] === 'string') ? intl.messages[id] as string : undefined);
    const translated = (id && (intl?.formatMessage({ id, defaultMessage: emptyNotFound ? (directLookup || ' ') : undefined })));

    // console.log('__', typeof id, emptyNotFound, translated===id, typeof translated, `'${translated}'!=='${id}'`);
    if (emptyNotFound && (translated===id || `${translated}`.replace(/\W/, '')==='')) return '';
    return translated || '';
    // return translated || '';
  };
};

// TODO: this should be used like a hook, as it is a hook, because it's using a hook inside
export const __ = (id?: string, emptyNotFound=false) => {
  const translations = useTranslate();
  return translations(id, emptyNotFound);
};

export const useAllIntlText = () => {
  const intl = useIntl();
  return intl.messages;
};

// function recurseJSONKeys(branch: RecurseJsonType, prefix: string) {
//   if (typeof branch === 'object' && branch?.constructor?.name?.toLowerCase() === 'array') {
//     return Object.keys(branch).map(key => recurseJSONKeys(branch[key] as unknown as RecurseJsonType, `${prefix}.${key}`));
//   }
//   if (typeof branch === 'object') {
//     return Object.keys(branch).reduce((keys, key) => ({ ...keys, [key]: recurseJSONKeys(branch[key] as unknown as RecurseJsonType, `${prefix}.${key}`) }), {});
//   }
//   return `${prefix}`;
// }

// const getFlatKeys = (prefix: string) => {
//   const intl = useIntl();
//   const match = new RegExp(`^${prefix}`);
//   return Object.entries(intl.messages).reduce(((messages, [id, message]) => ({
//     ...messages,
//     ...(id.match(match) ? { [id]: message } : {}),
//   })), {});
// };

// export const __keys = (prefix: string) => {
//   const branch = get(translation, prefix, {});

//   return recurseJSONKeys(branch, prefix);
// };

export const useAllIntlTree = () => {
  const intl = useIntl();
  switch (intl.locale) {
    case 'de': return translationDe;
    case 'fr': return translationFr;
    default: return translation;
  }
};

export interface LanguageProps {
  id: string;
  name: string;
}

export const useAllLanguages = (): LanguageProps[] => {
  const { languages: lang } = useAllIntlTree();
  return Object.entries(lang)
    .map(([id]) => ({
      id,
      name: lang[id as Locale],
    }));
};

export const useDefaultLanguageId = () => {
  const meta = useSiteMeta();
  return getDefaultLanguageId(meta.languages);
};

export const useCanonicalUrl = () => {
  const meta = useSiteMeta();
  const location = useLocation();
  const url = getCanonicalUrl(location?.protocol && location?.host ? `${location.protocol}//${location.host}` : meta.siteUrl, location?.pathname, meta.languages);
  return url;
};

export const useLocale = () => {
  const intl = useIntl();
  return intl.locale as Locale;
};

const supportedLanguages = process.env.GATSBY_APP_LANGS;
const supportedLangString = '(en|de|fr)';
export const languages = (supportedLanguages?.match(new RegExp(`^${supportedLangString}(,${supportedLangString})*$`, 'i')) ? supportedLanguages.toLowerCase().split(',') : ['de', 'en', 'fr']) as Locale[];
