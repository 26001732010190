import axios, { AxiosResponse } from 'axios';
import React, { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FaFileUpload } from 'react-icons/fa';
import { FS_TYPES, StrapiResponse } from '@types';
import { themeConfig } from '@themed';
import { useUserToken } from '@store';
import { Grid, SectionWithContent, SubTitle } from '@core';
import { getStrapiRoutes } from 'src/modules/networking/routes';
import { formStyles, IFormInput, SnackBar, titleStyles, useUploadState } from '../components/Upload';
import { ExcelUploadForm } from './ExcelUploadForm';

const defaultFormValues = { Date: new Date(), Type: FS_TYPES[0], description: '' };

export const FinnoscoreUploader = () => {
  const token = useUserToken();
  const methods = useForm<IFormInput>({ defaultValues: defaultFormValues });
  const { handleSubmit, register } = methods;
  const { error, setError, isLoading, setIsLoading, success, setSuccess } = useUploadState();

  const onSubmit: SubmitHandler<IFormInput> = useCallback(async (data) => {
    if (!data.Date) return;
    setError(null);
    setIsLoading(true);
    const formData = new FormData();
    formData.append('date', data.Date.toISOString());
    formData.append('file', data.file[0]);
    formData.append('fsType', data.Type);
    formData.append('description', data.description);
    formData.append('fileName', data.file[0].name);
    try {
      const res: AxiosResponse<StrapiResponse> = await axios.post(getStrapiRoutes().cms.excelUpload, formData, { headers: { 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}` } });
      if (res.status === 200) setSuccess(true);
      if (res.status !== 200) setError({ status: res.status, message: 'Error happened on xls upload', missingImages: [] });
    } catch (err) {
      setError({ status: err.response.data.status, message: err.response.data?.message, missingImages: err.response.data?.missingImages });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <SectionWithContent>
      <Grid sx={formStyles}>

        <SubTitle sx={titleStyles}>
          <FaFileUpload color={themeConfig.colors.strapiBlue} />
          Finnoscore uploader
        </SubTitle>

        <FormProvider {...methods}>
          <ExcelUploadForm handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} isLoading={isLoading} />
        </FormProvider>

      </Grid>

      <SnackBar open={success} handleClose={() => setSuccess(false)} type="success">Successful Upload!</SnackBar>
      <SnackBar open={!!error} handleClose={() => setError(null)} type="error">
        {error?.message}
        {error?.missingImages?.map(imageName => (
          <li>
            {imageName}
          </li>
        ))}
      </SnackBar>

    </SectionWithContent>
  );
};
