import React from 'react';

interface ErrorObject {
  status: number;
  message: string;
  missingImages: string[];
}

export const useUploadState = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ErrorObject | null>(null);
  const [success, setSuccess] = React.useState<boolean>(false);

  return {
    success,
    setSuccess,
    isLoading,
    setIsLoading,
    error,
    setError,
  };
};
