import { SxStyleProp } from 'theme-ui';

export const positionBarStyles = (position?: number): SxStyleProp => ({
  position: 'absolute',
  left: position ? `${position}%` : 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
  width: '3px',
  height: '130%',
  borderRadius: '1px',
  '&:active:after': {
    background: 'black',
    borderRadius: '5px',
    bottom: '26px',
    color: '#fff',
    content: 'attr(title)',
    left: '20%',
    padding: '5px',
    position: 'absolute',
    zIndex: '98',
  },
});
