import * as React from "react"

const SvgBank = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    viewBox="0 0 60 60"
    {...props}
  >
    <rect width={60} height={60} fill="#000" />
    <path
      fill="#fff"
      d="M40.969 39.318h-.407v-2.843c0-.66-.558-1.22-1.218-1.22h-.813v-7.718h-2.437v7.719h-2.438v-7.719H31.22v7.719H28.78v-7.719h-2.437v7.719h-2.438v-7.719H21.47v7.719h-.813c-.71 0-1.218.559-1.218 1.219v2.843h-.407c-.71 0-1.218.559-1.218 1.22v.812c0 .253.152.406.406.406H41.78a.4.4 0 0 0 .407-.406v-.813c0-.66-.56-1.219-1.22-1.219Zm-2.844 0h-16.25v-1.625h16.25v1.625Zm3.656-16.351-11.02-4.621c-.253-.102-.507-.102-.761-.102-.305 0-.559 0-.813.102l-11.019 4.62c-.203.052-.355.305-.355.56v1.37c0 .356.253.61.609.61h1.015v.61c0 .355.254.609.61.609h19.906c.305 0 .61-.254.61-.61v-.61h1.015c.305 0 .61-.253.61-.608v-1.372c0-.253-.204-.507-.407-.558Zm-21.531 1.32L30 20.631l9.75 3.656h-19.5Z"
    />
  </svg>
)
export default SvgBank
