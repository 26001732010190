import React from 'react';
import { Box, Text } from 'theme-ui';
import { DimensionKey, Institution } from '@types';
import { ComparisonChart as Chart } from './ComparisonChart';

const container = {
  width: '100%',
  row: {
    height: '100%',
    display: 'grid',
    placeItems: 'center left',
    gridTemplateColumns: ['200px 1fr'],
    gridTemplateAreas: ["'title chart'"],
    columnGap: '10px',
    rowGap: '10px',
    my: 5,
  },
  h5: {
    gridArea: 'title',
    width: '100%',
  },
};

const DefaultDimensions:DimensionKey[] = ['Omnichannel-Kommunikation', 'Social Media & Community', 'Loyalty & Ökosystem', 'Mobile Apps', 'Online Banking', 'Innovation und Nachhaltigkeitsagenda'];

interface Props {
  worldwide: Institution | null;
  current: Institution | null;
  relative: Institution | null;
  national: Institution | null;
  dimensions?: DimensionKey[]
}

export const MultiComparisonChart = React.forwardRef<HTMLDivElement, Props>(({
  worldwide, current, relative, national, dimensions=DefaultDimensions,
}, ref) => {
  const instituteList = [relative, current, national, worldwide];

  const getDimensionValues=(dimensionKey:DimensionKey) => instituteList.map((institute) => {
    const dimensionObject = institute?.dimensions.find(dimension => dimension.key===dimensionKey);
    if (dimensionObject) {
      return +dimensionObject.value;
    }
    return 5;
  });

  return (
    <Box sx={container} ref={ref}>

      <Box>
        {dimensions.map((item) => (
          <Box key={item} sx={container.row}>
            <Text contentEditable suppressContentEditableWarning as="h5">{item}</Text>
            <Chart keys={dimensions} dimensionValues={getDimensionValues(item)} />
          </Box>
        ))}
      </Box>

    </Box>
  );
});
