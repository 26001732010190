import { SxStyleProp } from 'theme-ui';

export const styles = {
  imageBox: {
    borderRadius: 'fullSize',
    width: ['41vw', 9, 10],
    height: ['41vw', 9, 10],
    overflow: 'hidden',
    position: 'relative',
    zIndex: 4,
  } as SxStyleProp,
  imageOverlay: {
    backgroundColor: 'background',
    width: ['41vw', 9, 10],
    height: ['41vw', 9, 10],
    position: 'absolute',
    zIndex: 3,
    opacity: 0,
    borderRadius: '50%',
  } as SxStyleProp,
  svgIcon: {
    variant: 'images.circleIcon',
    content: 'attr(name)',
    fontSize: 'xl',
    fontWeight: 'bold',
  },
};
