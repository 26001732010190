/** @jsx jsx */
import { jsx } from 'theme-ui';
import { SegmentedControl } from '@core';
import { getNumber } from '@modules';

export const styles = {
  mb: 2,
  borderColor: 'grey.4',
  bg: 'grey.4',

  '&:before': {
    bg: 'background',
  },
};
interface LegacySwitchProps{
  value: number;
  changeHandler: (n:number)=>void;
  items?: {
    title:string;
  }[]
}
export const LegacySwitch = ({
  value,
  changeHandler,
  items = [
    { title: 'Institution' },
    { title: 'Statistic' },
  ],
}:LegacySwitchProps) => (
  <SegmentedControl
    defaultIndex={getNumber(value)}
    sx={styles}
    items={items}
    onChange={v => changeHandler(v.index)}
  />
);
