import { SxStyleProp } from 'theme-ui';

export const styles = {
  section: {
    zIndex: 4,
    position: 'relative',
    // backgroundColor: ['primary', 'transparent'],
    backgroundColor: 'primary',
    padding: [4, null],
  } as SxStyleProp,
  textBox: {
    textAlign: 'left',
    flexDirection: 'column',
  } as SxStyleProp,
  title: {
    color: 'background',
  },
  rocketIcon: {
    variant: 'images.pureCircleIcon',
    marginBottom: 7,
  },
};
