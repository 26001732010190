import { useLocale } from '@modules';
import { Article, ContentWithImage, SiteMeta, hasContentImage } from '@types';

export const useGenericSchema = ({
  title,
  description,
  author,
  version,
  street,
  postalCode,
  city,
  houseNumber,
  phone,
  email,
  logoFinnoconsult,
  type,
  primaryImage,
}: SiteMeta) => {
  const lang = useLocale();

  return {
    '@context': 'https://schema.org/',
    '@type': type,
    '@id': 'https://finnoconsult.at/#website',
    name: `${title}`,
    url: 'https://finnoconsult.at/',
    author: `${author}`,
    description: `${description}`,
    inLanguage: `${lang}`,
    additionalType: 'Service',
    headline: `${title}`,
    version: `${version}`,
    about: {
      '@type': 'Organization',
      '@id': 'https://finnoconsult.at/#organization',
      name: `${title}`,
      url: 'https://finnoconsult.at/',
      sameAs: 'https://www.linkedin.com/company/finnoconsult/',
      address: {
        '@type': 'PostalAddress',
        streetAddress: `${street} ${houseNumber}`,
        addressLocality: `${city}`,
        addressRegion: `${city}`,
        postalCode: `${postalCode}`,
        addressCountry: 'AT',
      },
      email: `${email}`,
      telephone: `${phone}`,
      logo: {
        '@type': 'ImageObject',
        '@id': 'https://finnoconsult.at/#logo',
        inLanguage: `${lang}`,
        url: 'https://finnoconsult.at/favicon-32x32.png',
        contentUrl: 'https://finnoconsult.at/favicon-32x32.png',
        width: '2000',
        height: '1052',
        caption: `${title}`,
      },
      image: {
        '@type': 'ImageObject',
        '@id': 'https://finnoconsult.at/#image',
        inLanguage: `${lang}`,
        url: logoFinnoconsult,
        contentUrl: logoFinnoconsult,
        width: '2000',
        height: '1052',
        caption: `${title}`,
      },
    },
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: '€8000',
      highPrice: '€16000',
      priceCurrency: 'EUR',
      url: `https://finnoconsult.at//${lang}/workshops/`,
      offerCount: 2,
    },
    primaryImageOfPage: {
      '@type': 'ImageObject',
      '@id': `https://finnoconsult.at/${lang}/#primaryimage`,
      inLanguage: `${lang}`,
      url: `${primaryImage}`,
      contentUrl: `${primaryImage}`,
      width: 1920,
      height: 1080,
    },
    breadcrumb: {
      '@type': 'BreadcrumbList',
      '@id': 'https://finnoconsult.at/#breadcrumb',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': `https://finnoconsult.at/${lang}/`,
            name: 'home',
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': `https://finnoconsult.at/${lang}/finnoscore/`,
            name: 'finnoscore',
          },
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@id': `https://finnoconsult.at/${lang}/case-studies/`,
            name: 'projects',
          },
        },
        {
          '@type': 'ListItem',
          position: 4,
          item: {
            '@id': `https://finnoconsult.at/${lang}/rapid/`,
            name: 'products & services',
          },
        },
        {
          '@type': 'ListItem',
          position: 5,
          item: {
            '@id': `https://finnoconsult.at/${lang}/workshops/`,
            name: 'workshops',
          },
        },
        {
          '@type': 'ListItem',
          position: 5,
          item: {
            '@id': `https://finnoconsult.at/${lang}/about-us/`,
            name: 'about us',
          },
        },
        {
          '@type': 'ListItem',
          position: 6,
          item: {
            '@id': `https://finnoconsult.at/${lang}/blog/`,
            name: 'blog',
          },
        },
        {
          '@type': 'ListItem',
          position: 6,
          item: {
            '@id': `https://finnoconsult.at/${lang}/contact/`,
            name: 'contact',
          },
        },
      ],
    },
    isPartOf: {
      '@type': 'WebSite',
      '@id': 'https://finnoconsult.at/#website',
      name: `${title}`,
      url: 'https://finnoconsult.at/',
      headline: `${title}`,
      inLanguage: `${lang}`,
      description: `${description}`,
      publisher: {
        '@type': 'Organization',
        '@id': 'https://finnoconsult.at/#organization',
        name: `${title}`,
        url: 'https://finnoconsult.at/',
        sameAs: 'https://www.linkedin.com/company/finnoconsult/',
      },
    },
  };
};

export const useMainArticleImageUrl = (article: Article) => {
  const mainThumbnail = article.thumbnails?.[0];
  const firstValidImage = article.content?.find((c): c is ContentWithImage => hasContentImage(c) && !!c.image.url && c.image.url.length > 0)?.image;
  const mainImageOfThePage = (mainThumbnail || firstValidImage)?.url;
  return mainImageOfThePage;
};

interface Author {
  '@type': string;
  name: string;
  url: string;
}
export interface ArticleSchema {
  '@context': string;
  '@type': string;
  headline: string;
  image: string [];
  datePublished: string;
  dateModified: string;
  author: Author | Author[]
}

export interface ArticleSchemaProps extends SiteMeta {
  publishDate?: Date | string;
  modified?: Date | string;
  authorUrl: string;
}

export const useArticleSchema = (pageContext: Article): ArticleSchema => {
  const primaryImage = useMainArticleImageUrl(pageContext);
  return ({
    '@context': 'https://schema.org/',
    '@type': 'NewsArticle',
    headline: pageContext.title || '',
    image: [primaryImage || ''],
    datePublished: `${pageContext.publishDate}`,
    dateModified: `${pageContext.modified || pageContext.publishDate}`,
    author: [{
      '@type': 'Person',
      name: pageContext.author?.name || 'Christian Berger',
      url: pageContext.author?.avatar?.url || '',
    }],
  });
};
