import * as React from "react";

const SvgChartTrendUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={56}
    viewBox="0 0 48 56"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="chart-trend-up_svg__Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="chart-trend-up_svg__Case-Studies-Full"
        transform="translate(-709 -4530)"
        fillRule="nonzero"
      >
        <g id="chart-trend-up_svg__Group-17" transform="translate(0 4280)">
          <g
            id="chart-trend-up_svg__Abschluss/casestudies"
            transform="translate(676 221)"
          >
            <g id="chart-trend-up_svg__trend-up" transform="translate(33 29)">
              <path
                d="M45.245 1.34l.044.143 1.98 7.39a2 2 0 01-3.82 1.179l-.043-.143-.754-2.81C35.382 20.438 19.1 32.132 2.275 34.469a2 2 0 01-.55-3.962C17.266 28.347 32.457 17.433 39.13 5.204l-2.646.709a2 2 0 01-2.406-1.272l-.044-.143a2 2 0 011.272-2.405l.143-.044 7.39-1.98a2 2 0 012.406 1.271z"
                id="chart-trend-up_svg__Combined-Shape"
                fill="#00BD59"
              />
              <path
                d="M9.013 42.487H3.108c-.612 0-1.108.582-1.108 1.3v10.4c0 .718.496 1.3 1.108 1.3h5.905c.612 0 1.107-.582 1.107-1.3v-10.4c0-.718-.495-1.3-1.107-1.3zM20.973 38.487h-5.905c-.612 0-1.108.6-1.108 1.342v14.316c0 .74.496 1.342 1.108 1.342h5.905c.612 0 1.107-.601 1.107-1.342V39.829c0-.741-.495-1.342-1.107-1.342zM32.933 31.487h-5.905c-.612 0-1.108.62-1.108 1.384v21.231c0 .765.496 1.385 1.108 1.385h5.905c.612 0 1.107-.62 1.107-1.385v-21.23c0-.765-.495-1.385-1.107-1.385zM44.893 18.487h-5.905c-.612 0-1.108.637-1.108 1.423v34.154c0 .786.496 1.423 1.108 1.423h5.905c.612 0 1.107-.637 1.107-1.423V19.91c0-.786-.495-1.423-1.107-1.423z"
                id="chart-trend-up_svg__Path"
                fill="#000"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgChartTrendUp;
