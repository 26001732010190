/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { Box } from '@themed';
import { selectedSearchListItemId, useGoToSelected, useSearch } from '@modules';
import { GenericListItemProps, ListItemProps, ListItem as ListItemType } from '@core';
import { List } from './List';
import { SearchBar } from './SearchBar';
import { ItemsBySection, SectionType, SectionedList } from './SectionedList';
import { styles } from './SearchList.styles';

interface BaseProps {
  onSelect?: (id: string) => void;
  selected?: string;
  ListItemComponent: (props: ListItemProps) => JSX.Element;
}

interface SearchListProps extends BaseProps {
  listItems: ListItemType[];
  searchKey?: string;
  type?: string;
}

export const SearchList = ({
  listItems, onSelect, searchKey = 'text', ListItemComponent, selected, type,
}: SearchListProps) => {
  const { searchTerm, setSearchTerm, filteredItems } = useSearch(listItems, searchKey as keyof GenericListItemProps);
  useGoToSelected();

  return <Flex variant="flex.colCenter" sx={styles.box}>
    <SearchBar
      value={searchTerm}
      onChange={setSearchTerm}
      onClick={(e:React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    />
    <Box sx={styles.list(listItems.length)}>
      <List
        items={filteredItems}
        itemComponent={(props) => <ListItemComponent {...props} onSelect={onSelect} type={type} selected={selected === props.id} className={selected === props.id ? selectedSearchListItemId : ''} />}
      />
    </Box>
  </Flex>;
};

interface SectionedSearchListProps extends BaseProps {
  listItemsBySection: ItemsBySection[];
}

export const SectionedSearchList = ({
  listItemsBySection, onSelect, selected, ListItemComponent,
}: SectionedSearchListProps) => {
  useGoToSelected();

  const { searchTerm, setSearchTerm: setListItemSearchTerm, filteredItems: filteredListItems } = useSearch<ListItemType>(listItemsBySection.reduce((accum, curr) => [...accum, ...curr.items], [] as ListItemType[]), 'text');
  const { setSearchTerm: setSectionSearchTerm, filteredItems: filteredSections } = useSearch<SectionType>(listItemsBySection.map(section => section.section), 'text');

  const filteredSecitonsWithItems = listItemsBySection.filter(section => filteredSections.includes(section.section));
  const sectionsToSearchItemsIn = filteredSecitonsWithItems.length === 0 ? listItemsBySection : filteredSecitonsWithItems;

  const filteredlistItemsBySection = sectionsToSearchItemsIn
    .map(section => ({
      ...section,
      items: filteredSecitonsWithItems.length === sectionsToSearchItemsIn.length
        ? section.items
        : section.items.filter(item => filteredListItems.includes(item)),
    }))
    .filter(section => section.items.length > 0);

  return <Flex variant="flex.colCenter" sx={styles.box}>
    <SearchBar
      value={searchTerm}
      onChange={text => {
        setListItemSearchTerm(text);
        setSectionSearchTerm(text);
      }}
      onClick={(e:React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    />
    <Box sx={styles.list}>
      <SectionedList
        itemsBySection={filteredlistItemsBySection}
        itemComponent={(props) => <ListItemComponent onSelect={onSelect} selected={selected === props.id} {...props} className={selected === props.id ? selectedSearchListItemId : ''} />}
      />
    </Box>
  </Flex>;
};
