/** @jsx jsx */
import { jsx, HeadingProps } from 'theme-ui';
import React from 'react';
import { Title } from '../themed';

export const PageTitle = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ children, ...props }, ref) => (
  <Title
    variant="styles.h3"
    as="h3"
    {...props}
    sx={{
      display: ['block', 'none'],
      ...props.sx,
    }}
    ref={ref}
  >
    {children}
  </Title>
));
