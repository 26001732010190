import {
  CountryCalculationBase, CountryCalculationType, Dimension, DimensionKey, FsType, Locale, Node,
} from '@types';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelectedFsType } from '.';
import { useLocale } from '../i18n';

export interface DimensionItem {
  name: DimensionKey;
  best?: number;
  average?: number;
}

interface CountryCalculation extends CountryCalculationBase {
  language?: Locale;

}

interface ComparisonCountryCalculationQueryResult {
  allCountryCalculation: {
    edges: Node<CountryCalculation>[];
  }
}

const addDimensions = (finalDimensions: DimensionItem[], dimensions: Dimension[], type: CountryCalculationType) => dimensions.reduce((final, dim) => {
  const newValue: DimensionItem = {
    name: dim.key,
    [type]: dim.value,
  };
  const foundIndex = finalDimensions.findIndex(dim1 => dim1.name === dim.key);
  return (foundIndex >= 0)
    ? [...final.slice(0, foundIndex), { ...final[foundIndex], ...newValue } as DimensionItem, ...final.slice(foundIndex+1)]
    : [...final, newValue];
}, finalDimensions);

const formatDimensions = (data: ComparisonCountryCalculationQueryResult, type: FsType, locale: Locale): DimensionItem[] => data.allCountryCalculation.edges
  .filter(({ node: { fsType, language } }) => type === fsType && locale === language)
  .reduce(
    (finalDimensions, { node: { type: calcType, dimensions } }) => addDimensions(finalDimensions, dimensions, calcType),
    [] as DimensionItem[],
  );

export const useDimensions = () => {
  const [type] = useSelectedFsType();
  const locale = useLocale();

  // const locale = useLocale(); // NOTE, we always select the english for now
  const data: ComparisonCountryCalculationQueryResult = useStaticQuery(graphql`
    query {
      allCountryCalculation(filter: {country: {countryCode: {eq: "ALLE"}}}) {
        edges {
          node {
            fsType
            id
            total
            type
            language
            dimensions {
              key
              value
            }
          }
        }
      }
    }
  `);

  return formatDimensions(data, type, locale);
};
