import React from 'react';
import { Grid, SxProps } from '@themed';
import { BlogCard } from '@composite';
import { Article } from '@types';
import {
  useLocale, getFormattedDate, getContentTypeUrl, ContentTypes,
} from '@modules';

interface BlogListProps extends SxProps {
  articles?: Article[];
}

const styles = {
  grid: {
    width: '100%',
    p: [5, 5, 0],
    mt: '100px',
    mb: 9,

    variant: 'grids.articleList',
    // gap: 5,
  },
};

export const BlogList = ({
  articles, ...props
}: BlogListProps) => {
  const locale = useLocale();

  return (
    <Grid
      {...props}
      sx={{ ...styles.grid, ...props.sx }}
      data-cy="blog-container"
    >
      {
        articles && articles
          .map((item, index) => (
            <BlogCard
              key={`${item.title}_${index}`}
              title={item.title}
              paragraph={item.subtitle}
              image={item.thumbnails?.[0]}
              date={getFormattedDate(item.publishDate, locale)}
              link={getContentTypeUrl(item.slug, locale, ContentTypes.blog)}
            />
          ))
      }
    </Grid>
  );
};
