import React from 'react';
import { Box, Text } from '@themed';
import { ContactButtonList } from '@core';
import { useLocale, useLocation, useTranslate } from '@modules';
import { Tag as TagType } from '@types';
import { PostContentContainer } from './PostContentContainer';
import { TagList } from './TagList';
import { styles } from './PostFooter.styles';

interface PostFooter {
  tags?: TagType[];
  title?: string;
}

export const PostFooter = ({ tags, title }: PostFooter) => {
  const locale = useLocale();
  const path = useLocation([locale]);
  const __ = useTranslate();

  return (
    <PostContentContainer sx={styles.container}>
      <Box>
        {tags && <TagList tags={tags} />}
        <Box sx={styles.section}>
          <Text sx={styles.title}>{__('pages.blogDetails.share')}</Text>
        </Box>
        {path && (
          <ContactButtonList
            sx={styles.buttonList}
            linkedin={`https://www.linkedin.com/shareArticle?url=${path}&title=${title}`}
            xing={`https://www.xing.com/spi/shares/new?url=${path}`}
            twitter={`https://twitter.com/share?url=${path}&text=${title}`}
            email={`?subject=${title}&body=${path}`}
          />
        )}
      </Box>
    </PostContentContainer>
  );
};
