import { SxStyleProp } from 'theme-ui';

export const chartStyles: SxStyleProp = {
  width: 'chart',

  '&>div:first-of-type': {
    width: '30%',
  },
  '&>div:last-of-type': {
    width: '70%',
  },
};
