import React from 'react';
import { FsTypeBase } from '@types';

export const useFsTypes = <TextType> () => {
  const [types, setTypes] = React.useState<FsTypeBase<TextType>[]>([]);
  const [typeId, setTypeId] = React.useState<string>('');

  const type = types.find(t => t.id === typeId);

  return {
    types,
    setTypes,
    typeId,
    setTypeId,
    type,
  };
};
