import { BoxProps } from 'theme-ui';
import React from 'react';
import { Children } from '@types';
import PercentageContext from '../context/PercentageContext';
import { Monochrome } from './Monochrome';
import { PositionBar } from './PositionBar';
import { Bar } from './Bar';

interface DoubleChartItemProps extends BoxProps {
  percentages: {
    a: number;
    b: number;
  };
  fillComponents?: {
    a: Children;
    b: Children;
  };
}

export const DoubleChartItem = React.forwardRef<HTMLDivElement, DoubleChartItemProps>(({
  percentages, fillComponents = {
    a: <Monochrome />,
    b: <PositionBar />,
  }, ...props
}, ref) => <Bar ref={ref as unknown as any} {...props}>
  <PercentageContext.Provider value={percentages.a}>
    {fillComponents.a}
  </PercentageContext.Provider>
  <PercentageContext.Provider value={percentages.b}>
    {fillComponents.b}
  </PercentageContext.Provider>
</Bar>);
