/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { forwardRef } from 'react';
import { BoxProps } from '@themed';
import { Flex, List, SectionWithContent, Title, Grid } from '@core';
import { useAllIntlTree } from '@modules';
import { TriggerElementProps } from '@types';
import { useAnimationLists } from '../landing/animateOursupport';
import WorkshopOffer from './WorkshopOffer';
import { styles } from './FinnoscoreWorkshop.styles';

const CountList = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (<Grid
  {...props}
  ref={ref}
  sx={styles.list}
/>));

const contentBlocks = [
  { title: 'impuls', icon: 'rocket' },
  { title: 'discovery', icon: 'eye' },
];

export const FinnoscoreWorkshop = ({ triggerRef }: TriggerElementProps) => {
  const titleRef = React.useRef(null);
  const listRef = React.useRef(null);
  const messages = useAllIntlTree();

  useAnimationLists({
    triggerRef,
    title: () => titleRef,
    list: () => listRef,
  });

  return (
    <SectionWithContent sx={styles.section}>
      <Flex sx={styles.sectionContent} ref={listRef}>
        <Title ref={titleRef} sx={styles.title} data-cy="workshop-types-title">{messages.pages.workshops.finnoworkshops.title}</Title>
        <List component={CountList} items={messages.pages.workshops.finnoworkshops.list} data-cy="workshop-types-list" />
        <Flex
          variant="flex"
          sx={styles.offers}
          data-cy="workshop-types-offers"
        >
          {contentBlocks.map(({ title, icon }, index) => (
            <WorkshopOffer title={title} id={index} key={title} icon={icon} />
          ))}
        </Flex>
      </Flex>
    </SectionWithContent>);
};
