import * as React from "react";

const SvgEye = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={93}
    height={71}
    viewBox="0 0 93 71"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="eye_svg__Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="eye_svg__Landing-Full"
        transform="translate(-760 -1543)"
        fillRule="nonzero"
      >
        <g id="eye_svg__Group-11" transform="translate(691 1490)">
          <g id="eye_svg__Group-2" transform="translate(29)">
            <g id="eye_svg__eye" transform="translate(40.028 53)">
              <path
                d="M46.712 15.778c10.623 0 20.198 5.59 28.632 16.518a5.245 5.245 0 01-.009 6.42l-.444.569C66.569 49.83 57.144 55.222 46.712 55.222c-10.622 0-20.198-5.59-28.631-16.518a5.245 5.245 0 01.008-6.42l.445-.569C26.856 21.17 36.28 15.778 46.712 15.778zm0 5.259c-8.658 0-16.634 4.563-24.015 13.916l-.427.547.426.545c7.386 9.357 15.362 13.918 24.016 13.918 8.659 0 16.635-4.563 24.015-13.916l.427-.547-.425-.545c-7.386-9.357-15.362-13.918-24.017-13.918zm0 5.917c4.742 0 8.585 3.826 8.585 8.546 0 4.72-3.843 8.546-8.585 8.546-4.741 0-8.585-3.826-8.585-8.546 0-4.72 3.844-8.546 8.585-8.546z"
                id="eye_svg__Combined-Shape-Copy-16"
                fill="#00BD59"
              />
              <path
                d="M46.712 58.51c1.03 0 1.876.781 1.972 1.782l.01.19v8.546c0 1.089-.888 1.972-1.982 1.972a1.978 1.978 0 01-1.972-1.782l-.009-.19V60.48c0-1.089.887-1.972 1.981-1.972zm19.726-5.56l.14.129 6.044 6.043c.77.77.767 2.021-.007 2.795a1.978 1.978 0 01-2.654.134l-.141-.128-6.043-6.043a1.977 1.977 0 01.006-2.795 1.978 1.978 0 012.655-.134zm-40.225 0a1.978 1.978 0 012.655.135c.773.774.776 2.025.006 2.795l-6.043 6.043-.14.128a1.978 1.978 0 01-2.656-.134 1.977 1.977 0 01-.006-2.795l6.043-6.043zM10.519 33.825c1.089 0 1.972.887 1.972 1.981 0 1.03-.782 1.876-1.783 1.972l-.19.01H1.973A1.977 1.977 0 010 35.804c0-1.03.782-1.876 1.782-1.972l.19-.009h8.547zm79.452 0c1.09 0 1.973.887 1.973 1.981 0 1.03-.783 1.876-1.783 1.972l-.19.01h-8.546a1.977 1.977 0 01-1.972-1.982c0-1.03.782-1.876 1.782-1.972l.19-.009h8.546zM22.237 8.95l.14.128 6.044 6.043c.77.77.767 2.021-.006 2.795a1.978 1.978 0 01-2.655.134l-.14-.128-6.044-6.043a1.977 1.977 0 01.006-2.795 1.978 1.978 0 012.655-.134zm48.177 0a1.978 1.978 0 012.655.134c.773.774.776 2.025.006 2.795l-6.043 6.043-.14.128a1.978 1.978 0 01-2.656-.134 1.977 1.977 0 01-.006-2.795l6.043-6.043zM46.712 0c1.03 0 1.876.782 1.972 1.782l.01.19v8.547c0 1.089-.888 1.972-1.982 1.972a1.978 1.978 0 01-1.972-1.783l-.009-.19V1.973C44.731.883 45.618 0 46.712 0z"
                id="eye_svg__Combined-Shape"
                fill="#000"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgEye;
