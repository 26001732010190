import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { FsType } from '@types';

interface SelectProps {
  name: string;
  options: string[] | readonly FsType[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const Select = ({ name, options }: SelectProps) => {
  const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <MuiSelect
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={options[0]}
            {...field}
          >
            {options.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </MuiSelect>
          {fieldState.error ? (
            <FormHelperText error>
              {fieldState.error?.message}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
};
