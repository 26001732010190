import { SxProps } from 'theme-ui';
import { Paragraph } from '@themed';
import { ChildrenProps } from '@types';
import { postContentStyles } from './index.styles';

interface PostParagraphProps extends ChildrenProps, SxProps {
}

export const PostParagraph = ({ children, sx, ...props }: PostParagraphProps) => (
  <Paragraph {...props} sx={{ ...postContentStyles.format, ...sx }}>
    {children}
  </Paragraph>
);
