import { atom, useRecoilValue } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { StrapiUser } from 'src/types/StrapiLogin';

export interface User {
  token:string | null,
  user: StrapiUser | null
}

const { persistAtom } = recoilPersist();

export const authState = atom({
  key: 'authState',
  default: {
    token: null,
    user: null,
  } as User,
  effects_UNSTABLE: [persistAtom],
});

export const useUserAtom = () => {
  const user = useRecoilValue<User>(authState);
  return user;
};

export const useUserToken = () => {
  const user = useRecoilValue<User>(authState);
  return user.token;
};
