const _reset = {
  display: 'inline-block',
  position: 'relative',
  maxWidth: 'unset !important',
  minWidth: 0,
  minHeight: 0,
  maxHeight: 'unset',
  width: 'auto',
  height: 'auto',
  padding: 0,
  margin: 0,
  transform: 'none !important', // good to disable all gsap stuff as well
  overflow: 'visible',
  textIndent: 'inherit',
};

export const printStyles = (print = true) => ({
  [`html body #___gatsby${print ? '' : ' [data-browser=legacy]'}`]: {
    'div, section, p, h1, h2, h3, h4, aside': {
      ..._reset,
      display: 'block',
      width: '100%',
      color: 'black',
      background: 'none',
      border: 'none',
      borderRadius: 'none',
    },
    section: {
      marginBottom: '32px',
      padding: '16px',
      background: 'none !important',
      backgroundColor: 'none !important',
    },
    h1: {
      paddingBottom: '8px',
      borderBottom: 'solid 1px black',
      fontSize: '22px',
    },
    'h1 br, h2 br, h3 br, h4 br': {
      display: 'none',
    },
    h2: {
      paddingTop: '8px',
      fontSize: '20px',
    },
    hr: {
      marginTop: '10px',
    },
    'span, a': {
      ..._reset,
    },
    a: {
      color: '#00bd59 !important',
    },
    'a>span, [data-cta=button]': {
      padding: '8px 24px',
      width: 'max-content',
      margin: '16px 16px 16px 0 !important',
    },
    'section#contact a span, section [data-cta=button], section [data-cta=button] span': {
      background: '#00bd59',
      color: 'white',
    },
    'video, svg, figure, picture, img, .gatsby-image-wrapper': {
      display: 'none !important',
    },
    '[name=logo] svg': {
      display: 'block !important',
    },
    '#desktop-header': {
      padding: '16px',
    },
    '#desktop-menu': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    '#desktop-menu>*': {
      width: 'max-content',
      marginRight: '16px',
    },
    '#mobile-header, #desktop-header-show': {
      display: 'none',
    },
    '[data-print=no], [data-animation=parallax-image]': {
      display: 'none',
    },
    '[data-animation=parallax-background]': {
      background: 'white',
    },
    '[data-animation=parallax-background]:before, [data-animation=parallax-background]:after': {
      content: 'unset',
      display: 'none !important',
    },
    '.philosophyOperator': {
      margin: 0,
      padding: 0,
      display: 'block',
      fontSize: '20px',
    },
    '.philosophiesSubTitle + .listSection *': {
      textAlign: 'center',
    },
  },
  // ...(!print ? {
  //   'html body [data-browser=legacy]::before': {
  //     content: '"This device is not supported, please use this webpage with Chrome, Safari, Edge or Opera with a newer device!"',
  //     // position: 'fixed',
  //     // left: '25vw',
  //     // top: '20vh',
  //     // height: '30vh',
  //     ..._reset,
  //     width: '95vw',
  //     margin: '10px auto',
  //     padding: '40px 20px',
  //     background: 'white',
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     zIndex: 9999,
  //     color: 'red',
  //     border: 'double 3px black',
  //     borderRadius: '32px',
  //   },
  // } : {}),
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any; // TODO specify correct type, but neither SystemCssProperties, GlobalProps<any>, nor SxStlyedProps were working.
