import { SxStyleProp } from 'theme-ui';

export const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '0.75fr 1fr',
    placeItems: 'center end',
    height: '30px',
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    my: 5,
    height: '100%',
    width: '100%',
  } as SxStyleProp,
};
