import { Box, Checkbox, Label } from 'theme-ui';
import { SearchList } from '@core';
import { ChartDropDownControlProps, ChildrenProps, Dimension, DimensionKey, FsTypeBase } from '@types';
import { InstitutionListItem } from '../../../finnoscore-data/List';
import { ChartDropDownControl } from '../../ChartDropDownControl';
import { dropdownStyles } from '../../charts/DropdownStyles.styles';

export interface ChartDropDownComponentProps extends ChildrenProps, ChartDropDownControlProps {
  list: FsTypeBase<string>[],
  selectedId: string,
  setter: (id: string) => void,
  dropdownComponent?: ()=>JSX.Element
}

export const ChartDropDownControlComponent = ({
  list, selectedId, setter, ...props
}:ChartDropDownComponentProps) => (
  <ChartDropDownControl
    header={props.children}
    sx={dropdownStyles(props.zIndex)}
  >
    <SearchList
      listItems={list.map(({ id, ...item }) => ({ id: `${id}`, ...item }))}
      onSelect={setter}
      selected={selectedId}
      searchKey="text"
      ListItemComponent={InstitutionListItem}
    />
  </ChartDropDownControl>
);

export interface CheckboxDropDownControlComponentProps extends ChildrenProps{
  list?: Dimension[]
  selectedList?: DimensionKey[]
  onCheckHandler: (e:React.ChangeEvent<HTMLInputElement>) => void
  zIndex:number,
}

export const CheckboxDropDownControlComponent = ({
  list, selectedList, onCheckHandler, ...props
}:CheckboxDropDownControlComponentProps) => (
  <ChartDropDownControl
    // label="Dimensions" // TODO
    header={props.children}
    sx={dropdownStyles(props.zIndex)}
    closeOnContentClick={false}
  >
    <Box sx={{ m: 4 }}>
      {list?.map((elem) => (
        <Label htmlFor={elem.key}>
          <Checkbox
            checked={selectedList?.includes(elem.key) || false}
            id={elem.key}
            key={elem.key}
            value={elem.key}
            onChange={onCheckHandler}
          />
          {elem.key}
        </Label>
      ))}
    </Box>
  </ChartDropDownControl>
);
