export const styles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'max-content 1fr',
  gridTemplateAreas: '"countryTitle groupTitle" "countryInstitutions groupInstitutions"',
  gap: 5,
};
export const mixedStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'max-content 1fr',
  gridTemplateAreas: '"countryTitle" "countryInstitutions"',
  gap: 5,
  maxWidth: '600px',
};
