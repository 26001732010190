import React from 'react';
import { useDimensions, DimensionItem, useTopInstitutions } from '@modules';
import { InfoButton, Box, Flex, Image } from '@themed';
import { styles } from './CompareThroughGlasses.styles';

interface DimensionListProps {
  dimensions: DimensionItem[]
}
const DimensionList =({ dimensions }: DimensionListProps) => (
  <Flex sx={styles.dimensionList} aria-label="dimension list">
    {new Array(2).fill(null).map(() => (
      dimensions.map(({ name }) => <InfoButton key={name} sx={styles.dimension}>{name}</InfoButton>)
    ))}
  </Flex>
);

export default () => {
  const institutions = useTopInstitutions();
  const dimensions = useDimensions();
  const dimensionHalf = Math.floor(dimensions.length / 2);

  return (
    <Box sx={styles.container} data-cy="finnoscore-compare-glasses-container">
      {institutions.map(({ logo, name }, index) => (
        <Image
          src={logo?.url}
          alt={logo?.alternativeText}
          aria-label="top-logo"
          key={name + logo?.alternativeText}
          sx={styles.logo(index)}
          data-parallax-size={styles?.logo(index)?.height}
        />
      ))}

      <Box sx={styles.glasses} aria-label="eyeglasses">
        <Box sx={styles.view}>
          <DimensionList dimensions={dimensions.slice(0, dimensionHalf)} />
        </Box>
        <Box sx={styles.view}>
          <DimensionList dimensions={dimensions.slice(dimensionHalf)} />
        </Box>
      </Box>
    </Box>
  );
};
