import { SxStyleProp } from 'theme-ui';

export const styles = {
  display: {
    display: ['flex', 'flex', 'none'],
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 'header',
  } as SxStyleProp,
  defaultState: {
    width: 'fullSize',
  },
  hambugerIcon: {
    alignItems: 'center',
    textAlign: 'right',
    bg: 'background',
    position: 'absolute',
    top: 0,
    right: 0,
    m: 3,
    mr: 0,
    p: 3,
    borderRadius: 999,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  } as SxStyleProp,
  logo: {
    bg: 'background',
    position: 'relative',
    width: '100%',
    py: 4,
    px: 4,
    display: 'flex',
    alignItems: 'center',
  } as SxStyleProp,
  navigationButton: {
    marginLeft: 'auto',
    display: 'inline-block',
    bg: 'primary',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    padding: '4px',
    cursor: 'pointer',
  },
  bubble: {
    visibility: 'hidden',
    width: '20px',
    height: '20px',
    bg: 'primary',
    position: 'absolute',
    borderRadius: '50%',
    top: '16px',
    right: '16px',
    zIndex: 'bubble',
  } as SxStyleProp,
  contactLink: {
    fontSize: 'xs',
    mr: 3,
    fontWeight: 'normal',
  },
};
