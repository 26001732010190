/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { useCookie } from '@modules';
import { DesktopFinnoscoreBanner } from './DesktopFinnoscoreBanner';
import { MobileFinnoscoreBanner } from './MobileFinnoscoreBanner';

export const FinnoscoreBanner = () => {
  const cookieName = 'userClicked2023FinnoscorePrivateBanner';
  const { value: hasUserClickedBanner, setValue: saveHasUserClickedBanner } = useCookie(cookieName);
  const hideBannerWithCookie = () => saveHasUserClickedBanner('true', 365);

  if (hasUserClickedBanner) return null;

  return (
    <React.Fragment>
      <DesktopFinnoscoreBanner onClick={hideBannerWithCookie} />
      <MobileFinnoscoreBanner onClick={hideBannerWithCookie} />
    </React.Fragment>
  );
};
