/** @jsx jsx */
import { jsx, SxProps, BoxProps, Box } from 'theme-ui';
import { Global } from '@emotion/core';
import React from 'react';
import { ImageProps, ChildrenProps } from '@types';
import { useBackgroundImage } from './useBackgroundImage';
import { backgroundStyles } from './Background.styles';

export interface BackgroundImageProps extends ImageProps {
  preload?: boolean;
  showSmall?: boolean;
  // backgroundSize?: string;
}

export interface BackgroundImageComponentProps extends BackgroundImageProps, ChildrenProps, SxProps, BoxProps {
  // Eslint disable until solution + solution for the one in BackgroundImageLoader.tsx
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: React.ElementType<any>;
  onLoad?: () => void;
}

export const BackgroundImage = React.forwardRef<HTMLDivElement, BackgroundImageComponentProps>(({
  src, preload = true, showSmall = true, ...props
}, ref) => {
  const fluidSource = useBackgroundImage(src);

  if (!fluidSource) {
    console.error('background image not found!', src);
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  // fluid.src the size matching maxHeight above, so we are aiming for a bigger image from srcSet
  const bigSrc = fluidSource?.srcSet?.split(',')?.pop()?.replace(/\W\d+\w?$/, '')
  || fluidSource.src;

  const smallSrc = (showSmall && (
    fluidSource?.srcSet?.split(',')?.shift()?.replace(/\W\d+\w?$/, '')
    || fluidSource.base64
    || fluidSource.src
  )) || '';

  const styles = backgroundStyles(bigSrc, { smallSrc });

  return (
    <Box
      {...props}
      sx={styles.background}
      ref={ref}
    >
      {/* <Box className="before" sx={styles.background['&::before']} /> */}
      {props.children}
      {/* <Box className="after" sx={styles.background['&::after']} /> */}

      {/* Add preloading support to the global style definition for the big image */}
      {preload && <Global
        styles={{
          'body::before': {
            ...styles.preload,
          },
        }}
      />}
    </Box>
  );
});
