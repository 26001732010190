import { translateDimensionsForLocale, formatIcon } from '../commonFormatting';
import {
  Translation, InstitutionFromApi, Institution, Trend,
} from '../../../types';
import { languages } from '../../i18n';
import { getTranslatedCountries } from './formatCountries';

const getTrend = (institution: InstitutionFromApi, previousPeriodInstitution: InstitutionFromApi[]) => {
  const prevInstitution = previousPeriodInstitution.find(b => institution.name === b.name && institution.country.countryCode === b.country.countryCode);

  if (!prevInstitution) return Trend.New;
  return Trend.New;
  // if (prevInstitution && prevInstitution.rank > institution.rank) {
  //   return Trend.Ascending;
  // } if (prevInstitution && prevInstitution.rank < institution.rank) {
  //   return Trend.Descending;
  // }
  // return Trend.Stagnant;
};

const invalidInstitutionFsTypes = [
  'privatebank_weight',
  'insurance_weight',
  'bank_weight',
  'bankMiddleEast_weight',
];

export const formatInstitutions = (latestPeriodInstitutions: InstitutionFromApi[], previousPeriodInstitutions: InstitutionFromApi[], translations: Translation[]): Institution[] => languages.reduce((accum, language) => {
  const validInstitutions = latestPeriodInstitutions.filter(ins => !invalidInstitutionFsTypes.includes(ins.fsType));
  const translatedFormattedInstitutions = validInstitutions.map(institution => {
    const translatedCountries = getTranslatedCountries(institution.country, translations);
    const country = translatedCountries.find(c => c.language === language) || translatedCountries[0];
    const logo = formatIcon(institution.logo);
    const trend = getTrend(institution, previousPeriodInstitutions);
    const dimensions = translateDimensionsForLocale(institution.dimensions, translations, language);

    return {
      ...institution,
      country,
      language,
      logo,
      trend,
      dimensions,
    } as Institution;
  });

  const sortedByScore = translatedFormattedInstitutions.sort((a, b) => b.total - a.total);
  return [...accum, ...sortedByScore];
}, [] as Institution[]);
