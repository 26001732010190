/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';

import { Paragraph, SxStyleProp } from '@core';
import { ChildrenProps } from '@types';

const chartLabelStyles: SxStyleProp = {
  margin: 0,
  padding: 0,
};

interface ChartLabelProps extends SxProps, ChildrenProps{}

const ChartLabel = ({ children, ...props }: ChartLabelProps) => <Paragraph contentEditable suppressContentEditableWarning as="p" {...props} sx={{ ...chartLabelStyles, ...props.sx }}>{children}</Paragraph>;
export default ChartLabel;
