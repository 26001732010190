import { SxStyleProp } from 'theme-ui';

export const barStyles: SxStyleProp = {
  backgroundColor: 'grey.3',
  width: '100%',
  height: '4',
  borderRadius: '12px',
  position: 'relative',
  '&:hover *': {
    visibility: 'visible',
  },
  '&:active *': {
    visibility: 'visible',
  },
};
