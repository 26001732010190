export const styles = {
  author: {
    my: 6,
    alignItems: 'center',
  },
  authorAvatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
  },
  authorInfo: {
    ml: 4,
  },
};
