import React from 'react';
import { Flex, FlexProps, List, DefaultListItem, GenericItemProps } from '@core';
import { SubTitle, SxStyleProp } from '@themed';
import { useAllIntlTree } from '@modules';

interface ClientProps extends GenericItemProps {
  title?: string;
  name?: string;
}

enum StyleObject {
  list='list',
  item='item',
}

interface StyledTestimonalsProps {
  styles: {[key in StyleObject]: SxStyleProp};
}

export const StyledTestimonals = ({ styles }: StyledTestimonalsProps) => {
  const messages = useAllIntlTree();

  const TestimonialList = React.forwardRef<HTMLDivElement, FlexProps>((p, ref) => <Flex {...p} sx={styles.list} ref={ref} />);

  const TestimonialItem = (p: ClientProps) => (
    <DefaultListItem {...p} sx={styles.item}>
      <SubTitle data-cy="testimonialItem-name" as="h2" variant="styled.h4">{p.name}</SubTitle>
      <SubTitle data-cy="testimonialItem-title" as="h3" variant="styled.h5">{p.title}</SubTitle>
    </DefaultListItem>
  );

  return (
    <List component={TestimonialList} itemComponent={TestimonialItem} items={messages.pages.finnoscore.testimonials.list} />
  );
};
