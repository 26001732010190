/** @jsx jsx */
import { jsx, Grid, Button, Styled } from 'theme-ui';
import React from 'react';
import { EditableContext } from './EditableStore';
import { editableStyles } from './index.styles';

export const EditedOverlay = () => {
  const { texts, persistAll, revertAll } = React.useContext(EditableContext);

  if (Object.keys(texts).length === 0) return null;

  return (
    <Grid sx={editableStyles.editOverlay}>
      <Styled.ul>
        {Object.entries(texts).map(([key, value]) => (
          <Styled.li key={key}>{key} = {value}</Styled.li>
        ))}
      </Styled.ul>
      <Button sx={editableStyles.button} onClick={() => { if (persistAll)persistAll(); }}>SAVE All</Button>
      <Button sx={editableStyles.button} onClick={() => { if (revertAll)revertAll(); }}>Cancel all</Button>
    </Grid>
  );
};
