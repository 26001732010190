import { FsType } from '@types';

export function capitalize(str: string): string {
  return str[0].toUpperCase() + str.slice(1);
}

export const lcfirst = (string: string) => String(string).charAt(0).toLowerCase() + String(string).slice(1);
export const ucfirst = (string: string) => String(string).charAt(0).toUpperCase() + String(string).slice(1);
export const camelize = (string: string, change1st = false) => (
  String(string)
    // eslint-disable-next-line comma-spacing
    .replace(/[+./_]/,'-')
    .split('-')
    .map((part, index) => ((index || change1st) && ucfirst(part)) || part)
    .join('')
);

export const pluralize = (string: string) => `${string}s`;
export const pluralizeFsType = (type: FsType) => {
  switch (type) {
    case 'bankMiddleEast':
      return 'banks-middleeast';
    default:
      return pluralize(type);
  }
};

export const parseDate = (date: string) => {
  /* eslint-disable no-restricted-globals */
  const parsed = Date.parse(date);
  if (!isNaN(parsed)) {
    return new Date(parsed);
  }

  const arr = date.split(/[- :]/);
  const constructed = new Date(+arr[0], +arr[1]-1, +arr[2], +arr[3], +arr[4], +arr[5]).valueOf();
  if (!isNaN(constructed)) {
    return new Date(constructed);
  }

  throw new Error(`Date cannot be parsed! ${date}`);
  /* eslint-enable no-restricted-globals */
};

export function getNumber(numberinput?: string | number, defaultNumber = 0) {
  const number = `${numberinput}`.replace(/[,]/g, '.').replace(/[ ]+/g, '').replace(/^([+-])?[0]+/, '0');
  try {
    if (!Number.isNaN(number as unknown as number)) {
      return parseFloat(number);
    }
  } catch (e) {
    console.warn('getNumber error', numberinput, number, Number.isNaN(number as unknown as number), e);
  }
  return defaultNumber;
}

export const splitString = (input: string, separator=',\\n') => input
  .replace(new RegExp(`\\W*[${separator}]\\W*[${separator}]?\\W*`, 'ig'), ',')
  .split(',')
  .filter(item => !!item);
