import { SxStyleProp } from 'theme-ui';

export const styles = {
  svg: {
    fontSize: 's',
    mr: 3,
  },
  '&:hover': {
    svg: {
      color: 'primary',
    },
  },
  '.active': {
    color: 'red',
  },
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
} as SxStyleProp;
