export type RefFuncType = () => React.RefObject<HTMLDivElement>;

type AllRefType = RefFuncType | Element | null | undefined;

function isElement(ref?: AllRefType): ref is Element {
  return typeof ref !== 'function';
}

export function getElement(ref?: AllRefType): Element | null {
  // TODO: we should make some generic error handling
  if (!ref) return null;

  if (isElement(ref)) return ref;

  // NOTE: gatsby's ref on Img is actually not animateable, but it provides access to the img tag's ref
  // https://github.com/gatsbyjs/gatsby/issues/10723
  // it's apparently a bug, and will be fixed
  // i'd rather not wrap ImageLoader in multiple places, it's easier to remove this code, if gatsby fixes the problem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imgRef = (ref && ref().current as any)?.imageRef?.current;
  if (imgRef) return imgRef;

  // NOTE: gatsby's ref on BackgroundImage is actually not animateable, but it provides access to the img tag's ref
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bgRef = (ref && ref().current as any)?.selfRef;
  if (bgRef) return bgRef;

  // default
  return (ref && ref()?.current) || null;
}
// export const __el = getElement;

export function __el(ref?: AllRefType) {
  return getElement(ref);
}
export function __tr(ref?: AllRefType) {
  return getElement(ref) || undefined;
}
export function __rect(ref?: Element | null): DOMRect {
  return getElement(ref)?.getBoundingClientRect()
  // || undefined;
  || {
    height: -1,
    width: -1,
    x: -1,
    y: -1,
    bottom: -1,
    left: -1,
    right: -1,
    top: -1,
    toJSON: () => '{invalid: -1}',
  };
}

export function __select(selector: string, ref?: AllRefType): Element | null {
  if (isElement(ref)) return ref?.querySelector(selector);
  return __el(ref)?.querySelector(selector) || null;
}
export function __selectAll(selector: string, ref?: AllRefType): Element[] {
  if (isElement(ref)) return Array.from(ref?.querySelectorAll(selector) || []);
  return Array.from(__el(ref)?.querySelectorAll(selector) || []);
}
