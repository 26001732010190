import React from 'react';
import { SectionWithContent } from '@core';
import { Box, Grid, SmallText } from '@themed';
import { CountryDropdown } from '@types';
import { ChartItem, ExportButton, InternationalComparisonChart } from '../components';
import { finnoChartStyles as gridStyles } from '../components/index.styles';
import { DefaultChartData } from '../helpers';
import { CountrySelector } from '../../finnoscore-list/CountrySelector';
import { ChartDropDownHeader } from '../ChartDropDownControl';
import { ComparisonHeader } from '../../finnoscore-comparison/ComparisonSelector';
import { ClosableColorPicker, useColorAtIndex } from '../../colorpicker';
import { useTypeDropDownControl } from '../components/control/useTypeDropDownControl';
import { usePeriodDropDownControl } from '../components/control/usePeriodDropDownControl';
import { useInstitutionDropDownControl } from '../components/control/useInstitutionDropDownControl';
import useCountryInstitutionSelection from '../hooks/useCountryInstitutionSelection';
import { ChartDropDownControlComponent } from '../components/control/ChartDropDownControl';
import { InstitutionDropDownWithState } from '../../finnoscore-comparison/InstitutionGroupedDropDown';
import { LegacySwitch } from '../components/BubbleComparison/LegacySwitch';
import { Monochrome } from '../components/BasicComponents/Monochrome';
import { fillColorStyles } from '../components/BasicComponents';
import { ChartWizardStep } from './ChartWizardStep';
import { styles } from './HistoryChart.styles';

interface Props{
  countries: CountryDropdown[]
}
export const InternationalChart = ({ countries }:Props) => {
  const [colorA, setColorA] = useColorAtIndex(0);
  const [colorB, setColorB] = useColorAtIndex(1);
  const exportChartRef = React.useRef(null);

  const { types, setTypeId, typeId, type: fsType, isLoading: isTypeLoading } = useTypeDropDownControl();
  const { periods, period, periodId, setPeriodId, isLoading: isPeriodLoading } = usePeriodDropDownControl(fsType);
  const { institutions, institution, institutionSetHandler, isLoading: isInstitutionLoading } = useInstitutionDropDownControl(fsType, period);
  const { limitedList, similars } = useCountryInstitutionSelection({ institutionList: institutions, institution });

  const [isMixed, setIsMixed] = React.useState<number>(0);

  return (
    <SectionWithContent sx={gridStyles.main}>
      <Grid sx={{ ...gridStyles.content, gridTemplateColumns: '0.9fr 2fr' }}>

        <ChartWizardStep id="type" label="Type" isLoading={isTypeLoading}>
          <ChartDropDownControlComponent
            zIndex={20}
            list={types}
            selectedId={typeId}
            setter={setTypeId}
          >
            <ChartDropDownHeader title={fsType?.text || 'Choose a type'} />
          </ChartDropDownControlComponent>
        </ChartWizardStep>

        {fsType && (
          <ChartWizardStep id="pastPeriod" label="Period" isLoading={isPeriodLoading}>
            <ChartDropDownControlComponent
              zIndex={15}
              list={periods}
              selectedId={periodId}
              setter={setPeriodId}
            >
              <ChartDropDownHeader title={period?.text || 'Choose a period'} />
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {period && <Box id="currentPeriod">
          <SmallText>Compare List:</SmallText>
          <CountrySelector
            countries={countries}
            colorPickerComponent={<Box onClick={e => e.stopPropagation()}><ClosableColorPicker defaultColor={colorA} setColorHandler={setColorA} /></Box>}
            sx={{ width: '100% !important', zIndex: 10 }}
          />
        </Box>}

        {fsType && period && (
          <ChartWizardStep id="bankSelector" label="Institution" isLoading={isInstitutionLoading}>
            <LegacySwitch items={[{ title: 'Separated' }, { title: 'Mixed' }]} value={isMixed} changeHandler={setIsMixed} />
            <InstitutionDropDownWithState
              institutions={institutions}
              onSelect={institutionSetHandler}
              selectedInstitution={institution}
              sx={{ zIndex: 5 }}
              header={<ComparisonHeader
                subject={institution || DefaultChartData.sample}
                chartComponent={<ChartItem
                  percentage={(institution?.total|| DefaultChartData.sample.total) * 10}
                  sx={styles.headerChart}
                  fillComponent={<Monochrome sx={fillColorStyles(colorB)} />}
                />}
                colorPickerComponent={<Box onClick={e => e.stopPropagation()}><ClosableColorPicker defaultColor={colorB} setColorHandler={setColorB} /></Box>}
              />}
            />
          </ChartWizardStep>
        )}

        {institution && (
          <Box id="downloadButton">
            <ExportButton element={exportChartRef.current} type="chart" name={`international.${institution.name}`} />
          </Box>
        )}

        <Box id="chart" variant={institution ? undefined : 'styles.sampleChart'}>
          <InternationalComparisonChart
            ref={exportChartRef}
            institutions={institution ? limitedList : DefaultChartData.international.banks}
            currentInstitution={institution || DefaultChartData.international.banks[0]}
            similarInstitutions={similars || DefaultChartData.international.similars}
            isMixed={isMixed === 1}
          />
        </Box>

      </Grid>

    </SectionWithContent>
  );
};

export default InternationalChart;
