import { ItemsBySection } from '@core';
import {
  fetchPeriods, fetchCountries, formatInstitutions, formatCountries, formatCountryCalculations, FetchPeriodsProps,
  // TODO: import directly instead of modules
} from '@modules';
import {
  Institution, Country, CountryCalculation, Locale,
} from '@types';

interface GetComparisonSelectionDynamicProps extends FetchPeriodsProps{
  locale: Locale;
}

const translateBeforeSort = (text: string) => text.toLowerCase()
  .replace(/[áâàäǎã]/gi, 'a')
  .replace(/[éêèëě]/gi, 'e')
  .replace(/[íîìïǐ]/gi, 'i')
  .replace(/[úûùüű]/gi, 'u')
  .replace(/[óôòöőõ]/gi, 'o');

export const getComparisonSelectionDynamic = async ({
  fsType, locale, date, countryCode, compareDate,
}: GetComparisonSelectionDynamicProps) => {
  const periodsWithTranslation = await fetchPeriods({ fsType, date, countryCode, compareDate });
  // const bankPeriodsWithTranslation = await fetchPeriods({ fsType: 'bank', date, countryCode, compareDate });
  const countriesWithTranslations = await fetchCountries({ fsType, date, countryCode, fsPeriod: periodsWithTranslation.lastestPeriod.id });

  const formattedCountries = formatCountries(countriesWithTranslations.countries, countriesWithTranslations.translations);
  const formattedInstitutions = formatInstitutions(periodsWithTranslation.latestPeriodInstitutions, periodsWithTranslation.previousPeriodInstitutions, periodsWithTranslation.translations);
  const formattedCountryCalculations = formatCountryCalculations(periodsWithTranslation.latestCountryCalculations, periodsWithTranslation.translations);

  const countriesForLocale: Country[] = formattedCountries
    .filter((country: Country) => !country.language || country.language === locale);

  const institutionsForLocale: Institution[] = formattedInstitutions
    .filter((i: Institution) => !i.language || i.language === locale);

  const countryCalculationsForLocale: CountryCalculation[] = formattedCountryCalculations
    .filter((cc: CountryCalculation) => !cc.language || cc.language === locale);

  return { countries: countriesForLocale, institutions: institutionsForLocale, countryCalculations: countryCalculationsForLocale };
};

export const createInstitutionId = (institution: Institution) => `${institution.country.countryCode}.${institution.name}`;

export const getInstitutionsByCountries = (institutions: Institution[], country?: string) => {
  const institutionsByCountry: ItemsBySection[] = [];

  institutions.forEach(institution => {
    const foundCountry = institutionsByCountry.find(c => c.section.id === institution.country.countryCode);

    const institutionToAdd = {
      id: createInstitutionId(institution),
      text: institution.name,
    };

    if (foundCountry) {
      foundCountry.items.push(institutionToAdd);
    } else {
      const newCountry = {
        section: {
          id: institution.country.countryCode,
          text: institution.country.name || institution.country?.countryCode,
          icon: institution.country.flag,
        },
        items: [institutionToAdd],
      };
      institutionsByCountry.push(newCountry);
    }
  });

  institutionsByCountry.forEach(institutionByCountry => institutionByCountry.items.sort((a, b) => (translateBeforeSort(a.text) < translateBeforeSort(b.text) ? -1 : 1)));
  institutionsByCountry.sort((a, b) => (translateBeforeSort(a.section.text) < translateBeforeSort(b.section.text) ? -1 : 1));

  const selectedCountryIndex = institutionsByCountry.findIndex(section => section.section.text === country);
  if (selectedCountryIndex >= 0) {
    const selectedCountry = institutionsByCountry[selectedCountryIndex];
    institutionsByCountry.splice(selectedCountryIndex, 1);
    return [selectedCountry, ...institutionsByCountry];
  }
  return institutionsByCountry;
};
