/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { useRecoilState } from 'recoil';
import { useLocale } from '@modules';
import { authState } from '../../../../store/AuthState';
import { styles } from './Signout.styles';

export const SignOut = () => {
  const [, setUser] = useRecoilState(authState);
  const locale = useLocale();

  return (
    <Link to={`/${locale}/charts/login`} onClick={() => setUser({ token: null, user: null })} sx={styles.link}>
      SignOut
    </Link>
  );
};
