import React from 'react';
import { Institution } from '@types';

interface useRemoveDuplicatesProps {
  institutions: Institution[];
  currentInstitution: Institution;
  isMixed: boolean;
  similarInstitutions?: Institution[];
}

export const useRemoveDuplicates = ({ institutions, similarInstitutions, currentInstitution, isMixed }: useRemoveDuplicatesProps) => {
  const [mixedInstitutions, setMixedInstitutions] = React.useState<Institution[]>(institutions);

  React.useEffect(() => {
    const isCurrentInstitutionIncluded = institutions.find(i => i.id === currentInstitution.id);
    const filteredInstitutions = isCurrentInstitutionIncluded ? institutions.filter(i => i.id !== currentInstitution.id) : institutions;
    if (isMixed && similarInstitutions) {
      setMixedInstitutions([...filteredInstitutions, ...similarInstitutions]);
    } else {
      setMixedInstitutions(institutions);
    }
  }, [institutions, similarInstitutions, currentInstitution, isMixed]);

  return {
    mixedInstitutions,
  };
};
