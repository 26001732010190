import { SxStyleProp } from 'theme-ui';
import { compactSectionContentPadding, Theme } from '@themed';

export const styles = {
  section: {
    position: 'relative',
  } as SxStyleProp,
  sectionContent: {
    variant: 'grids.CompactSectionContentPadding',
    pb: [8, 8, 8, 9],
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  title: {
    color: 'background',
    textAlign: ['center', 'center', 'left'],
    ml: [4, 0],
  },
  list: {
    mt: 8,
    width: 'fullSize',
    gap: [0, 0, 8, 8],
    px: compactSectionContentPadding,
    gridTemplateColumns: ['1fr', '1fr', 'repeat(3, 1fr)'],
    span: {
      fontWeight: 'bold',
      fontSize: 's',
    },
    b: {
      color: 'background',
    },
    figure: {
      variant: 'images.pureCircleIcon',
      '&::before': {
        content: 'attr(name)',
        fontSize: 'xl',
        fontWeight: 'bold',
      },
    },
    '.listItem': {
      display: ['block', 'flex', 'block', 'block'],
    },
  },
  offers: {
    px: [4, 4, 0, 0],
    flexDirection: ['column', 'column', 'row', 'row'],
    '&>*:not(:last-child)::after': {
      content: ['none', 'none', '""'],
      borderLeft: (theme: Theme) => `2px solid ${theme.colors?.background}`,
      mr: 5,
      height: '80%',
    },
  },
};
