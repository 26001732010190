import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { TriggerElementProps } from '@types';
import { __el, __rect, __tr } from '@modules';
import { cleanupScrolls, maxScale, pin, scrub, useIsMobile, useSmoothScrollbar, useSmoothScrollbarListener } from '@core';
import { getBreakpoint, themeConfig } from '@themed';
import { finniConfig } from '@config';

interface AnimateFinniRevealCardsProps extends TriggerElementProps {
  container: () => React.RefObject<HTMLDivElement>;
  pacman: () => React.RefObject<HTMLDivElement>;
  image: () => React.RefObject<HTMLDivElement>;
}

const animateFinniRevealCards = ({
  triggerRef, image, pacman, container,
}: AnimateFinniRevealCardsProps) => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.saveStyles([
    __el(container),
    'section#oursupport',
  ]);

  // TODO: refine, as calculation is still not accurate, even with finniConfig.pacman.tolerance
  const relativeDuration = (vh: number) => (vh/(3.5-((finniConfig.pacman.tolerance)/window?.innerHeight)))*100;

  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];
  const trigger = __tr(triggerRef);
  const allWidthConfig = {
    trigger,
    ...scrub(),
    start: `top+=${finniConfig.pacman.tolerance} bottom`,
    // entire duration of the scroll is 350 vh!
    end: () => `bottom+=${window?.innerHeight * 2.5 || '250vh'} bottom`,
    onEnter: () => gsap.set(__el(pacman), { background: themeConfig.colors.primary, overflow: 'hidden', borderRadius: '50%' }),
    onLeaveBack: () => gsap.set(__el(pacman), { background: 'transparent', overflow: 'visible' }),
  };

  const initScrollersDesktop = () => {
    if (!trigger) return;
    scrollers.push(ScrollTrigger.create({
      trigger,
      start: 'top top',
      end: 'bottom center',
      toggleActions: 'restart none reverse none',
      ...scrub(),
      refreshPriority: 5,
      ...pin(),
    // markers: process.env.NODE_ENV === 'development',
    }));

    const {
      x, y, width, height,
    } = __rect(__el(pacman));
    // 1.3 multiplication is needed as we move pacman later by x coordinate, checkout `xPercent: 30,`
    const scale = maxScale(x * 1.3, y, width, height);

    const animation = gsap.timeline();
    animation
      .set('section#oursupport', { background: 'none' })
      .set(__el(image), { visibility: 'hidden' })
      .to(__el(pacman), {
        xPercent: 30,
        scale: 2,
        ease: 'back',
        duration: relativeDuration(0.5),
      })
      .set(__el(image), {
        opacity: 1,
        visibility: 'visible',
      })
      .from(__el(image), {
        yPercent: 100,
        ease: 'linear',
        duration: relativeDuration(0.5),
      })
      .to(__el(pacman), {
        scale: 0.1,
        ease: 'linear',
        duration: relativeDuration(0.5),
      })
      .set(__el(image), {
        display: 'none',
      })
      .to(__el(pacman), {
        scale,
        duration: relativeDuration(1),
      })
    // NOTE: the next section, oursupport, can be more than 100vh, so might be problematic as we cannot know when to hide the pacman bg color
    // DIRTY HACK: that's the reason with put back the bg color:
      .set('section#oursupport', {
        background: themeConfig.colors.primary,
      })
      .to(__el(pacman), {
        duration: relativeDuration(1),
      })
      .set(__el(pacman), {
        display: 'none',
      });

    scrollers.push(ScrollTrigger.create({
      ...allWidthConfig,
      // markers: process.env.NODE_ENV === 'development',
      toggleActions: 'restart none reverse none',
      animation,
      // TODO: we should snap to labels instead of this magic number, but for that the animation has to be split into multiple scroll triggers
      // or we could get these positions from the label in onEnter, but it's buggy
      snap: value => {
        const cardsRevealedPos = 0.2824204278818345;
        const finniFullyGrownPos = 0.706899499974365;
        const ending = 0.8;
        if (value > 0.15 && value < cardsRevealedPos) return cardsRevealedPos;
        if (value > 0.4 && value < finniFullyGrownPos) return finniFullyGrownPos;
        if (value > ending) return 1;
        return value;
      },
    }));
  };

  const initScrollersMobile = () => {
    if (!trigger) return;
    scrollers.push(ScrollTrigger.create({
      ...allWidthConfig,
    }));
  };

  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(1)})`]: () => initScrollersDesktop(),
    [`(max-width: ${getBreakpoint(1)})`]: () => initScrollersMobile(),
  });

  // if we really destroy scrolls, on any potential screen resize, the parallax and menu anims stop working
  // return () => cleanupScrolls(scrollers);
};

export const useAnimationFinniRevealCards = (props: AnimateFinniRevealCardsProps) => {
  const scrollBar = useSmoothScrollbar();
  const isMobile = useIsMobile();
  const [init, setInit] = React.useState(false);

  useSmoothScrollbarListener({
    pacmancontainer: __el(props.container),
  }, { disable: !(scrollBar && !isMobile) });

  React.useEffect(() => {
    // NOTE: Shall we init after loading scrollBar? no, as we need the animation without scrollbar as well on touch device
    // NOTE: we should only initialize revealcards once, not on every scrollbar and resonsive resize update, as that screws up the ScrollTrigger.create
    if (!init) {
      animateFinniRevealCards(props);
      setInit(true);
    }
    return () => {
      if (init) {
        cleanupScrolls();
      }
    };
  }, [scrollBar, isMobile]);
};
