import {
  Country, CountryFromApi, Locale, Translation,
} from '@types';
import { formatIcon } from '../commonFormatting';
import { languages } from '../../i18n';

export const ALL_COUNTRIES = 'ALLE';
export const ALL_COUNTRIES_REGIONAL = 'ALLERegion';

export const isAllCountries = (countryCode?: string | null) => [undefined, null, 'undefined', ALL_COUNTRIES, ''].includes(countryCode);

export const getTranslatedCountries = (country: CountryFromApi, translations: Translation[]): Country[] => {
  if (!country) {
    console.trace();
    throw new Error(`getTranslatedCountries misses valid country: ${country}`);
  }
  const translation = translations.find(tr => tr.key === country.countryCode);

  if (translation) {
    return languages.map(lang => ({
      countryCode: country.countryCode,
      flag: formatIcon(country.flag),
      name: translation[lang as Locale],
      language: lang as Locale,
    }));
  }

  throw new Error(`getTranslatedCountries misses translation for country: ${country.countryCode}`);

  // return [{
  //   ...country,
  //   flag: formatIcon(country.flag),
  // }];
};

export const formatCountries = (countries: CountryFromApi[], translations: Translation[]): Country[] => {
  const translated = countries.reduce((accum, curr) => [...accum, ...getTranslatedCountries(curr, translations)], [] as Country[]);

  const allCountriesEntries = translated.filter(c => c.countryCode === ALL_COUNTRIES);
  if (allCountriesEntries && allCountriesEntries.length > 0) {
    const filtered = translated.filter(c => c.countryCode !== ALL_COUNTRIES);
    return [...allCountriesEntries, ...filtered];
  }

  return translated;
};
