export const styles = {
  wrapper: {
    variant: 'grids.CompactSectionContentPadding',
  },
  authorName: {
    color: 'background',
    pt: 3,
  },
  authorDescription: {
    color: 'text',
    pt: 2,
    maxWidth: ['unset', 'unset', '50%'],
    a: {
      color: 'background',
    },
  },
  subtitle: {
    color: 'background',
    pt: 6,
    pb: 9,
  },
  avatar: {
    width: 'bigMobileIcon',
    height: 'bigMobileIcon',
    mr: 3,
    flex: '0 0 auto',
  },
};
