type WhereCondition = string;

interface WhereGenerator {
  [key: string]: (slug: string) => string;
}

const caseStudySelectors = [{
  to: 'en',
  from: 'english_case_study',
}, {
  to: 'fr',
  from: 'french_case_study',
}];

const query = (where: WhereCondition) => `{
  casestudies:germanCaseStudies(${where}publicationState: PREVIEW) {
    slug
    title
    subtitle
    teaser
    supportScope: support_scope
    publishDate: publish_date
    publishedAt: published_at
    published
    content {
      ... on ComponentArticleSingleImage {
        image {
          url
          alternativeText
          formats
        }
      }
      ... on ComponentArticleText {
        text
      }
      ... on ComponentArticleMultipleImageGallery {
        images {
          url
          alternativeText
          formats
        }
      }
      ... on ComponentArticleTabbedImageGallery {
        tabbedImages {
          title
          image {
            url
            alternativeText
            formats
          }
          selected
        }
      }
      ... on ComponentArticleQuote {
        quote {
          name
          jobTitle
          text
          profile {
            url
            alternativeText
            formats
          }
        }
      }
      ... on ComponentArticleVideo {
        video {
          headline
          video {
            url
            alternativeText
            formats
          }
          youtubeVideo
        }
      }
    }
    thumbnails: thumbnail {
      url
      alternativeText
      formats
    }
    covers: cover {
      url
      alternativeText
      formats
    }
    ${caseStudySelectors.map(selector => `${selector.to}: ${selector.from}{
      slug
      title
      subtitle
      teaser
      supportScope: support_scope
      publishDate: publish_date
      publishedAt: published_at
      published
      de: german_case_study {
        publishDate: publish_date
      }
      content {
        ... on ComponentArticleSingleImage {
          image {
            url
            alternativeText
            formats
          }
        }
        ... on ComponentArticleText {
          text
        }
        ... on ComponentArticleMultipleImageGallery {
          images {
            url
            alternativeText
            formats
          }
        }
        ... on ComponentArticleTabbedImageGallery {
          tabbedImages {
            title
            image {
              url
              alternativeText
              formats
            }
            selected
          }
        }
        ... on ComponentArticleQuote {
          quote {
            name
            jobTitle
            text
            profile {
              url
              alternativeText
              formats
            }
          }
        }
        ... on ComponentArticleVideo {
          video {
            headline
            video {
              url
              alternativeText
              formats
            }
            youtubeVideo
          }
        }
      }
    }`)}
  }
}`;

const where: WhereGenerator = {
  de: (slug: string) => (`where: { slug: "${slug}" }, `),
  en: (slug: string) => (`where: { english_case_study: { slug: "${slug}" } }, `),
  fr: (slug: string) => (`where: { french_case_study: { slug: "${slug}" } }, `),
};

export const getQuery = (locale: string, slug: string) => {
  const localeQuery = (where[locale] && query(where[locale](slug))) ||'';
  return localeQuery;
};
