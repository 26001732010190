import {
  isAllCountries, pluralizeFsType, useLocale,
} from '@modules';
import { FsType } from '@types';
import { useSelectedCountry } from '..';
import { useDatesParam } from './useDatesParam';

export const useComparisonLink = (a: string, b: string, fsType: FsType) => {
  const locale = useLocale();
  const dates = useDatesParam();
  const country = useSelectedCountry();
  const pluralStr = pluralizeFsType(fsType);

  const path = dates.link() ? `${pluralStr}/compare-preview` : `${pluralStr}/compare`;

  const extraQueryParams: string[] = [];
  if (!isAllCountries(country?.countryCode)) extraQueryParams.push(`&countryCode=${country.countryCode}`);
  if (dates.link) extraQueryParams.push(dates.link());

  return `/${locale}/${path}?a=${encodeURIComponent(a)}&b=${encodeURIComponent(b)}${extraQueryParams.join('')}`;
};
