export const styles = {
  sectionContent: {
    variant: 'grids.CompactSectionContentPadding',
  },
  container: {
    width: 'fullSize',
    maxWidth: 'unset',
    position: 'relative',
    gridTemplateColumns: 'repeat(2, 1fr)',
    display: ['flex', 'grid'],
    variant: 'flex.colLeft',
    placeItems: ['initial', 'center'],
  },
  title: {
    mb: [0, 0, 9],
    mt: ['34px', '100px'],
    ml: [4, 0],
  },
  outerIcons: {
    overflow: 'hidden',
    width: '100vw',
    mt: [6, 6, 9],
    mb: [6, 6, 9],
  },
  icons: {
    rowGap: 4,
    columnGap: [4, 6, 9],
    gridTemplateColumns: ['repeat(10, 33%)', 'repeat(10, 20%)', 'repeat(10, 10%)'],
    gridTemplateRows: 'repeat(3, 1fr)',
    // gridTemplateColumns: ['repeat(4, 1fr)', 'repeat(8, 1fr)'],
    // gridTemplateRows: ['auto auto auto 0px 0px 0px', 'auto auto 0px 0px 0px'],
    filter: 'grayscale(100%)',
    '&:before': {
      content: '"."',
      color: 'background',
      gridColumn: '1/2',
      gridRow: '2/3',
    },
    '&:after': {
      content: '"."',
      color: 'background',
      gridColumn: '1/3',
      gridRow: '3/4',
    },
  },
  image: {
    width: ['75vw', '40vw', '45vw', '550px'],
    height: ['75vw', '40vw', '45vw', '550px'],
    borderRadius: '50%',
    alignSelf: 'center',
  },
  content: {
    p: '0 !important', // disable default padding from Compact*Content
    minHeight: 'unset',
  },
  image1: {
    borderRadius: 'fullSize',
    border: 'thick',
    // borderWidth: [5, 6, 8], // have to use border, as padding is tricked out with gatsby image's position:absolute
    borderColor: 'grey.4',
    bg: 'grey.4',
    order: [1, 2],
    padding: [5, 5, 7],
  },
  image2: {
    order: [3, 3],
    mt: '100px',
    bg: 'primary', // hack to prevent white chopped background on mobile
  },
  content1: {
    order: [2, 1],
  },
  content2: {
    order: [4, 4],
  },
};
