import React from 'react';
import { Box, BoxProps } from '@core';

interface VideoProps extends BoxProps {
  src: string;
  loop?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Video = React.forwardRef<HTMLVideoElement, VideoProps>((props, ref) => <Box as="video" {...props} ref={ref} />);
