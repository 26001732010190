/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { NewsLetterSignup } from '@composite';
import { SectionWithContent } from '@core';
import { Article } from '@types';
import { Post } from '../common/Post';
import { PostFooter } from '../common/PostFooter';
import { BlogList } from '../common/BlogList';
import { DefaultNewsLetterContent } from '../NewsLetterContent';
import { AuthorHeader } from './AuthorHeader';

export interface MoreSimilarArticleProps extends Article {
  moreSimilarArticle: Article[];
}

export interface PostDetailsProps {
  pageContext: MoreSimilarArticleProps
}

export const PostDetails = (props: MoreSimilarArticleProps) => (
  <Post
    {...props}
    footer={(
      <React.Fragment>
        {props.author && <SectionWithContent sx={{
          bg: 'primary',
          minHeight: '50vh',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
        >
          <AuthorHeader {...props.author} />
        </SectionWithContent>}
        <SectionWithContent>
          <BlogList articles={props.moreSimilarArticle} />
        </SectionWithContent>
        <NewsLetterSignup id="pages.blog.contact">
          <DefaultNewsLetterContent id="pages.blog.contact" />
        </NewsLetterSignup>
      </React.Fragment>
    )}
  >
    <PostFooter
      tags={props.tags}
      title={props.title}
    />
  </Post>
);
