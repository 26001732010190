import { SxStyleProp } from 'theme-ui';

export const styles = {
  section: {
    pt: 'headerHeight',
    minHeight: '100vh !important',
    borderBottom: 'none',
  },
  content: {
    '&>*': {
      flex: ['1 1 38vw', '1 1 38vw', '1 1 43vw', '1 1 38vw', '1 1 45vw'],
    },
    '&>aside:last-of-type': {
      flex: ['1 1 61vw', '1 1 61vw', '1 1 57vw', '1 1 61vw', '1 1 55vw'],
      position: 'relative',
      minHeight: ['95vw', '25vh'],
      transform: ['translateY(-3vh)', 'none'],
    },
    'h1, h2': {
      fontSize: ['m', 'xl', '46px'],
      maxWidth: ['unset', 'unset', '36vw', '500px'],
    },
    h3: {
      display: 'none !important',
    },
    'p, .paragraph': {
      fontSize: ['xs', 'xs', 's'],
      maxWidth: ['unset', 'unset', '34vw', '500px'],
    },
  } as SxStyleProp,
  wrapper: {
    minHeight: 'unset !important',
  },
  box: {
    width: ['100%', '80%', '100%'],
    height: 'auto',
    mx: ['initial', 'auto', 'initial'],
    mt: ['headerHeight', 'initial'],
  },
};
