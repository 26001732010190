import React from 'react';
import { Box, Flex, Grid } from 'theme-ui';
import { ChildrenProps, Dimension, Dimensions } from '@types';
import { useUpdatedGlobalDimensions } from 'src/store/DimensionContext';
import { DimensionProp } from '../ScorePerBank/SimpleChartComponent';
import ChartLabel from './ChartLabel';
import { CharValueWithMonochromeAndPercentage } from './DoubleChartContainer';
import { styles } from './ComparisonChartWithLabel.styles';

export interface ComparisonChartProps extends DimensionProp {
  relativeDimension?: Dimensions,
  dimension: Dimensions,
}
interface ComparisonChartWithLabelProps extends ComparisonChartProps, ChildrenProps {
  chartComponent?: ()=>JSX.Element
  id?: string;
}
export interface DoubleChartComponentProps {
  dimensionValue?: string;
  relativeDimensionValue?:string;
  dimensionKey: string;
  globalDimensions?: Dimension[];
  setGlobalDimensions?: (d?:Dimension[])=>void;
}

export const ComparisonChartWithLabel = React.forwardRef<HTMLDivElement, ComparisonChartWithLabelProps>(({ id, dimension, relativeDimension, children }, ref) => {
  const [globalDimensions, setGlobalDimensions] = useUpdatedGlobalDimensions(dimension);

  React.useEffect(() => {
    setGlobalDimensions(dimension);
  }, [dimension]);

  return (
    <Box id={id}>
      {children}
      <Flex ref={ref} sx={styles.chart}>
        {dimension.map((d) => (
          <Grid key={d.key} sx={styles.grid}>
            <ChartLabel>{d.key}</ChartLabel>

            <CharValueWithMonochromeAndPercentage
              dimensionValue={dimension?.find(rd => rd.key === d.key)?.value}
              relativeDimensionValue={relativeDimension?.find(rd => rd.key === d.key)?.value}
              dimensionKey={d.key}
              globalDimensions={globalDimensions}
              setGlobalDimensions={setGlobalDimensions}
            />

          </Grid>
        ))}
      </Flex>
    </Box>
  );
});
