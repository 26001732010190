import { SxStyleProp } from '@themed';

export const useStyles = (isOpen: boolean) => ({
  contentBar: {
    position: ['static', 'fixed'],
    left: 0,
    top: [0, '150px'],
    right: 'unset',
    zIndex: 1000,
    width: ['100%', isOpen ? '18rem' : '1.5rem'],
    height: ['min-content', isOpen ? 'min-content' : '5rem'],
    overflow: 'hidden',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    boxShadow: ['none', 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;'],
    transition: 'all 0.3s ease-in',

    variant: 'flex.colCenter',
    backgroundColor: ['background', isOpen ? undefined : 'text'],
  } as SxStyleProp,
  contentList: {
    variant: 'flex.colLeft',
    display: ['flex', isOpen ? 'flex' : 'none'],
    // pt:2,
    width: '100%',
    'li::before': {
      all: 'unset',
    },
    li: {
      width: '100%',
      my: '5px',
      '&:hover': {
        width: '100%',
        cursor: 'pointer',
      },
      a: {
        color: 'text',
        fontWeight: 'normal',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  } as SxStyleProp,
  contentBarHeader: {
    display: ['none', 'flex'],
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    pr: isOpen ? 3 : 0,
    pt: isOpen ? 2 : 0,
    fontSize: 'm',
    borderTopRightRadius: '15px',
    svg: {
      height: 'fullSize',
      cursor: 'pointer',
    },
  },
});
