import { Locale } from './Locale';
import { Country, CountryFromApi } from './Country';
import { Dimensions } from './Dimension';
import { FsType } from './FsType';

export enum CountryCalculationType {
  Average = 'average',
  Best = 'best'
}

export interface CountryCalculationBase {
  id: number;
  fsType: FsType;
  total: number;
  type: CountryCalculationType;
  dimensions: Dimensions;
  rank?: number;
}

export interface CountryCalculationFromApi extends CountryCalculationBase {
  country: CountryFromApi;
}

export interface CountryCalculation extends CountryCalculationBase {
  country: Country;
  language?: Locale;
}
