import React, { useEffect } from 'react';
import { Institution } from '@types';

interface useComparisonChartProps{
  institutions: Institution[];
  currentInstitution: Institution;
  similarInstitutions?: Institution[];
}
export const useComparisonChartValueChange = ({ institutions, similarInstitutions }: useComparisonChartProps) => {
  const [editableInstitutions, setEditableInstitutions] = React.useState(institutions);
  const [editableSimilarInstitutions, setEditableSimilarInstitutions] = React.useState<Institution[] | undefined>(undefined);

  useEffect(() => {
    const sortedInstitutions = institutions.sort((a, b) => b.total - a.total);
    setEditableInstitutions(sortedInstitutions);
  }, [institutions]);

  useEffect(() => {
    setEditableSimilarInstitutions(similarInstitutions);
  }, [similarInstitutions]);

  return ([
    editableInstitutions,
    (newValue: number | number[], index: number) => {
      const newValues = editableInstitutions?.map((ins: Institution, i: number) => (i !== index ? ins : { ...ins, total: +newValue }));
      newValues.sort((a, b) => b.total - a.total);
      setEditableInstitutions(newValues);
    },
    editableSimilarInstitutions,
    (newValue: number | number[], index: number) => {
      const newValues = editableSimilarInstitutions?.map((ins: Institution, i: number) => (i !== index ? ins : { ...ins, total: +newValue })).sort((a, b) => b.total - a.total);
      setEditableSimilarInstitutions(newValues);
    },
  ] as const);
};
