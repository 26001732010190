import React from 'react';

export const setCookie = (name: string, value: string, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
};

export const getCookie = (name: string) => document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=');
  return parts[0] === name ? decodeURIComponent(parts[1]) : r;
}, '');

export const deleteCookie = (name: string, path: string) => {
  setCookie(name, '', -1, path);
};

export const useCookie = (name: string, dependencies?: unknown[]) => {
  const [isLoaded, setLoaded] = React.useState(false);
  const [value, setValue] = React.useState<string | null>(null);

  React.useLayoutEffect(() => {
    const cookie = getCookie(name);
    setValue(cookie);
    setLoaded(true);
  }, dependencies || []);

  const saveCookie = (val: string, days = 7) => {
    setCookie(name, val, days);
    setValue(val);
    setLoaded(true);
  };

  return { value, setValue: saveCookie, isLoaded };
};

interface CookieContextType {
  value: string | null;
  setValue: (val: string, days?: number) => void;
}

export const CookieContext = React.createContext<CookieContextType>({} as CookieContextType);
