const styles = {
  generalBackgroundImage: {
    variant: 'styles.maxWidth',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  pseudoElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: -1,
    display: 'flex',
    textIndent: '-200%',
  },
};

interface Options {
  smallSrc?: string;
}

interface BackgroundStylesReturnType {
  background: React.CSSProperties;
  preload: React.CSSProperties;
}

export function backgroundStyles(bigSrc: string, options: Options): BackgroundStylesReturnType {
  const preloadTinyStyle = {
    '&::before': {
      ...styles.generalBackgroundImage,
      ...styles.pseudoElement,

      // default to the small image
      backgroundImage: `url(${options.smallSrc})`,

      // preload image with content, but hide
      content: `url(${options.smallSrc})`,
    },
  };

  const bgStyle = {
    background: {
      ...styles.generalBackgroundImage,
      ...(options.smallSrc ? preloadTinyStyle : {}),
      '&::after': {
        ...styles.generalBackgroundImage,
        ...styles.pseudoElement,
        backgroundImage: `url(${bigSrc})`,
        // preload image with content, but hide
        content: `url(${bigSrc})`,
      },
      position: 'relative',
      overflow: 'hidden',
    } as React.CSSProperties,
    preload: {
      // hide it
      position: 'absolute',
      width: 0,
      height: 0,
      overflow: 'hidden',
      zIndex: -1,
      textIndent: '-200%',

      // add preloading for big image preload images
      content: `url(${bigSrc})`,
    } as React.CSSProperties,
  };

  return bgStyle;
}
