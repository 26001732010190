import * as React from "react";

const SvgBarchartTrend = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={66}
    height={75}
    viewBox="0 0 66 75"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="barchart-trend_svg__Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="barchart-trend_svg__Landing-Full"
        transform="translate(-744 -1901)"
        fillRule="nonzero"
      >
        <g id="barchart-trend_svg__Group-11" transform="translate(691 1490)">
          <g id="barchart-trend_svg__Group-4" transform="translate(0 362)">
            <g
              id="barchart-trend_svg__barchart-trend"
              transform="translate(53 49)"
            >
              <path
                d="M59.723 1.78l.058.189 2.614 9.813a2.657 2.657 0 01-1.867 3.252 2.637 2.637 0 01-3.175-1.688l-.058-.19-.994-3.73c-9.595 17.708-31.09 33.236-53.298 36.338A2.643 2.643 0 01.025 43.5a2.653 2.653 0 012.252-2.995c20.515-2.866 40.567-17.357 49.375-33.595l-3.493.942a2.637 2.637 0 01-3.176-1.689l-.058-.19a2.659 2.659 0 011.679-3.193l.188-.058L56.548.09a2.637 2.637 0 013.175 1.688z"
                id="barchart-trend_svg__Combined-Shape"
                fill="#00BD59"
              />
              <path
                d="M12.54 55.084c1.094 0 1.98.891 1.98 1.991v15.933c0 1.1-.886 1.992-1.98 1.992H1.98A1.986 1.986 0 010 73.008V57.075c0-1.1.887-1.991 1.98-1.991zm17.16-5.311c1.094 0 1.98.891 1.98 1.991v21.244c0 1.1-.886 1.992-1.98 1.992H19.14a1.986 1.986 0 01-1.98-1.992V51.764c0-1.1.887-1.991 1.98-1.991zm17.16-9.294c1.094 0 1.98.891 1.98 1.991v30.538c0 1.1-.886 1.992-1.98 1.992H36.3a1.986 1.986 0 01-1.98-1.992V42.47c0-1.1.887-1.991 1.98-1.991zm17.16-17.261c1.094 0 1.98.892 1.98 1.992v47.798c0 1.1-.886 1.992-1.98 1.992H53.46a1.986 1.986 0 01-1.98-1.992V25.21c0-1.1.887-1.992 1.98-1.992zM10.56 59.066h-6.6v11.95h6.6v-11.95zm17.16-5.31h-6.6v17.26h6.6v-17.26zm17.16-9.295h-6.6v26.555h6.6V44.461zm17.16-17.26h-6.6v43.815h6.6V27.201z"
                id="barchart-trend_svg__Combined-Shape"
                fill="#000"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgBarchartTrend;
