import React from 'react';
import { FsPeriod, FsType, FsTypeBase, isFsType } from '@types';
import { getAllFsPeriods } from '../../networking';
import { useFsTypes } from './useFsTypes';

const convertPeriodToFsTypeBase = (result:FsPeriod[]) => result.reduce((unique, item) => [...unique].concat(isFsType(item.text) && unique.find(u => u.text === item.text) ? [] : item as FsTypeBase<FsType>), [] as FsTypeBase<FsType>[]);

export const useTypeDropDownControl = () => {
  const { types, setTypes, typeId, setTypeId, type } = useFsTypes<FsType>();

  React.useEffect(() => {
    async function fetchData() {
      const result = await getAllFsPeriods();
      setTypes(convertPeriodToFsTypeBase(result));
    }
    fetchData();
  }, []);

  return ({
    type,
    typeId,
    setTypeId,
    types,
    isLoading: types.length===0,
  });
};
