import { SxStyleProp } from 'theme-ui';
import { isTouchDevice } from '@modules';

export const finniConfig = {
  pacman: {
    tolerance: 75, // to keep pacman still on the screen, despite the scrolling had started until this amount of pixels
  },

  container: {
    zIndex: 101,
  },

  pacmanBaseStyle: {
    bg: 'primary',
    // bg: 'primaryLighter.1', // just for debug
    borderRadius: 'fullSize',
    width: ['pacmanSmall', 'pacman'],
    height: ['pacmanSmall', 'pacman'],
    // overflow: 'hidden',
    color: 'background',
    textIndent: '-1000px',
    fontSize: '3px',
    letterSpacing: '-3px',
  },
};

const {
  container, pacman, pacmanBaseStyle,
} = finniConfig;

export const finniStyles = {
  container: {
    ...container,
    variant: 'flex.right',
    // position: 'relative', // need to use translation because of smooth-scrollbar
    position: ['relative', 'relative', isTouchDevice() ? 'fixed' : 'relative'], // keep finni scrolling with the page for mobile and tablet
    minHeight: ['pacmanSmall', 'pacman'], // required to be aligned the entire content to center, same with RevealCard
    ml: ['150vw', '150vw', '3vw'],
    transformStyle: 'preserve-3d',
  },
  pacman: {
    position: 'absolute', // still using, even with smooth-scrollbar
    left: '0',
    // top: '-100%', // NOTE: do not set top, as it messes up the since the default top comes by CSS layout
    // transformStyle: 'preserve-3d',
    ...pacmanBaseStyle,
    bg: 'transparent',
    transformOrigin: 'center',
  } as SxStyleProp,
  pacmanForehead: {
    ...pacmanBaseStyle,
    zIndex: 50,
    position: 'relative',
    height: ['halfPacmanSmall', 'halfPacman'],
    borderTopLeftRadius: '50% 100%',
    borderTopRightRadius: '50% 100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transformOrigin: 'bottom center',
  } as SxStyleProp,
  pacmanChin: {
    ...pacmanBaseStyle,
    position: 'relative',
    zIndex: 49,
    height: ['halfPacmanSmall', 'halfPacman'],
    borderBottomLeftRadius: '50% 100%',
    borderBottomRightRadius: '50% 100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    transformOrigin: 'top center',
  } as SxStyleProp,
  hat: {
    svg: {
      width: ['163px', '260px'],
      height: ['126px', '249px'],
    },
    position: 'absolute',
    zIndex: 51,
    top: ['-23px', '-48px'],
    left: ['-27px', '0'],
    transform: ['rotate(16deg)', 'rotate(22deg)'],
  } as SxStyleProp,
  tie: {
    position: 'absolute',
    zIndex: 51,
    bottom: 0,
    left: '50%',
    transform: ['translate(-50%, 15px)', 'translate(-50%, 20px)'],
    svg: {
      width: ['63px', '90px'],
      height: ['26px', '38px'],
    },
  } as SxStyleProp,
  foodContainer: {
  },
  food: (isChristmas: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(isChristmas ? {
      marginTop: '-20px',
      width: ['pacmanFoodSizeSmall', 'pacmanFoodSize'],
      height: ['pacmanFoodSizeSmall', 'pacmanFoodSize'],
      mr: ['pacmanFoodSpacingSmall', 'pacmanFoodSpacing'],
    } : {
      bg: 'text',
      borderRadius: 'fullSize',
      width: ['pacmanFoodSizeSmall', 'pacmanFoodSize'],
      height: ['pacmanFoodSizeSmall', 'pacmanFoodSize'],
      mr: ['pacmanFoodSpacingSmall', 'pacmanFoodSpacing'],
      overflow: 'hidden',
    }),
  }),

  image: {
    position: 'absolute',
    top: 0,
    opacity: 0,
    ...pacman,
    // transformStyle: 'preserve-3d',
    ...pacmanBaseStyle,
    bg: 'transparent',
    height: pacmanBaseStyle.width,
    transformOrigin: 'center',
  } as SxStyleProp,
};
