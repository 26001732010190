import React, { Fragment } from 'react';
import { SxProps } from '@core';
import { ChildrenProps, Content } from '@types';
import useGenerateContentBarLabels from '../../chart-generator/components/Guide/useGenerateContentBarLabels';
import { SectionListWithContentBar } from '../../chart-generator/components/Guide/ContentBar';
import { ContentMapper } from './ContentMapper';

export interface PostContentProps extends SxProps, ChildrenProps{
  content: Content[];
}

const wrap = (toWrap: ChildNode, wrapped:boolean, wrapper = document.createElement('strong')) => {
  if (toWrap && toWrap.parentNode && !wrapped) {
    toWrap.parentNode.appendChild(wrapper);
    return wrapper.appendChild(toWrap);
  }
  return 0;
};

export const PostContent = ({ content, children }: PostContentProps) => {
  React.useLayoutEffect(() => {
    const links = document.querySelectorAll('a[target="_blank"]');
    links.forEach(link => wrap(link.childNodes[0], link.childNodes[0].nodeName !== '#text'));
  }, []);

  return (
    <Fragment>
      {children}
      <ContentMapper content={content} />
    </Fragment>);
};

export const PostContentWithContentBar = ({ content }: Partial<PostContentProps>) => {
  const { links, contentWithId } = useGenerateContentBarLabels(content);

  return contentWithId ? (
    <PostContent
      content={contentWithId}
    >
      <SectionListWithContentBar links={links} />
    </PostContent>
  ): null;
};
