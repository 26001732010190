/** @jsx jsx */
import { Box, jsx, SxProps } from 'theme-ui';
import { MarkDownContent, OptionalLink } from '@core';
import { Children } from '@types';
import { styles } from './UnorderedList.styles';

export interface UnorderedListItem {
  id:string | number;
  text?:string;
  href?: string;
  icon?:Children;
}
interface UnorderedListProps extends SxProps{
  title?:string;
  onClick?: () => void;
  items: UnorderedListItem[];
}

export const UnorderedList = ({ title, items, sx, onClick, ...props }: UnorderedListProps) => (
  <Box variant="lists.primaryListType" {...props} sx={sx}>
    {title}
    {items.map((item) => (
      <li key={item.id} sx={{ px: '8px !important' }}>
        <OptionalLink to={item.href} onClick={onClick} sx={styles}>
          {item.href && item.icon}
          <MarkDownContent>{item.text}</MarkDownContent>
        </OptionalLink>
      </li>
    ))}
  </Box>
);
