import { Box, BoxProps } from 'theme-ui';
import React from 'react';
import { RGBColor } from 'react-color';
import { useColor } from '@composite';
import PercentageContext from '../context/PercentageContext';
import { fillColorStyles } from './ChartItem.styles';
import { monochromeStyles } from './Monochrome.styles';

export interface FillProps extends BoxProps {
  draggable?: boolean;
  defaultColor?: RGBColor;
}

export const Monochrome = React.forwardRef<HTMLDivElement, FillProps>(({ draggable = false, defaultColor, ...props }, ref) => {
  const percentage = React.useContext(PercentageContext);
  const [colorA] = useColor();
  const fillColor = defaultColor || colorA;
  return (
    <Box {...props} sx={{ ...monochromeStyles.container(percentage), ...fillColorStyles(fillColor), ...props.sx }}>
      {draggable && <Box ref={ref} title={`${(percentage / 10).toFixed(2)}`} sx={monochromeStyles.button(fillColor)} />}
    </Box>
  );
});
