import * as path from 'path';
import { Actions } from 'gatsby';
import {
  Country,
  Institution,
  CountryCalculation,
  GraphQlFunc,
  Node,
  QueryErrorResult,
  FS_TYPES,
} from '../../types';
import { pluralizeFsType } from '../string';
import { ALL_COUNTRIES, ALL_COUNTRIES_REGIONAL } from '../formatting';
import { filterInstitutions, filterCountries, filterCountryCalculations } from './utils';

interface SelectionQueryResult extends QueryErrorResult{
  data?: {
    allCountry: {
      edges: Node<Country>[];
    },
    allInstitution: {
      edges: Node<Institution>[];
    }
    allCountryCalculation: {
      edges: Node<CountryCalculation>[];
    }
  }
}

const getSelection = async (graphql: GraphQlFunc): Promise<SelectionQueryResult> => graphql(`
  query {
    allCountry {
      edges {
        node {
          countryCode
          name
          flag {
            url
            alternativeText
          }
          language
        }
      }
    }
    allCountryCalculation {
      edges {
        node {
          fsType
          total
          type
          country {
            countryCode
            name
            flag{
              url
              alternativeText
            }
          }
          dimensions {
            key
            value
          }
          language
        }
      }
    }
    allInstitution {
      edges {
        node {
          fsType
          name
          total
          rank
          logo{
            url
          }
          country {
            countryCode
            name
            flag {
              url
              alternativeText
            }
          }
          dimensions {
            key
            value
          }
          language
        }
      }
    }
  }
`);

export const createComparePage = async (graphql: GraphQlFunc, actions: Actions) => {
  const { createPage } = actions;

  const fetchResult = await getSelection(graphql);

  if (!fetchResult.data) throw new Error(fetchResult?.errors ? fetchResult?.errors[0] : 'No institutions, countries or country calculations found for comparison seletcion');

  const countries: Country[] = fetchResult.data.allCountry.edges.map((node) => node.node);
  const institutions: Institution[] = fetchResult.data.allInstitution.edges.map((node) => node.node);
  const countryCalculations: CountryCalculation[] = fetchResult.data.allCountryCalculation.edges.map((node) => node.node);
  const globalCountriesWithoutIcon = countries.filter(country => country.countryCode === ALL_COUNTRIES).map(c => ({ ...c, flag: undefined }));
  const filteredCountryCalculationsWithoutIconForBankInternational = filterCountryCalculations(countryCalculations, 'bank', globalCountriesWithoutIcon).map(
    cc => ({ ...cc, fsType: 'bankMiddleEast', country: { ...cc.country, countryCode: ALL_COUNTRIES, flag: undefined } } as CountryCalculation),
  );

  const component = path.resolve('./src/components/composite/finnoscore-comparison/index.tsx');

  FS_TYPES.forEach(fsType => {
    const filteredInstitutions = filterInstitutions(institutions, fsType);
    // TODO: filteredCountries shall not be retrieved & drilled here as a prop, as that is not needed for all component on the page
    const filteredCountries = filterCountries(countries, filteredInstitutions);
    const filteredCountryCalculations = filterCountryCalculations(countryCalculations, fsType, filteredCountries);
    const pluralString = pluralizeFsType(fsType);

    createPage({
      path: `/${pluralString}/compare`,
      component,
      context: {
        fsType,
        countries: fsType === 'bankMiddleEast'
          ? globalCountriesWithoutIcon
            .map(c => ({ ...c, countryCode: ALL_COUNTRIES_REGIONAL })) // convert to regional entires
            .concat(globalCountriesWithoutIcon) // and keep global ones by adding again
          : filteredCountries,
        institutions: filteredInstitutions,
        countryCalculations: fsType === 'bankMiddleEast'
          ? filteredCountryCalculations.filter(c => c.country.countryCode.match(ALL_COUNTRIES)).map(c => ({ ...c, country: { ...c.country, countryCode: ALL_COUNTRIES_REGIONAL, flag: undefined } as Country })).concat(filteredCountryCalculationsWithoutIconForBankInternational)
          : filteredCountryCalculations,
      },
    });
  });
};
