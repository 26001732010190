import React from 'react';
import { Box, Grid } from 'theme-ui';
import { styles } from './ClutchWidget.styles';

interface ClutchCo {
  Init: () => void;
  Destroy: () => void;
}

declare global {
  interface Window {
    CLUTCHCO?: ClutchCo;
  }
}

const useClutchInit = (ref: React.RefObject<HTMLDivElement> | null) => {
  let attempts = 10;
  let timeout: NodeJS.Timeout;

  const initClutch = React.useCallback(() => {
    attempts -= 1;
    if (ref?.current && window?.CLUTCHCO?.Init) {
      window.CLUTCHCO.Init();
    } else if (attempts>0) {
      timeout = setTimeout(() => initClutch(), 200);
    }
  }, [ref]);

  React.useEffect(() => {
    initClutch();
    return () => {
      clearTimeout(timeout);
      if (ref?.current && window?.CLUTCHCO?.Destroy) {
        window.CLUTCHCO.Destroy();
      }
    };
  }, [initClutch, ref]);
};

export const ClutchWidget = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  useClutchInit(ref);

  return (
    <React.Fragment>
      {/* <Helmet>
        <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js" />
      </Helmet> */}
      <Grid sx={styles} ref={ref} data-cy="cloutch-widgets">
        <Box
          className="clutch-widget"
          id="clutch-reviews"
          data-nofollow="true"
          data-url="https://widget.clutch.co"
          data-widget-type="2"
          data-height="44"
          data-clutchcompany-id="1928019"
          data-primary-color="#01bd59"
        />
        <Box
          className="clutch-widget"
          data-nofollow="true"
          data-url="https://widget.clutch.co"
          data-widget-type="7"
          data-height="65"
          data-clutchcompany-id="1928019"
        />
      </Grid>
    </React.Fragment>
  );
};

export const ClutchReviews = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  useClutchInit(ref);
  return (
    <React.Fragment>
      <Box ref={ref} data-cy="cloutch-widgets">
        <Box
          className="clutch-widget"
          id="clutch-reviews"
          data-nofollow="true"
          data-url="https://widget.clutch.co"
          data-widget-type="2"
          data-height="44"
          data-clutchcompany-id="1928019"
          data-primary-color="#01bd59"
        />
      </Box>
    </React.Fragment>
  );
};
