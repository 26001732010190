import { Box, Flex } from 'theme-ui';
import React from 'react';
import { Dimensions } from '@types';

import { ChartItem } from '../BasicComponents/ChartItem';
import { simpleChartStyles } from './SimpleChartComponent.styles';

export interface DimensionProp {
  dimension: Dimensions;
}

export const SimpleChartComponent = React.forwardRef<HTMLDivElement, DimensionProp>(({ dimension }, ref) => (
  <Box sx={simpleChartStyles} ref={ref}>
    {dimension.map((d, i:number) => <Flex key={`${i}-${d.value}`} className="chart" sx={simpleChartStyles.chartBox}>
      <ChartItem percentage={+d.value * 10} sx={simpleChartStyles.chartItem} />
    </Flex>)}
  </Box>
));
