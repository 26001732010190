import { ImageLoader } from '@core';
import { TabbedImage as TabbedImageType } from '@types';
import { Box, SxProps } from 'theme-ui';
import { TabbedCarousel } from '../../TabbedCarousel';
import { postContentStyles } from './index.styles';

interface TabbedImageProps extends SxProps {
  images: TabbedImageType[]
}

export const TabbedImage = ({ images, sx, ...props }: TabbedImageProps) => (
  <Box {...props} sx={{ ...postContentStyles.imageContainer, ...sx }}>
    <TabbedCarousel
      titles={images.map(img => img.title)}
      defaultSelectedIndex={images.indexOf(images.find(img => img.selected) || images[0])}
    >
      {images.map(img => <ImageLoader
        key={img.title}
        src={img.image.url}
        alt={img.image.alternativeText}
      />)}
    </TabbedCarousel>
  </Box>
);
