import React from 'react';
import { Box } from '@themed';
import { CompactSection, ParallaxImage } from '@core';
import { useTranslate } from '@modules';
import { styles } from './Init.styles';

export const Init = () => {
  const __ = useTranslate();
  const sectionRef = React.useRef(null);
  const backgroundRef = React.useRef<HTMLDivElement>(null);
  // {/* TODO: BlogHeader animation? */}

  return (
    <CompactSection titleAs="h1" ref={sectionRef} id="rapid.init" header={() => <Box sx={styles.background} ref={backgroundRef} />} sx={styles.init}>
      <ParallaxImage src="team_04.jpg" alt={__('pages.rapid.init.parallaxImgAlt')} sx={styles.image} />
    </CompactSection>

  );
};
