/** @jsx jsx */
import React from 'react';
import { jsx, Styled, Box, BoxProps } from 'theme-ui';
import { StyledComponent } from '@emotion/styled';
import { ChildrenProps, Image, OneComponentDefinitionType } from '../../../types';
import { Paragraph } from '../themed/ThemedTexts';
import { SvgIcon } from './SvgIcon';
import { ImageLoader } from './ImageLoader';
import { ContactButtonList, ContactButtonListProps } from './ContactButton';
import { styles } from './List.styles';

export interface GenericListItemProps extends ChildrenProps {
  title?: string;
  text: string;
  icon?: Image;
  iconStr?: Image;
  link?: string;
}
export interface ListItemControl {
  index: number;
  itemCount: number;
}
export interface ControlledListItemProps extends GenericListItemProps, ListItemControl {
  id?: string;
  type?: string;
}

export interface ContactListItemProps extends GenericListItemProps, ContactButtonListProps {
  image?: Image;
}

export interface GenericItemProps extends GenericListItemProps, ContactListItemProps {
  id?: string;
}

export interface SelectorProps {
  onSelect?: (id: string) => void;
  selected?: boolean;
  id?: string;
  className?: string;
}

export type ListItem = GenericListItemProps & { id: string };
export type ListItemProps = Partial<ControlledListItemProps> & SelectorProps

export const DefaultListItem = ({
  text, icon, image, children, iconStr, sx, ...props
}: GenericItemProps) => (
  <Box className="listItem" sx={sx} data-cy="default-list-item">
    {image
      && <Box
        className="listItemImage"
        sx={styles.imageBox}
        data-cy="list-item-image-box"
      >
        <Box
          className="listItemImageOverlay"
          sx={styles.imageOverlay}
        />
        <ImageLoader src={image.url} alt={image.alternativeText} />
      </Box>}
    {iconStr && <Box as="figure" sx={styles.svgIcon}>{iconStr.url}</Box>}
    {icon && <SvgIcon data-cy="list-item-svg" as="figure" name={icon.url} alt={icon.alternativeText} sx={styles.svgIcon} />}
    <Paragraph data-cy="list-item-paragraph" className="listItemText" variant="styles.p" as="div">{text}</Paragraph>
    <ContactButtonList data-cy="list-item-button" className="listItemButtonList" {...props} />
    {children}
  </Box>
);

export type ListComponentType = OneComponentDefinitionType | StyledComponent<React.ComponentProps<'div'>, ChildrenProps, any > | React.ForwardRefExoticComponent<any>;

interface ListProps extends BoxProps{
  items: (GenericItemProps)[];
  // Eslint disabled until proper solution
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ListComponentType;
  itemComponent?: (p: ControlledListItemProps) => JSX.Element;
}

export const List = React.forwardRef<HTMLDivElement, ListProps>(({ items, component: Component = Styled.ul, itemComponent: Item = DefaultListItem }, ref) => (
  <Component ref={ref} className="listSection" data-cy="list-section">
    {
      items && typeof items === 'object' && items?.map((item, idx) => (
        <Item {...item} key={JSON.stringify(item)} index={idx} itemCount={items.length} />
      ))
    }
  </Component>
));
