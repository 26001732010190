import { Slider } from '@material-ui/core';
import React from 'react';
import { Flex, SxProps } from 'theme-ui';
import { Institution } from '@types';
import { Paragraph } from '@themed';
import { useColors } from '@composite';
import { useInvertedThemeColorValues } from '../../../colorpicker';
import { internationalSliderStyle, labelStyle } from './InternationalChartItem.styles';

interface InternationalChartComponentProps extends SxProps {
  current: boolean;
  institution: Institution;
  valueChangeHandler: (newValue: number | number[], index: number) => void;
  index: number;
}

export const InternationalChartItem = React.forwardRef<HTMLDivElement, InternationalChartComponentProps>(({
  institution, current, index: i, valueChangeHandler,
}, _ref) => {
  const [[compareColor, currentColor]] = useColors();
  const [compareTextColor, currentTextColor] = useInvertedThemeColorValues();
  const totalLabel = institution.total.toFixed(2).toString();
  return (
    <Flex sx={internationalSliderStyle(current ? currentColor : compareColor)}>
      <Slider
        value={institution.total*10}
        onChange={(_, newValue) => valueChangeHandler(+newValue/10, i)}
        step={1}
        min={0}
        max={100}
      />
      <Paragraph sx={{ ...labelStyle, color: current ? currentTextColor : compareTextColor }}>{totalLabel}</Paragraph>
    </Flex>
  );
});
