/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Flex, SvgIcon, CompactSectionContentStyles, CompactSectionContent } from '@core';
import { styles } from './WorkshopOffer.styles';

interface FinnoscoreWorkshopOffersProps {
  title:string,
  id:number,
  icon:string,
}

const WorkshopOffer = React.forwardRef<HTMLDivElement, FinnoscoreWorkshopOffersProps>(({
  title, id, icon,
}, ref) => (
  <Flex sx={styles.offerContainer} ref={ref} className="listItem2" data-cy="workshop-types-offer">
    <SvgIcon name={icon} color="text" sx={id === 0 ? styles.rocket : styles.eye} />
    <CompactSectionContentStyles data-cy="compact-section-styles" key={`workshops.finnoworkshops.${title}`} sx={{ ...styles.content }}>
      <CompactSectionContent id={`workshops.finnoworkshops.${title}`} buttonVariant="ctaInvert" />
    </CompactSectionContentStyles>
  </Flex>
));

export default WorkshopOffer;
