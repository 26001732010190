import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { themeConfig } from '@themed';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

interface UploadButtonProps {
  children: React.ReactNode;
}
export const UploadButton = ({ children }: UploadButtonProps) => {
  const classes = useStyles();
  const { register, watch } = useFormContext();
  const { ref, ...field } = register('file');
  const file = watch('file');

  return (
    <div className={classes.root}>
      <input
        accept=".xls,.xlsx,.xlsm"
        className={classes.input}
        id="upload"
        multiple
        type="file"
        {...field}
        ref={ref}
      />
      <label htmlFor="upload">
        <Button
          variant="contained"
          style={{
            border: `1px solid ${themeConfig.colors.strapiBlue}`,
            backgroundColor: themeConfig.colors.white50Percent,
          }}
          component="span"
        >
          {children}
        </Button>
      </label>
      {file && <p>{file?.[0]?.name}</p>}
    </div>
  );
};
