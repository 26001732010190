import { SxStyleProp } from 'theme-ui';
import { __ } from '@modules';
import { SxStyles } from '../../../types/Sx';

export const itemStyles = {
  rank: {
    variant: 'styles.fsText',
    color: '#778399',
    width: 'fullSize',
    gridArea: 'rank',
    textAlign: 'right',
  },
  name: {
    variant: 'styles.fsText',
    fontWeight: 'bold',
    gridArea: 'name',
  },
  country: {
    variant: 'styles.fsText',
    fontSize: '0.875rem',
    gridArea: 'country',
  },
  logo: {
    width: 6,
    mx: 'auto',
    textAlign: 'center',
    mt: [1, 1, 0],
    gridArea: 'logo',
    fontSize: '1px', // used for alt text of missing logos
  } as SxStyleProp,
  trend: {
    mt: 1,
    gridArea: 'trend',
  },
  score: {
    gridArea: 'score',
    pr: [0, 0, 3],
  },
};

export const useInstitutionItemLayoutStyles = (): SxStyles => ({
  row: {
    display: 'flex',
    width: 'auto',
    minHeight: ['60px', '60px', '50px'],
    height: 'max-content',
    mx: [4, 4, 0],
    position: 'relative',

    borderBottom: 'thin',
    borderBottomColor: 'mutedUi',
  },

  link: {
    width: 'fullSize',
    display: 'grid',
    columnGap: ['6px', '6px', 3],
    rowGap: 1,
    gridTemplateColumns: [
      '32px 22px 1fr 22px 40px',
      '32px 22px 1fr 22px 40px',
      '50px 62px 4fr 3fr 30px 50px',
    ],
    gridTemplateRows: [
      'min-content 1fr',
      'auto',
    ],
    gridTemplateAreas: [
      '"logo rank name trend score" "logo rank country trend score"',
      '"logo rank name trend score" "logo rank country trend score"',
      '"rank logo name country trend score"',
    ],
    pt: [3, 3, 0],
    alignItems: ['flex-start', 'flex-start', 'center'],
    justifyItems: 'flex-start',

    '&:hover': {
      bg: theme => theme.colors.primaryLighter[5],
    },

    '&:before': {
      content: `"${__('common.compare')}"`,
      variant: 'buttons.cta',
      p: 0,
      position: 'absolute',
      left: '50%',
      display: 'flex',
      minHeight: '40px',
      justifyContent: 'center',
      margin: 'auto !important',
      transition: 'all 0.3s ease',
      transform: 'translateX(-50%) scale(0.9) !important',
      opacity: 0,
    },
    '&:hover:before': {
      opacity: 1,
      transform: 'translateX(-50%) scale(1) !important',
    } as SxStyleProp,

  },
});
