import React from 'react';
import { Box, Flex, List, SectionWithContent, SectionWithContentProps, SubTitle, ThemedIcon, Title } from '@core';
import { useAllIntlTree, useTranslate } from '@modules';
import { styles } from './OurPhilosophy.styles';

interface PhilosophyItemProps {
  text: string;
  index: number;
  itemCount: number;
}

const PhilosophyItem = ({ text, index, itemCount }: PhilosophyItemProps) => {
  const __ = useTranslate();

  let operator = '+';
  if (index === itemCount - 1) {
    operator = '';
  } else if (index === itemCount - 2) {
    operator = '=';
  }

  return <Flex className="listItem" sx={styles.philosophyListItemContainer}>
    <ThemedIcon className="philosophyItem" sx={styles.philosophyItem}>
      <SubTitle className="philosophySubTitle" sx={styles.philosophyTitle}>{__(text)}</SubTitle>
    </ThemedIcon>
    {operator && <Box className="philosophyOperator" as="code" sx={styles.operation}>
      {operator}
    </Box>}
  </Flex>;
};

export const OurPhilosophy = React.forwardRef<HTMLDivElement, SectionWithContentProps>((props: SectionWithContentProps, ref) => {
  const messages = useAllIntlTree();
  const __ = useTranslate();
  return (<SectionWithContent {...props} sx={styles.section} ref={ref} data-cy="our-philosophy">
    <Box sx={styles.sectionContent} data-cy="our-philoshopy-container">
      <Title className="philosophiesTitle" sx={styles.title} data-cy="our-philoshopy-title">{__('pages.aboutus.philosophy.title')}</Title>
      <SubTitle className="philosophiesSubTitle" sx={styles.subtitle} data-cy="our-philoshopy-container">{__('pages.aboutus.philosophy.paragraph')}</SubTitle>
      <List component={Flex} items={messages.pages.aboutus.philosophy.list} itemComponent={PhilosophyItem} />
    </Box>
  </SectionWithContent>);
});
