import { useCanonicalUrl, useLocale } from '@modules';
import { SiteMeta } from '@types';

export const formatPageMetaOgImage = (primaryImage?: string) => (primaryImage? [{
  property: 'og:image',
  content: primaryImage,
}, {
  name: 'twitter:image',
  content: primaryImage,
}]: []);

export const usePageMetaAttributes = ({
  title,
  description,
  author,
  version,
  viewport,
  type,
  primaryImage,
}: SiteMeta) => {
  const lang = useLocale();
  const canonicalUrl = useCanonicalUrl();

  return {
    htmlAttributes: { lang },
    title,
    link: [{ rel: 'canonical', href: canonicalUrl }],
    meta: [
      {
        name: 'theme-color',
        content: '#ffffff',
      },
      {
        name: 'description',
        property: 'og:description',
        content: description,
      },
      {
        name: 'viewport',
        content: viewport,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:type',
        content: type,
      },
      {
        property: 'og:locale',
        content: lang,
      },
      {
        property: 'version',
        content: version,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:creator',
        content: author,
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: description,
      },
      ...formatPageMetaOgImage(primaryImage),
    ],
  };
};
