import React from 'react';
import { Box } from '@core';
import { DesktopHeader, DesktopHeaderProps } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

export const Header = ({ invertedHeader }: DesktopHeaderProps) => (
  <Box as="header">
    <DesktopHeader invertedHeader={invertedHeader} />
    <MobileHeader />
  </Box>
);
