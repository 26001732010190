import { Box, ImageLoader, SxProps } from '@core';
import { postContentStyles } from './index.styles';

interface PostImageProps extends SxProps{
  url: string;
  alt?: string;
}

export const PostImage = (props: PostImageProps) => (
  <Box {...props} sx={{ ...postContentStyles.imageContainer, ...props.sx }}>
    <ImageLoader src={props.url} alt={props.alt} sx={postContentStyles.image} />
  </Box>
);
