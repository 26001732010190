/** @jsx jsx */
import React, { MouseEvent } from 'react';
import { jsx, SxProps } from 'theme-ui';
import { openPopupWidget } from 'react-calendly';
// DIRTY FIX: typing is in the package but not exported correctly
// eslint-disable-next-line import/no-unresolved
import { PopupWidgetOptions } from 'react-calendly/typings/components/PopupButton/PopupButton';
import { Global } from '@emotion/core';
import { IoCalendar } from 'react-icons/io5';
import { ContactButton } from './ContactButton';

interface CalendlyButtonProps extends Omit<PopupWidgetOptions, 'url'>, SxProps {
  href?: string;
  title?: string;
}
export const CalendlyButton = ({
  href, title, sx, ...props
}: CalendlyButtonProps) => {
  if (!href) return null;

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openPopupWidget({ url: href, ...props });
  };

  return (
    <React.Fragment>
      <Global styles={{
        '.calendly-overlay': {
          backdropFilter: 'blur(3px)',
        },
      }}
      />
      <ContactButton
        {...props}
        href={href}
        title={title || 'common.scheduleMeeting'}
        target="_calendly"
        sx={{ variant: 'buttons.cta', ...sx }}
        icon={() => <IoCalendar color="black" />}
        onClick={onClick}
      />
    </React.Fragment>

  );
};
