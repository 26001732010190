import React from 'react';
import { Content, hasContentImage, hasContentImageList, hasContentQuote, hasContentTabbedImageList, hasContentText, hasContentVideo } from '@types';
import { ImageCarousel } from './ImageCarousel';
import { PostImage } from './Image';
import { PostParagraph } from './Paragraph';
import { PostVideo } from './Video';
import { Quote } from './Quote';
import { TabbedImage } from './TabbedImage';
import { PostContentProps } from '.';

export const ContentMapper = ({ content }: PostContentProps) => (
  <React.Fragment>
    {content.map((part: Content, index: number) => {
      const key = `part_${index}`;

      if (hasContentText(part)) return <PostParagraph key={key}>{part.text}</PostParagraph>;

      if (hasContentImage(part)) return <PostImage key={key} url={part.image.url} alt={part.image.alternativeText} />;

      if (hasContentImageList(part)) return <ImageCarousel key={key} images={part.images} />;

      if (hasContentQuote(part)) return <Quote key={key} quote={part.quote} />;

      if (hasContentTabbedImageList(part)) return <TabbedImage key={key} images={part.tabbedImages} />;

      if (hasContentVideo(part)) return <PostVideo key={key} video={part.video} />;

      return null;
    })}
  </React.Fragment>
);
