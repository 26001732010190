import React, { ElementType } from 'react';
import { Text, BoxProps, Heading, HeadingProps, SxStyleProp } from 'theme-ui';
import { Children } from '@types';
import { themeConfig } from '@themed';
import { MarkDownContent } from '../editable/EditableMarkDownContent';
import { preMarkdown, prepLineBreak } from './preMarkDown';

export { MarkDownContent } from '../editable/EditableMarkDownContent';

export { Text, Heading } from 'theme-ui';

// if we got any markup already in the content, assume already having correct line breaking
// ideally <wbr> or <br> should be used in that case in the original title
export const nobreakStyles = (content: Children): SxStyleProp => (
  typeof content === 'string' && `${content}`.match(/<[^\]]+>/)
    ? {
      whiteSpace: 'nowrap',
      [`@media screen and (max-width: ${themeConfig.sizes.desktopMaxWidth})`]: {
        whiteSpace: 'normal',
      },
    }
    : {}
);

export const SmallText = React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
  <Text variant="small" as="span" {...props} ref={ref}>
    <MarkDownContent prep={preMarkdown}>{children}</MarkDownContent>
  </Text>
));

interface HeadingWithContentProps extends HeadingProps {
  displayAs?: ElementType<any>
}

// uses variant=text.heading by default
export const Title = React.forwardRef<HTMLHeadingElement, HeadingWithContentProps>(({
  children, sx, displayAs, ...props
}, ref) => (
  <Heading as={displayAs || 'h2'} {...props} sx={{ ...nobreakStyles(children), ...sx }} ref={ref}>
    <MarkDownContent prep={prepLineBreak}>{children}</MarkDownContent>
  </Heading>
));

// uses variant=text.heading by default
export const SubTitle = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ children, sx, ...props }, ref) => (
  // TODO: evalulate if we can move variant as prop instead of sx
  <Heading as="h3" sx={{ variant: 'styles.h2', ...nobreakStyles(children), ...sx }} {...props} ref={ref}>
    <MarkDownContent prep={prepLineBreak}>{children}</MarkDownContent>
  </Heading>
));

// NOTE: we should try to render as `div` by default, as due to preMarkdown we might have some elements (ie u, ol, hr) inside
export const Paragraph = React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
  <Text variant="styles.p" as="div" {...props} sx={{ ...props.sx }} ref={ref}>
    <MarkDownContent prep={preMarkdown}>{children}</MarkDownContent>
  </Text>
));
