/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ThemedIcon } from '../themed/ThemedIcon';
import { Children } from '../../../types';
import { SvgIcon, TypedIconProps } from './SvgIcon';
import { styles } from './ImageWithIcon.styles';

export interface ImageWithCaptionProps extends TypedIconProps {
  name: string;
  title?: string;
  type?: string;
  children?: Children;
}

export const ImageWithCaption = ({
  children, name, type, color, ...props
}: ImageWithCaptionProps) => (
  <ThemedIcon as="figure" {...props} sx={{ ...styles.figure, ...props.sx }}>
    <SvgIcon name={name} type={type} color={color} sx={styles.imageContainer} />
    {children}
    <figcaption>{props.title || name}</figcaption>
  </ThemedIcon>
);
