import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { TriggerElementProps } from '@types';
import { __el, __tr, __selectAll } from '@modules';
import { withAnimation } from '@core';
import { themedProp, getBreakpoint } from '@themed';

interface AnimateListsProps extends TriggerElementProps {
  title: () => React.RefObject<HTMLDivElement>;
  list: () => React.RefObject<HTMLDivElement>;
}

export const animateLists = ({
  triggerRef, title, list,
}: AnimateListsProps) => {
  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];
  const trigger = __tr(triggerRef);
  const figure = __selectAll('figure', list);
  const listEl = __selectAll('.listItemText', list);
  const listEl2 = __selectAll('.listItem2', list);

  const initScrollersAll = () => {
    if (!trigger) return;
    const revealItemsWithStagger = gsap.timeline()
      .from(figure, {
        scale: 0,
        boxShadow: themedProp('images.circleIcon.boxShadow').replace(/^\d+px \d+px/, '0px 0px'),
        ease: 'back',
        stagger: 0.5,
      })
      .from(listEl, {
        opacity: 0,
        stagger: 0.5,
      }, 0);

    if (listEl2 && listEl2.length) {
      revealItemsWithStagger.from(listEl2, {
        opacity: 0,
        stagger: 0.5,
      });
    }

    scrollers.push(ScrollTrigger.create({
      trigger: __tr(triggerRef),
      start: 'top center',
      toggleActions: 'play complete none none',
      animation: revealItemsWithStagger,
    }));
  };

  const initScrollersDesktop = () => {
    if (!trigger) return;
    // need to hide points the to be able to reveal later
    scrollers.push(ScrollTrigger.create({
      trigger: __tr(triggerRef),
      start: () => `top-=${window?.innerHeight} top`, // start 1 screen earlier
      end: 'top top',
      toggleActions: 'restart complete reverse reset',
      // markers: process.env.NODE_ENV === 'development',
      animation: gsap.timeline()
        .to(__el(title), { duration: 1 })
        .from(__el(title), { color: themedProp('colors.background'), duration: 1 }),
    }));
  };

  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(0)})`]: initScrollersDesktop,
    all: initScrollersAll,
  });
};

export const useAnimationLists = (props: AnimateListsProps) => withAnimation<AnimateListsProps>(animateLists, props);
