import domtoimage from 'retina-dom-to-image';
import { useTranslate } from './i18n';

export const downloadAsPng = async (node: Element | null, filename = 'chart') => {
  try {
    if (!node) return;
    const dataUrl = await domtoimage.toPng(node, { quality: 1 });
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = `${filename}.png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('An error happened while trying to export chart as image: ', error);
  }
};

const canWriteToClipboard = async () => {
  try {
    const permission = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
    if (!permission || permission.state !== 'granted') {
      return false;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const saveToClipboard = async (node: Element | null) => {
  if (!node) return;
  const __ = useTranslate();
  try {
    const canWrite = await canWriteToClipboard();
    if (!canWrite) {
      // eslint-disable-next-line no-alert
      alert(__('No permission for copying to clipoard'));
      return;
    }

    const pngImageBlob = await domtoimage.toBlob(node, { quality: 1 });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigator.clipboard.write([
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new ClipboardItem({
        'image/png': pngImageBlob,
      }),
    ]);
  } catch (error) {
    console.error('An error happened while trying to add chart to clipboard: ', error);
  }
};

export const copyTextToClipboard = (node: Element | null) => {
  if (!node) return;
  const texts = node.getElementsByTagName('p');

  const textArea = document.createElement('textarea');
  document.body.appendChild(textArea);

  Array.from(texts).forEach(text => {
    textArea.append(`${text.innerText}\n`);
  });

  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};
