import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    bg: 'black50Percent',
    position: 'fixed',
    zIndex: 'popup',
    left: 0,
    top: 0,
    width: 'browserWidth',
    height: 'browserHeight',
    variant: 'flex.center',
  } as SxStyleProp,
  popup: {
    width: 'fullSize',
    maxWidth: '675px',
    bg: 'background',
    minHeight: 10,
    gap: 0,
    pl: [4, 5],
    pr: [5, 8],
    py: 6,
    m: 4,
  },
  button: {
    variant: 'flex.center',
    width: 'max-content',
    minWidth: '250px',
    m: 'auto',
    my: 3,
  },
};
