import * as React from "react";

const SvgN26 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={94}
    height={50}
    viewBox="0 0 94 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="n26_svg__Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="n26_svg__n26" fill="#000" fillRule="nonzero">
        <path
          d="M4.04 15.4l17.94 26.49h3.29V7.37h-3.99v26.25L3.91 8.02l-.44-.65H1.59l-.03-.04v.04H.05v34.52h3.99V15.4zm47.51 12.68C57.62 24.4 60 21.24 60 16.79c0-6.11-4.13-10.06-10.5-10.06A12.14 12.14 0 0038.38 14l-.6 1.34L41.39 17l.61-1.29a8.15 8.15 0 017.48-5c4 0 6.45 2.28 6.45 6.11 0 2.46-1.07 4.64-6.37 7.9-9.46 5.8-11.55 8.39-11.8 14.6v2.6h22.13v-4h-18c.36-3.18 1.76-5.03 9.66-9.84zm30.95-8.64a14.06 14.06 0 00-8.22 3c.62-7.44 3.9-11.8 9-11.8a8.4 8.4 0 016.07 2.41l1.14 1L93.08 11l-1.14-1a12.61 12.61 0 00-8.67-3.29c-8.07 0-13.08 7.07-13.08 18.45 0 12.79 6.34 17.35 12.27 17.35C90 42.53 94 36.55 94 30.64c0-8.27-6.19-11.2-11.5-11.2zm7.5 11.2c0 3.95-2.58 7.94-7.51 7.94-3.35 0-7.47-2.85-8.2-10.85 1.45-1.74 4.58-4.35 8.24-4.35 6.71.01 7.47 5.08 7.47 7.26zM0 46.39h25.32v2.97H0v-2.97zM.09 0h25.23v2.97H.09V0z"
          id="n26_svg__Shape"
        />
      </g>
    </g>
  </svg>
);

export default SvgN26;
