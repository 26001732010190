export const styles = {
  contact: {
    minHeight: '0 !important',
    '&>*': {
      minHeight: '0 !important',
    },
  },
  image: {
    borderRadius: 'fullSize',
    variant: 'flex.center',
    width: 'bigMobileIcon',
    height: 'bigMobileIcon',
  },
};
