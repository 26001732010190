import { RGBColor } from 'react-color';
import { isColorLighter } from 'src/components/composite/colorpicker';

export const containerStyles = {
  backgroundColor: 'transparent',
  borderRadius: '100%',
  width: '100%',
  height: 'auto',
  minWidth: '26rem',
  maxWidth: '55rem',
  maxHeight: '47rem',
  px: '4%',
  pb: '1%',
  pt: '2%',
  '@media (min-width: 1500px)': {
    pt: '6%',
  },
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr max-content',
  gridTemplateAreas: '"institutionDropdown" "chart"',
  '&>#chart': {
    gridArea: 'chart',
  },
  '&>#institutionDropdown': {
    gridArea: 'institutionDropdown',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '18px',
  },
};

export const bubbleInstituteStyles = {
  bubble: {
    gridArea: 'bubble',
    justifySelf: ['center', 'stretch'],
    alignSelf: ['center', 'stretch'],
    width: ['5.5rem', '7.5rem'],
    height: ['5.5rem', '7.5rem'],
  },
  bubbleColorStyles: (color: RGBColor) => ({
    '.logo': {
      '#finnoscore_svg__Fi, #finnoscore_svg__oScore': {
        fill: isColorLighter(color) ? 'text' : 'background',
      },
      '#finnoscore_svg__Fill-8': {
        fill: isColorLighter(color) ? 'background' : 'primary',
      },
      '& + div': { // next text
        color: isColorLighter(color) ? 'text' : 'background',
      },
    },
  }),
  chart: {
    gridArea: 'chart',
    justifySelf: 'center',
    alignSelf: 'center',
    height: 3,
    width: 6,
  },
  logo: {
    gridArea: 'logo',
    alignSelf: 'end',
    img: {
      width: ['20%', '30%'],
      height: 'auto',
      maxWidth: '60px',
      minWidth: '20px',
    },
  },
  year: {
    gridArea: 'year',
    fontSize: ['0.8rem', '1.2rem'],
  },
  subtitle: {
    gridArea: 'subtitle',
    fontSize: ['0.7rem', '1.2rem'],
    padding: 0,
  },
};
