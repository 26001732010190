import React from 'react';
import { Text } from 'theme-ui';

interface ChildrenProps{
  children: React.ReactNode;
}

export const ErrorMessage = ({ children }:ChildrenProps) => (
  <Text sx={{ color: 'warning' }}>{children}</Text>
);
