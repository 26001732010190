import { SxStyleProp } from 'theme-ui';

export const headerStyles = {
  box: {
    display: ['none', 'none', 'grid'],
    gridTemplateColumns: '1fr 42px 42px 1fr',
    gap: '21px',
    alignItems: 'center',
    width: '100%',
    height: 7,

    bg: 'background',
    borderBottom: 'solid 1px',
    borderBottomColor: 'mutedUi',

    '*:first-of-type': {
      pl: '21px',
    },
    '*:nth-of-type(2)': {
      pl: '10px',
    },
  },
  text: {
    variant: 'styles.fsText',
    color: 'secondary2',
    width: '50%',
    textAlign: 'left',

    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
};

export const rowStyles = {
  row: {
    gridTemplateColumns: ['1fr', '1fr', '1fr 42px 42px 1fr'],
    gridTemplateAreas: ['"dimension" "chart"', '"dimension" "chart"', '"dimension scoreA scoreB chart"'],
    alignContent: 'center',
    placeItems: 'center',
    justifyContent: 'center',
    gap: [0, 0, '21px'],
    px: '21px',
    width: '100%',
    height: [7, 7, 6],
  },
  dimensionName: {
    gridArea: 'dimension',
    textAlign: ['left', 'left', 'right'],
    width: 'fullSize',
  },
  score: {
    fontWeight: 'bold',
    textAlign: 'right',
    display: ['none', 'none', 'block'],
  } as SxStyleProp,
  scoreA: {
    gridArea: 'scoreA',
  },
  scoreB: {
    gridArea: 'scoreB',
    color: 'primary',
  },
  chartItem: {
    gridArea: 'chart',
    height: ['10px', '10px', '14px'],
  },
};
export const styles = {
  dataBox: {
    pt: 3,
    pb: 4,
    bg: 'background',
  },
};
