import { atom, RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { UpdatedInstitution } from '@types';

export type GlobalUpdatedInstitutionStackType = UpdatedInstitution[] | [];

export const globalUpdatedInstitutionStack: RecoilState<GlobalUpdatedInstitutionStackType> = atom({
  key: 'institutionState',
  default: [] as UpdatedInstitution[] | [],
  effects: [recoilPersist({ key: 'institutionState' }).persistAtom],
});

export const useGlobalUpdatedInstitutionStack = () => useRecoilState(globalUpdatedInstitutionStack);

export const useGlobalUpdatedInstitutionStackValue = () => useRecoilValue<GlobalUpdatedInstitutionStackType>(globalUpdatedInstitutionStack);
