import React, { useState } from 'react';
import { __, countryCalculationToSubject, getComparisonSelectionDynamic, useLocale, useFormatCountryCalculationName } from '@modules';
import { ComparisonSubject, ComparisonSubjectType, Country, CountryCalculation, FsType, FsTypeBase } from '@types';
import { formatFsTypeForGQL } from '../../helpers';

const findCountryByCode = (countryList: CountryCalculation[], selectedCountryCode?: string) => (selectedCountryCode && countryList.find(calc => calc.country.countryCode === selectedCountryCode)) || countryList[0];
const findAverageCountryCode = (countryList: CountryCalculation[]) => countryList.find(calc => calc.country.countryCode === 'ALLE') || countryList[0];

function useCountryCalculationDropDown(fsType?: FsTypeBase<FsType>, period?: string | null, selectedCountryCode?: string, comparisonType?: number) {
  const locale = useLocale();
  const [countries, setCountries] = useState<Country[]>([]);
  const [countryCalculations, setCountryCalculations] = useState<CountryCalculation[]>([]);
  const [selectedCountry, setSelectedCountry] = React.useState<ComparisonSubject | null>(null);

  const formatName = useFormatCountryCalculationName();

  const selectValidCountry = React.useCallback((country?: CountryCalculation) => {
    if (country) {
      const countryCalculation = countryCalculationToSubject(formatName, country);
      setSelectedCountry(countryCalculation);
    }
  }, [fsType]);

  React.useEffect(() => {
    switch (fsType?.text) {
      case 'bankMiddleEast':
      case 'privatebank': {
        const countryCode = findAverageCountryCode(countryCalculations);
        selectValidCountry(countryCode);
        break;
      }
      default: {
        const countryCode = findCountryByCode(countryCalculations, selectedCountryCode);
        selectValidCountry(countryCode);
      }
    }
  }, [selectedCountryCode, comparisonType]);

  const fetchCountryCalculations= async () => {
    if (fsType && period) {
      setCountries([]);
      setCountryCalculations([]);
      setSelectedCountry(null);
      const type = formatFsTypeForGQL<FsType>(fsType.text);
      const { countries: countriesFromGraphQL, countryCalculations: countryCalculationsFromGraphQL } = await getComparisonSelectionDynamic({ fsType: type, locale, date: period });
      setCountries(countriesFromGraphQL);
      setCountryCalculations(countryCalculationsFromGraphQL);
      selectValidCountry(findCountryByCode(countryCalculationsFromGraphQL, selectedCountryCode));
    }
  };

  React.useEffect(() => {
    fetchCountryCalculations();
  }, [fsType, period]);

  const selectedCountryHandler = (countryCalculationId:string) => {
    const selected = countryCalculations.find(cc => `${ComparisonSubjectType.CountryCalculation}.${cc.country.countryCode}.${cc.type}` === countryCalculationId);
    selectValidCountry(selected);
  };

  return {
    countries,
    countryCalculations,
    selectedCountry,
    selectedCountryHandler,
  };
}

export default useCountryCalculationDropDown;
