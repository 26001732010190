export const testimonialStyles = {
  list: {
    variant: 'flex.colAlternate3',
    width: ['100%', '100%', '80%', '80%', '100%'],
    overflow: 'visible',
  },
  item: {
    mt: [0, 0, -8],
    variant: 'flex.colLeft',
    flex: '0 0 min-content',
    maxWidth: 'min-content',
    '.listItemImage': {
      mt: [0, -4],
      mb: [4, 5],
      width: 9,
      height: 9,
      order: 2,
    },
    '.listItemText': {
      variant: 'flex.center',
      textAlign: 'center',
      width: ['90vw', '90vw', '67vh', '55vh'],
      height: ['90vw', '90vw', '67vh', '55vh'],
      maxWidth: 11,
      maxHeight: 11,
      fontSize: ['0.875rem', 'xs', 'xs', 'xs'],
      p: [6, 7, 7, 8],
      mb: -8,
      borderRadius: 'fullSize',
      bg: 'background',
      order: 1,
    },
    h2: {
      fontSize: 'xs',
      order: 3,
    },
    h3: {
      fontSize: 'xs',
      fontWeight: 'normal',
      order: 4,
    },
  },
};
