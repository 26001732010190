import { Grid, SxStyleProp } from 'theme-ui';
import React from 'react';
import { ChildrenProps } from '@types';

const styles = {
  grid: {
    width: '65%',
    display: 'grid',
    gridTemplateColumns: '120px 32px 1fr',
    gridTemplateRows: ['25px min-content 1fr', '64px min-content 1fr'],
    gridTemplateAreas:
      '"bubble . logo " "bubble chart subtitle" "bubble . ."',
    gap: '12px',
  },
};

interface BubbleInstitutionProps extends ChildrenProps {
  sx?: SxStyleProp,
}

export const BubbleInstituteProfile = ({
  children, sx, ...props
}:BubbleInstitutionProps) => (
  <Grid {...props} sx={{ ...styles.grid, ...sx }}>
    {children}
  </Grid>
);
