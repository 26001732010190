export const styles = {
  section: (index: number) => ({
    alignItems: 'flex-start',
    p: {
      pb: 4,
    },
    '&:first-child': {
      mt: 'headerHeight',
    },
    ...(index % 2 ? {}: {
      bg: 'primary',
      'h1, h2': {
        color: 'white !important',
      },
      h3: {
        color: 'inherit !important',
      },
      '&.static-section': {
        'a, a dd': {
          color: 'background',
          outline: 'none', // NOTE: WORKAROUND: we need at least 1 more prop besides color, to make the color reflected in the section :(
        },
        'a[target=_blank]:before': {
          content: '"↗ "',
          color: 'transparent',
          backgroundPosition: 'left 70%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 60%',
          backgroundImage: 'url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAABA0lEQVR4AdXTgaYCQRTG8XvvJYKlIHqEIARBABYF6AEiCWAfISxCvUFYVPQGG4VFQABhIewbpELC6cMBR2ZGc6DDX4yZflPMTxj1rBFRik78uUATNEQhaqDA9h2uUEH2ufBltuIyZYasyB960ufTdIXq5DddV6iE2qiPIjRHG3RAhcOvHbtArhdJDVDMkBfyj9ZknoQhL2RF9tkzpIpc0Uys5QypIh3UEut3hvQQ3lNDcqqqCO/7RQ/5aNUQsf8sH606wmcy+Wh1Ee7NmVgd4bNT+Wg9ECM0QjnaoQQNFBFzrsjNjNiTyFId4ZQROxSgox5i/usqjElEDZJYJhFVSPa10Av0tZGZtu/EHQAAAABJRU5ErkJggg==\')',
        },
      },
    }),
  }),
  content: {
    variant: 'grids.StaticSectionContent',
  },
};
