export const bigBubble = {
  width: ['40vh', '44vh', '50vh', '55vh'],
  height: ['40vh', '44vh', '50vh', '55vh'],
  maxWidth: '510px',
  maxHeight: '510px',
};

export const smallBubble = {
  width: ['40vh', '44vh'],
  height: ['40vh', '44vh'],
  maxWidth: '402px',
  maxHeight: '402px',
};
