import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  ClosableColorPicker, useColorAtIndex,
} from '@composite';
import { ImageLoader } from '@core';
import { Image } from '@types';

interface ColorPaletteItemProps{
  // color: RGBColor | RGBColor[],
  logo?: Image,
  title: string,
  index: number
}

const container = {
  display: 'grid',
  gridTemplateColumns: 'max-content max-content 1fr',
  placeItems: 'center',
  gap: '10px',
  width: '15rem',
  my: 2,
  image: {
    width: 'auto',
    height: '3rem',
    borderRadius: '999px',
  },
};

export const ColorPaletteItem=({
  title, index, logo,
}:ColorPaletteItemProps) => {
  const [color, setColor]=useColorAtIndex(index);

  return (
    <Grid sx={container} id="colorPalette">
      <ClosableColorPicker defaultColor={color} setColorHandler={setColor} />
      {logo ? <ImageLoader src={logo.url} alt={logo.alternativeText} sx={container.image} /> : null}
      <Text contentEditable suppressContentEditableWarning>{title}</Text>
    </Grid>
  );
};
