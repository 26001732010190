import React from 'react';

export function useSearch<Item>(items: Item[], searchKey: keyof Item) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredItems, setFilteredListItems] = React.useState(() => items);

  React.useEffect(() => {
    const newListItems = items?.filter(li => li[searchKey]
      && typeof li[searchKey] === 'string'
      && (li[searchKey] as unknown as string).toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredListItems(newListItems);
  }, [searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
    filteredItems,
  };
}
