/** @jsx jsx */
import { jsx } from 'theme-ui';
import { themeConfig } from '@themed';
import { SegmentedControl } from '@core';
import { useLocale, useSelectedFsType, useTranslate } from '@modules';
import { FsType, FS_TYPES_PUBLIC } from '@types';

export const styles = {
  borderColor: 'background',
  bg: 'background',
  my: [5, 5, 0],

  '& button': {
    [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
      fontSize: '0.8rem',
    },
  },

  '&:before': {
    bg: 'grey.4',
  },
};

export const SwitchInstitutionType = () => {
  const locale = useLocale();
  const __ = useTranslate();
  const items = [
    {
      title: __('navbar.finnoscore-banks'),
      value: `/${locale}/finnoscore`,
    },
    {
      title: __('navbar.finnoscore-insurances'),
      value: `/${locale}/finnoscore/insurance`,
    },
    {
      title: __('navbar.finnoscore-privatebanks'),
      value: `/${locale}/finnoscore/privatebanks`,
    },
  ];

  const [type, setType] = useSelectedFsType();
  const defaultIndex = FS_TYPES_PUBLIC.findIndex(t => type === t) || 0;

  return (
    <SegmentedControl
      defaultIndex={defaultIndex}
      sx={styles}
      items={items}
      onChange={v => setType(v.value as FsType)}
    />
  );
};
