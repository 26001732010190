/** @jsx jsx */
import { jsx, Image, Box } from 'theme-ui';
import { forwardRef } from 'react';
import { ImageProps } from '@types';

const styles = {
  container: {
    height: 'auto',
    width: 'fullSize',
  },
  image: {

  },
};

const generateAlt = (src: string) => src
  // just use the filename, and strip the url prefix
  .replace(/.*\/(.*)\.\w+$/gi, '$1')
  // remove random hashes at the end of the filename
  .replace(/_[a-f0-9]{8,10}$/gi, '')
  // remove any other non-word character
  .replace(/[\W_-]+/gi, ' ');

export const StaticImage = forwardRef<HTMLDivElement, ImageProps>(({
  src, sx, alt, ...props
}, ref) => (
  <Box className={props.className} {...props} sx={styles.container} ref={ref}>
    <Image
      src={src}
      alt={alt || generateAlt(src)}
      sx={{ ...styles.image, ...sx }}
      {...props}
    />
  </Box>
));
