import React from 'react';
import { SectionWithContent } from '@core';
import { Box, Flex, Grid } from '@themed';
import { ComparisonHeader } from '../../finnoscore-comparison/ComparisonSelector';
import { finnoChartStyles as gridStyles } from '../components/index.styles';
import { ChartItem, ExportButton, MultiComparisonChart as ComparisonChart } from '../components';
import { DefaultChartData } from '../helpers';
import { ChartDropDownHeader } from '../ChartDropDownControl';
import { useInstitutionDropDownControl } from '../components/control/useInstitutionDropDownControl';
import { useTypeDropDownControl } from '../components/control/useTypeDropDownControl';
import useMultiChartInstitutions from '../components/control/useMultiChartInstitutions';
import useDimensionDropDownControl from '../components/control/useDimensionDropDownControl';
import { ChartDropDownControlComponent, CheckboxDropDownControlComponent } from '../components/control/ChartDropDownControl';
import { InstitutionDropDownWithState } from '../../finnoscore-comparison/InstitutionGroupedDropDown';
import { usePeriodDropDownControl } from '../components/control/usePeriodDropDownControl';
import { ColorPaletteList } from '../components/MultiComparison/ColorPaletteList';
import { ChartWizardStep } from './ChartWizardStep';
import { styles } from './HistoryChart.styles';

export const MultiComparison = () => {
  const exportChartRef = React.useRef<HTMLDivElement>(null);
  const exportLegendRef = React.useRef<HTMLDivElement>(null);

  const { types, setTypeId, typeId, type: fsType, isLoading: isTypeLoading } = useTypeDropDownControl();
  const { periods: pastPeriodList, period: pastPeriod, periodId: pastPeriodId, setPeriodId: setFirstPeriodId, isLoading: isFirstPeriodLoading } = usePeriodDropDownControl(fsType);
  const { periods: currentPeriodList, period: currentPeriod, periodId: currentPeriodId, setPeriodId: setcurrentPeriodId } = usePeriodDropDownControl(fsType);

  const { institutions, institution, institutionSetHandler, isLoading: isInstitutionLoading } = useInstitutionDropDownControl(fsType, currentPeriod);
  const { relative, current, national, worldwide, isLoading: isLegendLoading } = useMultiChartInstitutions(fsType?.text, pastPeriod, currentPeriod, institution?.name, institution?.country?.countryCode);
  const { dimensionKeys, onCheck } = useDimensionDropDownControl(institution);

  return (
    <SectionWithContent sx={gridStyles.main}>
      <Grid sx={{ ...gridStyles.content, minHeight: '950px' }}>

        <ChartWizardStep id="type" label="Type" isLoading={isTypeLoading}>
          <ChartDropDownControlComponent
            zIndex={20}
            list={types}
            selectedId={typeId}
            setter={setTypeId}
          >
            <ChartDropDownHeader title={fsType?.text || 'Choose a type'} />
          </ChartDropDownControlComponent>

        </ChartWizardStep>

        {fsType && (
          <ChartWizardStep id="pastPeriod" label="Relative period" isLoading={isFirstPeriodLoading}>
            <ChartDropDownControlComponent
              zIndex={15}
              list={pastPeriodList}
              selectedId={pastPeriodId}
              setter={setFirstPeriodId}
            >
              <ChartDropDownHeader title={pastPeriod?.text || 'Choose a period'} />
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {fsType && (
          <ChartWizardStep id="currentPeriod" label="Current period" isLoading={isFirstPeriodLoading} loading={null}>
            <ChartDropDownControlComponent
              zIndex={10}
              list={currentPeriodList}
              selectedId={currentPeriodId}
              setter={setcurrentPeriodId}
            >
              <ChartDropDownHeader title={currentPeriod?.text || 'Choose a period'} />
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {fsType && currentPeriod && (
          <ChartWizardStep id="bankSelector" label="Institution" isLoading={isInstitutionLoading}>

            <InstitutionDropDownWithState
              institutions={institutions}
              onSelect={institutionSetHandler}
              selectedInstitution={institution}
              sx={{ zIndex: 15 }}
              header={<ComparisonHeader
                subject={institution}
                chartComponent={<ChartItem
                  percentage={(institution?.total || 0) * 10}
                  sx={styles.headerChart}
                />}
              />}
            />

            <ChartWizardStep id="bankSelector" label="Dimensions" isLoading={!(fsType && institution)}>
              <CheckboxDropDownControlComponent
                zIndex={10}
                list={institution?.dimensions}
                selectedList={dimensionKeys}
                onCheckHandler={onCheck}
              >
                <ChartDropDownHeader title={`Selected dimensions (${dimensionKeys?.length}/6)`} />
              </CheckboxDropDownControlComponent>
            </ChartWizardStep>

            <ChartWizardStep id="bankSelector" label="Color palette" isLoading={isLegendLoading} ref={exportLegendRef}>
              <ColorPaletteList institutions={[current, relative, national, worldwide]} />
            </ChartWizardStep>
          </ChartWizardStep>
        )}

        {institution && (
          <Flex id="exportButton" sx={{ height: 'min-content' }}>
            <ExportButton element={exportChartRef.current} type="chart" name={`multi.${institution.name}`} />
            <ExportButton element={exportLegendRef.current} type="legend" />
          </Flex>
        )}

        <Box id="chart" variant={institution ? undefined : 'styles.sampleChart'}>
          <ComparisonChart
            key={institution?.id}
            ref={exportChartRef}
            relative={relative || DefaultChartData.multi.relative}
            current={current || DefaultChartData.multi.current}
            national={national || DefaultChartData.multi.national}
            worldwide={worldwide || DefaultChartData.multi.worldwide}
            dimensions={dimensionKeys || undefined}
          />
        </Box>

      </Grid>
    </SectionWithContent>
  );
};
