import { SxStyleProp } from 'theme-ui';

export const styles = {
  display: {
    display: ['none', 'none', 'flex'],
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'header',
  } as SxStyleProp,
  layout: {
    width: 'fullSize',
  },
  defaultStyles: {
    variant: 'styles.desktopHeaderBackground',
  },
  invertedStyles: {
    variant: 'styles.desktopHeaderBackgroundInverted',
    '#desktop-menu > * > .active': {
      borderBottomColor: 'white !important',
    },
  },
  hiddenState: {
    transform: 'translateY(-100px)',
    textAlign: 'right',
    bg: 'transparent',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  } as SxStyleProp,
  content: {
    width: '100%',
    variant: 'styles.layoutMaxWidth',
    mx: 'auto',
    // py: 4,
  },
  menubar: {
    height: 'headerHeight',
    variant: 'flex.center',
  },
  logo: {
    variant: 'links.nav',
    display: 'flex',
    alignItems: 'center',
    ml: [0, 0, 4, 0],
    '&.active': {
      border: 'none',
    },
  },
  navbar: {
    ml: 'auto',
    variant: 'flex.right',
    alignItems: 'flex-start',
    mr: [3, 3, 0, 3],
  },
};
