import React from 'react';

export const selectedSearchListItemId = 'selectedSearchListItem';

export const useGoToSelected = (className: string = selectedSearchListItemId) => {
  React.useLayoutEffect(() => {
    const li = document.querySelector(`.${className}`);
    if (li) {
      li.scrollIntoView({ block: 'center' });
    }
  });
};
