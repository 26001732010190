/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { useState } from 'react';
import { RGBColor, SketchPicker } from 'react-color';
import { useColor } from './context/ColorContext';
import { defaultColors } from './BasicColorPicker';
import { colorStyles, styles } from './ClosableColorPicker.styles';

interface ClosableColorPickerProps {
  defaultColor?: RGBColor;
  setColorHandler?: (color:RGBColor) => void;
}

export const ClosableColorPicker = ({
  defaultColor={ r: 0, g: 189, b: 89, a: 1 }, setColorHandler,
}:ClosableColorPickerProps) => {
  const [rgbColor, setRgbColor] = useColor();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (col: RGBColor) => {
    if (!setColorHandler) setRgbColor(col);
    if (setColorHandler) setColorHandler(col);
  };

  return (
    <Box className="closableColorPicker">
      <Box sx={styles.swatch} onClick={handleClick}>
        <Box sx={colorStyles(defaultColor)} />
      </Box>
      { displayColorPicker ? <Box sx={styles.popover}>
        <Box sx={styles.cover} onClick={handleClose} />
        <SketchPicker presetColors={defaultColors} color={defaultColor || rgbColor} onChange={(col) => handleColorChange(col.rgb)} />
      </Box> : null }
    </Box>
  );
};
