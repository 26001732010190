import { DimensionKey, FsPeriod } from '@types';

export const multipleRGBColorObj = [
  {
    r: 0, g: 0, b: 0, a: 1,
  }, {
    r: 0, g: 189, b: 89, a: 1,
  }, {
    r: 86, g: 116, b: 149, a: 1,
  },
  {
    r: 0, g: 158, b: 226, a: 1,
  },
  {
    r: 207, g: 205, b: 207, a: 1,
  },
];

export const convertFsTypes = (fstypes:FsPeriod[]) => Array.from(new Set(fstypes.map(({ text }) => text)));

export const formatFsTypeForGQL = <ResponseType, >(type: string): ResponseType => {
  const result = type === 'bankMiddleEast' ? type : type.toLowerCase();
  return result as unknown as ResponseType;
};

export const earlierThanFirstPeriod = (date:string, firstPeriod:string) => +firstPeriod.split('-')[0] > +date.split('-')[0];

export const earlierThanSecondPeriod = (date:string, secondPeriod:string) => (secondPeriod ? +secondPeriod.split('-')[0] <= +date.split('-')[0] : false);

interface DimensionSetHandlerProps{
  dimensionKey: DimensionKey;
  selectedDimensions: DimensionKey[]
  setter: (dimensions: DimensionKey[]) => void;
}

export const dimensionCheckHandler = ({ dimensionKey, selectedDimensions, setter }:DimensionSetHandlerProps) => {
  if (selectedDimensions && selectedDimensions.includes(dimensionKey)) {
    const newDimensions = selectedDimensions.filter(key => key!==dimensionKey);
    setter(newDimensions);
  }
  if (selectedDimensions && selectedDimensions.length < 6 && !selectedDimensions.includes(dimensionKey)) {
    setter([...selectedDimensions, dimensionKey]);
  }
};
