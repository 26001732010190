import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import React from 'react';
import { OptionalTriggerElementProps } from '@types';
import { __el, __tr } from '@modules';
import { scrub, useScrollTriggerRefresh } from '@core';

interface AnimateContactProps extends OptionalTriggerElementProps {
}

export const animateContact = ({
  triggerRef,
}: AnimateContactProps) => {
  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];

  const fakeScrollY = 0.25*(window?.innerHeight || 800);

  const initScrollrs = () => {
    const trigger = __tr(triggerRef);
    if (!trigger) return;
    scrollers.push(ScrollTrigger.create({
      trigger,
      start: () => 'bottom bottom', // needed due to a previous section pin
      end: 'bottom top',
      toggleActions: 'restart none reverse none',
      // pin: true,
      // pinSpacing: false,
      // pinType: 'fixed',
      ...scrub(),
      // markers: process.env.NODE_ENV === 'development',
      animation: gsap.timeline()
        .to(__el(triggerRef), {
          y: () => fakeScrollY, ease: 'linear',
        }, 0),
    }));
  };
  React.useEffect(() => {
    if (triggerRef) initScrollrs();
  }, [triggerRef]);
  // Don't need this anymore as we have the features in useAnimationContact;
  // ScrollTrigger.matchMedia({
  //   [`(min-width: ${getBreakpoint(0)})`]: initScrollrs,
  // });
};

export const useAnimationContact = (props: AnimateContactProps) => useScrollTriggerRefresh<AnimateContactProps>(animateContact, props);
