/** @jsx jsx */
import { Box, jsx, SxProps } from 'theme-ui';
import React from 'react';
import { Global } from '@emotion/core';
import { RecoilRoot } from 'recoil';
import { ChildrenProps } from '@types';
import { Header, DesktopHeaderProps, NotSupportedPopup, CookieConsentContainer, CookieConsentProvider } from '@composite';
import { useIsLegacy } from '@modules';
import { printStyles } from '@config';
import { DataPrivacyProvider } from 'src/store/DataPrivacyContext';
import { EditableProvider, EditedOverlay } from '../editable';
import { cleanupScrolls, useSmoothScrollbar } from '../animation';

import { ShowBackButtonProvider } from './ShowBackButton';
import { Footer } from './Footer';

interface Props extends ChildrenProps, DesktopHeaderProps, SxProps {
  className?: string;
}

// onbeforeunload is only called if there was user interaction on the page
// if it's called, this fixes the scroll trigger calculation issues on reload
// if smooth scrollbar is enabled, this is not needed
const scrollToTopBeforeFullPageReload = () => {
  if (!window !== undefined) {
    window.onbeforeunload = () => {
      const html = document.querySelector('html');
      if (html) html.style.scrollBehavior = '';
      window.scrollTo(0, 0);
      return null;
    };
  }
};

export const Layout = ({
  children, invertedHeader, sx, className,
}: Props) => {
  const isLegacy = useIsLegacy();

  // scroll up on internal routing
  const scrollBar = useSmoothScrollbar();
  React.useEffect(() => {
    scrollToTopBeforeFullPageReload();

    if (scrollBar) {
      scrollBar.setPosition(0, 0);
      scrollBar.update();
    }
    return () => {
      cleanupScrolls();
    };
  }, []);

  return (
    <ShowBackButtonProvider>
      <DataPrivacyProvider>
        <CookieConsentProvider>
          <EditableProvider>
            <RecoilRoot>
              <React.Fragment>
                <Global
                  styles={theme => ({
                    a: {
                      // at least we should attempt to have <a> to be formatted like it were with themed components.
                      background: theme?.colors[theme?.styles?.a.background],
                      color: theme?.colors[theme?.styles?.a.color],
                      fontSize: theme?.fontSizes[theme?.styles?.a.fontSize],
                      fontWeight: theme?.fontWeights[theme?.styles?.a.fontWeight],
                      fontFamily: theme?.fonts[theme?.styles?.a.fontFamily],
                      textDecoration: theme?.styles?.a.textDecoration,
                    },
                    '::-moz-selection': { backgroundColor: theme?.colors?.primary, color: theme?.colors?.background },
                    '::selection': { backgroundColor: theme?.colors?.primary, color: theme?.colors?.background },
                    ...printStyles(false),
                    '@media print': {
                      ...printStyles(true),
                    },
                  })}
                />
                {/* These two boxes with these styles are replicating the two transition link divs that were present during the development of the app.
            Transition link was removed later to fix a transition bug, but the codebase depended on these divs and styles, so we're adding them back here.
        */}
                {/* TODO: remove these two divs and fix layout and animations */}
                {isLegacy && <NotSupportedPopup />}
                <Box
                  sx={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                  }}
                  data-browser={isLegacy && 'legacy'}
                >
                  <Box
                    sx={{
                      zIndex: 1,
                      opacity: 1,
                      width: '100%',
                      float: 'left',
                      position: 'relative',
                    }}
                    id="scroller"
                  >
                    <Header invertedHeader={invertedHeader} />
                    <main
                      sx={sx}
                      className={className}
                    >
                      {children}
                      <Footer />
                    </main>
                    <CookieConsentContainer />
                  </Box>
                </Box>
              </React.Fragment>
            </RecoilRoot>
            <EditedOverlay />
          </EditableProvider>
        </CookieConsentProvider>
      </DataPrivacyProvider>
    </ShowBackButtonProvider>
  );
};
