import React from 'react';

// TODO: implement with icon
export default () => (
  <svg viewBox="0 0 206 27" width="206px" height="27px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-6" transform="translate(-87.000000, -27.000000)">
        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="1440" height="79" />
        <g id="Group" transform="translate(87.000000, 27.000000)">
          <polygon id="Fill-5" fill="#1A1919" points="1.0658141e-14 26.1649 1.0658141e-14 0.9599 15.927 0.9599 15.927 5.6169 4.906 5.6169 4.906 11.8379 14.718 11.8379 14.718 16.3529 4.941 16.3529 4.941 26.1649" />
          <path d="M21.9325,0 C23.5675,0 24.8475,1.315 24.8475,2.951 C24.8475,4.515 23.5675,5.83 21.9325,5.83 C20.3325,5.83 19.0175,4.515 19.0175,2.951 C19.0175,1.315 20.3325,0 21.9325,0 L21.9325,0 Z M19.5865,26.165 L24.3145,26.165 L24.3145,8.674 L19.5865,8.674 L19.5865,26.165 Z" id="Fill-6" fill="#1A1919" />
          <path d="M62.3933,8.6973 L57.8663,8.6973 L47.4193,25.5743 L47.4193,26.1643 L51.5313,26.1643 L62.3933,8.6973 Z M47.4193,24.4463 L51.3093,18.1463 L51.3093,8.6973 L47.4193,8.6973 L47.4193,24.4463 Z M39.5343,26.1643 L43.4003,26.1643 L43.4003,9.5413 L39.5343,15.8003 L39.5343,26.1643 Z M43.2253,8.6973 L38.6723,8.6973 L28.2243,25.6183 L28.2243,26.1643 L32.3363,26.1643 L43.2253,8.6973 Z M28.2243,24.4903 L32.1143,18.1903 L32.1143,8.6973 L28.2243,8.6973 L28.2243,24.4903 Z M58.7303,15.7563 L58.7303,26.1643 L62.5953,26.1643 L62.5953,9.4973 L58.7303,15.7563 Z" id="Fill-8" fill="#00BD59" />
          <g id="Group-21" transform="translate(64.641000, 7.339300)" fill="#1A1919">
            <path d="M18.9287,10.0805 C18.9287,15.4485 14.9827,19.3585 9.7567,19.3585 C4.5307,19.3585 0.5847,15.4485 0.5847,10.0805 C0.5847,4.6765 4.5307,0.8015 9.7567,0.8015 C14.9827,0.8015 18.9287,4.6765 18.9287,10.0805 M14.2007,10.0805 C14.2007,6.7745 12.0677,5.1035 9.7567,5.1035 C7.4457,5.1035 5.3127,6.7745 5.3127,10.0805 C5.3127,13.3515 7.4457,15.0575 9.7567,15.0575 C12.0677,15.0575 14.2007,13.3865 14.2007,10.0805" id="Fill-9" />
            <path d="M26.7833,10.0805 C26.7833,13.2445 28.8453,15.0215 31.2623,15.0215 C33.6803,15.0215 34.8883,13.4225 35.2793,12.0715 L39.4393,13.4575 C38.6573,16.3725 35.9203,19.3585 31.2623,19.3585 C26.1083,19.3585 22.0553,15.4485 22.0553,10.0805 C22.0553,4.6765 26.0363,0.8015 31.1203,0.8015 C35.8843,0.8015 38.5863,3.7525 39.3323,6.7035 L35.1023,8.1255 C34.6753,6.6675 33.5733,5.1745 31.2273,5.1745 C28.8093,5.1745 26.7833,6.9165 26.7833,10.0805" id="Fill-11" />
            <path d="M59.808,10.0805 C59.808,15.4485 55.862,19.3585 50.636,19.3585 C45.41,19.3585 41.464,15.4485 41.464,10.0805 C41.464,4.6765 45.41,0.8015 50.636,0.8015 C55.862,0.8015 59.808,4.6765 59.808,10.0805 M55.079,10.0805 C55.079,6.7745 52.946,5.1035 50.636,5.1035 C48.325,5.1035 46.192,6.7745 46.192,10.0805 C46.192,13.3515 48.325,15.0575 50.636,15.0575 C52.946,15.0575 55.079,13.3865 55.079,10.0805" id="Fill-13" />
            <path d="M68.2667,18.8256 L63.5387,18.8256 L63.5387,1.3346 L68.1247,1.3346 L68.1247,3.5036 C69.1917,1.6906 71.2887,0.8726 73.1727,0.8726 C77.5097,0.8726 79.5007,3.9656 79.5007,7.8056 L79.5007,18.8256 L74.7727,18.8256 L74.7727,8.6226 C74.7727,6.6676 73.8127,5.1386 71.5377,5.1386 C69.4757,5.1386 68.2667,6.7386 68.2667,8.7656 L68.2667,18.8256 Z" id="Fill-15" />
            <path d="M86.8581,13.0668 C86.9641,14.4528 87.9951,15.7328 90.0571,15.7328 C91.6221,15.7328 92.3681,14.9158 92.3681,13.9908 C92.3681,13.2088 91.8351,12.5688 90.4841,12.2848 L88.1731,11.7518 C84.7961,11.0048 83.2671,8.9778 83.2671,6.5258 C83.2671,3.3968 86.0401,0.8018 89.8091,0.8018 C94.7851,0.8018 96.4561,3.9658 96.6701,5.8498 L92.7241,6.7388 C92.5811,5.7078 91.8351,4.3928 89.8441,4.3928 C88.5991,4.3928 87.6041,5.1388 87.6041,6.1348 C87.6041,6.9878 88.2441,7.5208 89.2041,7.6988 L91.6931,8.2318 C95.1411,8.9428 96.8831,11.0398 96.8831,13.5998 C96.8831,16.4438 94.6791,19.3588 90.0931,19.3588 C84.8311,19.3588 83.0181,15.9458 82.8051,13.9558 L86.8581,13.0668 Z" id="Fill-17" />
            <path d="M111.884,16.9413 C110.924,18.5763 108.898,19.2883 107.049,19.2883 C102.819,19.2883 100.401,16.1953 100.401,12.3913 L100.401,1.3353 L105.129,1.3353 L105.129,11.4313 C105.129,13.3863 106.124,14.9513 108.329,14.9513 C110.426,14.9513 111.635,13.5283 111.635,11.5023 L111.635,1.3353 L116.363,1.3353 L116.363,15.6613 C116.363,17.0123 116.47,18.2213 116.541,18.8253 L112.026,18.8253 C111.955,18.4703 111.884,17.6533 111.884,16.9413" id="Fill-19" />
          </g>
          <polygon id="Fill-22" fill="#1A1919" points="186.05 26.1643 190.779 26.1643 190.779 0.4263 186.05 0.4263" />
          <path d="M201.6562,8.6743 L205.1762,8.6743 L205.1762,12.8693 L201.6562,12.8693 L201.6562,20.1923 C201.6562,21.7213 202.3672,22.2193 203.7182,22.2193 C204.2872,22.2193 204.9262,22.1473 205.1762,22.0773 L205.1762,25.9873 C204.7482,26.1653 203.8962,26.4133 202.5092,26.4133 C199.0962,26.4133 196.9632,24.3873 196.9632,21.0103 L196.9632,12.8693 L193.7992,12.8693 L193.7992,8.6743 L194.6882,8.6743 C196.5372,8.6743 197.3902,7.4653 197.3902,5.9013 L197.3902,3.4483 L201.6562,3.4483 L201.6562,8.6743 Z" id="Fill-23" fill="#1A1919" />
        </g>
      </g>
    </g>
  </svg>
);
