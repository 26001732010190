import { SxStyleProp } from 'theme-ui';

export const styles = {
  content: {
    position: 'relative',
    zIndex: 0,
    // width: '100%',
    // variant: 'styles.layoutMaxWidth',
    mx: '0',
    width: 'max-content',
    py: [0, 0, 4],
    pl: [0, 4, 0],
    pt: 4,
  } as SxStyleProp,
  menuButton: {
    position: 'relative',
    zIndex: 'header',
    display: 'inline-block',
    marginLeft: 'auto',
    bg: 'background',
    borderRadius: 5,
    px: 4,
    py: 3,
    color: 'text',
  } as SxStyleProp,
  flex: {
    alignItems: 'center',
  },
  icon: {
    alignItems: 'center',
    mr: [0, 0, 3],
  },
  title: (mobileTitle?: boolean) => ({
    fontWeight: 'bold',
    display: mobileTitle ? 'block' : ['none', 'none', 'block'],
    ml: [3, 3, 0],
  }),
};
