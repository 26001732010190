import React from 'react';
import { Box, BoxProps } from 'theme-ui';
import { Colors, ForwardRef } from '@types';
import { reColorSvg } from '../ui/reColorSvg';

export interface ThemedIconBoxProps extends BoxProps {
  color?: Colors;
}

export type ThemedIconProps = ForwardRef<HTMLDivElement, ThemedIconBoxProps>;

export const ThemedIcon = React.forwardRef<HTMLDivElement, ThemedIconBoxProps>(({ ...props }, ref) => <Box {...props} sx={{ ...reColorSvg(props.color), ...props.sx }} ref={ref} />);
