import { compactSectionContentPadding } from '@themed';

export const styles = {
  content: {
    variant: 'flex.left',
    px: compactSectionContentPadding,
    width: '100%',
  },
  video: {
    variant: [undefined, undefined, 'styles.section'],
    borderBottom: 'none',
    bg: 'background',
    '#youtube-container': {
      maxWidth: '100%',
      iframe: {
        minHeight: 'auto',
        height: 'auto',
        width: '100%',
        aspectRatio: '16 / 9',
      },
    },
    h2: {
      variant: 'text.h2',
    },
    p: {
      fontSize: ['xs', 'xs', 's'],
    },
  },
};
