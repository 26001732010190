import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const styles = {
  contentBox: {
    variant: 'grids.CompactSectionContentPadding',
    textAlign: 'left',
  },
  title: {
    mb: 6,
  },
  founderList: {
    flexWrap: 'wrap',
    gap: 4,
    '&>*': {
      width: ['100%', '100%', '30%'],
    },
    '.listItem': {
      img: {
        variant: 'images.grayScale',
      },
      '&:hover': {
        img: {
          variant: 'images.grayScaleHover',
        },
      },
      variant: ['flex.col'],
      justifyContent: 'flex-start',
      [`@media screen and (min-width: ${themeConfig.sizes.mobileMaxWidth}) and (max-width: ${themeConfig.sizes.tabletMaxWidth})`]: {
        display: 'block',
        pb: 5,
        '.listItemImage': {
          float: 'left',
        },
        '.listItemText': {
          float: 'right',
          clear: 'right',
          maxWidth: '76%',
          pt: 0,
        },
        '.listItemButtonList': {
          clear: 'both',
        },
      },
    },
    '.listItem:nth-of-type(even) .listItemImage': {
      alignSelf: ['flex-end', 'flex-end', 'flex-start'],
    },
    '.listItemText': {
      flex: '1 1 auto',
      b: {
        display: ['block', 'inherit'],
      },
    },
    [`@media screen and (min-width: ${themeConfig.sizes.tabletMaxWidth}) and (max-width: ${themeConfig.sizes.desktopMaxWidth})`]: {
      '.listItemButtonList a': {
        px: 4,
        // need onlyto have the 3rd tag, the other 155 are the default
        minWidth: 'unset',
      },
    },
    '.listItemButtonList a:not(.common-scheduleMeeting)': {
      variant: 'buttons.contactIcon',
    },
    '@media screen and (max-width: 480px)': {
      '.listItemButtonList a.common-scheduleMeeting': {
        variant: 'buttons.contactIcon',
        '.icon': {
          display: 'flex',
          m: 0,
        },
      },
    },
  } as SxStyleProp,
};
