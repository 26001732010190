import React from 'react';
import { BoxProps } from 'theme-ui';
import { Box, Button, Flex, SvgIcon, Video } from '@core';
import { Video as VideoType } from '@types';
import { styles } from './VideoPlayer.styles';

interface VideoSrcProps extends BoxProps {
  src: string;
}

export const YoutubeVideoPlayer = ({ src, ...props }: VideoSrcProps) => <Box id="youtube-container" sx={{ ...styles.youtube, ...props.sx }}>
  <iframe
    title="Youtube"
    src={src}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
</Box>;

export const HTML5VideoPlayer = ({ src, sx }: VideoSrcProps) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const playOrPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
        setIsPlaying(false);
      } else {
        video.play();
        setIsPlaying(true);
      }
    }
  };

  return <Box sx={{ ...styles.videoBox, ...sx }} onClick={playOrPause}>
    <Button
      variant="pure"
      sx={{
        ...styles.videoOverlay,
        opacity: () => (isPlaying ? 0 : 1),
      }}
      aria-label="play"
    >
      <Flex variant="flex.center" sx={styles.playButtonBox}>
        <SvgIcon name="play" />
      </Flex>
    </Button>
    <Video ref={videoRef} src={src} loop sx={styles.video} />
  </Box>;
};

interface Props extends BoxProps {
  video: VideoType
}

export const VideoPlayer = ({ video, ...props }: Props) => {
  const { url } = video;
  let { youtubeUrl } = video;
  if (youtubeUrl) {
    youtubeUrl = youtubeUrl.replace('watch?v=', 'embed/'); // TODO replace CMS youtube urls from 'watch?v=' to 'embed' so this line is not needed
    return <YoutubeVideoPlayer src={youtubeUrl} {...props} />;
  }
  if (url) {
    return <HTML5VideoPlayer src={url} {...props} />;
  }
  return <Box />;
};
