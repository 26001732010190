/** @jsx jsx */
import { jsx } from 'theme-ui';
import { forwardRef } from 'react';
import { Flex, Paragraph, SectionWithContent, SvgIcon, Title } from '@core';
import { useTranslate } from '@modules';
import { styles } from './QuickWins.styles';

export const QuickWins = forwardRef<HTMLDivElement, unknown>((_props, ref) => {
  const __ = useTranslate();

  return (<SectionWithContent data-cy="finnoscore-quickwins" sx={styles.section} ref={ref}>
    <Flex variant="flex.colCenter">
      <SvgIcon sx={styles.rocketIcon} name="rocket" />
      <Flex sx={styles.textBox} data-cy="finnoscore-quickwins-text">
        <Title sx={styles.title}>{__('pages.finnoscore.quickwins.title')}</Title>
        <Paragraph>{__('pages.finnoscore.quickwins.paragraph')}</Paragraph>
      </Flex>
    </Flex>
  </SectionWithContent>);
});
