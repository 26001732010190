import { SxStyleProp } from 'theme-ui';

export const mobileColors = {
  bg: 'grey.4',
  highlight: 'background',
};

export interface OffsetProps {
  left: number;
  width: number;
  transition?: string;
}

export const styles = {
  container: (offset: OffsetProps, isMobile?: boolean): SxStyleProp => ({
    borderRadius: 5,
    border: 'thick',
    borderColor: isMobile ? mobileColors.bg : mobileColors.highlight,
    bg: isMobile ? mobileColors.bg : mobileColors.highlight,
    width: ['100%', 'max-content'],
    height: '50px',
    position: 'relative',

    '&:before': {
      borderRadius: 5,
      transition: 'all 0.3s ease',
      position: 'absolute',
      top: 0,
      content: '""',
      height: '100%',
      bg: isMobile ? mobileColors.highlight : mobileColors.bg,
      zIndex: 0,
      ...offset,
    },
  }),
  item: (current: boolean): SxStyleProp => ({
    position: 'relative',
    color: 'text',
    flex: '1 1 auto',
    borderRadius: 5,
    fontWeight: ['normal', 'bold'],
    fontSize: 'xxs',
    px: [0, 4],
    py: '12px',
    ml: 2,
    '&:first-of-type': {
      ml: 0,
    },

    ...(current ? { boxShadow: 'none !important' } : {}),
    '&:hover': {
      transform: 'none !important',
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'translate(0px,2px) !important',
    },
  }),
};
