import React from 'react';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { Data2d } from 'smooth-scrollbar/interfaces';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useLocation } from '@reach/router';
import { isTouchDevice, useIsLegacy } from '@modules';
import { useIsMobile } from '../themed/ThemedHelper';
// eslint-disable-next-line import/no-extraneous-dependencies

type Direction = 'x' | 'y'

class ScrollDisablerPlugin extends ScrollbarPlugin {
  static pluginName = 'disableScroll';

  static defaultOptions: { direction: null | Direction } = {
    direction: null,
  };

  transformDelta(delta: Data2d) {
    const newDelta = { ...delta };

    if (this.options.direction) {
      newDelta[this.options.direction as Direction] = 0;
    }

    return newDelta;
  }
}

Scrollbar.use(ScrollDisablerPlugin);

let bodyScrollBar: Scrollbar | null;

const useShouldUseSmoothScrollbar = () => {
  const { pathname } = useLocation();
  const isTouchSupported = isTouchDevice();
  const legacy = useIsLegacy();
  const isDev = process.env.NODE_ENV === '_NEVER_development'; // NOTE: disable smooth scroll in development, if we want to test GSAP markers
  if (pathname.match(/\/((banks(?!-middleeast))|(insurances)|(guide))/) || pathname.match(/(\/blog\/.+)/)) {
    return false;
  }

  return (!isTouchSupported && !legacy) || isDev;
};

export const useSmoothScrollbar = () => {
  const useScrollBar = useShouldUseSmoothScrollbar();

  if (!bodyScrollBar && useScrollBar) {
    bodyScrollBar = Scrollbar.init(document.body, {
      damping: 0.1,
      delegateTo: document,
    });
    bodyScrollBar.updatePluginOptions('disableScroll', { direction: 'x' });

    gsap.registerPlugin(ScrollTrigger);

    // const scrollerElement = 'body';

    ScrollTrigger.scrollerProxy(document.body, {
      scrollTop(value) {
        if (value !== undefined && bodyScrollBar) {
          bodyScrollBar.scrollTop = value;
        }
        return bodyScrollBar?.scrollTop;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    ScrollTrigger.defaults({
      scroller: document.body,
    });

    bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.refresh();
  } else if (bodyScrollBar && !useScrollBar) {
    bodyScrollBar.destroy();
    bodyScrollBar=null;
  }

  return bodyScrollBar;
};

// https://github.com/idiotWu/smooth-scrollbar/issues/49
// position: fixed behaves like absolute inside transformed container, hence have to apply offset

type TranslateType = 'top' | 'transform';

interface UseSmoothScrollbarProps {
  [key: string]: Element | null
}

function translateElementWithScroll(element: HTMLElement, y: number, type?: TranslateType) {
  switch (type) {
    case 'top':
    // eslint-disable-next-line no-param-reassign
      element.style.top = `${y}px`;
      break;
    default:
    // eslint-disable-next-line no-param-reassign
      element.style.transform = `translate3d(0, ${y}px, 0)`;
  }
}

export interface WithSmoothScrollBarOptions {
  isMobile?: boolean;
}
export interface UseSmoothScrollbarListenerOptions {
  type?: TranslateType;
  disable?: boolean;
}

interface ScrollerFuncProps {
  offset: Data2d;
}
interface ScrollerFuncs {
  [key: string]: (p: ScrollerFuncProps) => void;
}
const funcs: ScrollerFuncs = {};

export const useSmoothScrollbarListener = (elements: UseSmoothScrollbarProps, {
  type: propType, disable,
}: UseSmoothScrollbarListenerOptions= {
}) => {
  if (bodyScrollBar && !disable) {
    const type = propType || 'transform';

    Object.entries(elements).forEach(([key, element]) => {
      if (element && bodyScrollBar) {
        const func = ({ offset }: ScrollerFuncProps) => translateElementWithScroll(element as HTMLElement, offset.y, type);
        // TODO: shall we add for touch devices? don't thinso, but the following snippet could help instead of bodyScrollBar.addListener
        // window.addEventListener('scroll', () => console.log('scroll', window.scrollY || window.pageYOffset));
        bodyScrollBar.addListener(func);
        funcs[key] = func;
      }
    });
  } else if (bodyScrollBar) {
    const keys = Object.entries(elements).map(([key]) => key);
    const disableFuncs = Object.entries(funcs).filter(([key]) => keys.includes(key));
    disableFuncs.forEach(([, func]) => bodyScrollBar?.removeListener(func));
  }
};

export function withSmoothScrollBar <Props>(animationFunction: (p: Props, o?: WithSmoothScrollBarOptions) => void, props: Props) {
  const scrollBar = useSmoothScrollbar();
  const isMobile = useIsMobile();
  React.useEffect(() => {
    animationFunction(props, { isMobile }); // we should init after loading scrollBar
  }, [scrollBar, isMobile]);
}

export const useSmoothScrollbarForComponent = (selector:keyof HTMLElementTagNameMap | string) => {
  const useScrollBar = useShouldUseSmoothScrollbar();

  React.useLayoutEffect(() => {
    if (!useScrollBar) return;

    const scrollBox = document.querySelector(selector) as HTMLElement;
    if (scrollBox) {
      Scrollbar.init(scrollBox, {
        damping: 0.1,
      });
    }
  });
};
