import { Image, SxStyleProp } from 'theme-ui';
import React from 'react';
import cler from '../../../images/nearshoring/cler.png';
import rv from '../../../images/nearshoring/rv.png';
import sparda from '../../../images/nearshoring/sparda.png';
import adac from '../../../images/nearshoring/adac.png';
import stadtWien from '../../../images/nearshoring/stadt-wien.png';

const caseStudyLogos = [
  {
    name: 'Adac',
    img: adac,
    coords: {
      top: [-20, 50],
      left: [60, 150],
    },
  },
  {
    name: 'Rv',
    img: rv,
    coords: {
      bottom: [50, 80],
      right: [0, -30],
    },
  },
  {
    name: 'Sparda',
    img: sparda,
    coords: {
      top: [20, 80],
      right: [0, 20],
    },
  },
  {
    name: 'Cler',
    img: cler,
    coords: {
      left: [100, 200],
      bottom: [0, -30],
    },
  },
  {
    name: 'Stadt-wien',
    img: stadtWien,
    coords: {
      left: [0, 50],
      bottom: [200, 100],
    },
  },
];

interface CoordinationProps {
  top?: number | number[];
  bottom?: number | number[];
  left?: number | number[];
  right?: number | number[];
}
const caseStudyImgStyles = (coordinationProps: CoordinationProps): SxStyleProp => ({
  ...coordinationProps,
  position: 'absolute',
  width: ['7rem', '10rem'],
  height: ['7rem', '10rem'],
});

export const FloatingCaseStudyLogos = () => (
  <>
    {caseStudyLogos.map(logo => (
      <Image src={logo.img} alt={logo.name} sx={caseStudyImgStyles(logo.coords)} />
    ))}
  </>
);
