import * as React from "react";

const SvgArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={25}
    viewBox="0 0 23 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="arrow_svg__Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M.833 2.028v20.94c0 1.387 1.497 2.256 2.701 1.568l18.337-10.47c1.214-.693 1.214-2.443 0-3.136L3.534.46C2.33-.227.834.642.834 2.028z"
        id="arrow_svg__play"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgArrow;
