export const styles={
  mt: 6,
  gridTemplateColumns: ['1fr', 'minmax(200px, 1fr) 4fr'],
  gridTemplateAreas: ['"first" "second"', '"first second"'],
  placeItems: ['center', 'flex-start'],
  alignItems: 'center',
  width: '100%',
  '&>*:nth-of-type(1)': {
    padding: '4px 0 0 4px',
    gridArea: 'first',
  },
  '&>*:nth-of-type(2)': {
    gridArea: 'second',
  },
};
