export * from './BaseProps';
export * from './Children';
export * from './Page';
export * from './Refs';
export * from './Theme';
export * from './Image';
export * from './Locale';
export * from './Video';
export * from './Direction';
export * from './Sx';
export * from './StrapiLogin';

// GRAPH QL TYPES
export * from './GraphQl';
export * from './Article';
export * from './Author';
export * from './Tag';
export * from './CaseStudy';
export * from './Seo';
export * from './Node';
export * from './Country';
export * from './Translation';
export * from './Institution';
export * from './CountryCalculation';
export * from './FsType';
export * from './Comparison';
export * from './Dimension';
export * from './Charts';
