import { SxStyleProp } from 'theme-ui';

export const styles = {
  box: {
    variant: 'styles.blogTextMaxWidth',
    position: 'relative',
    py: 5,
  },
  background: {
    backgroundColor: 'muted',
    position: 'absolute',
    top: 0,
    left: '-50vw',
    width: '150vw',
    height: '100%',
    zIndex: -1,
  } as SxStyleProp,
  profile: {
    flex: '1 0 auto',
  },
  quoteTexts: {
    flexDirection: 'column',
    ml: 5,
  } as SxStyleProp,
  name: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  jobTitle: {
    fontSize: '0.9rem',
    fontWeight: 'normal',
  },
};
