/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { useLocale, useTranslate } from '@modules';
import { useDataPrivacy } from 'src/store/DataPrivacyContext';
import { Link } from '../ui';
import { useSiteMeta } from './useSiteMeta';
import { styles } from './Footer.styles';

export const Footer = () => {
  const { path, text } = useDataPrivacy();
  const meta = useSiteMeta();
  const locale = useLocale();
  const __ = useTranslate();

  return (
    <Flex as="footer" sx={styles.footerContainer}>
      <Flex sx={styles.links}>
        ©{new Date().getFullYear()}
        <Link to={meta.siteUrl}>{meta.title}</Link>
        <Link to={`/${locale}/imprint`}>{__('navbar.imprint')}</Link>
        <Link to={`/${locale}${path}`}>{text}</Link>
      </Flex>
    </Flex>
  );
};
