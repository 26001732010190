export const styles = {
  flagContainer: {
    variant: 'flex.center',
  },
  flag: {
    borderRadius: '100%',
    width: '16px',
    height: '16px',
    objectFit: 'cover',
  },
  selectContainer: {
    minWidth: [100, 100, 50, 100],
  },
  selectTitle: {
    textTransform: ['none', 'none', 'uppercase', 'none'],
  },
};
