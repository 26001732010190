import React from 'react';
import { useStrapiPageContent } from '../../../content/useStrapiPageContent';
import { StaticSectionList } from './StaticSectionList';

interface StrapiSectionListContainerProps {
  pageId: string;
}

export const StrapiSectionListContainer = React.forwardRef<HTMLDivElement, StrapiSectionListContainerProps>(({ pageId, ...props }, ref) => {
  const strapiPage = useStrapiPageContent(pageId);

  return <StaticSectionList content={strapiPage?.content} {...props} ref={ref} />;
});
