import { SxStyleProp, themeConfig } from '@themed';
import { translateWithSize } from '@core';

export const styles = {
  containerRow: {
    mt: ['headerHeight', 'headerHeight', 0],
  },
  topRow: {
    variant: 'flex.center',
    width: ['fullSize', 'fullSize', '110%', 'fullSize'],
    flexDirection: ['column-reverse', 'column-reverse', 'row'],
    mb: 5,
  },
  textContainer: {
    flexBasis: '66%',
  },
  topTeamContainer: {
    variant: 'flex.right',
    flexBasis: ['33%'],
  },
  topTeam: {
    width: ['66vw', '66vw', '430px'],
    height: ['66vw', '66vw', '430px'],
    borderRadius: '50%',
  },
  title: {
  },
  bottomTeam: (height: number) => ({
    position: 'absolute',
    left: ['-20%', '-10%', '50%'],
    width: ['60vw', '40vw', '200px', '320px'],
    height: ['60vw', '40vw', '200px', '320px'],
    borderRadius: '50%',
    [`@media screen and (min-width: ${themeConfig.sizes.tabletMaxWidth}) and (max-width: ${themeConfig.sizes.desktopMaxWidth})`]: {
      transform: 'translate(-50%, -20%)',
    },
    '@media screen and (max-height: 800px) and (orientation: landscape)': {
      maxWidth: '36vh',
      maxHeight: '36vh',
      transform: () => `translate(-${translateWithSize(80, height-600, 200)}px, -${translateWithSize(113, height-600, 200)}px)`,
    },
    '@media screen and (orientation: landscape) and (max-height: 600px)': {
      display: 'none',
    },
  }) as SxStyleProp,
};
