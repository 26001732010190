import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import { TriggerElementProps } from '@types';
import { __el, __tr } from '@modules';
import { scrub, withAnimation } from '@core';

interface AnimateClientsProps extends TriggerElementProps {
  iconsRef: () => React.RefObject<HTMLDivElement>;
}

export const animateClients = ({
  triggerRef, iconsRef,
}: AnimateClientsProps) => {
  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];
  const trigger = __tr(triggerRef);
  const icons = __el(iconsRef);

  if (!trigger || !icons) return;

  const containerWidth = trigger.getBoundingClientRect().width;
  const iconsWidth = icons.scrollWidth;
  const padding = (__tr(triggerRef)?.querySelector('.gatsby-image-wrapper')?.getBoundingClientRect().width || 170);
  const delta = iconsWidth - containerWidth + (padding * 0.7);

  const animation = gsap.timeline()
    .set(icons, {
      x: padding,
    })
    .to(icons, {
      x: -delta,
    });

  scrollers.push(ScrollTrigger.create({
    trigger,
    start: () => 'top bottom',
    end: () => 'bottom top',
    ...scrub(),
    animation,
    // markers: process.env.NODE_ENV === 'development',
    // invalidateOnRefresh: true,
    // onRefresh: () => animation.invalidate(),
  }));
};

export const useAnimationClients = (props: AnimateClientsProps) => withAnimation<AnimateClientsProps>(animateClients, props);
