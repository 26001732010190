import { gsap } from 'gsap';
import { __selectAll, __select, RefFuncType, __el } from '@modules';

export type AnimationFunc = (ref: RefFuncType) => gsap.core.Timeline;

export const getAnimationAdac: AnimationFunc = (ref: RefFuncType) => {
  const x = window.innerWidth > 1440 ? 100 : window.innerHeight * 0.1;
  const y = window.innerHeight > 1000 ? 40 : window.innerHeight * 0.04;
  const angle = window.innerWidth > 1440 ? 22 : window.innerWidth * (22/1440);

  return gsap
    .timeline()
    .set(__select('.shadow', ref), {
      zIndex: 1,
    })
    .set(__el(ref), {
      marginBottom: 100, // due to shadow
    })
    .to(__selectAll('.cards', ref), {
      rotate: gsap.utils.distribute({
        base: -1,
        amount: 2,
        ease: 'none',
      }),
      zIndex: (index, _item, items) => ((items?.length || 4)+1)-index,
      duration: 0,
    })
    .from(__selectAll('.cards', ref), {
      duration: 3,

    })
    .to(__selectAll('.cards', ref), {
      rotate: gsap.utils.distribute({
        base: -3-angle,
        amount: 2*angle,
        ease: 'none',
      }),
      transform: (index) => (index > 0 ? 'rotate3d(1,-1,0,30deg)' : 'rotate3d(0,0,0,0)'),
      x: gsap.utils.distribute({
        base: -1.5 * x,
        amount: x,
        // ease: 'power1.in',
        ease: 'none',
      }),
      y: gsap.utils.distribute({
        base: -1.1 * y,
        amount: y,
        // ease: 'power1.in',
        ease: 'none',
      }),
      duration: 7,
      // TODO: handle drop shadow
    })
    .from(__select('.shadow', ref), {
      opacity: 0,
      y: '-30%',
      duration: 7,
    }, '<');
};

export const getAnimationSags: AnimationFunc = (ref: RefFuncType) => {
  const left = window.innerWidth * 0.04;
  const x = window.innerHeight * 0.09;

  return gsap
    .timeline()
    .set(__select('.shadow', ref), {
      opacity: 0,
      display: 'none',
    })
    .set(__selectAll('.cards', ref), {
      transformOrigin: 'center center',
    })
    .to(__selectAll('.cards', ref), {
      x: gsap.utils.distribute({
        base: left,
        amount: left,
        ease: 'none',
      }),
      duration: 3,
    })
    .to(__selectAll('.cards', ref), {
      // delay: 3,
      duration: 7,
      x: gsap.utils.distribute({
        base: -3*x,
        amount: 4*x,
        ease: 'none',
      }),
      scale: gsap.utils.distribute({
        base: 0.88,
        amount: 0.16,
        ease: 'none',
      }),
      // TODO: handle drop shadow
    });
};

export const getAnimationTeo: AnimationFunc = (ref: RefFuncType) => {
  const leftMovement = window.innerWidth > 1500 ? 230 : 160;
  const rightMovement = 140;

  const [card1, card2] = __selectAll('.cards', ref);

  const shadow = __select('#shadow', ref);

  return gsap
    .timeline()
    .set(card1, { scale: 0.6, transformOrigin: 'bottom center' })
    .set(card2, { scale: 1.5, transformOrigin: 'center center' })
    .to(card1, { x: rightMovement })
    .to(card2, { x: -leftMovement }, '<')
    .to(shadow, { x: rightMovement, scale: 1.08 }, '<')
    .set(card1, { zIndex: 2 })
    .set(card2, { zIndex: 1 })
    .to(card1, { x: 0 })
    .to(card2, { x: -leftMovement+60 }, '<')
    .to(shadow, { x: 0, opacity: 0.7, scale: 1 }, '<');
};

export const getAnimationRv: AnimationFunc = (ref: RefFuncType) => {
  const wideScreen = window.innerWidth > 1500;

  // const upMovementMobile = wideScreen ? 120 : 100;

  // const downMovementMobile = wideScreen ? 200 : 150;
  // const downMovementLaptop = 40;

  const [card1, card2] = __selectAll('.cards', ref);
  const shadowContainer = __selectAll('.shadow', ref);

  return gsap.timeline()
    .set(shadowContainer, { display: 'none' })
    .set(card1, {
      scale: 0.6, transformOrigin: 'top left', x: () => (wideScreen?200:100), yPercent: 40,
    })
    .set(card2, { scale: 1.5, transformOrigin: 'center center', x: () => (wideScreen?-150:-100) })
    // .to(card1, { y: -upMovementMobile })
    // .to(card2, { y: downMovementLaptop }, '<')
    .set(card1, { zIndex: 2 })
    .set(card2, { zIndex: 1 })
    // .to(card1, { y: downMovementMobile, ease: 'linear' })
    // .to(card1, { x: 150, ease: 'power2.out' }, '<')
    // .to(card2, { y: 0 }, '<');
    .from(card1, { xPercent: -15 }, '<')
    .from(card2, { xPercent: 15 }, '<');
};

/* animations */

export function getAnimation(title: string): AnimationFunc {
  if (title.match(/(ADAC)|(Von)|(Null)|(Zak)/i)) { return getAnimationAdac; }
  if (title.match(/(Sag's)|(Vienn)|(Wien)/i)) { return getAnimationSags; }
  if (title.match(/(Teo)|(Lifestyle-Banking)/i)) { return getAnimationTeo; }
  if (title.match(/(Raiffeisen)|(Versicherung)/i)) { return getAnimationRv; }

  console.warn('animation function cannot be determined');
  return () => gsap.timeline();
}
