/** @jsx jsx */
import { jsx, Box, Text, Textarea, TextProps, SxProps, Button, Grid } from 'theme-ui';
import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { useAllIntlText } from '@modules';
import { ChildrenProps, OneComponentDefinitionType } from '@types';
import { EditableContext } from './EditableStore';
import { editableStyles } from './index.styles';
import { EditComponentType } from './EditorComponentType';

export interface TextLookupProps extends ChildrenProps{
}

interface EditableProps extends TextLookupProps, SxProps {
  render?: OneComponentDefinitionType,
  edit?: EditComponentType,
}

const Span = forwardRef<HTMLDivElement, TextProps>(({ children, ...props }, ref) => <Text as="span" {...props} ref={ref}>{children}</Text>);

export const Editable = forwardRef<HTMLDivElement, EditableProps>(({
  children,
  render: Render = Span,
  edit: Edit = Textarea,
  ...props
}, ref) => {
  const [enabled, setEnabled] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const origRef = useRef<HTMLDivElement>(null);
  const [origElementProps, setOrigElementProps] = useState({} as React.CSSProperties);
  const [tempText, setTempText] = useState(null as (string | null));
  const messages = useAllIntlText();

  useEffect(() => {
    if (inputRef && inputRef.current && isEditing === true) {
      inputRef.current.focus();
    }
  }, [isEditing, inputRef]);

  useEffect(() => {
    setEnabled(/* process.env.NODE_ENV === 'development' && */ `${window?.location?.href}`.match(/inline-edit/) !== null);
  }, []);

  if (enabled && typeof children === 'string') {
    const origText = children;
    const message = Object.entries(messages)
      .find(([, value]) => children === value);
    const messageId = message && message[0];

    const {
      texts, updateText, cancelText,
      // persistAll,
    } = React.useContext(EditableContext);
    const renderText = tempText || (messageId && texts[messageId]) || origText;

    // If we cannot detect the origin, it doesn't make sense to offer the editing features.
    if (!messageId) return <React.Fragment>{children}</React.Fragment>;

    const hasChanged = renderText !== (children);

    const startEditing = () => {
      setTempText(renderText);
      setEditing(true);
      setOrigElementProps(JSON.parse(JSON.stringify(origRef?.current?.getBoundingClientRect())));
    };

    const preview = () => {
      if (updateText) updateText({ key: messageId, value: `${tempText}` });
      setTempText(null);
      setEditing(false);
    };
    // const save = () => {
    //   preview();
    //   // TODO: it should be an async action, waiting for if (updateText) updateText!
    //   persistAll();
    // };
    const cancel = () => {
      setTempText(null);
      if (cancelText) cancelText(messageId);
      setEditing(false);
    };

    const onKeyDown = ({ key }: {key: string}) => {
      // TODO: prepare for Shift or Ctrl + Enter for save?
      switch (key) {
        case 'Enter': preview();
          break;
        case 'Escape': cancel();
          break;
        default:
      }
    };

    return (
      <Box ref={ref}>
        {isEditing && (
          <Grid sx={editableStyles.editContainer(origElementProps)}>
            <Edit
              {...props}
              ref={inputRef}
              value={`${tempText}`}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setTempText(e.target.value)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(e: any) => onKeyDown(e)}
              sx={{ ...props.sx, ...editableStyles.editing(origElementProps), ...editableStyles.changed(hasChanged) }}
              // THIS is buggy, due to local setTempText() actioned asyn & slow
              // onBlur={() => setTimeout(() => { if (isEditing) cancel(); }, 200)}
            />
            <Button sx={editableStyles.button} onClick={() => preview()}>Preview</Button>
            {/* <Button sx={editableStyles.button} onClick={() => save()}>SAVE</Button> */}
            <Button sx={editableStyles.button} onClick={() => cancel()}>Cancel</Button>
            <Box sx={editableStyles.hint}>Keyboard Hint: <wbr />Enter = Save, <wbr />Escape = Cancel </Box>
          </Grid>
        )}
        {!isEditing && (
          <Box onClick={() => startEditing()} ref={origRef}>
            <Render {...props} sx={{ ...props.sx, ...editableStyles.editable, ...editableStyles.changed(hasChanged) }}>
              {renderText}
            </Render>
          </Box>
        )}
      </Box>
    );
  } if (typeof children === 'string') {
    return (
      <Render {...props}>
        {children}
      </Render>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
});
