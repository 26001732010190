import React, { FormEvent, useEffect } from 'react';
import { Grid, SxProps } from 'theme-ui';
import { AxiosResponse } from 'axios';
import { Paragraph } from '@themed';
import {
  Content,
  findFirstContentWithImage,
  findFirstContentWithTabbedImageList,
  PageMeta,
} from '@types';
import { __, useAsyncAction, useCookie } from '@modules';
import { CTAWithOptionalLink, ImageLoader } from '@core';
import { saveSubscription } from 'src/modules/networking/digital-experience/saveFormData';
import { StudyNewsLetterContent } from '../NewsLetterContent';
import { NewsLetterSignup } from '../NewsLetterSignup';
import { getTextFromContent } from '../chart-generator/components/Guide';
import LoadingPacman from '../chart-generator/LoadingPacman';
import { cmsTextStyles, newsletterStyles, styles } from './PDFForm.styles';

export interface ContentMapperProps extends SxProps {
  content: Content | undefined;
}

export const ContentMapper = ({ content, ...props }: ContentMapperProps) => (
  <Paragraph {...props} sx={{ ...props.sx }} id="text">
    {getTextFromContent(content)}
  </Paragraph>
);

export interface PageMetaProps {
  content: PageMeta | undefined;
}

const useSubscribed = () => {
  const { value, setValue } = useCookie('study-subscription');
  return {
    // TODO: enable cookie based PDF link display in case we would like to launch the report again:
    value: value || true,
    setValue,
  };
};

export const PDFForm = ({ content }: PageMetaProps) => {
  const [newsletterID, setNewsletterID] = React.useState('digital-experience.newsletter');

  const { value: isSubscribed, setValue: setIsSubscribed } = useSubscribed();

  const [formData, setFormData] = React.useState<any>(undefined);

  const downloadPdf = findFirstContentWithImage(content?.content)?.image;
  const donwloadLinks = findFirstContentWithTabbedImageList(content?.content)?.tabbedImages;
  const teaserImage = donwloadLinks?.[0].image;

  const asyncResponse = useAsyncAction(() => saveSubscription(formData), [formData]);
  const { resource, error } = asyncResponse;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const [firstName, lastName, email, privacy] = e.target as any;
    setFormData({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      privacy: privacy.checked,
      source: 'study20231130',
    });
  };

  useEffect(() => {
    const base = 'digital-experience.newsletter';
    if (teaserImage) setNewsletterID(`${base}-subscribed`);
    if (resource && resource.status === 200) {
      // setNewsletterID(`${base}-subscribed-successfully`);
      setIsSubscribed('true');
    }
    if (error || (resource && resource.status !== 200)) setNewsletterID(`${base}-subscribed-unsuccessfully`);
  }, [asyncResponse]);

  const newsLetterSignupStyles = {
    ...newsletterStyles(true, '#002b3c'),
    ...newsletterStyles(true).newsLetterParagraphStyles(!resource && !error),
  };

  const NewsLetterSignupIcon = isSubscribed && teaserImage?.url && (
    <ImageLoader
      src={teaserImage.url}
      alt={__(teaserImage.alternativeText, true)}
      sx={{ my: 4 }}
    />
  );

  return (
    <Grid id="digital-experience-study" sx={styles()}>
      <ContentMapper sx={cmsTextStyles} content={content?.content?.[0]} />
      <NewsLetterSignup
        id={newsletterID}
        sx={newsLetterSignupStyles}
        onSubmit={onSubmit}
        Icon={NewsLetterSignupIcon || undefined}
      >
        {isSubscribed
          ? <CTAWithOptionalLink to={downloadPdf?.url}>{__('digital-experience.newsletter-subscribed.button')}</CTAWithOptionalLink>
          : <NewsletterContent {...asyncResponse} />}
      </NewsLetterSignup>
    </Grid>
  );
};

interface NewsletterContentProps {
  isLoading: boolean;
  resource: AxiosResponse<any, any> | null;
  error?: any;
}

const NewsletterContent = ({ isLoading, resource, error }:NewsletterContentProps) => (
  <React.Fragment>
    {isLoading && <LoadingPacman sx={newsletterStyles().loading} />}
    {!error && !resource && !isLoading && <StudyNewsLetterContent id="digital-experience.newsletter" />}
  </React.Fragment>
);
