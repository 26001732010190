/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';
import React from 'react';
import { VscGlobe } from 'react-icons/vsc';
import { Children, Country, SxStyles } from '@types';
import { DefaultDropDownHeader, SearchList, DropDownWithContext, DropDownContextProvider, DropDownContext } from '@core';
import { themeConfig as theme } from '@themed';
import { useTranslate, SelectedCountryContext, convertCountriesToListItems } from '@modules';
import { CountryListItem } from '../finnoscore-data/List';

const styles: SxStyles = {
  dropDown: {
    width: ['100%', '100%', '400px'],
    height: 'max-content',
    boxShadow: ['none', 'none', '0 0 6px 0 rgba(0, 0, 0, 0.1)'],
    zIndex: 1,
  },
};

interface Props extends SxProps {
  countries: Country[]
  colorPickerComponent?: Children
}

const _CountrySelector = ({ countries, colorPickerComponent, ...props }: Props) => {
  const { country, setCountry } = React.useContext(SelectedCountryContext);
  const { setIsOpen } = React.useContext(DropDownContext);
  const __ = useTranslate();
  const countryListItems = convertCountriesToListItems(countries);

  const onListItemSelected = (countryCode: string) => {
    const foundCountry = countryListItems.find(c => c.id === countryCode);
    if (foundCountry) {
      setCountry({ name: foundCountry.text, countryCode: foundCountry.id });
      setIsOpen(false);
    }
  };

  return (
    <DropDownWithContext
      {...props}
      sx={{ ...styles.dropDown, ...props.sx }}
      header={<DefaultDropDownHeader
        title={country.name || __('finnoscore.allCountries')}
        leftComponent={colorPickerComponent || <VscGlobe style={{
          width: '20px',
          height: '20px',
          color: theme.colors.primary,
        }}
        />}
      />}
    >
      <SearchList
        listItems={countryListItems}
        onSelect={onListItemSelected}
        selected={country.countryCode}
        ListItemComponent={CountryListItem}
      />
    </DropDownWithContext>
  );
};

export const CountrySelector = (props: Props) => <DropDownContextProvider>
  <_CountrySelector {...props} />
</DropDownContextProvider>;
