import { Flex, Grid, Input } from 'theme-ui';
import { Slider } from '@material-ui/core';
import React from 'react';
import { useColors } from '@composite';
import { DimensionKey } from '@types';
import { chartRowStyles, labelStyles, sliderStyles } from './ComparisonChartStyles';

interface CompetitorsChartProps{
  dimensionValues: number[]
  keys: DimensionKey[]
}

interface ChartRowProps {
  value: number;
  valueChangeHandler: (newValue: number | number[], i: number) => void
  index: number;
}

const Bar = ({ value, valueChangeHandler, index }:ChartRowProps) => {
  const [colors] = useColors();
  const valuetext = (v: number) => `${v}`;

  return (
    <Flex className="chart" sx={sliderStyles(colors[index])}>
      <Slider
        value={value}
        onChange={(_, newValue) => valueChangeHandler(+newValue, index)}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-small-steps"
        step={0.01}
        min={0}
        max={10}
        valueLabelDisplay="auto"
      />
    </Flex>
  );
};

const ChartRow = ({ value, valueChangeHandler, index }: ChartRowProps) => (
  <Grid sx={chartRowStyles}>
    <Input min={0} max={10} type="number" onChange={e => valueChangeHandler(+e.target.value, index)} value={value.toFixed(2)} sx={labelStyles} />
    <Bar value={value} valueChangeHandler={valueChangeHandler} index={index} />
  </Grid>
);

export const ComparisonChart = ({ dimensionValues, keys }: CompetitorsChartProps) => {
  const [values, setValues] = React.useState<number[]>(dimensionValues);

  const valueChangeHandler=(newValue:number | number[], index:number) => {
    const newValues = values?.map((value:number, i:number) => (i!==index?value:+newValue));
    setValues(newValues || null);
  };
  return (
    <Flex variant="flex.colCenter" sx={{ width: '100%' }}>
      {values.map((v, i) => (
        <ChartRow value={v} key={keys[i]} index={i} valueChangeHandler={valueChangeHandler} />
      ))}
    </Flex>
  );
};
