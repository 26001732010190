/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Flex, SubTitle, HTML5VideoPlayer, Image } from '@core';
import { useLocale } from '@modules';
import weDoGif from '../../../images/aboutus/we-do.gif';
import weDoEnGif from '../../../images/aboutus/we-do-en.gif';
import weDoFrGif from '../../../images/aboutus/we-do-fr.gif';
import { styles } from './Video.styles';

export default () => {
  const locale = useLocale();

  const image = (() => {
    // NOTE: ImageLoader + allImageSharp is not supporting GIFs :(
    switch (locale) {
      case 'fr': return weDoFrGif;
      case 'en': return weDoEnGif;
      default: return weDoGif;
    }
  })();
  return (
    <Flex sx={styles.videoContainer}>
      <HTML5VideoPlayer sx={styles.videoPlayer} src="https://media.finnoconsult.at/videos/finno_tick_tock.mp4" />
      <SubTitle sx={{ ...styles.motto }}>
        <Image sx={styles.image} src={image} alt="we do" />
        <del>Man könnte</del>
        <del>Man sollte</del>
        <del>Man müsste</del>
        <ins>Wir machen!</ins>
      </SubTitle>
    </Flex>
  );
};
