import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import { ChildrenProps } from '@types';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface SnackBarProps extends ChildrenProps {
  open: boolean;
  handleClose: () => void;
  type: 'success' | 'warning' | 'error' | 'info'
}

export const SnackBar = ({ open, handleClose, children, type }: SnackBarProps) => (
  <div>
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {children}
      </Alert>
    </Snackbar>
  </div>
);
