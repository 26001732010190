export const formStyles = {
  variant: 'flex.col',
  aligntItems: 'center',
  width: '28rem',
  minHeight: 'calc(100vh - 80px - 36px - 1px) !important',
  gap: 5,
  gridTemplateRows: '0.2fr 1fr 0.2fr',
  placeItems: 'start center',
  svg: {
    mx: 'auto',
  },
  form: {
    py: 7,
    px: 6,
    width: '100%',
    borderTop: '2px solid #007eff',
    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px;',
    '&>*': {
      my: 4,
      fontSize: ['xxs'],
      display: 'flex',
      flexDirection: 'column',
    },
    '.rememberMe': {
      flexDirection: 'row',
    },
    input: {
      border: '1px solid #eef1f7',
      borderRadius: 0,
      p: 3,
      mt: 4,
      '&:focus': {
        outline: '1px solid #78caff',
      },
    },
    button: {
      flexDirection: 'row',
      justifyContent: 'center',
      variant: 'buttons.pure',
      textAlign: 'center',
      width: '100%',
      color: 'background',
      bg: '#007eff',
      borderRadius: 0,
      textTransform: 'uppercase',
      py: 3,
    },
  },
  a: {
    mx: 'auto',
    fontWeight: 'normal',
    fontSize: 'xxs',
    color: 'black',
  },
};
