import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Grid, Text } from 'theme-ui';
import { SectionWithContent } from '@core';
import { useLocale } from '@modules';

import Logo from '../../../../images/icons/logo/Logo';
import { EmailSent } from './EmailSent';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginForm } from './LoginForm';
import { formStyles } from './ForgotPasswordForm.styles';

export const Login = () => {
  const locale = useLocale();

  return (
    <SectionWithContent>
      <Grid sx={formStyles}>
        <Logo />
        <LoginForm sx={formStyles.form} />
        <Link to={`/${locale}/charts/forgotpassword`}><Text>Forgot password</Text></Link>
      </Grid>
    </SectionWithContent>
  );
};

export const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const locale = useLocale();

  return (
    <SectionWithContent>
      <Grid sx={formStyles}>
        <Logo />
        {emailSent ? (
          <EmailSent locale={locale} sx={formStyles.form} />
        ):(
          <ForgotPasswordForm locale={locale} emailSentSetter={setEmailSent} />
        )}

      </Grid>
    </SectionWithContent>
  );
};
