/** @jsx jsx */
import { jsx, Card, Heading, Text, Button } from '@themed';
import { Link, ParallaxImage } from '@core';
import { useTranslate } from '@modules';
import { Image } from '@types';
import { styles } from './BlogCard.styles';

interface BlogCardProps {
  title?: string;
  paragraph?: string;
  date: string;
  link: string;
  image?: Image;
}

// TODO: implement again with:
// <CompactSectionContentStyles sx={styles.caseItem}>
//                   <CompactSectionContent {...caseData} />
//                 </CompactSectionContentStyles>
export const BlogCard = ({
  title, image, paragraph, link, date,
}: BlogCardProps) => {
  const __ = useTranslate();
  return (
    <Card data-cy="blog-card">
      <Link to={link} sx={styles.link}>
        {/* TODO: Implements links to details page when blog posts comes from strapi */}
        <Heading variant="h2" as="h2" sx={styles.heading} data-cy="blog-card-title">
          {title}
        </Heading>
        {image && (
          // TODO: handle multiple images, like for References
          <ParallaxImage
            // TODO: resolve this interim fix from gatsby to keep the same size for like image
            resize={link.match(/(what-the-finno-score-tells-us-how-do-good-banks-communicate-with-their-customers-online)|(aus-dem-finnoscore-lernen)/) === null}
            src={image?.url}
            alt={image?.alternativeText}
            sx={styles.image}
          />
        )}
        <Text sx={styles.text} data-cy="blog-card-paragraph">
          {paragraph}
        </Text>
        <Text sx={styles.date} data-cy="blog-card-date">
          {date}
        </Text>
        <Button variant="cta" data-cy="blog-card-button">
          {__('common.readArticle', true)}
        </Button>
      </Link>
    </Card>
  );
};
