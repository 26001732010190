export const styles = {
  link: {
    variant: 'links.undercover',
  },
  heading: {
    mb: 4,
  },
  image: {
    width: '100%',
    height: 'giantIcon',
    '.parallax.parallax-resize': {
      transform: 'scale(1.2)',
    },
  },
  text: {
    mt: 4,
    fontSize: ['xs', 's'],
    fontWeight: 'normal',
  },
  date: {
    fontSize: 'xxs',
    fontWeight: 'normal',
    pt: 3,
    pb: 5,
  },
};
