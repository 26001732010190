export const styles = {
  section: {
    variant: 'grids.CompactSectionContentPadding',
    bg: 'primary',
  },
  title: {
    color: 'background',
    mr: ['auto'],
    mb: 8,
    maxWidth: ['unset', 'unset', 'unset', '60%'],
  },
  list: {
    width: 'fullSize',
    gap: [0, 0, 8, 8],
    gridTemplateColumns: ['1fr', '1fr', 'repeat(3, 1fr)'],
    span: {
      fontWeight: 'bold',
      fontSize: 's',
    },
    b: {
      color: 'background',
    },
    figure: {
      variant: 'images.pureCircleIcon',
    },
    '.listItem': {
      display: ['block', 'flex', 'block', 'block'],
    },
  },
};
