import React from 'react';
import { CompactSection, ParallaxImage } from '@core';
import { useTranslate } from '@modules';
import { useAnimationList } from './animateList';
import { styles } from './Implementation.styles';

export const Implementation = () => {
  const ref = React.useRef(null);
  useAnimationList({ triggerRef: () => ref });
  const __ = useTranslate();

  return (
    <CompactSection id="rapid.implementation" sx={styles.section} ref={ref}>
      <ParallaxImage src="running-track.jpg" alt={__('pages.rapid.implementation.parallaxImgAlt')} sx={styles.image} />
    </CompactSection>

  );
};
