import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    mt: 8,
  },
  title: {
    fontWeight: 'bold',
    pb: 3,
  },
  section: {
    pt: 5,
  },
  buttonList: {
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  } as SxStyleProp,
};
