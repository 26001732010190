import { themeConfig } from '@themed';

export const styles = {
  background: {
    '&:before': {
      backgroundSize: ['auto 75%', 'auto 120%'],
      backgroundPosition: ['top', 'center'],
    },
    '&:after': {
      backgroundSize: ['auto 75%', 'auto 120%'],
      backgroundPosition: ['top', 'center'],
    },
  },
  iconList: {
    variant: ['flex.colRight', 'flex.colRight', 'flex.colAlternate'],
    justifyContent: ['space-around', 'space-around', 'center'],
    [`@media screen and (max-width: ${themeConfig.sizes.tabletMaxWidth})`]: {
      alignSelf: 'flex-end',
      ml: 6,
    },
    '@media screen and (max-height: 600px)': {
      transform: 'scale(0.7)',
    },
  },
  icon: {
    variant: 'images.circleIcon',
    boxShadow: 'none !important',
    maxWidth: 'unset !important',
  },
  iconShrink: {
    variant: 'images.circleIcon',
    boxShadow: 'none !important',
    maxWidth: 'unset !important',
    // Gatsby-image loader hack
    picture: {
      img: {
        transform: 'scale(0.8)',
        transformOrigin: 'top center',
      },
    },
  },
  sectionContentBox: {
    height: ['auto', 'browserHeight'],
    justifyContent: ['flex-end', 'flex-end', 'center'],
    'span::selection': {
      bg: 'secondary',
    },
  },
  sectionContent: {
    // p: [0, 0].concat(compactSectionContentPadding.slice(2)),
    // TODO: the above throws an error when running tests, saying compactSectionContentPadding is undefined
    p: [0, 0, 4, 0],
    // minHeight: ['auto'],
    '>div:first-of-type': {
      // display: ['none', 'block'],
      // minHeight: ['50vh', '50vh', 'unset'],
      justifyContent: ['flex-start', 'flex-start', 'center'],
      bg: ['primary', 'primary', 'transparent'],
      p: [4, 4, 0],
      pt: [6, 6, 0],
    },
    h2: {
      color: 'background',
      maxWidth: ['unset', 'unset', '33vw', '470px'],
      // textShadow: ['none', '#000 0px 4px 4px', '#000 0px 4px 4px', 'none'],
      // background: 'linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 10%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0) 100%)',
    },
    // 'p, .paragraph>*': {
    //   background: ['none', 'linear-gradient(180deg,rgba(255,255,255,0.01) 0%,rgba(255,255,255,0.3) 100%)', 'linear-gradient(180deg,rgba(255,255,255,0.01) 0%,rgba(255,255,255,0.3) 100%)', 'none'],
    // },
    'p, .paragraph': {
      color: 'background',
      maxWidth: ['unset', 'unset', '35vw', '470px'],
      // color: ['background', 'inherit'], // this looks ugly with the desktop as overlaps bg image white
      // textShadow: '#000 0px 1px 1px',
    },
    'button, a': {
      '&, & *': {
        color: ['primary', 'primary', '#009BDE'],
      },
    },
  },
};
