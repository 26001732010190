/** @jsx jsx */
import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { jsx, Box, Flex } from '@themed';
import { LanguageSelector, FloatingIconButton, DesktopNavbar } from '@core';
import { useNavbarItems, useLogoWithLink, useLocation, useTranslate } from '@modules';
import { Children } from '@types';
import { isFinnoChartsPath } from '@config';
import { authState } from '../../store/AuthState';
import { animateHeaderToggle } from './animateHeaderToggle';
import { FloatingBackButton } from './common/FloatingBackButton';
import { SignOut as ChartSignOut } from './chart-generator/auth/SignOut';
import { styles } from './DesktopHeader.styles';

export interface DesktopHeaderProps {
  invertedHeader?: boolean;
  subLogo?: Children;
}

export const DesktopHeader = ({ invertedHeader }: DesktopHeaderProps) => {
  const navbarItems = useNavbarItems(false);
  const __ = useTranslate();

  const Logo = useLogoWithLink(invertedHeader ? 'inverted' : undefined);
  const location = useLocation();
  const [user] = useRecoilState(authState);

  const triggerRef = React.useRef(null);
  const defaultStateRef = React.useRef(null);
  const hiddenStateRef = React.useRef(null);
  const [menuAnim, setMenuAnim] = React.useState(null as unknown as () => gsap.core.Timeline);

  React.useEffect(() => setMenuAnim(animateHeaderToggle({
    triggerRef: () => triggerRef,
    defaultStateRef: () => defaultStateRef,
    hiddenStateRef: () => hiddenStateRef,
  })), []);

  const handleMenuButtonClick = () => {
    if (menuAnim) menuAnim.reverse();
  };

  const boxStyles = invertedHeader ? { ...styles.layout, ...styles.invertedStyles } : { ...styles.layout, ...styles.defaultStyles };

  return (
    <Box sx={styles.display} ref={triggerRef} id="desktop-header">
      <Box sx={boxStyles} ref={defaultStateRef}>
        <Box sx={styles.content} data-gsap-id="header-width">
          <Flex sx={styles.menubar}>
            <Logo sx={styles.logo} />
            <Flex sx={styles.navbar} id="desktop-menu">
              <DesktopNavbar items={navbarItems} />
            </Flex>
            {location && isFinnoChartsPath(location) && user.token && user.user
              &&<Box>
                <ChartSignOut />
              </Box>}
            <Box>
              <LanguageSelector />
            </Box>
          </Flex>
          <FloatingBackButton />
        </Box>
      </Box>
      <Box sx={styles.hiddenState} ref={hiddenStateRef} id="desktop-header-show">
        <FloatingIconButton icon={FaArrowUp} title={__('navbar.menu')} onClick={() => handleMenuButtonClick()} />
      </Box>
    </Box>
  );
};
