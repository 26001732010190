import { useStaticQuery, graphql } from 'gatsby';
import { MetaQuery, PageMeta } from '@types';
import { useLocale } from '@modules';

// interface PageContent {
//   pageId: string;
//   title: string;
//   description: string;
//   language: {
//     code: string;
//   }
//   content?: Content[];
// }

export function useStrapiPageContent(pageId: string): PageMeta | undefined {
  const locale = useLocale();
  const { allPageMeta } = useStaticQuery<MetaQuery>(graphql`
    query {
      allPageMeta {
        edges {
          node {
            pageId
            title
            description
            language {
              code
            }
            content {
              text
              images{
                url
                alternativeText
              }
              image{
                url
                alternativeText
              }
              tabbedImages {
                image {
                  url
                  alternativeText
                }
                title
              }
              video {
                headline
                youtubeUrl
              }
              button {
                link
                title
              }
            }
          }
        }
      }
    }
  `);
  const staticPage = allPageMeta.edges.find(({ node }) => node.pageId === pageId && node.language.code === locale);
  return staticPage?.node;
}
