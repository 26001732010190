export const postStyles = {
  pageWrapper: {
    minHeight: '100vh',
    width: '100%',
    maxWidth: ['maxBlogContentWidthMobile', 'maxBlogContentWidthMobile', 'maxBlogContentWidthDesktop'],
    paddingTop: [8, 8, '160px'],
    paddingBottom: [4, 4, 5],
    mx: 'auto',
  },
  contactButton: {
    my: 4,
  },
};
