import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { SelectedCountryContextProvider } from '@modules';
import { CountryDropdownList } from '@types';
import InternationalChart from '../charts/InternationalChart';

function InternationalComparisonChartWrapper() {
  const countries = useStaticQuery<CountryDropdownList>(graphql`
  query {
      allCountry(filter: {language: {eq: "en"}}) {
      edges {
        node {
          id
          text:name
          flag {
            alternativeText
            url
          }
          countryCode
        }
      }
    }
  }
`);

  const mappedCountries = countries.allCountry.edges.flatMap((val) => ([
    {
      ...val.node,
      countryCode: `${val.node.countryCode}_${10}`,
      id: `${val.node.text}_10`,
      name: `${val.node.text} top 10`,
    },
    {
      ...val.node,
      countryCode: `${val.node.countryCode}_${5}`,
      id: `${val.node.text}_5`,
      flag: undefined,
      name: `${val.node.text} top 5`,
    }]));

  return (
    <SelectedCountryContextProvider defaultValue={mappedCountries[0]}>
      <InternationalChart countries={mappedCountries} />
    </SelectedCountryContextProvider>
  );
}

export default InternationalComparisonChartWrapper;
