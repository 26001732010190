import { themeConfig } from '@themed';

export const styles = {
  container: {
    width: 'fullSize',
    [`@media screen and (min-width: ${themeConfig.sizes.tabletMaxWidth})`]: {
      '.newsletter-content': {
        pt: 4,
        '&>[name=mail]': {
          mr: 4,
          mb: 3,
          width: 'pacmanFoodSpacing',
          height: 'pacmanFoodSpacing',
          padding: 5,
          float: 'left',
        },
        h2: {
          mt: 4,
        },
        form: {
          display: 'grid',
          gridTemplateAreas: '"input input" "languages button"',
          gridTemplateColumns: '2fr 1fr',
          mb: 4,

          input: {
            gridArea: 'input',
          },
          '.select-languages': {
            gridArea: 'languages',
            variant: 'flex.center',
            justifyContent: 'space-between',
            m: '0 !important',
            height: 'fullSize',
          },
          button: {
            gridArea: 'button',
            ml: 'auto !important',
          },
        },
      },
      // display: ['block', 'block', 'grid'],
      // gridTemplateAreas: [undefined, undefined, '"icon title" "']
    },
  },
};
