import themeConfig from '../../../gatsby-plugin-theme-ui';

export * from './ThemedButtons';
export * from './ThemedTexts';
export * from './ThemedIcon';
export * from './ThemedVideo';

export * from 'theme-ui';

// themeConfig to the world
export { default as themeConfig } from '../../../gatsby-plugin-theme-ui';

export const compactSectionContentPadding = themeConfig.compactSectionContentPadding;

export * from './ThemedHelper';
