import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const styles = {
  container: (length: number) => ({
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: [
      `90px 190px ${length * 1.4}vw 160px`,
      `8vw 220px ${length * 1.1}vw 1fr`,
      `10vw 170px ${length * 1}vw 15.5vw`,
      `8vw 15.5vw ${length * 1}vw 15.5vw`,
      '110px 200px 160px 1fr',
    ],
    pl: ['40vw', 7, 0, '10vw', 0],
    gridTemplateAreas: '"best empty1 average wheel"',
    gridTemplateRows: '30px',
    placeContent: 'flex-end',
    alignItems: 'center',
    justifyItems: 'flex-start',
    borderRadius: '50%',
  }) as SxStyleProp,

  // circles
  dimension: (length: number) => ({
    pl: [`${length * 2}vw`, `${length * 1.3}vw`, `${length * 1.1}vw`, `${length * 1.1}vw`, '170px'],
    gridArea: 'wheel',
  }),
  text: {
    width: ['125px', '150px', '160px', '180px'],
    height: ['125px', '150px', '160px', '180px'],
    bg: 'grey.3',
    borderRadius: 'fullSize',
    border: 'solid 6px white',
    variant: 'flex.center',
    textAlign: 'center',
    fontSize: ['xxxs', 'xxs'],
    flex: '0 0 auto',
    p: 3,
  },

  // numerics
  best: {
    whiteSpace: ['break-spaces', 'nowrap'],
    gridArea: 'best',
    display: 'grid',
    fontWeight: 'bold',
    placeContent: ['flex-start', 'flex-end'],
    '[aria-label^=strong]': {
      whiteSpace: ['break-spaces', 'nowrap'],
      width: ['85px', 'auto'],
    },
    '[aria-label=best]': {
      color: 'primary',
      fontSize: ['40px'],
    },
    [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
      marginLeft: '15px',
    },
  },
  average: {
    whiteSpace: ['default', 'nowrap'],
    gridArea: 'average',
    display: 'grid',
    fontWeight: 'bold',
    '[aria-label=average]': {
      fontSize: ['40px'],
    },
    [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
      marginLeft: '-15px',
    },
  },
};
