import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const developmentReviewStyles = {
  [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const developmentTextStyles = {
  '#highlighted-subtitle': {
    color: 'primary',
  },
  h2: {
    fontSize: ['l', 'l', 'xl'],
  },
  px: [5, 5, 0],
  p: {
    my: 4,
  },
};
export const developmentReferenceStyles = {
  position: 'relative',
  width: '100%',
} as SxStyleProp;
