/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { CompactSectionContent, CompactSectionContentStyles, PureSection, SimpleSectionContent } from '@core';
import { useIsBankMiddleEastType, useSelectedFsType } from '@modules';
import { FinnoscoreBanner } from '../banners/FinnoscoreBanner';
import { SwitchInstitutionType } from './SwitchBankOrOnsurance';
import { DimensionsCircle } from './DimensionsCircle';
import { styles } from './Init.styles';

export const Init = () => {
  const [type] = useSelectedFsType();
  const isBankMiddleEastType = useIsBankMiddleEastType();
  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <PureSection sx={styles.section} ref={sectionRef}>
      <FinnoscoreBanner />
      <SimpleSectionContent sx={styles.wrapper}>
        <CompactSectionContentStyles sx={styles.content}>
          <CompactSectionContent
            data-cy="compact-section-left-side"
            titleAs="h1"
            id={`finnoscore.init.${type}`}
            contentTop={isBankMiddleEastType ? undefined : <SwitchInstitutionType />}
          >
            <DimensionsCircle triggerRef={() => sectionRef} />
          </CompactSectionContent>
        </CompactSectionContentStyles>
      </SimpleSectionContent>
    </PureSection>
  );
};
