import { SxStyleProp } from 'theme-ui';
import { Direction } from '@types';

export const arrowStyles = {
  box: (direction: Direction): SxStyleProp => ({
    zIndex: 1,
    backgroundColor: 'primary',
    width: '8',
    position: 'absolute',
    height: '8',
    borderRadius: '50%',
    top: '50%',
    left: direction === 'left' ? 3 : 'auto',
    right: direction === 'right' ? 3 : 'auto',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  }),
  icon: (direction: Direction): SxStyleProp => ({
    transform: direction === 'left' ? 'scaleX(-1)' : 'scaleX(1)',
  }),
};

export const styles = {
  box: {
    ul: {
      display: 'flex',
      alignItems: 'center',
      li: {
        background: 'transparent !important',
        div: {
          background: 'transparent !important',
        },
      },
    },
  },
};
