import { SxStyleProp } from 'theme-ui';

export const getNestedNavItemsStyles = (isOpen: boolean) => {
  const frameBorder = 1;
  const navBorder = 3;
  const frameTopPadding = 6;
  const frameLeftPadding = 12;

  return {
    transition: 'all 0.2s ease-out',
    display: 'block',
    overflow: 'hidden',

    position: 'absolute',
    top: `-${frameBorder + navBorder + frameTopPadding + 1}px`,
    left: `-${frameLeftPadding}px`,
    py: `${frameTopPadding}px`,
    px: `${frameLeftPadding}px`,
    lineHeight: 3,
    borderRadius: 4,

    ...(isOpen ? ({
      zIndex: 1,
      minWidth: 'max-content',
      width: 'max-content',
      height: 'max-content',
      bg: 'background',
      boxShadow: t => `0 0 4px ${t.colors.grey[2]}`,
      opacity: 1,
    }) : {
      minWidth: 'unset',
      width: '80px',
      height: '2rem',
      opacity: 0,
    }),
  } as SxStyleProp;
};
