import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';

export const chartRowStyles = {
  width: '100%',
  height: '12px',
  my: 2,
  gridArea: 'chart',
  display: 'grid',
  gridTemplateColumns: ['70px 1fr'],
  gridTemplateAreas: ["'label chartItem'"],
};

export const labelStyles: SxStyleProp = {
  fontSize: ['10px', '14px', '14px'],
  border: 'none',
  textAlign: 'right',
  '&:focus': {
    outline: 'none',
  },
};

export const sliderStyles = (color?: RGBColor, defaultThemeColor = 'primary') => ({
  width: '100%',
  '.MuiSlider-root': {
    position: 'relative',
    height: '10px',
    color: '#00bd59',
    '&:hover *': {
      visibility: 'visible',
    },
  },
  '.MuiSlider-thumb': {
    visibility: 'hidden',
    height: 15,
    width: 15,
    backgroundColor: 'background',
    border: `2px solid ${color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`: defaultThemeColor}`,
    marginTop: '-3px',
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  '.MuiSlider-valueLabel': {
    left: 'calc(-50% + 4px)',
    color: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`: defaultThemeColor,
  },
  '.MuiSlider-track': {
    height: '10px',
    borderRadius: '999px',
    backgroundColor: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`: defaultThemeColor,
  },

  '.MuiSlider-rail': {
    backgroundColor: 'gray',
    height: '10px',
    borderRadius: '999px',
  },
});
