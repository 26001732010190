import * as React from "react"

const SvgInsurance = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    viewBox="0 0 60 60"
    {...props}
  >
    <rect width={60} height={60} fill="#000" />
      <path
        fill="#fff"
        d="M44.6 29.898c-1.473-6.144-7.16-9.953-13.356-10.41v-1.27c0-.66-.559-1.218-1.219-1.218-.71 0-1.218.559-1.218 1.219v1.27c-6.196.456-11.883 4.214-13.407 10.41-.304 1.218 1.168 2.234 2.235 1.32v-.051c2.539-2.336 4.57-1.422 6.398 1.422.559.914 1.777.863 2.285 0 .711-1.067 1.422-2.031 2.488-2.488v9.242c0 .71-.558 1.218-1.218 1.218-.559 0-1.016-.304-1.168-.812-.254-.61-.914-.965-1.574-.71-.61.202-.965.913-.711 1.523.507 1.472 1.879 2.437 3.453 2.437 1.98 0 3.656-1.625 3.656-3.656v-9.242c1.27.558 1.93 1.675 2.438 2.488.558.863 1.726.914 2.285 0 1.879-2.844 3.91-3.707 6.398-1.422v.05c1.066.915 2.54-.1 2.234-1.32ZM34.85 30c-1.22-1.523-2.844-2.59-4.825-2.59-2.031 0-3.554.965-4.824 2.54-1.828-2.235-4.215-2.895-6.246-2.337 2.285-3.758 6.703-5.738 11.07-5.738 4.317 0 8.785 1.98 11.02 5.738-2.438-.71-4.52.407-6.196 2.387Z"
      />
  </svg>
)
export default SvgInsurance
