import React from 'react';
import { useTranslate } from '@modules';

interface DataContextProps {
  path: string;
  setPath: React.Dispatch<React.SetStateAction<string>>;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
}

export const DataPrivacyContext = React.createContext<DataContextProps>({
  path: '',
  setPath: () => {},
  text: '',
  setText: () => {},
});

export const useDataPrivacy = () => {
  const { path, setPath, text, setText } = React.useContext(DataPrivacyContext);
  return { path, setPath, text, setText };
};

interface DataProviderProps {
  children: React.ReactNode;
}

export const DataPrivacyProvider: React.FC<DataProviderProps> = ({ children }: DataProviderProps) => {
  const __ = useTranslate();

  const [path, setPath] = React.useState('/data-privacy');
  const [text, setText] = React.useState(__('navbar.dataprivacy'));

  const contextValue = { path, setPath, text, setText };

  return <DataPrivacyContext.Provider value={contextValue}>{children}</DataPrivacyContext.Provider>;
};

interface DataPrivacyConfigProps {
  path: string;
  text: string;
}

export const useDataPrivacyConfig = ({ path, text }: DataPrivacyConfigProps) => {
  const { setPath, setText } = useDataPrivacy();

  React.useEffect(() => {
    setText(text);
    setPath(path);
  }, []);

  return { path, text };
};

export const DataPrivacyConfig = ({ path, text }: DataPrivacyConfigProps) => {
  useDataPrivacyConfig({ path, text });
  return null;
};
