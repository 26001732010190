import get from 'lodash.get';
import { camelize } from '../../../modules';

type IconComponentType = (props: React.SVGProps<SVGSVGElement>) => JSX.Element;

interface IconContainerType {
  [key: string]: IconComponentType,
}
interface IconListType {
  'ico': {
    [key: string]: IconContainerType;
  }
}

export default function (iconList: IconListType, name?: string, path = 'ico.jsx'): IconComponentType {
  const icons = get(iconList, path, []) as IconContainerType;

  const key = camelize(`${path}-${name}`);

  // console.log('getIcon', path, key, icons[key]);

  if (icons && icons[key]) {
    return icons && icons[key] as IconComponentType;
  } if (name !== 'empty') {
    console.error('<Icon> not found per name', key);
  }
  return (() => null) as unknown as IconComponentType;
}

export function stripType(name: string, type: string) {
  return name.replace(new RegExp(`^${camelize(type)}`), '');
}
