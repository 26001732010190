export const styles = {
  tag: {
    mr: 4,
    mb: 3,
    height: '50px',
    px: 4,
    py: '12px', // should have been better with display flex & center, but way too much hassle
    borderRadius: 5,
    backgroundColor: 'muted',
    display: 'inline-block',
    fontSize: 'xxs',
    fontWeight: 'normal',
    color: 'primary',
    cursor: 'pointer',
    transition: 'all 0.2s ease-out',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.20) 0px 0px 5px',
      transform: 'scale(1.05) !important',
    },
  },
};
