import axios, { AxiosResponse } from 'axios';
import { getStrapiRoutes } from '../routes';

interface SubscriptionResponse {
  status: number;
  message: string;
}

interface SubscriptionFormData {
  answer:string;
  firstName:string;
  lastName:string;
  email:string;
  privacy: string;
  source: string;
  address?: string;
  additionalInfo?: Record<string, unknown>;
}

export const saveSubscription = async (data: SubscriptionFormData) => {
  if (data) {
    const response: AxiosResponse<SubscriptionResponse> = await axios.post(getStrapiRoutes().cms.saveSubscription, data, { headers: { 'Content-Type': 'application/json' } });
    return response;
  }
  return null;
};
