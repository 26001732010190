import React, { useState } from 'react';
import { Box } from 'theme-ui';
import { FsType, FsTypeBase, InstitutionWithImage } from '@types';
import { convertCountriesToCountryCalculationListItems, formatComparisonSubjectToInsitution } from '@modules';
import { ChartItem } from '../components';
import useCountryCalculationDropDown from '../components/control/useCountryCalculationDropDown';
import { useInstitutionDropDownControl } from '../components/control/useInstitutionDropDownControl';
import { DefaultChartData } from '../helpers';
import { ClosableColorPicker, useColorAtIndex } from '../../colorpicker';
import ComparisonDropDownWithState from '../../finnoscore-comparison/ComparisonDropDownWithState';
import { ComparisonHeader } from '../../finnoscore-comparison/ComparisonSelector';
import { InstitutionDropDownWithState } from '../../finnoscore-comparison/InstitutionGroupedDropDown';
import { PacmanLoader } from '../../finnoscore-data/PacmanLoader';
import { LegacySwitch } from '../components/BubbleComparison/LegacySwitch';
import { PositionBar } from '../components/BasicComponents/PositionBar';
import { fillColorStyles } from '../components/BasicComponents';
import { styles } from '../charts/HistoryChart.styles';

interface useOptionalCountryOrInstitutionDropdownProps{
  fsType?: FsTypeBase<FsType>;
  period?: FsTypeBase<string>;
  currentInstitution?: InstitutionWithImage ;
}

export function useOptionalCountryOrInstitutionDropdown({ fsType, period, currentInstitution }:useOptionalCountryOrInstitutionDropdownProps) {
  const [comparisonType, setComparisonType] = useState<number>(0);
  const [colorA, setColorA] = useColorAtIndex(0);

  const { institutions: relativeInstitutions, institution: relativeInstitution,
    institutionSetHandler: relativeInstitutionSetHandler } = useInstitutionDropDownControl(fsType, period);

  const { countries, selectedCountry, selectedCountryHandler } = useCountryCalculationDropDown(fsType, period?.date, currentInstitution?.country?.countryCode, comparisonType);

  const getRelativeInstitution = () => {
    if (currentInstitution && relativeInstitution) {
      if (comparisonType === 0) return relativeInstitution;
      if (selectedCountry && comparisonType === 1) return formatComparisonSubjectToInsitution(selectedCountry);
    }
    return DefaultChartData.sample;
  };

  React.useEffect(() => {
    setComparisonType(0);
  }, [fsType, period?.id]);

  return {
    getCountryOrInstitution: getRelativeInstitution,
    relativeInstitution,
    comparisonType,
    setComparisonType,
    CountryOrInstitutionDropDown: () => (
      <React.Fragment>
        <LegacySwitch value={comparisonType} changeHandler={setComparisonType} />

        {comparisonType === 1 && !selectedCountry && <PacmanLoader sx={{ height: 'bigMobileIcon' }} />}
        {comparisonType ===1 && selectedCountry && countries && (
          <ComparisonDropDownWithState
            countries={countries && convertCountriesToCountryCalculationListItems(countries, fsType?.text)}
            setSelected={selectedCountryHandler}
            selectedCalculation={selectedCountry}
            header={<ComparisonHeader
              subject={selectedCountry}
              chartComponent={<ChartItem
                percentage={(selectedCountry?.total || 0) * 10}
                sx={styles.headerChart}
                fillComponent={<PositionBar sx={fillColorStyles(colorA)} />}
              />}
              colorPickerComponent={<Box onClick={e => e.stopPropagation()}><ClosableColorPicker defaultColor={colorA} setColorHandler={setColorA} /></Box>}
            />}
          />
        )}

        {comparisonType === 0 && !relativeInstitutions && <PacmanLoader sx={{ height: 'bigMobileIcon' }} />}
        {comparisonType === 0 && relativeInstitutions && (
          <InstitutionDropDownWithState
            institutions={relativeInstitutions}
            onSelect={relativeInstitutionSetHandler}
            selectedInstitution={relativeInstitution}
            sx={{ zIndex: 5 }}
            header={<ComparisonHeader
              subject={relativeInstitution}
              chartComponent={<ChartItem
                percentage={(relativeInstitution?.total || 0) * 10}
                sx={styles.headerChart}
                fillComponent={<PositionBar sx={fillColorStyles(colorA)} />}
              />}
              colorPickerComponent={<Box onClick={e => e.stopPropagation()}><ClosableColorPicker defaultColor={colorA} setColorHandler={setColorA} /></Box>}
            />}
          />
        )}
      </React.Fragment>
    ),
  };
}
