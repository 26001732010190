import { useCallback, useEffect } from 'react';
import { FsTypeBase } from '@types';
import { getFsPeriodsByFsType } from '../../networking';
import { useFsTypes } from './useFsTypes';

export const usePeriodDropDownControl = (type?: FsTypeBase<string>, query = getFsPeriodsByFsType()) => {
  const {
    types: periods, setTypes: setPeriods, typeId: periodId, setTypeId: setPeriodId, type: period,
  } = useFsTypes<string>(); // TODO: reeval if we need typeId and SetTypeid?

  const getFsPeriods = useCallback(async () => {
    setPeriods([]);
    setPeriodId('');
    if (type) {
      const data = await query(type.text);
      setPeriods(data.map(({ id, text, published, date, ...item }) => ({ ...item, id, date, text: `${text} ${!published ? '(draft)' : ''}` })));
    }
  }, [type]);

  useEffect(() => { getFsPeriods(); }, [type]);

  return ({
    periods,
    setPeriods,
    period,
    periodId,
    setPeriodId,
    isLoading: (periods.length===0 && !!type),
  });
};
