import { request } from './request';
import { getStrapiRoutes } from './routes';

interface GraphqlResponse<Resource> {
  data: Resource;
}

export async function graphqlRequest<Resource>(query: string, token?: string | null) {
  const strapiUrl = getStrapiRoutes().root;
  if (!strapiUrl) throw new Error('No strapi url found in environment for preview page');
  const url = `${strapiUrl}/graphql`;

  const fullQuery = JSON.stringify({
    query,
  });

  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : '';

  const response = await request<GraphqlResponse<Resource>, string>(url, {
    method: 'POST',
    headers: {
      ...authorizationHeader,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: fullQuery,
  });

  return response.data;
}
