import {
  ContentFromApi, Content, Image, Locale, Dimensions, Translation, InstitutionWithImage, AvailableFormat,
} from '@types';
import { DimensionKey } from '../../types/Dimension';
import { getStrapiRoutes } from '../networking/routes';

const cdnImageUrl = (url?: string, defUrl='') => {
  const { prefix, regex } = getStrapiRoutes().cdn;
  return (url ? `${url.match(regex) ? '' : prefix}${url}` : defUrl);
};
export const formatImage = (image?: Image, preferredFormat: AvailableFormat | undefined = 'large') : Image => ({ url: cdnImageUrl(image?.formats?.[preferredFormat]?.url || image?.url), alternativeText: image?.alternativeText || '' });
export const formatIcon = (image?: Image) => formatImage(image, 'thumbnail');
export const formatThumbnail = (image?: Image) => formatImage(image, 'medium');

export const formatImages = (images: Image[], preferredFormat?: AvailableFormat) => images?.reduce(
  (array, image) => {
    const formatted = formatImage(image, preferredFormat);
    return formatted ? [...array, formatted] : array;
  },
  [] as Image[]
);
export const formatIcons = (images: Image[]) => formatImages(images, 'thumbnail');
export const formatThumbnails = (images: Image[]) => formatImages(images, 'medium');

export const formatContent = (content?: ContentFromApi[]): Content[] => (!content ? [] : content.map(c => ({
  text: c.text,
  image: formatImage(c.image),
  images: c.images ? formatImages(c.images) : [],
  quote: c.quote && { ...c.quote, profile: c.quote?.profile },
  tabbedImages: c.tabbedImages?.map(tabbed => ({ ...tabbed, image: formatImage(tabbed.image) })),
  video: {
    // TODO: formatVideo?
    headline: c.video?.headline,
    url: c.video?.video?.url,
    youtubeUrl: c.video?.youtubeVideo,
  },
  button: {
    link: c.button?.link,
    title: c.button?.title,
  },
})));

type ResourceWithSlugPerLocale = {
  [key in Locale]?: {
    slug: string;
  }
}

export function getMainResource<Resource extends ResourceWithSlugPerLocale>(slug: string, locale: Locale, mainResources?: Resource[]) {
  return mainResources?.find(resource => resource[locale]?.slug === slug);
}

const getTranslatedDimensionKeysForLocale = (dimensions: Dimensions, translations: Translation[], locale: Locale): string[] => dimensions.map(dimension => {
  const translation = translations.find(tr => tr.key === dimension.key);
  if (translation) {
    return translation[locale] || dimension.key;
  }
  return dimension.key;
});

const translateDimensions = (dimensions: Dimensions, translatedKeys: string[]): Dimensions => dimensions.map((dimension, index) => ({
  key: translatedKeys[index] as DimensionKey,
  value: dimension.value,
}));

export const translateDimensionsForLocale = (dimensions: Dimensions, translations?: Translation[] | null, locale?: Locale): Dimensions => {
  if (!translations || !locale) return dimensions;
  const translatedDimensionKeys = getTranslatedDimensionKeysForLocale(dimensions, translations, locale);
  return translateDimensions(dimensions, translatedDimensionKeys);
};

export const translateInstitutionDimensionForLocale = (institution: InstitutionWithImage, translations?: Translation[] | null, locale?: Locale) => ({
  ...institution,
  dimensions: translateDimensionsForLocale(institution.dimensions, translations, locale),
});

export const translateInstitutionListDimensionsForLocale = (institutions: InstitutionWithImage[], translations?: Translation[] | null, locale?: Locale) => institutions.map(institution => translateInstitutionDimensionForLocale(institution, translations, locale));
