import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { __tr, __rect, __select, __selectAll, RefFuncType, __el } from '@modules';
import { pin, scrub, withAnimation } from '@core';

export interface AnimateTestimonialsProps {
  sectionRef: RefFuncType;
}

const calcDistance = (sectionRef: RefFuncType) => __selectAll('.listItem', sectionRef).reduce((sum, item) => sum - __rect(item).width, __rect(__select('.section-content', sectionRef)).width)-60;

export const animateTestimonials = ({
  sectionRef,
}: AnimateTestimonialsProps) => {
  gsap.registerPlugin(ScrollTrigger);

  const container = __el(sectionRef);

  ScrollTrigger.create({
    trigger: __tr(sectionRef),
    // markers: process.env.NODE_ENV === 'development',
    start: 'top top',
    end: () => '+300%',
    ...scrub(),
    ...pin(),
    invalidateOnRefresh: true,
    refreshPriority: 5,
    animation: gsap.timeline()
      .to(container, {
        x: () => calcDistance(sectionRef),
        ease: 'linear',
      }),
  });
  // it seems we don't need ScrollTrigger.saveStyles(
};

export const useAnimationTestimonials = (props: AnimateTestimonialsProps) => withAnimation<AnimateTestimonialsProps>(animateTestimonials, props);
