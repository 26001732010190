import { SxStyleProp } from 'theme-ui';

export const styles = {
  box: {
    width: ['90%', '100%'],
    overflow: 'hidden',
    margin: 'auto',
    '.carousel-arrow': {
      width: '20px',
      height: '20px',
      svg: {
        width: '10px',
        height: '10px',
      },
    },
  },
  logoColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content max-content',
    placeItems: 'center',
    '.logoMapper-link': {
      height: '100%',
      width: '100%',
    },
  },
};

export const landingStyles: SxStyleProp = {
  mt: 8,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 2,
  rowGap: 5,
  '.logo': {
    width: ['150px', '100px', '150px', '160px'],
    height: 'auto',
    maxHeight: 7,
    filter: 'grayscale(100%)',
    transition: 'all 0.2s ease-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
};
