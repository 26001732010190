/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';
import { BiSearch } from 'react-icons/bi';
import { Flex, Input } from '@themed';
import { styles } from './SearchBar.styles';

interface Props extends SxProps {
  value?: string;
  onChange?: (value: string) => void;
  onClick?: (e:React.MouseEvent<HTMLInputElement>) => void;
}

export const SearchBar = ({ value, onChange, ...props }: Props) => <Flex variant="flex.rowCenter" {...props} sx={{ ...styles.box, ...props.sx }}>
  <BiSearch style={styles.icon} />
  <Input
    sx={styles.input}
    type="text"
    name="search"
    value={value}
    onChange={e => onChange && onChange(e.target.value)}
  />
</Flex>;
