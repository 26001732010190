import { SxStyleProp } from 'theme-ui';

export const styles: SxStyleProp = {
  backgroundColor: 'muted',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  pt: 4,
};
export const headlineStyle = {
  fontSize: ['xs', 'm', 'm'],
  fontWeight: 'bold',
};
