export const styles = {
  box: {
    display: ['flex', 'flex', 'block', 'block'],
  },
  title: {
    display: ['none', 'none', 'block', 'block'],
    mt: [0, 0, 4, 0],
    fontSize: 'mImportant',
  },
  paragraph: {
    display: ['flex', 'flex', 'flex', 'block'],
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 3,
    pt: [0, 3, 0, 3],
    pb: [4, 4, 0, 4],
  },
  cta: {
    display: 'flex',
    mt: ['8px !important', 0, 0, '16px !important'],

    '&, & a': {
      width: ['min-content !important', 'min-content !important', 'max-content !important'],
      height: ['min-content !important', 'min-content !important', 'max-content !important'],
      px: [0, 0, 5],
      py: [0, 0, 3],
      margin: 0,
      minWidth: 'unset',
      minHeight: ['unset', 'unset', '50px'],
      justifyContent: 'center',
      span: {
        minWidth: 'unset',
      },
    },
    '.text': {
      display: ['none', 'none', 'initial'],
    },
    svg: {
      ml: [0, 0, 4],
      transform: ['scale(1.5)', 'scale(1.5)', 'none'],
    },
  },
};
