import { VideoPlayer } from '@core';
import { Paragraph, SubTitle } from '@themed';
import { Video } from '@types';
import { Box, SxProps } from 'theme-ui';
import { postContentStyles, videoStyles } from './index.styles';

interface PostVideoProps extends SxProps {
  video?: Video
}

export const PostVideo = ({ video, sx, ...props }: PostVideoProps) => (
  video ? (
    <Box {...props} sx={{ ...postContentStyles.format, ...sx }}>
      {video.headline && (
        <Paragraph>
          <SubTitle sx={{ variant: 'styles.h2' }}>{video.headline}</SubTitle>
        </Paragraph>
      )}
      <VideoPlayer
        video={video}
        sx={videoStyles}
      />
    </Box>
  )
    : null
);
