import React from 'react';
import { navigate } from 'gatsby';
import { ChildrenProps, Locale } from '@types';
import { useLocale } from '@modules';

interface LocaleGuardProps extends ChildrenProps {
  enabledLocales: Locale[];
  redirectTo: string;
}

export const LocaleGuard = ({ enabledLocales, children, redirectTo }: LocaleGuardProps) => {
  const locale = useLocale();

  React.useEffect(() => {
    if (!enabledLocales.includes(locale)) {
      navigate(redirectTo);
    }
  }, [locale]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};
