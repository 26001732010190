export const FS_TYPES = ['bank', 'bankMiddleEast', 'insurance', 'privatebank'] as const;
export const FS_TYPES_PUBLIC = FS_TYPES.filter((type) => type !== 'bankMiddleEast');

export type FsType = (typeof FS_TYPES)[number];

export interface WithFsType {
  fsType: FsType;
}

export function isFsType(type:string): type is FsType {
  return FS_TYPES.includes(type as FsType);
}
