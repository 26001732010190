import { ImageLoader } from '@core';
import { Image } from '@types';
import { SxProps } from 'theme-ui';
import { Carousel } from '../../Carousel';
import { postContentStyles } from './index.styles';

interface ImageCarouselProps extends SxProps{
  images?: Image[]
}

export const ImageCarousel = ({ images, sx, ...props }: ImageCarouselProps) => (
  images && images.length
    ? (
      <Carousel {...props} sx={{ ...postContentStyles.images, ...sx }}>
        {images?.map((img) => (
          <ImageLoader alt={img.alternativeText} key={img.url} src={img.url} />
        ))}
      </Carousel>
    )
    : null
);
