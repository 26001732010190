import React from 'react';
import { useLocation } from '@reach/router';
import { ContactSection } from '@composite';
import { SelectedFsTypeContextProvider } from '@modules';
import { SectionWithContent, SvgIcon, ThemedIcon } from '@core';
import { Flex } from '@themed';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { getTypeFromPath } from '@config';
import { StyledTestimonals } from '../Testimonals';
import { Video } from '../digital-experience/Video';
import { Init } from './Init';
import { Compare } from './Compare';
import { QuickWins } from './QuickWins';
import { Services } from './Services';
import { testimonialStyles } from './Testimonial.styles';
import { useAnimationTestimonials } from './animateTestimonials';
import { styles } from './index.styles';

export const FinnoScorePage = () => {
  const { pathname } = useLocation();
  const pageContent = useStrapiPageContent(`finnoscore-${getTypeFromPath(pathname)}`);
  const testimonialRef = React.useRef(null);
  const servicesRef = React.useRef(null);
  const quickWins = React.useRef(null);

  useAnimationTestimonials({
    sectionRef: () => testimonialRef,
  });

  return (
    <React.Fragment>
      <SelectedFsTypeContextProvider>
        <Init />
        <Video content={pageContent} sx={styles.video} />
        <Compare />
      </SelectedFsTypeContextProvider>
      <QuickWins ref={quickWins} />
      <Services ref={servicesRef} />

      <SectionWithContent data-cy="finnoscore-testimonials" ref={testimonialRef} variant="styles.layoutMaxWidth">
        <Flex sx={styles.content} variant="flex.colCenter">
          <StyledTestimonals styles={testimonialStyles} />
        </Flex>
      </SectionWithContent>

      <ContactSection id="finnoscore.contact" triggerRef={() => servicesRef}>
        <ThemedIcon sx={{ variant: 'images.pureCircleIcon' }}><SvgIcon name="rocket" /></ThemedIcon>
      </ContactSection>
    </React.Fragment>
  );
};
