import React from 'react';
import { ListItem, RequiredDropDownContextProviderProps, SearchList } from '@core';
import { ComparisonSubject, Children } from '@types';
import { ChartItem } from '../chart-generator';
import { CountryCalculationListItem } from '../finnoscore-data/List';
import { PositionBar } from '../chart-generator/components/BasicComponents/PositionBar';
import { ComparisonDropDownWithContext } from './ComparisonSelector';
import { styles } from './ComparisonDropDown.styles';

export interface CountryCalculationDropDownProps extends RequiredDropDownContextProviderProps {
  countries: ListItem[];
  selectedCalculation: ComparisonSubject;
  onSelect: (value: string) => void;
  header?: Children;
}

export const CountryCalculationDropDown = ({
  countries, isOpen, setIsOpen, onSelect, selectedCalculation, ...props
}: CountryCalculationDropDownProps) => (
  <ComparisonDropDownWithContext
    {...props}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    subject={selectedCalculation}
    chartComponent={<ChartItem
      percentage={selectedCalculation.total * 10}
      sx={styles.headerChart}
      fillComponent={<PositionBar />}
    />}
  >
    <SearchList
      listItems={countries}
      onSelect={onSelect}
      type={selectedCalculation.type}
      selected={`${selectedCalculation.id}`} // TODO: handle better, to make SearchList string | number?
      searchKey="title"
      ListItemComponent={CountryCalculationListItem}
    />
  </ComparisonDropDownWithContext>
);
