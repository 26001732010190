/** @jsx jsx */
import React from 'react';
import { Box, BoxProps, Flex, jsx } from 'theme-ui';
import { HiOutlineX } from 'react-icons/hi';
import { useLogoWithLink, useNavbarItems } from '@modules';
import { MobileLanguageSelector } from '@core';
import { themeConfig } from '@themed';
import { navigationButtonClose, styles } from './MobileNav.styles';
import { MobileNavbar } from './MobileNavbar';

interface MobileNavProps extends BoxProps {
  onClose: () => void;
}

export const MobileNav = React.forwardRef<HTMLDivElement, MobileNavProps>(({
  onClose,
}, ref) => {
  const navbarItems = useNavbarItems(false);

  const Logo = useLogoWithLink('inverted');

  const closeMobileNavigation = () => {
    onClose();
  };

  return (
    <Box sx={styles.container} ref={ref}>
      <Flex sx={styles.header}>
        <Logo onClick={() => closeMobileNavigation()} />
        <Box sx={navigationButtonClose} onClick={() => closeMobileNavigation()}>
          <HiOutlineX size={24} color={themeConfig.colors.primary} />
        </Box>
      </Flex>
      <MobileNavbar items={navbarItems} />
      <MobileLanguageSelector onChange={() => closeMobileNavigation()} />
    </Box>
  );
});
