/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import React from 'react';
import { Institution } from '@types';
import { useColors } from '@composite';
import { InstitutionGroupedDropDown } from 'src/components/composite/finnoscore-comparison/InstitutionGroupedDropDown';
import { Header } from 'src/components/composite/finnoscore-comparison/Charts';
import { ComparisonChartWithLabel } from '../BasicComponents/ComparisonChartWithLabel';
import { containerStyles } from './BubbleInstitutionComparisonChart.styles';

export interface InstitutionProps {
  currentInstitution: Institution,
  relativeInstitution: Institution
}

export const BubbleInstitutionComparisonChart = React.forwardRef<HTMLDivElement, InstitutionProps>(({
  currentInstitution, relativeInstitution,
}, ref) => {
  const [[relativeColor, currentColor]] = useColors();

  return (
    <Box sx={containerStyles} ref={ref}>

      <Box id="institutionDropdown">
        <InstitutionGroupedDropDown
          isOpen={false}
          disabled
          setIsOpen={() => console.log()}
          onSelect={() => console.log()}
          selectedInstitution={relativeInstitution}
          institutionColor={relativeColor}
        />
        <InstitutionGroupedDropDown
          isOpen={false}
          disabled
          setIsOpen={() => console.log()}
          onSelect={() => console.log()}
          selectedInstitution={currentInstitution}
          institutionColor={currentColor}
        />
      </Box>

      <ComparisonChartWithLabel
        id="chart"
        dimension={currentInstitution.dimensions}
        relativeDimension={relativeInstitution.dimensions}
      >
        <Header sx={{ background: 'none !important' }} />
      </ComparisonChartWithLabel>
    </Box>
  );
});
