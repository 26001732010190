import { SxStyleProp } from 'theme-ui';

export const styles = {
  formStyles: {
    textAlign: 'center',
    h3: {
      mt: 5,
      fontSize: ['s', 'm'],
    },
  } as SxStyleProp,
  icon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transform: 'rotate(-8deg)',
    height: ['3rem', '4rem'],
  },
};
