export const styles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'repeat(10, min-content)',
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    mb: 1,
  },
};
