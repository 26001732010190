import { SxStyleProp } from 'theme-ui';

export const styles = {
  section: (hasFourCTAButtons: boolean) => ({
    '.section-content .wrapper .content': {
      display: ['flex', 'grid'],
      gridTemplateColumns: ['', 'min-content min-content 1fr'],
      gridTemplateAreas: hasFourCTAButtons
        ? ['', '"h2 h2 h2" "paragraph paragraph paragraph" "cta cta2 cta3" "cta4 cta4 cta4"']
        : ['', '"h2 h2 h2" "paragraph paragraph paragraph" "cta cta2 cta3"'],
      columnGap: 4,
      h1: {
        gridArea: 'h1',
      },
      h2: {
        gridArea: 'h2',
      },
      h3: {
        gridArea: 'h3',
      },
      a: {
        gridArea: 'cta',
        '& *': {
          minWidth: 'unset',
        },
      },
      'p, .paragraph': {
        gridArea: 'paragraph',
      },
      'a:nth-of-type(2)': {
        gridArea: 'cta2',
      },
      'a:nth-of-type(3)': {
        gridArea: 'cta3',
      },
      'a:nth-of-type(4)': {
        gridArea: 'cta4',
      },
    },
    '.section-content .wrapper aside': {
      alignItems: ['center', 'center', 'center', 'flex-end'],
      justifyContent: ['flex-start', 'flex-start', 'center'],
      minHeight: ['40vh', '40vh', '25vh'],
      transform: ['scale(0.7)', 'none'],
    },
  }),
  box: {
    position: 'relative',
  } as SxStyleProp,
};
