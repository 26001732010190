/** @jsx jsx */
import { jsx, Text, Flex, Box } from 'theme-ui';
import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { Button } from '@themed';
import { ImageLoader, ListItemProps } from '@core';
import { useTranslate } from '@modules';
import { commonStyles, countryCalculationListItemStyles, countryListItemStyles, institutionListItemStyles } from './List.styles';

export const CountryListItem = ({
  text, icon, onSelect, id, className, selected,
}: ListItemProps) => <Button
  className={className}
  variant="buttons.pure"
  sx={countryListItemStyles.box(selected)}
  onClick={() => onSelect && id && onSelect(id)}
>
  {icon ? <ImageLoader src={icon.url} alt={icon.alternativeText} sx={commonStyles.icon} />: <Box sx={commonStyles.icon} />}
  <Flex sx={countryListItemStyles.textWithSelection}>
    <Text variant="fsText">{text}</Text>
    {selected && <FiCheck style={commonStyles.tick} />}
  </Flex>
</Button>;

export const CountryCalculationListItem = ({
  text, icon, onSelect, id, title, selected, className, type,
}: ListItemProps) => {
  const __ = useTranslate();
  const translatedText = type === 'bankMiddleEast'
    ? __(`common.${id?.replace(/ALLE(\w+)\./, `ALLE$1.${type}.`)}`)
    : text;

  return <Button
    data-testid={id}
    className={className}
    variant="buttons.pure"
    sx={countryCalculationListItemStyles.box(selected)}
    onClick={() => onSelect && id && onSelect(id)}
  >
    <Flex sx={countryCalculationListItemStyles.titleArea(id)}>
      {icon && title && <React.Fragment>
        <ImageLoader src={icon.url} alt={icon.alternativeText} sx={commonStyles.icon} />
        <Text variant="fsText" sx={countryCalculationListItemStyles.title(id)}>{title}</Text>
      </React.Fragment>}
    </Flex>
    <Flex sx={countryCalculationListItemStyles.textArea}>
      <Text variant="fsText">{translatedText}</Text>
      {selected && <FiCheck style={commonStyles.tick} />}
    </Flex>
  </Button>;
};

export const InstitutionListItem = ({
  text, onSelect, id, selected, className,
}: ListItemProps) => <Button
  data-cy={text}
  className={className}
  variant="buttons.pure"
  sx={institutionListItemStyles.item(selected)}
  onClick={() => onSelect && id && onSelect(id)}
>
  {selected && <FiCheck style={institutionListItemStyles.tick} />}
  {text && <Text>{text}</Text>}
</Button>;
