import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Button, Input, Label, Text } from 'theme-ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { Email } from 'src/types/StrapiLogin';
import { getStrapiRoutes } from '@modules';
import { ErrorMessage } from './ErrorMessage';
import { formStyles } from './ForgotPasswordForm.styles';

interface Props{
  locale: string
  emailSentSetter: (emailSent: boolean) => void
}

export const ForgotPasswordForm = ({ locale, emailSentSetter }:Props) => {
  const { register, handleSubmit, formState: { errors } } = useForm<Email>();
  const [error, setError] = useState('');

  const onSubmit: SubmitHandler<Email> = async (data) => {
    try {
      await axios.post(getStrapiRoutes().cms.forgotPassword, {
        email: data.email,
      });
      emailSentSetter(true);
    } catch (err) {
      setError(err?.response?.data?.message);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} id="form" sx={formStyles.form}>
        <Label>
          Email
          <Input {...register('email')} type="email" placeholder="your.name@finnoconsult.at" />
        </Label>
        {errors.email && <ErrorMessage>Email is required</ErrorMessage>}

        <Button type="submit">Send Email</Button>
        <ErrorMessage>{error}</ErrorMessage>
      </form>
      <Link to={`/${locale}/charts/login`}><Text>Ready to Log in?</Text></Link>
    </React.Fragment>
  );
};
