import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    '&, &>*, main>&:first-of-type, main>&>*:first-of-type': {
      minHeight: ['unset', 'unset', '100vh'],
    },

    position: 'relative',
    overflow: 'visible',
    p: [4, 4, 0],
    h1: {
      color: 'background',
      mt: ['headerHeight', 'headerHeight', 0],
    },
  } as SxStyleProp,
  background: {
    position: 'absolute',
    top: 0,
    left: '-10vw',
    bottom: 0,
    right: 0,
    width: '120vw',
    height: ['70vh', '70vh', '100vh'],
    bg: 'primary',
    zIndex: -1,
  } as SxStyleProp,
  button: {
    width: 'fullSize',
    height: 'fullSize',
  },
};
