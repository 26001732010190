import React from 'react';
import { Text, TextProps } from 'theme-ui';
import Markdown from 'react-markdown/with-html';
import { ChildrenProps } from '../../../types';
import { markDownStyles } from './MarkDownContent.styles';

const MarkDownStyles = (props: Omit<TextProps, 'ref'>) => <Text as="span" sx={markDownStyles} {...props} />;
const MarkDownRenderer = (props: Omit<TextProps, 'ref'>) => <Text as="span" {...props} />;

export interface MarkDownContentProps extends ChildrenProps {
  prep?: ((s: string) => string);
  renderers?: {[nodeType: string]: React.ElementType};
}

export const MarkDownContent = ({
  children, prep, renderers, ...props
}: MarkDownContentProps): JSX.Element => {
  if (typeof children === 'string') {
    const source = prep ? prep(children) : children;
    return (
      <MarkDownStyles>
        <Markdown
          source={source}
          escapeHtml={false}
          renderers={renderers || { paragraph: MarkDownRenderer }}
          {...props}
        />
      </MarkDownStyles>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};
