import { NewsLetterSignup } from '@composite';
import { useTranslate } from '@modules';
import { Box } from '@themed';
import { useModalOverlayTimer } from '@core';
import { styles } from './NewsletterModal.styles';
import { DefaultNewsLetterContent } from './NewsLetterContent';

export const NewsletterModal = () => {
  const __ = useTranslate();
  const { close, Component: ModalOverlayTimer } = useModalOverlayTimer({ seconds: Number(process.env.GATSBY_OFFER_FINNOSCORE_NEWSLETTER_TIMEOUT) || 30 });
  if (!__('finnoscore.newsletter.title', true)) return null;
  return (
    <ModalOverlayTimer>
      <Box sx={styles.container}>
        <NewsLetterSignup id="finnoscore.newsletter" onSubmit={() => close()}>
          <DefaultNewsLetterContent id="finnoscore.newsletter" />
        </NewsLetterSignup>
      </Box>
    </ModalOverlayTimer>
  );
};
