import {
  Institution, Comparison, ComparisonSubject, CountryCalculation, ComparisonSubjectType, AllComparisonSubjectTypes,
} from '@types';
import {
  useQueryParam, createInstitutionId,
} from '@modules';
import { useTranslate } from '../../i18n';

const institutionToSubject = (institution?: Institution): ComparisonSubject => {
  if (!institution) throw new Error('Institution not found');

  return {
    ...institution,
    id: createInstitutionId(institution),
    image: institution.logo,
  };
};

// TODO: merge this code into formatComparison
export const countryCalculationToSubject = (formatName: FormatCountryCalculationName, countryCalculation?: CountryCalculation): ComparisonSubject => {
  if (!countryCalculation) throw new Error('Country not found');

  const { countryCode } = countryCalculation.country;

  return {
    id: `${countryCode}.${countryCalculation.type}`,
    rank: countryCalculation.rank,
    dimensions: countryCalculation.dimensions,
    name: formatName(countryCalculation),
    image: countryCalculation.country.flag,
    total: countryCalculation.total,
    country: countryCalculation.country,
    type: countryCalculation.fsType,
  };
};

export const parseSubjectFromParam = (param: string) => param.match(new RegExp(`^(?<type>(${AllComparisonSubjectTypes.join('|')}))\\.(?<country>\\w+)\\.(?<value>.*\\S)\\s*$`))?.groups;

const getSubjectFromParam = (formatName: FormatCountryCalculationName, institutions?: Institution[], countryCalculations?: CountryCalculation[], param?: string | null) => {
  if (!param || !institutions || !countryCalculations) return undefined;

  const strings = parseSubjectFromParam(param);
  if (!strings || !strings?.type || !strings?.country || !strings?.value) return undefined;

  const subject = {
    type: strings.type as ComparisonSubjectType,
    countryCode: strings.country,
    value: strings.value,
  };

  switch (subject.type) {
    case ComparisonSubjectType.Institution:
      return institutionToSubject(institutions.find(institution => institution.name.trim().toLowerCase() === subject.value.trim().toLowerCase() && institution.country.countryCode === subject.countryCode));
    default:
      return countryCalculationToSubject(
        formatName,
        countryCalculations.find(cc => cc.country.countryCode === subject.countryCode && cc.type === subject.value),
      );
  }
};

type FormatCountryCalculationName = (countryCalculation: CountryCalculation) => string;

export function useFormatCountryCalculationName() {
  const __ = useTranslate();

  return (countryCalculation: CountryCalculation) => {
    const { countryCode } = countryCalculation.country;

    return countryCalculation.fsType === 'bankMiddleEast'
      ? __(`common.${countryCode}.${countryCalculation.fsType}.${countryCalculation.type}`)
      : `${countryCode} – ${__(`common.${countryCalculation.type}`)}`;
  };
}

export const useComparison = (institution?: Institution[], countryCalculations?: CountryCalculation[]) => {
  const [a, setA] = useQueryParam('a');
  const [b, setB] = useQueryParam('b');

  const formatName = useFormatCountryCalculationName();

  try {
    const comparison = (() => {
      const subjectA = getSubjectFromParam(formatName, institution, countryCalculations, a);
      const subjectB = getSubjectFromParam(formatName, institution, countryCalculations, b);

      if (!subjectA || !subjectB) return null;

      return {
        a: subjectA,
        b: { ...subjectB, winner: true },
      } as Comparison;
    })();

    return {
      comparison,
      setA,
      setB,
      error: null,
    };
  } catch (e: any) {
    return {
      comparison: null,
      setA,
      setB,
      error: new Error(e.message),
    };
  }
};
