import React from 'react';

function useSafeSetState<Arg>(setState: (arg: Arg) => void) {
  const isMounted = React.useRef(false);
  React.useLayoutEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);
  return React.useCallback(
    (arg: Arg) => (isMounted.current ? setState(arg) : undefined),
    [setState],
  );
}

export function useAsyncAction<Resource = void, Trigger = void>(action: () => Promise<Resource>, triggers: Trigger[] = []) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [resource, setResource] = React.useState<Resource | null>(null);

  const executeAction = React.useCallback(action, [...triggers]);

  const safeSetIsLoading = useSafeSetState<boolean>(setIsLoading);
  const safeSetError = useSafeSetState<Error | null>(setError);
  const safeSetResource = useSafeSetState<Resource | null>(setResource);

  React.useEffect(() => {
    (async () => {
      try {
        safeSetIsLoading(true);
        const result = await executeAction();
        safeSetResource(result);
      } catch (e) {
        safeSetError(e);
      } finally {
        safeSetIsLoading(false);
      }
    })();
  }, [executeAction]);

  return {
    isLoading,
    error,
    resource,
  };
}
