import { SxStyleProp } from 'theme-ui';

export const styles = {
  mt: 'headerHeight',
  title: {
    fontSize: ['m', 'l', 'l'],
    textAlign: 'center',
    my: 4,
  } as SxStyleProp,
  subTitle: {
    fontSize: ['xxs', 'xs', 'xs'],
    textAlign: 'center',
    mb: [4, 8],
  } as SxStyleProp,
};
