export const sectionStyles = {
  section: {
    variant: 'styles.section',
    maxWidth: 'unset',
    m: 0,
  },
  sectionContent: {
    variant: 'styles.section',
    border: 'none',
    width: 'fullSize',
    height: 'fullSize',
  },
};
