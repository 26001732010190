import * as React from "react";

const SantaHat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="136px" height="138px" viewBox="0 0 236 238" version="1.1" xmlns="http://www.w3.org/2000/svg"  {...props}>
  <g id="Finno_christmas_pacman-(1)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(0.531, 0.0125)">
    <path d="M118.143033,33.7094564 C116.797033,31.4626564 94.6820327,6.63135636 79.0350327,1.44905636 C63.3870327,-3.73334364 47.4006327,6.70775636 40.9798327,5.96805636 C34.5590327,5.22835636 23.2122327,9.82075636 16.2199327,16.8448564 C9.22773274,23.8689564 2.52913274,38.3998564 3.91413274,48.1559564 C6.38743274,65.6084564 6.84393274,90.3524564 8.97703274,110.088456 L22.1245327,109.646456 C22.1245327,109.646456 25.7168327,111.772456 25.6212327,110.850456 C25.5257327,109.928456 23.3756327,99.0884564 23.3747327,94.1334564 C23.3189327,91.4644564 23.6031327,88.7994564 24.2203327,86.2014564 L26.3662327,91.3754564 C26.3662327,91.3754564 33.2800327,104.936456 34.7804327,99.5904564 C36.2808327,94.2444564 118.143033,33.7094564 118.143033,33.7094564 Z" id="Path" fill="#E50013"></path>
    <path d="M24.3951327,136.274456 C28.3780327,134.202456 31.3926327,130.654456 32.7948327,126.388456 C34.1971327,122.123456 33.8760327,117.479456 31.9000327,113.447456 C29.9239327,109.415456 26.4498327,106.316456 22.2195327,104.811456 C17.9892327,103.306456 13.3383327,103.516456 9.26013274,105.394456 C5.27723274,107.466456 2.26273274,111.014456 0.860432741,115.280456 C-0.541867259,119.545456 -0.220767259,124.189456 1.75533274,128.221456 C3.73133274,132.253456 7.20543274,135.352456 11.4357327,136.857456 C15.6660327,138.362456 20.3169327,138.152456 24.3951327,136.274456 Z" id="Path" fill="#FFFFFF"></path>
    <path d="M21.8719327,132.444456 C18.5076327,134.079456 14.6995327,134.563456 11.0329327,133.823456 C7.36643274,133.083456 4.04453274,131.159456 1.57793274,128.347456 C3.56633274,132.404456 7.08483274,135.505456 11.3594327,136.967456 C15.6341327,138.430456 20.3147327,138.135456 24.3716327,136.146456 C28.4285327,134.158456 31.5294327,130.639456 32.9920327,126.365456 C34.4547327,122.090456 34.1594327,117.410456 32.1711327,113.353456 C31.4879327,111.960456 30.6213327,110.666456 29.5943327,109.504456 C30.5955327,111.517456 31.1900327,113.707456 31.3437327,115.950456 C31.4974327,118.193456 31.2073327,120.444456 30.4900327,122.575456 C29.7727327,124.706456 28.6424327,126.674456 27.1636327,128.367456 C25.6848327,130.061456 23.8866327,131.446456 21.8719327,132.444456 Z" id="Path" fill="#EEEEEF"></path>
    <path d="M20.6609327,104.116456 C22.0815327,104.446456 23.4541327,104.956456 24.7455327,105.633456 C24.0218327,101.813456 23.5564327,97.9484564 23.3525327,94.0664564 C23.2967327,91.3974564 23.5809327,88.7314564 24.1981327,86.1344564 L26.1597327,90.8634564 C24.8970327,79.7894564 31.6830327,73.3898564 40.9694327,66.3884564 C47.8707327,61.2464564 69.0570327,43.5791564 79.8980327,37.9765564 C85.2420327,35.2461564 93.6570327,32.6698564 101.404033,30.7769564 C97.7390327,31.0698564 93.5470327,31.2869564 89.9600327,31.3077564 C80.7740327,31.2884564 73.2520327,36.8235564 60.0270327,46.4681564 C46.8023327,56.1127564 30.0954327,72.2636564 29.3058327,73.1294564 C28.5163327,73.9954564 26.6581327,75.4074564 26.3818327,73.5937564 C25.9346327,70.6675564 26.4150327,67.6748564 27.7556327,65.0357564 C29.5413327,61.4989564 31.2293327,48.8557564 34.2323327,40.1452564 C36.1904327,34.8780564 39.2713327,30.0999564 43.2612327,26.1426564 C40.1111327,27.6909564 37.2882327,29.8306564 34.9464327,32.4452564 C31.2095327,36.7044564 27.8692327,46.2262564 27.1384327,52.6426564 C26.4077327,59.0590564 24.4560327,65.4836564 23.8044327,61.9274564 C23.1529327,58.3712564 17.7988327,38.7446564 17.3967327,38.6967564 C16.9946327,38.6488564 19.7458327,62.7807564 22.0343327,67.4500564 C24.3228327,72.1194564 22.5994327,82.4634564 22.5994327,82.4634564 C22.5994327,82.4634564 19.5170327,91.0344564 20.5857327,96.6914564 C21.0017327,99.1474564 21.0271327,101.652456 20.6609327,104.116456 Z" id="Path" fill="#CE131C"></path>
    <path d="M25.0961327,95.2154564 C27.7892327,105.255456 31.6864327,109.525456 35.4277327,112.592456 C41.6841327,117.699456 83.6820327,84.1204564 87.9490327,81.1484564 C123.013033,56.7578564 143.801033,65.1563564 130.941033,38.9182564 C128.019033,32.9558564 124.165033,28.2964564 119.257033,27.9399564 C90.8220327,25.7509564 18.4138327,69.9480564 25.0961327,95.2154564 Z" id="Path" fill="#FFFFFF"></path>
    <path d="M35.9658327,107.395456 C33.7091327,107.526456 31.4607327,107.023456 29.4747327,105.944456 C31.0674327,108.497456 33.0872327,110.758456 35.4458327,112.628456 C41.7022327,117.736456 83.7000327,84.1564564 87.9670327,81.1844564 C119.105033,59.5312564 138.974033,63.7140564 134.060033,46.5998564 C131.192033,50.4437564 127.678033,53.7601564 123.674033,56.4011564 C113.829033,62.8077564 109.265033,61.4030564 100.910033,64.8966564 C92.5550327,68.3902564 82.7510327,79.0864564 72.9430327,87.0444564 C63.1350327,95.0034564 42.0474327,106.920456 35.9658327,107.395456 Z" id="Path" fill="#EEEEEF"></path>
  </g>
</svg>
);
export default SantaHat;
