import React from 'react';
import { SectionWithContent, Title, List, Grid } from '@core';
import { BoxProps } from '@themed';
import { useAllIntlTree, useTranslate } from '@modules';
import { useAnimationList } from './animateList';
import { styles } from './Deliver.styles';

const DeliverIconList = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Grid
    {...props}
    ref={ref}
    sx={styles.list}
  />
));

export const Deliver = () => {
  const ref = React.useRef(null);
  const __ = useTranslate();
  const messages = useAllIntlTree();
  useAnimationList({
    triggerRef: () => ref,
    headerSelector: 'h1',
    listTagSelector: '.listSection',
    listItemTagSelector: '.listItem',
  });

  return (
    <SectionWithContent data-cy="rapid-deliver" sx={styles.section} ref={ref}>
      <Title sx={styles.title}>{__('pages.rapid.deliver.title')}</Title>
      <List component={DeliverIconList} items={messages.pages.rapid.deliver.list} />
    </SectionWithContent>

  );
};
