import React from 'react';
import { CookieContext, useCookie, __el, useTranslate } from '@modules';
import { Paragraph } from '@themed';
import { useSmoothScrollbar, useSmoothScrollbarListener, Flex, CTAButton, OptionalLink, useIsMobile } from '@core';
import { Children } from '@types';
import { styles } from './CookieConsentContainer.styles';
import { useHeight } from './useHeight';

const CookieConsentProviderComponent = ({ name, children }: { name: string; children: Children }) => {
  const { value, setValue } = useCookie(name);
  return <CookieContext.Provider value={{ value, setValue }}>{children}</CookieContext.Provider>;
};

export const CookieConsentProvider = ({ children }: {children: Children }) => (
  <CookieConsentProviderComponent name="finnoconsultCookieConsent">{children}</CookieConsentProviderComponent>
);

// TODO: move react-markdown into text components?
export const CookieConsentContainer = () => {
  const __ = useTranslate();
  const { value: shouldHideCookieBanner, setValue: setShouldHideCookieBanner } = React.useContext(CookieContext);
  const scrollBar = useSmoothScrollbar();
  const isMobile = useIsMobile();
  const ref = React.useRef<HTMLDivElement>(null);
  const [bannerHeight, setBannerHeight] = React.useState(50);
  const height = useHeight(() => setBannerHeight(__el(() => ref)?.getBoundingClientRect().height || 50));

  const disableScrollBar = !(scrollBar && !isMobile);

  const bannerTop = disableScrollBar ? { bottom: 0 }: { top: height - bannerHeight };

  useSmoothScrollbarListener({
    cookieconsent: __el(() => ref),
  }, { disable: disableScrollBar });

  return shouldHideCookieBanner === 'true' ? null : (
    <Flex sx={{ ...styles.banner, ...bannerTop }} ref={ref}>
      <Paragraph sx={styles.text}>{__('common.cookie.banner')}</Paragraph>
      <Flex sx={styles.buttons}>
        <CTAButton data-cy="cookie-consent-accept" sx={{ ...styles.button, ...styles.cta }} onClick={() => setShouldHideCookieBanner('true', 3*30)}>
          {__('common.cookie.accept')}
        </CTAButton>
        <OptionalLink sx={{ ...styles.button, ...styles.info }} to="/data-privacy">
          {__('common.cookie.info')}
          <small>i</small>
        </OptionalLink>
      </Flex>
    </Flex>
  );
};
