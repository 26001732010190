import { getTranslationsQuery, graphqlRequest } from '@modules';
import {
  Dimension, Dimensions, FsTypeBase, Image, InstitutionList, InstitutionWithImage, Translation,
} from '@types';
import { formatFsTypeForGQL } from '../helpers';
import { institutionFields } from './getInstitutions';

interface MultiComparisonChartFromAPI{
  worldwide: InstitutionList[]
  current: InstitutionList[];
  relative: InstitutionList[];
  national: InstitutionList[]
  translations: Translation[];
}

const getBestDimension = (institutions: InstitutionWithImage[]): Dimensions => institutions.reduce((dimensions, institution) => institution.dimensions.reduce(
  (ds, d) => {
    const currentValue = ds.find(current => current.key===d.key)?.value;
    const currentIndex = ds.findIndex(current => current.key===d.key);
    // replace new best value in case we have it
    if (currentIndex >= 0 && currentValue && currentValue < d.value) return [...ds.slice(0, currentIndex), d, ...ds.slice(currentIndex+1)];
    // insert new best value in case we didn't have it
    if (currentIndex === -1) return [...ds, d];
    // no change on smaller value then the best
    return ds;
  },
  dimensions as Dimension[],
), [] as Dimensions);

interface anonimizeInstitutionProps{
  institution: InstitutionWithImage;
  fallbackImage: Image;
}

const anonimizeInstitution = ({ institution, fallbackImage }: anonimizeInstitutionProps):InstitutionWithImage => ({
  ...institution,
  image: fallbackImage,
  logo: fallbackImage,
  name: 'Best',
  text: 'Best',

});

interface institutionWithFallbackLogoProps {
  institution: InstitutionWithImage;
  fallbackInstitution: InstitutionWithImage;
  fallbackImage: Image;
}

const institutionWithFallbackLogo = ({ institution, fallbackInstitution, fallbackImage }:institutionWithFallbackLogoProps) => {
  const image = institution.image || fallbackInstitution.image;
  const logo = institution.logo || fallbackInstitution.logo;

  if (!image || !logo) {
    return {
      ...institution,
      image: fallbackImage,
      logo: fallbackImage,
    };
  }

  return {
    ...institution,
    image,
    logo,
  };
};

export const getHistoryNationalWordwideByInstitution = async (type: string, pastPeriod: FsTypeBase<string>, currentPeriod: FsTypeBase<string>, name: string, countryCode: string) => {
  try {
    const filterCurrentPeriod = currentPeriod ? `date:"${currentPeriod.date}", ` : '';
    const filterPastPeriod = pastPeriod ? `date:"${pastPeriod.date}", ` : '';
    const filterType = `fsType:"${`${formatFsTypeForGQL(type)}`}"`;
    const query = `
    {
      relative:fsPeriods(sort:"date:desc",where:{${filterPastPeriod}${filterType}},publicationState: PREVIEW){
        date
        institutions:fs_institutions(where:{name:"${name}",fs_country: {countryCode: "${countryCode}"}, ${filterType}}, limit:1){
          ${institutionFields}
        }
      }
      current:fsPeriods(sort:"date:desc",where:{${filterCurrentPeriod}${filterType}},publicationState: PREVIEW){
        date
        institutions:fs_institutions(where:{name:"${name}",fs_country: {countryCode: "${countryCode}"}, ${filterType}}, limit:1){
          ${institutionFields}
        }
      }
      national:fsPeriods(sort:"date:desc",where:{${filterCurrentPeriod}${filterType}},publicationState: PREVIEW){
        date
        institutions:fs_institutions(sort:"total:desc", where:{fs_country: {countryCode: "${countryCode}"}, ${filterType}}){
          ${institutionFields}
        }
      }
      worldwide:fsPeriods(sort:"date:desc",where:{${filterCurrentPeriod}${filterType}}, publicationState: PREVIEW){
        date
        institutions:fs_institutions(sort:"total:desc",where:{${filterType}}){
          ${institutionFields}
        }
      }
      ${getTranslationsQuery()}
    }`;

    const response = await graphqlRequest<MultiComparisonChartFromAPI>(query);

    const fallbackImage = { url: 'https://freeiconshop.com/wp-content/uploads/edd/bank-flat.png', alternativeText: 'generic logo' };

    const nationalInstitutions = response.national[0].institutions;
    const worldwideInstitutions = response.worldwide[0].institutions;
    const currentInstitution = response.current[0].institutions[0];
    const relativeInstitution = response.relative[0].institutions[0] || currentInstitution; // we need to compare to itself if there. This affects Insurance type as we have only 1 upload so far

    const national = { ...anonimizeInstitution({ institution: nationalInstitutions[0], fallbackImage }), dimensions: getBestDimension(nationalInstitutions) };
    const worldwide = { ...anonimizeInstitution({ institution: worldwideInstitutions[0], fallbackImage }), dimensions: getBestDimension(worldwideInstitutions) };
    const current = { ...institutionWithFallbackLogo({ institution: currentInstitution, fallbackInstitution: relativeInstitution, fallbackImage }) };
    const relative = { ...institutionWithFallbackLogo({ institution: relativeInstitution, fallbackInstitution: currentInstitution, fallbackImage }) };

    return {
      national,
      worldwide,
      current,
      relative,
      tranlations: response.translations,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};
