export const styles = {
  question: {
    fontSize: 'xsImportant',
  },
  profileBox: {
    mt: [0, 3, 0, 4],
    justifyContent: ['space-between', 'flex-start'],
    img: {
      variant: 'images.grayScale',
    },
    '&:hover': {
      img: {
        variant: 'images.grayScaleHover',
      },
    },
  },
  profile: {
    borderRadius: 'fullSize',
    width: '74px',
    mr: [0, 0, 0, 4],
    ml: [4, 4, 4, 0],
  },
  leftProfile: {
    display: ['none', 'none', 'none', 'block'],
  },
  rightProfile: {
    display: ['block', 'block', 'block', 'none'],
  },
};
