import React from 'react';
import { Text, BoxProps } from '@themed';
import { ChildrenOrString } from '@types';

interface ResponsiveTextShortenerProps extends BoxProps {
  short?: ChildrenOrString;
  children: ChildrenOrString;
  maxHeight: number;
}
export const ResponsiveTextShortener = ({
  short, maxHeight, children, ...props
}: ResponsiveTextShortenerProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [content, setText] = React.useState({ children, isShort: false });

  const evalHeight = React.useCallback(() => {
    if (!content.isShort && ref.current && ref.current.getBoundingClientRect().height > maxHeight) {
      setText({ children: short, isShort: true });
    } else {
      setText({ children, isShort: false });
    }
  }, [maxHeight, short, ref]);

  React.useEffect(() => {
    evalHeight();
    window.addEventListener('resize', () => evalHeight());
  }, [ref, short, children]);
  return <Text {...props} ref={ref}>{content.children}</Text>;
};
