/** @jsx jsx */
import { IoChevronDown } from 'react-icons/io5';
import React from 'react';
import { Button, jsx, SxProps, Box, Flex } from '@themed';
import { Children, ChildrenProps } from '@types';
import { SubTitle } from '@core';
import { arrowStyles, defaultDropDownHeaderStyles, styles } from './DropDownWithContext.styles';

export interface DropDownProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  disabled?: boolean;
}

export const DropDownContext = React.createContext<DropDownProps>({} as DropDownProps);

export interface DropDownContextProviderProps {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  disabled?: boolean;
}

export const DropDownContextProvider = ({ children, isOpen, setIsOpen, disabled }: DropDownContextProviderProps & ChildrenProps) => {
  const [isOpenState, setIsOpenState] = React.useState(false);

  const value = {
    isOpen: isOpen !== undefined ? isOpen : isOpenState,
    setIsOpen: setIsOpen !== undefined ? setIsOpen : setIsOpenState,
    disabled,
  };

  return <DropDownContext.Provider value={value}>{children}</DropDownContext.Provider>;
};

export const DropDownArrow = () => {
  const { isOpen } = React.useContext(DropDownContext);

  return <IoChevronDown style={arrowStyles(isOpen)} />;
};

interface DefaultDropDownHeaderProps {
  title: string;
  leftComponent?: Children;
}

export const DefaultDropDownHeader = ({ title, leftComponent }: DefaultDropDownHeaderProps) => (
  <Flex variant="flex.left" sx={defaultDropDownHeaderStyles.header}>
    {leftComponent}
    <Flex sx={defaultDropDownHeaderStyles.titleArea}>
      <SubTitle sx={defaultDropDownHeaderStyles.title}>{title}</SubTitle>
      <DropDownArrow />
    </Flex>
  </Flex>
);

interface DropDownWithContextProps extends SxProps, ChildrenProps {
  header: Children,
  className?: string,
}
export interface RequiredDropDownContextProviderProps{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  disabled?: boolean;
}

interface DropDownComponentProps extends DropDownWithContextProps, RequiredDropDownContextProviderProps {
  closeOnContentClick?: boolean;
}

export const DropDownComponent = ({
  isOpen, disabled, setIsOpen, header, children, sx, className, closeOnContentClick = true,
}: DropDownComponentProps) => (
  <Flex id="dropdownComponent" sx={styles.box(isOpen)}>
    <Flex sx={{ ...styles.body, ...sx }} className={className}>
      <Button
        data-testid="dropdown-selector"
        variant="buttons.pure"
        sx={styles.header}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      >
        {header}
      </Button>
      {isOpen && <Box sx={{ ...styles.content, ...sx }} onClick={() => closeOnContentClick && setIsOpen(!isOpen)}>{children}</Box>}
    </Flex>
  </Flex>
);

export const DropDownWithContext = (props: DropDownWithContextProps) => {
  const { isOpen, setIsOpen, disabled } = React.useContext(DropDownContext);
  return (
    <DropDownComponent isOpen={isOpen} setIsOpen={setIsOpen} disabled={disabled} {...props} />
  );
};
