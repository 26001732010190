import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const styles = {
  section: {
    bg: 'primary',
    justifyContent: ['flex-start', 'center'],
    '&>*': {
      mt: [7, 'auto'],
    },
  },
  sectionContent: {
    variant: 'grids.CompactSectionContentPadding',
    maxWidth: '100%',
  },
  title: {
    color: 'background',
  },
  subtitle: {
    fontWeight: 'normal',
    maxWidth: '70% !important',
    pt: 8,
    pb: 6,
    [`@media screen and (max-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
      variant: 'styles.p',
      maxWidth: '100vw !important',
      mb: 8,

    },

  },
  operation: {
    variant: 'text.default',
    fontSize: 'xl',
    fontWeight: 'bold',
    m: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // philosophyContainer: {
  //   overflow: 'hidden',
  //   width: '100%',
  // },
  philosophyListItemContainer: {
    overflow: ['hidden', 'hidden', 'hidden', 'initial'],
    flex: '0 0 auto',
  },
  philosophyItem: {
    variant: 'images.pureCircleIcon', // resized locally
    flexBasis: ['224px', 10, 10],
    width: ['224px', 10, 10],
    height: ['224px', 10, 10],
    '& + &:before': {

    },
  },
  philosophyTitle: {
    textAlign: 'initial',
    fontSize: ['m', 'l'],
    br: {
      display: 'block !important',
    },
  } as SxStyleProp,
};
