import React from 'react';
import { Institution } from '@types';
import { ColorPaletteItem } from './ColorPaletteItem';

const titles: string[] = ['Old website', 'New website', 'Best National', 'Best Worldwide'];

interface ColorPaletteListProps {
  institutions: (Institution | null)[]
}

export const ColorPaletteList = ({ institutions }:ColorPaletteListProps) => {
  const getLogo = (i:number) => institutions?.[i]?.logo;

  return (
    <React.Fragment>
      {titles.map((_, i) => (
        <ColorPaletteItem title={titles[i]} logo={getLogo(i)} index={i} key={titles[i]} />
      ))}
    </React.Fragment>
  );
};
