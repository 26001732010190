import { useStaticQuery, graphql } from 'gatsby';
import { SiteMeta, SiteMetaQuery } from '@types';
import { useTranslate } from '../../../modules/i18n';

export interface SiteMetaProps {
  description?: string;
  author?: string;
  title?: string;
  viewport?: string;
  type?: string;
  primaryImage?: string;
}

export function useSiteMeta(props?: SiteMetaProps): SiteMeta {
  const __ = useTranslate();
  const data = useStaticQuery<SiteMetaQuery>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          version
          author
          viewport
          siteUrl
          languages
        }
      }
    }
  `);
  const meta = data.site.siteMetadata;
  const addressStreet = __('pages.contact.address').split(' ');

  return ({
    ...data.site.siteMetadata,
    version: meta.version,
    title: props?.title || meta.title,
    description: props?.description || meta.description,
    viewport: props?.viewport || meta?.viewport,

    type: props?.type || 'WebSite', // or FinancialService?
    phone: __('pages.contact.person.ctaText'),
    email: __('pages.imprint.company.email'),
    houseNumber: (addressStreet[1].split('<')[0]),
    postalCode: addressStreet[2],
    street: addressStreet[0],
    city: addressStreet[3],
    logoFinnoconsult: `${meta.siteUrl}/images/seo-finnoconsult.png`,
    author: props?.author || meta.author,

    primaryImage: props?.primaryImage || `${meta.siteUrl}/images/seo-finnoconsult.png`,
  });
}
