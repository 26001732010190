import { SxStyles } from '@types';

export const finnoChartCellPadding = [4, 4, 0];

const dropdownComponentChildStyles = {
  '&>#dropdownComponent': {
    width: '100% !important',
  },
};

export const finnoChartStyles = {
  main: {
    // backgroundColor: 'muted',
  },
  section: {
    bg: ['background', 'background', 'transparent'],
    height: '100vh !important',
  },
  page: {
    variant: 'styles.section',
    width: 'fullSize',
    maxWidth: ['tabletMaxWidth', 'tabletMaxWidth', 'tabletMaxWidth', 'desktopMaxWidth'],
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    mt: 'headerHeight',
  },
  control: {
    width: 'fullSize',
    px: [4, 4, 0],
    position: 'relative',
    display: 'flex',
    flexDirection: ['row'],
    justifyContent: ['flex-end'],
    alignItems: 'center',
  } as SxStyles,
  content: {
    height: 'fit-content',
    minHeight: '600px', // in order to have all controls visible
    '@media screen and (min-height: 780px)': {
      height: '100vh !important',
    },
    gridTemplateColumns: ['minmax(330px,0.3fr) 1fr 1fr'],
    gridTemplateRows: ['80px 80px max-content max-content min-content'],
    gridTemplateAreas: [`
      "type chart chart"
      "pastPeriod chart chart"
      "currentPeriod chart chart"
      "bankSelector chart chart"
      "updateStack saveButton downloadButton"`,
    ],
    rowGap: ['12px', 4],
    columnGap: [0, 0, 4, 6],
    '&>#type': {
      ...dropdownComponentChildStyles,
      gridArea: 'type',
      px: finnoChartCellPadding,
      zIndex: 30,
    },
    '&>#pastPeriod': {
      ...dropdownComponentChildStyles,
      alignSelf: ['flex-end', 'flex-start'],
      gridArea: 'pastPeriod',
      px: finnoChartCellPadding,
      zIndex: 15,
      '.selectBox': {
        flexGrow: 1,
      },
    },
    '&>#currentPeriod': {
      ...dropdownComponentChildStyles,
      gridArea: 'currentPeriod',
      px: finnoChartCellPadding,
      zIndex: 12,
      mb: [6, 6, 6, 0],
      '.selectBox': {
        flexGrow: 1,
      },
    },
    '&>#bankSelector': {
      ...dropdownComponentChildStyles,
      gridArea: 'bankSelector',
      zIndex: 2,
    },
    '&>#loader': {
      gridArea: 'content',
      mx: 'auto',
    },
    '&>#updateStack': {
      gridArea: 'updateStack',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
      height: '1rem',
      width: 'max-content',
      zIndex: 1,
    },
    '&>#downloadButton': {
      gridArea: 'downloadButton',
      display: 'flex',
      justifyContent: 'center',
      height: '1rem',
      maxWidth: 'max-content',
      marginTop: '1rem',
      zIndex: 1,
      placeSelf: 'center',
    },
    '&>#saveButton': {
      gridArea: 'saveButton',
      display: 'flex',
      justifyContent: 'center',
      height: '1rem',
      maxWidth: 'max-content',
      marginTop: '1rem',
      zIndex: 1,
      placeSelf: 'center',
    },
    '&>#chart': {
      gridArea: 'chart',
      width: '100%',
      placeSelf: 'flex-end',
      mb: 5,

      justifyContent: 'flex-start',
      '&>*': {
        width: '100%',
      },
    },
    width: 'fullSize',
    '.closableColorPicker': {
      ml: 3,
      mt: 2,
    },
  },
  error: {
    mt: 4,
  },
};
