import { themeConfig } from '@themed';

export const styles = {
  box: {
    height: '38px',
    borderRadius: '38px',
    bg: ['background', 'background', 'muted'],
    px: 4,
  },
  icon: {
    width: '20px',
    height: '20px',
    color: themeConfig.colors.mutedUi,
  },
  input: {
    variant: 'forms.textInput',
    bg: 'transparent',
  },
};
