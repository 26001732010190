import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { findFirstContentWithButton } from '@types';
import { useLocale } from '../i18n';
import { isInternalLink } from '../links';

export const useFinnoscoreBannerContent = (id: string) => {
  const pageContent = useStrapiPageContent(id);
  const locale = useLocale();

  const buttonContent = findFirstContentWithButton(pageContent?.content);

  const isInternal = isInternalLink(`${buttonContent?.button.link}`);

  return {
    title: pageContent?.title,
    paragraph: pageContent?.description,
    link: isInternal
      ? `/${locale}/${buttonContent?.button.link}`
      : buttonContent?.button.link,
    target: isInternal
      ? '_self'
      : '_blank',
    buttonText: buttonContent?.button.title,
  };
};
