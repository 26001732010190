import React from 'react';
import { ChildrenProps, Dimension } from '@types';

export type UpdatedDimensionProps = [Dimension[] | undefined, ((dimensions?: Dimension[]) => void)];

export const UpdatedDimensionContext = React.createContext<UpdatedDimensionProps>(null as unknown as UpdatedDimensionProps);

export const UpdatedDimensionContextProvider = ({ children }: ChildrenProps) => {
  const [updatedDimensions, setUpdatedDimensions] = React.useState<Dimension[] | undefined>();

  return (
    <UpdatedDimensionContext.Provider value={[updatedDimensions, setUpdatedDimensions]}>{children}</UpdatedDimensionContext.Provider>
  );
};

export const useUpdatedGlobalDimensions = (dimensions?: Dimension[]) => {
  const [updatedDimensions, setUpdatedDimensions] = React.useContext(UpdatedDimensionContext);

  return [updatedDimensions || dimensions, setUpdatedDimensions] as const;
};
export const useUpdatedGlobalDimensionsValue = () => {
  const [updatedDimensions] = React.useContext(UpdatedDimensionContext);
  return updatedDimensions;
};
