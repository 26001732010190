import { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/all';
import { useIsPageLoaded } from '@themed';
import { useIsLegacy } from '@modules';

export function useScrollTriggerRefresh<Props>(animationFunction: (p: Props) => void, props: Props) {
  // we should trigger only this once we have the virtual DOM build and and window object is available:
  const isLoaded = useIsPageLoaded();
  const legacy = useIsLegacy();
  // DIRTY HACK to wait for all previous ScrollTriggers' pins ready
  useEffect(() => {
    if (isLoaded && legacy) {
      setTimeout(() => {
        // NOTE: need to refresh the start & end values due to changes by previous ScrollTrigger::pin
        ScrollTrigger.refresh();
        animationFunction(props);
        // add few ms, to really wait for other items
      }, 100);
    }
  }, [isLoaded, legacy]);
}
