export const styles = {
  blogContainer: {
    mb: [-5, -10],
  },
  wrapper: {
    bg: 'primary',
    height: ['20vh', '35vh'],
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  subtitleWrapper: {
    height: 'inherit',
    width: 'inherit',
    variant: 'styles.wideLayoutMaxWidth',
  },
  subtitle: {
    color: 'background',
    pt: [7, 8],
    pl: 8,
  },
};
