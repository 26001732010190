/** @jsx jsx */
import { BoxProps, jsx } from 'theme-ui';
import React from 'react';
import { RGBColor } from 'react-color';
import { Children } from '@types';
import PercentageContext from '../context/PercentageContext';
import { Monochrome } from './Monochrome';
import { Bar } from './Bar';

interface ChartItemProps extends BoxProps {
  percentage: number;
  fillComponent?: Children;
  defaultColor?: RGBColor;
}

// TODO: fix  <Bar ref={ref as unknown as any} {...props}>
export const ChartItem = React.forwardRef<HTMLDivElement, ChartItemProps>(({
  percentage, children, defaultColor, fillComponent = <Monochrome defaultColor={defaultColor} />, ...props
}, ref) => <PercentageContext.Provider value={percentage}>
  <Bar ref={ref as unknown as any} {...props}>
    {fillComponent}
    {children}
  </Bar>
</PercentageContext.Provider>);
