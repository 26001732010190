import React from 'react';
import { useLocation } from '@reach/router';
import { BoxProps, Box, Paragraph, SubTitle } from '@themed';
import { useFinnoscoreBannerContent } from '@modules';
import { CTAWithOptionalLink, SvgIcon } from '@core';
import { getTypeFromPath } from '@config';
import { styles } from './ReportAdvertLink.styles';

interface Props extends BoxProps {}

export default ({
  sx, ...props
}: Props) => {
  const { pathname } = useLocation();
  const { title, paragraph, link, target, buttonText } = useFinnoscoreBannerContent(`finnoscore-${getTypeFromPath(pathname)}-banner`);

  return (
    <Box {...props} sx={{ ...styles.box, ...sx }}>
      <SubTitle sx={styles.title}>{title}</SubTitle>

      <Paragraph sx={styles.paragraph}>{paragraph}</Paragraph>

      {(link && buttonText) && (
        <CTAWithOptionalLink to={link} target={target} sx={styles.cta}>
          <span className="text">{buttonText}</span>
          <SvgIcon name="tailedArrow" />
        </CTAWithOptionalLink>
      )}
    </Box>
  );
};
