import { bigBubble } from './bubbleSize';

export const styles = {
  section: {
    bg: 'grey.5',
    ul: {
      variant: 'lists.airyListType',
    },
    h2: {
      fontSize: ['s', 'm'],
    },
  },
  image: {
    borderRadius: '50%',
    ...bigBubble,
  },
};
