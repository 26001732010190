import React from 'react';
import { Grid, Text } from 'theme-ui';
import { useSelectedCountry } from '@modules';
import { Institution } from '@types';
import { InstitutionChartRows } from './InstitutionChartRow';
import { useComparisonChartValueChange } from './useComparisonChartValueChange';
import { useRemoveDuplicates } from './useRemoveDuplicates';
import { mixedStyles, styles } from './InternationalComparisonChart.styles';

export type InternationalComparisonChartProps = {
  institutions: Institution[];
  currentInstitution: Institution;
  similarInstitutions: Institution[];
  isMixed?:boolean;
}

export const InternationalComparisonChart = React.forwardRef<HTMLDivElement, InternationalComparisonChartProps>((({ institutions, currentInstitution, similarInstitutions, isMixed=false }, ref) => {
  const { mixedInstitutions } = useRemoveDuplicates({ institutions, similarInstitutions, currentInstitution, isMixed });
  const [
    editableInstitutions,
    setEditableInstitutions,
    editableSimilarInstitutions,
    setEditableSimilarInstitutions,
  ] = useComparisonChartValueChange({ institutions: mixedInstitutions, similarInstitutions, currentInstitution });

  const groupTitle = `${currentInstitution?.name.split(' ')[0]} Group`;
  const selectedCountry = useSelectedCountry();
  const countryTitle = selectedCountry?.name;

  return (
    <React.Fragment>
      {isMixed ? (
        <Grid sx={mixedStyles} ref={ref}>
          <Text sx={{ gridArea: 'countryTitle' }} contentEditable as="h3" suppressContentEditableWarning>{countryTitle} & {groupTitle}</Text>
          <InstitutionChartRows
            sx={{ gridArea: 'countryInstitutions' }}
            institutions={editableInstitutions}
            currentInstitution={currentInstitution}
            valueChangeHandler={setEditableInstitutions}
            shouldRenderCountryCode
          />
        </Grid>
      ):(
        <Grid sx={styles} ref={ref}>
          <Text sx={{ gridArea: 'countryTitle' }} contentEditable as="h3" suppressContentEditableWarning>{countryTitle}</Text>
          <Text sx={{ gridArea: 'groupTitle' }} contentEditable as="h3" suppressContentEditableWarning>{groupTitle}</Text>
          <InstitutionChartRows
            sx={{ gridArea: 'countryInstitutions' }}
            institutions={editableInstitutions}
            currentInstitution={currentInstitution}
            valueChangeHandler={setEditableInstitutions}
          />
          <InstitutionChartRows
            sx={{ gridArea: 'groupInstitutions' }}
            institutions={editableSimilarInstitutions}
            currentInstitution={currentInstitution}
            valueChangeHandler={setEditableSimilarInstitutions}
            shouldRenderCountryCode
          />
        </Grid>
      )}
    </React.Fragment>
  );
}));
