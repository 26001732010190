import { parseDate } from '../../string';

type BasicGQLParam = string | number | null | undefined; // TODO: how shall we treat boolean?
type ExtendedGQLParam = BasicGQLParam | Date;
const isDate = (value: ExtendedGQLParam): value is Date => (typeof value === 'object' && value?.constructor?.name === 'Date');

export type FormatParam = (where: string, value: ExtendedGQLParam, name: string) => string;
const defaultFormatParam: FormatParam = (where, value, name) => `${where ? `${where}, ` : ''}${value ? `${name}: "${value}"` : ''}`;

type ResolverBuilder<T> = (name: string, value: T, formatter?: FormatParam) => ResolverFunction;

interface GqlQueryBuilderResult {
  where: string;
  extraFlags: string;
}

export const applyStrapiQueryParam: ResolverBuilder<BasicGQLParam> = (name, value, formatter = defaultFormatParam) => ({ where, ...query }) => ({
  ...query,
  where: formatter(where, value, name),
}) as GqlQueryBuilderResult;

export const applyStrapiQueryDate: ResolverBuilder<ExtendedGQLParam> = (name, value, formatter = defaultFormatParam) => (query) => {
  try {
    const dateValue = isDate(value) ? value : parseDate(`${value}`);
    return ({
      ...query,
      where: formatter(query.where, dateValue.toISOString().substring(0, 10), name),
      extraFlags: `, publicationState: PREVIEW${query.extraFlags}`,
    });
  } catch (e) {
    console.error(`applyStrapiQueryDate failed to parse ${name}='${value}' as Date`, e);
    return query;
  }
};

type ResolverFunction = (query: GqlQueryBuilderResult) => GqlQueryBuilderResult;

export type QueryTemplate = (query: GqlQueryBuilderResult) => string;
export const defaultQueryTemplate: QueryTemplate = ({ extraFlags, where }) => `${extraFlags}${where ? `, where: { ${where} }`: ''}`;
export const excludedExtraFlagsQueryTemplate: QueryTemplate = ({ where }) => `${where ? `, where: { ${where} }`: ''}`;

export const strapiQueryBuilder = (query: string, params: ResolverFunction[], template = defaultQueryTemplate) => {
  const { where, extraFlags } = params.reduce(
    (currentQuery, resolver) => resolver(currentQuery),
    { where: query, extraFlags: '' } as GqlQueryBuilderResult,
  ) as GqlQueryBuilderResult;
  return template({ where, extraFlags });
};

/* helpers mappers for shorter api */

export const applyAllStrapiQueryParams = (params?: {[key: string]: ExtendedGQLParam}) => (params ? Object
  .entries(params)
  .filter(([, value]) => !!value) // consider only if not nullish
  .map(([name, value]) => {
    if (name === 'date') return applyStrapiQueryDate('date_lte', value);
    return applyStrapiQueryParam(name, `${value}`);
  }) : []);
