/** @jsx jsx */
// import { TabSelector } from '@core';
import { navigate } from 'gatsby';
import React from 'react';
import { ALL_COUNTRIES, SelectedCountryContext, useInstitutionOptionList } from '@modules';
import { jsx, BoxProps } from '@themed';
import { FsType, Institution } from '@types';
import { SegmentedControl } from '@core';

interface Props extends BoxProps {
  institutions?: Institution[];
  fsType?: FsType;
}

export default ({ institutions, fsType, ...props }: Props) => {
  const { country } = React.useContext(SelectedCountryContext);

  const topInstitution = country.countryCode === ALL_COUNTRIES ? institutions?.[0] : institutions?.filter(i => i.country.countryCode === country.countryCode)[0];
  const options = useInstitutionOptionList(topInstitution, fsType);

  return (
    <SegmentedControl
      {...props}
      items={options}
      onChange={({ value }) => navigate(`${value}`)}
    />
  );
};
