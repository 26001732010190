import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import React from 'react';
import { Box, Flex } from 'theme-ui';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NavigationItem } from '@config';
import { themeConfig } from '@themed';
import { NavBarItem, NavbarProps, desktopStyles } from './Navbar';
import './MobileNavbar.css';

const mobileStyles = (length:number, idx:number) => ({
  ...desktopStyles(length, idx),
  mt: 3,
  pb: 3,
  borderBottom: ['1px solid #01ad53', 0],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const iconStyles = {
  my: 'auto',
};

const isSubmenu = (items:NavigationItem[], currentItems:NavigationItem[]) => items?.[0]?.id !== currentItems?.[0]?.id;

export const MobileNavbar = ({ items }: NavbarProps) => {
  const [menuItems, setMenuItems] = React.useState(items);

  return (
    <TransitionGroup>
      <CSSTransition
        key={JSON.stringify(menuItems)}
        classNames={items !== menuItems ? 'right-to-left' : 'left-to-right'}
        timeout={1000}
      >
        <Box sx={{ height: '300px' }}>
          {isSubmenu(items, menuItems) && <IoIosArrowBack color={themeConfig.colors.background} size={30} onClick={() => setMenuItems(items)} />}
          {menuItems.map((item, idx) => (
            <Flex sx={mobileStyles(items.length, idx)} key={`${item.id}_${idx}`} onClick={() => item?.items && setMenuItems(item?.items)}>
              <NavBarItem {...item} />
              {item?.items && <IoIosArrowForward sx={iconStyles} color={themeConfig.colors.background} size={25} onClick={() => setMenuItems(items)} />}
            </Flex>
          ))}
        </Box>
      </CSSTransition>
    </TransitionGroup>
  );
};
