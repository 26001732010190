/** @jsx jsx */
import React, { ReactElement, useState } from 'react';
import { Box, BoxProps, Flex } from 'theme-ui';
import { IoClose, IoChevronForwardOutline } from 'react-icons/io5';
import { jsx } from '@themed';
import { UnorderedList, UnorderedListItem } from './UnorderedList';
import { SectionProps } from './Section';
import { useStyles } from './ContentBar.styles';

interface ContentBarProps extends BoxProps {
  items?: UnorderedListItem[];
}

function ContentBar({ items, ...props }:ContentBarProps) {
  const [open, setOpen] = useState(true);
  const styles = useStyles(open);

  if (items && items.length > 3) {
    return (
      <Box {...props} id="contentBar" sx={{ ...styles.contentBar, ...props.sx }}>
        <Flex sx={styles.contentBarHeader} onClick={() => setOpen(!open)}>
          {open && <IoClose size={30} color="black" />}
          {!open && <IoChevronForwardOutline color="white" size={30} />}
        </Flex>
        <UnorderedList items={items} sx={styles.contentList} onClick={() => setOpen(false)} />
      </Box>
    );
  }
  return null;
}
interface SectionListWithContentBarProps extends BoxProps {
  children?: ReactElement<SectionProps>[];
  links: {
    id: string;
    text: string;
    icon: JSX.Element;
    href: string;
  }[] | undefined
}

export const SectionListWithContentBar = ({ links, children, ...props }: SectionListWithContentBarProps) => {
  const sections = children ? React.Children.map(children, ({ props: { id, subTitle, icon } }) => ({ id, icon, text: subTitle, href: `#${id}` })) : links;
  return (
    <React.Fragment>
      <ContentBar {...props} items={sections} />
      {children}
    </React.Fragment>
  );
};
