import React from 'react';
import { Box, Flex, Grid, Link } from 'theme-ui';
import { CTAButton } from '@themed';
import { PageMeta } from '@types';
import { __ } from '@modules';
import { styles } from '../digital-experience/PDFForm.styles';
import { ContentMapper } from '../digital-experience/PDFForm';
import { ClutchReviews } from '../landing/ClutchWidget';
import { findFirstContentWithImageList } from '../../../types/Article';
import { developmentReferenceStyles, developmentReviewStyles, developmentTextStyles } from './Development.styles';
import { Reference } from './DevelopmentReference';
import { FloatingCaseStudyLogos } from './FloatingCaseStudyLogos';

export interface PageMetaProps{
  content: PageMeta | undefined;
}

export const Development = ({ content }: PageMetaProps) => (
  <Grid id="nearshoring" sx={styles()}>
    <Box>
      <ContentMapper sx={developmentTextStyles} content={content?.content?.[0]} />
      <Flex sx={developmentReviewStyles}>
        <Link href="https://clutch.co/profile/finnoconsult-gmbh#portfolio">
          <CTAButton>{__('nearshoring.development.clutchBtn')} ↗</CTAButton>
        </Link>
        <ClutchReviews />
      </Flex>
    </Box>
    <Box sx={developmentReferenceStyles}>
      <FloatingCaseStudyLogos />
      <Reference {...{
        id: 'von-null-zak',
        title: 'Von Null auf Zak',
        thumbnails: findFirstContentWithImageList(content?.content)?.images,
      }}
      />
    </Box>
  </Grid>
);
