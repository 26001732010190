/** @jsx jsx */
import { jsx, Flex, Button } from 'theme-ui';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { CarouselBox } from '@composite';
import { Paragraph } from '../core/ui';
import { tabSelectorItemStyles, tabSelectorStyles } from './TabCarousel.styles';

interface TabSelectorItemProps {
  selected: boolean;
  title: string;
  onSelect: () => void;
}

export const TabSelectorItem = ({ selected, title, onSelect }: TabSelectorItemProps) => <Button
  sx={tabSelectorItemStyles(selected)}
  onClick={onSelect}
>
  <Paragraph>{title}</Paragraph>
</Button>;

interface TabSelectorProps {
  titles: string[];
  selectedIndex: number;
  onSelect: (index: number) => void;
}

export const TabSelector = ({ titles, selectedIndex, onSelect }: TabSelectorProps) => {
  if (titles.length <= selectedIndex) {
    throw new Error('TabSelector: selected index can\'t be bigger than titles length ');
  }

  return <Flex sx={tabSelectorStyles.box}>
    {titles.map((title, index) => <TabSelectorItem
      key={title}
      title={title}
      selected={index === selectedIndex}
      onSelect={() => onSelect(index)}
    />)}
  </Flex>;
};

interface Props {
  defaultSelectedIndex?: number;
  children: React.ReactChild[];
  titles: string[];
}

export const TabbedCarousel = ({ defaultSelectedIndex, children, titles }: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelectedIndex || 0);

  return <CarouselBox>
    <Flex variant="flex.colCenter">
      <TabSelector
        titles={titles}
        selectedIndex={selectedIndex}
        onSelect={index => setSelectedIndex(index)}
      />
      <Carousel
        selectedItem={selectedIndex}
        swipeable={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
      >
        {children}
      </Carousel>
    </Flex>
  </CarouselBox>;
};
