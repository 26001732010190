/** @jsx jsx */
import React from 'react';
import { Box, jsx, SxProps, Text } from 'theme-ui';
import { SvgIcon } from '@core';
import { useTranslate } from '@modules';
import { ChildrenProps } from '@types';
import { styles } from './BubbleScore.styles';

interface BubbleScoreProps extends ChildrenProps, SxProps{
  logo?: string;
  score?: number;
  inverted?:boolean;
}

export const BubbleScore = React.forwardRef<HTMLDivElement, BubbleScoreProps>(({
  children, logo, score, ...props
}, ref) => {
  const __ = useTranslate();

  return (
    <Box className="bubble" ref={ref} {...props} sx={{ ...styles.bubble, ...props.sx }}>
      {logo && <SvgIcon className="logo" name={logo} alt={__('navbar.logoAlt')} sx={styles.logo} />}
      {score && <Text contentEditable suppressContentEditableWarning sx={styles.text}>{score.toFixed(2)}</Text>}
      {children}
    </Box>
  );
});
