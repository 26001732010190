import { SxStyleProp } from 'theme-ui';

export const styles = {
  box: {
    width: '100%',
    padding: '12px',
    backgroundColor: 'background',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.1)',
    display: ['flex', 'flex', 'none'],
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    left: 0,
    right: 0,
  } as SxStyleProp,
  titleButton: {
    width: '100%',
  },
  titleBox: {
    width: '100%',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 'xs',
    span: {
      display: 'inline',
    },
    color: 'primary',
  },
  paragraph: {
    fontSize: 'xs',
    span: {
      display: 'inline',
    },
  },
  content: (isOpen: boolean) => ({
    display: isOpen ? 'block' : 'none',
  } as SxStyleProp),
  openArrow: {
    svg: {
      path: {
        fill: 'primary',
      },
      height: '14px',
      transform: 'rotateZ(-90deg)',
    },
  },
  closeIcon: {
    svg: {
      path: {
        fill: 'text',
      },
      height: '16px',
    },
  },
  link: {
    color: 'background',
  },
};
