import React, { useEffect } from 'react';
import { Dimension, DimensionKey, Institution } from '@types';
import { dimensionCheckHandler } from '../../helpers';

export const generateDefaultDimensions = (dimensions?:Dimension[]) => dimensions?.slice(0, 6).map((dim) => dim.key);

function useDimensionDropDownControl(institution?:Institution | null) {
  const [dimensionKeys, setDimensionKeys] = React.useState<DimensionKey[] | undefined>(generateDefaultDimensions(institution?.dimensions));

  useEffect(() => {
    if (institution) {
      setDimensionKeys(generateDefaultDimensions(institution?.dimensions));
    }
  }, [institution]);

  const checkHandler = (e:React.ChangeEvent<HTMLInputElement>) => dimensionKeys && dimensionCheckHandler({ dimensionKey: e.target.value as DimensionKey, selectedDimensions: dimensionKeys, setter: setDimensionKeys });

  return {
    dimensionKeys,
    onCheck: checkHandler,
    loading: dimensionKeys && dimensionKeys.length < 1,
  };
}

export default useDimensionDropDownControl;
