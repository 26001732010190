import { SxStyleProp } from 'theme-ui';

export const styles = {
  section: {
    variant: 'grids.ContactScene',
    backgroundColor: 'primary',
    justifyContent: 'flex-start',
    flexDirection: 'column !important',
    position: 'relative',
    minHeight: 'unset !important',
    zIndex: 4,
    '& .content': {
      margin: 0,
    },
  },
  contentBox: {
    variant: 'grids.CompactSectionContentPadding',
    flexDirection: 'column',
    flex: '1 1 auto',

    width: '100%',
    textAlign: 'left',
  },
  icon: {
    variant: 'images.pureCircleIcon',
    mx: 'auto',
    my: 4,
  },
  title: {
    color: 'background',
  },
  paragraph: {
    fontSize: ['xs', 'xs', 's'],
  },
  form: {
    variant: 'flex.col',
    width: '100%',
    '&>*': {
      my: t => `${t.sizes[4]} !important`,
    } as SxStyleProp,
  },
  emailInput: {
    variant: 'forms.textInput',
    width: '100%',
  },
  submit: {
    mx: '0 !important',
  },
  labelInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  } as SxStyleProp,
  checkboxWithLabel: {
    gridTemplateColumns: '0.1fr 1fr',
    placeItems: 'center',
    svg: {
      height: 6,
      width: 6,
    },
    '.checkbox-paragraph': {
      fontSize: 'xxs',
    },
  },
};
