import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import { __tr, __selectAll } from '@modules';
import { withAnimation, scrub } from '@core';

export interface AnimateListProps {
  triggerRef: () => React.RefObject<HTMLDivElement>;
  headerSelector?: string;
  listTagSelector?: string;
  listItemTagSelector?: string;
}

export const animateList = ({
  triggerRef,
  headerSelector = 'h2',
  listTagSelector = 'ul',
  listItemTagSelector = 'li',
}: AnimateListProps) => {
  gsap.registerPlugin(ScrollTrigger);
  const trigger = __tr(triggerRef);

  if (trigger) {
    __selectAll(headerSelector, trigger).forEach(h2 => ScrollTrigger.create({
      trigger: h2,
      // markers: process.env.NODE_ENV === 'development',
      start: () => 'top 60%',
      end: () => 'top 50%',
      ...scrub(),
      // invalidateOnRefresh: true,
      animation: gsap.timeline()
        .from(h2, {
          x: -10,
          opacity: 0,
          duration: 0.5,
        }),
    }));
    __selectAll(listTagSelector, trigger).forEach(ul => ScrollTrigger.create({
      trigger: ul,
      // markers: process.env.NODE_ENV === 'development',
      start: () => 'top 70%',
      end: () => 'top 30%',
      ...scrub(),
      // invalidateOnRefresh: true,
      animation: gsap.timeline()
        .from(__selectAll(listItemTagSelector, ul), {
          opacity: 0,
          stagger: 0.15,
        }),
    }));
  }
};

export const useAnimationList = (props: AnimateListProps) => withAnimation<AnimateListProps>(animateList, props);
