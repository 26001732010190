import React from 'react';
import { Box, Flex, SxProps } from 'theme-ui';
import { Dimensions } from '@types';
import ChartLabel from '../BasicComponents/ChartLabel';
import { styles } from './SimpleLabelComponent.styles';

interface SimpleLabelComponentProps extends SxProps {
  dimension: Dimensions;
}

export const SimpleLabelComponent = React.forwardRef<HTMLDivElement, SimpleLabelComponentProps>(({ dimension }, ref) => (
  <Box ref={ref}>{dimension.map((d) => (
    <Flex key={d.key} className="label" sx={styles.label}>
      <ChartLabel sx={styles.labelText}>{d.key}</ChartLabel>
    </Flex>
  ))}
  </Box>
));
