import { SxStyles } from '@types';

export const sectionStyles = {
  // these are pixels, because react-icons don't take sx prop
  icon: {
    width: '24px',
    marginRight: '8px',
    height: 'auto',
  },
};

export const defaultSectionStyles = {
  text: {
    fontWeight: 'bold',
  },
};

export const sectionedListStyles: SxStyles = {
  sectionWithItems: {
    mx: 4,
    mt: 3,

    pb: 2,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: 'mutedUi',
  },
};
