import React from 'react';
import { navigate } from 'gatsby';
import { Box, Button, Text } from 'theme-ui';
import { SubTitle, SvgIcon, SxProps } from '@core';
import { styles } from './EmailSent.styles';

interface EmailSentProps extends SxProps {
  locale: string
}

export const EmailSent = ({ locale, sx }:EmailSentProps) => (
  <Box sx={{ ...styles.formStyles, ...sx }}>
    <SvgIcon sx={styles.icon} name="mail" />
    <SubTitle>Email sent</SubTitle>
    <Text>It can take a few minutes to receive your password recovery link.</Text>
    <Text>If you do not receive this link, please contact your administrator.</Text>
    <Button onClick={() => navigate(`/${locale}/charts/login`)}>Sign in</Button>
  </Box>
);
