export const styles = {
  motto: {
    variant: 'flex.colCenter',
    flex: '0 0 auto',
    width: 'auto',
    ml: [0, 0, 6, 9],
    mr: [0, 0, 6, 9],
    mt: 'auto',
    mb: 'auto',
    letterSpacing: '0.1em',
    fontSize: 'xxl',
    position: 'relative',

    img: {
      // position: 'absolute',
      // zIndex: 3,
    },

    '&>*': {
      display: 'block',
    },
    del: {
      display: 'none', // hide all element, as we animate with gif
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    'del:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      mt: 6,
      borderBottom: 'thick',
      borderBottomWidth: 3,
      borderColor: 'primary',
    },
    ins: {
      display: 'none', // hide all element, as we animate with gif
      fontFamily: '"Lucida Handwriting", "Snell Roundhand", sans-serif',
      fontWeight: 'bold',
      color: 'primary',
    },
  },
  videoContainer: {
    width: 'fullSize',
    flexDirection: ['column', 'row'],
    m: 'auto',
    '&>*': {
      width: 'auto',
      minWidth: 'unset',
    },
  },
  videoPlayer: {
    maxWidth: ['unset', '308px'],
    maxHeight: ['unset', '545px'],
    width: '100%',
  },
  image: {
    width: '100%',
    maxWidth: '500px',
  },
};
