import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { TriggerElementProps } from '@types';
import {
  __el, __tr, __rect, __select, __selectAll,
} from '@modules';
import { maxScale, scrub, withAnimation } from '@core';
import { getBreakpoint, themeConfig } from '@themed';

interface AnimateFinniRevealCaseTeasersProps extends TriggerElementProps {
  finni: () => React.RefObject<HTMLDivElement>;
}

export const animateFinniRevealCaseTeasers = ({
  triggerRef, finni,
}: AnimateFinniRevealCaseTeasersProps) => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);

  const buttonAnim = gsap.timeline({
    repeat: -1,
  })
    // start mouth open
    .to(__el(finni), { scale: 1.05, ease: 'sine.in' })
    .to(__el(finni), { scale: 1, ease: 'sine.in' })
    .duration(1);

  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];

  const {
    x, y, width, height,
  } = __rect(__el(finni));
  const scale = maxScale(x, y + 1.5 * window.innerHeight, width, height); // need to add 1 more page Height, as it is translating that much upwards, with smooth scrollbar

  const isMobileMaxWidth = window.innerWidth <= themeConfig.sizes.mobileMaxWidthInPx;
  const start = isMobileMaxWidth ? '300px bottom' : 'top bottom';

  const pictures = __selectAll('picture', triggerRef);
  const trigger = __tr(triggerRef);

  scrollers.push(ScrollTrigger.create({
    trigger,
    start,
    end: 'top top',
    toggleActions: 'restart none reverse none',
    invalidateOnRefresh: true,
    ...scrub(),
    onEnter: () => buttonAnim.pause(),
    // markers: process.env.NODE_ENV === 'development',
    animation: gsap.timeline()
      .set(__el(triggerRef), {
        background: 'none',
      })
      .to(__el(finni), {
        scale,
        duration: 1,
      })
      .to(__select('svg', finni), {
        opacity: 0,
        scale: 1/scale, // the icon should be remaining small inside the container which is being scaled up
        duration: 0.5,
      }, '<')
      .set(__el(triggerRef), {
        background: themeConfig.colors.primary,
      })
      .set(__el(finni), {
        display: 'none',
      }),
  }));

  const initScrollrs = () => {
    if (!trigger) return;

    if (pictures && pictures.length) {
      scrollers.push(ScrollTrigger.create({
        trigger: __tr(triggerRef),
        start: 'top bottom',
        end: 'top top',
        toggleActions: 'restart none reverse none',
        ...scrub(),
        invalidateOnRefresh: true,
        markers: process.env.NODE_ENV === 'development',
        animation: gsap.timeline()
        // TODO: don't do this step for mobile
          .to(pictures, {
            opacity: 0,
            duration: 0.001,
          })
          .to(pictures[0], {
            opacity: 1,
            duration: 0.5,
          }, '<+0.5'),
      }));
    }

    const pictureTl = gsap.timeline();
    const pictures2Move = Array.from(pictures).slice(0, pictures.length-1);

    pictures2Move.forEach((picture, index) => {
      const distance = MotionPathPlugin.getRelativePosition(picture, pictures[index+1]);
      // const distance = { y: Math.floor(distance0.y), x: Math.floor(Math.abs(distance0.x)) };
      pictureTl
      // reveal new icon
        .to(picture, {
          opacity: 1,
          duration: 0.01,
        }, '<-0.02');
      if (index) {
      // stay with icon a bit longer
        pictureTl
          .to(picture, { duration: 0.1 });
      }
      // move icon to the next location
      pictureTl
        .to(picture, {
          motionPath: {
            path: [{ x: 0, y: 0 }, { x: distance.x*0.7, y: distance.y*0.03 }, { x: distance.x*0.9, y: distance.y*0.3 }, distance],
            type: 'cubic',
          },
          duration: 0.1,
        })
      // hide icon
        .to(picture, {
          opacity: 0,
          duration: 0.01,
        });
    });

    const lastPicture = pictures[pictures.length-1];

    if (lastPicture) {
      pictureTl
        .to(lastPicture, {
          opacity: 1,
          duration: 0.01,
        }, '<-0.02')
        .to(lastPicture, {
          duration: 0.6,
        });
    }

    scrollers.push(ScrollTrigger.create({
      trigger: __tr(triggerRef),
      start: 'top top',
      end: () => 'bottom top',
      toggleActions: 'restart none reverse none',
      ...scrub(),
      markers: process.env.NODE_ENV === 'development',
      animation: pictureTl,
      invalidateOnRefresh: true,
      onRefresh: () => pictureTl.invalidate(), // real distance for pictures2Move is calculated by MotionPathPlugin.getRelativePosition which we need to force on resize
    }));
  };
  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(0)})`]: initScrollrs,
  });
};

export const useAnimationFinniRevealCaseTeasers = (props: AnimateFinniRevealCaseTeasersProps) => withAnimation<AnimateFinniRevealCaseTeasersProps>(animateFinniRevealCaseTeasers, props);
