export const referenceStyles = {
  ml: [0, 0, '20%', 0, '20%'],
  height: '100%',
  maxHeight: '600px',
  '& *': {
    maxHeight: ['auto', 'auto', 'auto', 'auto', '400px'],
  },
  '.shadow': {
    display: 'none',
  },
};
