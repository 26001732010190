/** @jsx jsx */
import React from 'react';
import { BoxProps, Grid, Box, jsx, SxStyleProp, Flex } from '@themed';
import { ChildrenProps, FsType } from '@types';
import { OptionalLink } from '../core/ui';
import { Carousel } from './Carousel';
import { LogoMapper } from './digital-experience/LogoMapper';
import { landingStyles, styles } from './Press.styles';
import { groupMatrix, usePressLogosFromPageMeta } from './usePressLogos';

interface PressProps extends BoxProps {
  gridSx?: SxStyleProp;
  fsType?: FsType;
}

interface OptionalLinkComponent extends ChildrenProps {
  to?: string;
  showLink?: boolean;
}
const OptionalLinkComponent = ({ children, to, showLink, ...props }: OptionalLinkComponent) => (
  <OptionalLink {...props} className="link" to={showLink ? to : undefined} target="_blank">
    {children}
  </OptionalLink>
);

export const BankPress = ({ fsType, ...props }: PressProps) => {
  const [showLink, setShowLink] = React.useState(true);
  const logos = usePressLogosFromPageMeta(fsType);

  if (!logos) return null;

  const logosMatrix = groupMatrix(logos, 4);

  return (
    <Box {...props} sx={styles.box} data-testid="press">
      <Carousel
        autoPlay
        arrow
        interval={2000}
        stopOnHover
        onSwipeMove={() => { setShowLink(false); return true; }}
        onSwipeEnd={() => setShowLink(true)}
      >
        {logosMatrix.map((logo, i) => (
          <Grid key={logos[i].link} sx={styles.logoColumn}>
            <LogoMapper logos={logo} linkComponent={OptionalLinkComponent} showLink={showLink} />
          </Grid>
        ))}
      </Carousel>
    </Box>
  );
};

export const LandingPress = ({ fsType, ...props }: PressProps) => {
  const logos = usePressLogosFromPageMeta(fsType);

  if (!logos) return null;

  return (
    <Box {...props} sx={styles.box} data-testid="press">
      <Flex sx={landingStyles}>
        <LogoMapper logos={logos} />
      </Flex>
    </Box>
  );
};
