import React, { useRef } from 'react';
import { SectionWithContent } from '@core';
import { Box, Grid } from '@themed';
import { BubbleHistoryComparisonChart, ExportButton } from '../components';
import { ClosableColorPicker, useColorAtIndex } from '../../colorpicker';
import { finnoChartStyles as gridStyles } from '../components/index.styles';
import { DefaultChartData } from '../helpers';
import { useTypeDropDownControl } from '../components/control/useTypeDropDownControl';
import { usePeriodDropDownControl } from '../components/control/usePeriodDropDownControl';
import { useInstitutionDropDownControl } from '../components/control/useInstitutionDropDownControl';
import { ChartDropDownHeader } from '../ChartDropDownControl';
import { ChartDropDownControlComponent } from '../components/control/ChartDropDownControl';
import { InstitutionDropDownWithState } from '../../finnoscore-comparison/InstitutionGroupedDropDown';
import { Monochrome } from '../components/BasicComponents/Monochrome';
import { fillColorStyles } from '../components/BasicComponents';
import { ChartWizardStep } from './ChartWizardStep';

export const HistoryChart = () => {
  const exportChartRef = useRef(null);
  const [colorA, setColorA] = useColorAtIndex(0);
  const [colorB, setColorB] = useColorAtIndex(1);

  const { types, setTypeId, typeId, type: fsType, isLoading: isTypeLoading } = useTypeDropDownControl();
  const { periods: pastPeriodList, period: pastPeriod, periodId: pastPeriodId, setPeriodId: setFirstPeriodId, isLoading: isFirstPeriodLoading } = usePeriodDropDownControl(fsType);
  const { periods: currentPeriodList, period: currentPeriod, periodId: currentPeriodId, setPeriodId: setcurrentPeriodId } = usePeriodDropDownControl(fsType);

  const { institutions, institution, institutionSetHandler, isLoading: isInstitutionLoading } = useInstitutionDropDownControl(fsType, currentPeriod);
  const { institutions: pastInstitutionList, isLoading: isPastInstitutionLoading } = useInstitutionDropDownControl(fsType, pastPeriod);
  const pastInstitution = pastInstitutionList?.find(i => i.name === institution?.name && i.country?.countryCode === institution?.country?.countryCode);

  return (
    <SectionWithContent>
      <Grid sx={gridStyles.content}>

        <ChartWizardStep id="type" label="Type" isLoading={isTypeLoading}>
          <ChartDropDownControlComponent
            zIndex={20}
            list={types}
            selectedId={typeId}
            setter={setTypeId}
          >
            <ChartDropDownHeader title={fsType?.text || 'Choose a type'} />
          </ChartDropDownControlComponent>
        </ChartWizardStep>

        {fsType && (
          <ChartWizardStep id="pastPeriod" label="Relative period" isLoading={isFirstPeriodLoading}>
            <ChartDropDownControlComponent
              zIndex={15}
              list={pastPeriodList}
              selectedId={pastPeriodId}
              setter={setFirstPeriodId}
            >
              <ChartDropDownHeader title={pastPeriod?.text || 'Choose a period'}>
                <Box onClick={e => e.stopPropagation()}><ClosableColorPicker defaultColor={colorA} setColorHandler={setColorA} /></Box>
              </ChartDropDownHeader>
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {fsType && (
          <ChartWizardStep id="currentPeriod" label="Current period" isLoading={isFirstPeriodLoading} loading={null}>
            <ChartDropDownControlComponent
              zIndex={10}
              list={currentPeriodList}
              selectedId={currentPeriodId}
              setter={setcurrentPeriodId}
            >
              <ChartDropDownHeader title={currentPeriod?.text || 'Choose a period'}>
                <Box onClick={e => e.stopPropagation()}><ClosableColorPicker defaultColor={colorB} setColorHandler={setColorB} /></Box>
              </ChartDropDownHeader>
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {fsType && pastPeriod && currentPeriod && (
          <ChartWizardStep id="bankSelector" label="Institution" isLoading={isInstitutionLoading || isPastInstitutionLoading}>
            <InstitutionDropDownWithState
              institutions={institutions}
              onSelect={institutionSetHandler}
              selectedInstitution={institution}
              fillComponent={<Monochrome sx={fillColorStyles(colorB)} />}
            />
          </ChartWizardStep>
        )}

        {institution && pastInstitution && (
          <Box id="downloadButton">
            <ExportButton element={exportChartRef.current} type="chart" name={`history.${institution.name}`} />
          </Box>
        )}

        <Box id="chart" variant={institution && pastInstitution ? undefined : 'styles.sampleChart'}>
          <BubbleHistoryComparisonChart
            currentInstitute={institution || DefaultChartData.sampleRelative}
            pastInstitute={pastInstitution || DefaultChartData.sample}
            currentYear={currentPeriod?.text?.split('-')[0]}
            pastYear={pastPeriod?.text?.split('-')[0]}
            ref={exportChartRef}
          />
        </Box>

      </Grid>

    </SectionWithContent>
  );
};
