/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Image, Layout, SEO, useMainArticleImageUrl, formatPageMetaOgImage } from '@core';
import { CaseStudy } from '@types';
import { ContactSection } from '../ContactSection';
import { Post } from '../common/Post';
import { SlugContext } from '../../core/layout/SlugContext';
import { styles } from './PostDetails.styles';

interface PostDetailsProps{
  pageContext: CaseStudy
}

const PostDetails = ({ pageContext }: PostDetailsProps) => {
  const {
    ...props
  } = pageContext;
  const primaryImage = useMainArticleImageUrl(pageContext);
  return (
    <SlugContext.Provider value={pageContext.slugs}>
      <Layout>
        <SEO
          title={props.title}
          description={props.subtitle}
          meta={formatPageMetaOgImage(primaryImage)}
        />
        <Post
          {...props}
          footer={(
            <ContactSection id="casestudies.footer" variant="grids.CaseStudyContactScene" sx={styles.contact}>
              <Image src="/images/pacman-white_2x.gif" alt="pacman animation" sx={styles.image} />
            </ContactSection>
          )}
        />
      </Layout>
    </SlugContext.Provider>
  );
};

export default PostDetails;
