/** @jsx jsx */
import React from 'react';
import { Box, jsx } from '@themed';
import { useColors } from '@composite';
import { Institution } from '@types';
import { InstitutionGroupedDropDown } from 'src/components/composite/finnoscore-comparison/InstitutionGroupedDropDown';
import { Header } from 'src/components/composite/finnoscore-comparison/Charts';
import { containerStyles } from '../BubbleComparison/BubbleInstitutionComparisonChart.styles';
import { ComparisonChartWithLabel } from '../BasicComponents/ComparisonChartWithLabel';

interface Props{
  pastInstitute: Institution,
  currentInstitute: Institution
  pastYear?: string;
  currentYear?: string;
}
export const BubbleHistoryComparisonChart = React.forwardRef<HTMLDivElement, Props>(({
  pastInstitute, currentInstitute,
}, ref) => {
  const [[pastColor, currentColor]] = useColors();

  return (
    <Box sx={containerStyles} ref={ref}>

      <Box id="institutionDropdown">
        <InstitutionGroupedDropDown
          isOpen={false}
          disabled
          setIsOpen={() => console.log()}
          onSelect={() => console.log()}
          selectedInstitution={pastInstitute}
          institutionColor={pastColor}
        />
        <InstitutionGroupedDropDown
          isOpen={false}
          disabled
          setIsOpen={() => console.log()}
          onSelect={() => console.log()}
          selectedInstitution={currentInstitute}
          institutionColor={currentColor}
        />
      </Box>

      <ComparisonChartWithLabel
        id="chart"
        dimension={currentInstitute.dimensions}
        relativeDimension={pastInstitute.dimensions}
      >
        <Header sx={{ background: 'none !important' }} />
      </ComparisonChartWithLabel>
    </Box>
  );
});
