import { compactSectionContentPadding } from '@themed';

export const styles = {
  footerContainer: {
    bg: 'primary',
    height: 'footerHeight',
    overflow: 'hidden',
  },
  links: {
    variant: 'styles.layoutMaxWidth',
    padding: compactSectionContentPadding,
    pt: '0 !important',
    pb: '0 !important',
    width: 'fullSize',
    flexWrap: 'wrap',
    m: '0 auto',
    justifyContent: ['center', 'flex-start'],
    gap: 3,
    '&, a': {
      color: 'background',
      fontWeight: 'normal',
      fontSize: ['xxxs', 'xxs'],
    },
    a: {
      pl: 4,
    },
    'a:hover': {
      textDecoration: 'underline',
    },
  },
};
