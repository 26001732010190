/** @jsx jsx */
import { useLocation } from '@reach/router';
import { useTranslate } from '@modules';
import { jsx, Flex, Text } from '@themed';
import { getTypeFromPath } from '@config';
import { styles } from './DesktopInstitutionHeader.styles';
import { useInstitutionItemLayoutStyles } from './InstitutionItem.styles';

export const DesktopInstitutionHeader = () => {
  const __ = useTranslate();
  const { pathname, search } = useLocation();
  const layoutStyles = useInstitutionItemLayoutStyles();
  const type = getTypeFromPath(`${pathname}${search}`);

  return (
    <Flex sx={{ ...layoutStyles.link, ...styles.header }}>
      <Text sx={{ ...styles.cell, gridArea: 'rank' }}>{__('finnoscore.list.rank')}</Text>
      <Text sx={{ ...styles.cell, gridArea: 'logo' }}>{__(`finnoscore.list.${type}`)}</Text>
      <Text sx={{ ...styles.cell, gridArea: 'country', textAlign: 'left' }}>{__('finnoscore.list.country')}</Text>
      <Text sx={{ ...styles.cell, gridArea: 'trend' }}>{__('finnoscore.list.finnoscore')}</Text>
    </Flex>
  );
};
